import axios from 'axios';

export const fetchProducts = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/fetchproducts`, {})
        .then(res => { return res.data.data.filter(product => JSON.parse(product.variants)[0].stock != 0); })
        .catch(err => { return err; })
};

export const fetchCategories = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/fetchcategories`, {})
        .then(res => { return res.data.data; })
        .catch(err => { return err; })
};

export const fetchIntroSliders = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetchintrosliders`, {})
        .then(res => { return res.data.data; })
        .catch(err => { return err; })
};

export const fetchClientTestimonials = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetchclienttestimonials`, {})
        .then(res => { return res.data.data; })
        .catch(err => { return err; })
};

export const fetchNetworks = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetchnetworks`, {})
        .then(res => { return res.data.data; })
        .catch(err => { return err; })
};

export const fetchTrackings = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/fetchtrackings`, {})
        .then(res => { return res.data.data; })
        .catch(err => { return err; })
};

export const fetchTeamMembers = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetchteammembers`, {})
        .then(res => { return res.data.data; })
        .catch(err => { return err.response; })
};

export const fetchCatWall = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetchcatwall`, {})
        .then(res => { return res.data.data; })
        .catch(err => { return err.response; })
};

export const fetchGalleryPosts = () => {
    var data = {
        userToken: localStorage.getItem('usertokenShivshahiCustomer'),
        calledFor: window.location.pathname == '/gallery' ? 'gallery' : 'my-posts'
    }
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetchgalleryposts`, data)
        .then(res => { return res.data.data; })
        .catch(err => { return err; })
};