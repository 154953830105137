import React, { Component } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { Helmet } from 'react-helmet';
// import { Link } from 'react-router-dom';

class VerifyEmail extends Component {

    componentDidMount() {
        var checkusertoken = localStorage.getItem('usertokenShivshahiCustomer');
        if (checkusertoken != null) {
            this.props.history.push(`${process.env.PUBLIC_URL}/`)
        } else {
            // setTimeout(() => {
            //     this.props.history.push(`${process.env.PUBLIC_URL}/auth/login-register/:data`)
            // }, 5000);
        }
    }

    render() {
        return (
            <div className="main">
                <Helmet>
                    <title>Shivshahi Network</title>
                </Helmet>

                <h1 className="d-none">Shivshahi Network</h1>

                <div className="login-page bg-image pt-8 pb-8 pt-md-12 pb-md-12 pt-lg-17 pb-lg-17" style={{ backgroundImage: `url('assets/images/backgrounds/login-bg.jpg')` }}>
                    <div className="container">
                        <div className="form-box all-bg">
                            <div className="form-tab">
                                <Tabs selectedTabClassName="show" defaultIndex={0}>
                                    <TabList className="nav nav-pills nav-fill">
                                        <Tab className="nav-item all-font">
                                            <span className="nav-link">Verify Email</span>
                                            {/* <span className="nav-link">Proceed to Login</span> */}
                                        </Tab>
                                    </TabList>

                                    <div className="tab-content">
                                        <TabPanel style={{ paddingTop: "2rem", textAlign: 'center' }}>
                                            <div style={{ color: '#22ab50' }}>
                                                Your account has been successfully registered.
                                                <br />To complete the verification process, please check
                                                <br />your email or phone for a validation request.
                                                <br /><span style={{ color: '#c2c2c2' }}>Do Check the <strong style={{ color: 'red' }}>SPAM</strong> folder also..!</span>
                                            </div>
                                            {/* <div style={{ color: '#22ab50' }}>
                                                Your Email has been successfully Verified.
                                            </div>
                                            <div style={{ color: '#c2c2c2' }}>
                                                You will be redirectd to Login in 5 seconds..
                                            </div>
                                            <div className="pt-1">
                                                <Link className="forgot-link" to={`${process.env.PUBLIC_URL}/auth/login-register/:data`} >Click here to Login</Link>
                                            </div> */}
                                        </TabPanel>
                                    </div>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VerifyEmail;