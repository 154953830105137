import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link } from 'react-router-dom';
import ifsc from 'ifsc'
import { connect } from 'react-redux';

import Accordion from './../../components/accordion/accordion';
import Card from './../../components/accordion/card';

import * as userApi from './../../../api/user'

import MyAccountHeader from './../../components/my-account-header';

class Wallet extends Component {

    constructor(props) {
        super(props)

        this.state = {
            normal_wallet: 0,
            ultrapremium_wallet: 0,
            premium_wallet: 0,
            light_wallet: 0,
            basic_wallet: 0,
            requests: [],
            withdrawlRequests: [],

            amount: '',
            accountNumber: '',
            ifscCode: '',
            accountName: '',
            accountType: '',
            phone: '',
            bankName: '',
            branchName: '',

            transferfrom: ''
        }
    }

    callfetchRechargeRequests = async (data) => {
        let spinner = document.getElementById("spinneranimid");
        await userApi.fetchRechargeRequests(data)
            .then(async res => {
                if (res.status == 200) {
                    this.setState({ requests: (res.data).reverse() }, () => {
                        spinner.style.display = "none";
                    })
                } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                    alert('Please Login Again...!')
                    // localStorage.removeItem('usertokenShivshahiCustomer')
                    localStorage.clear();
                    window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                } else {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                }
            })
            .catch((err) => {
                alert('Error Occured.. Please Try after some time')
                spinner.style.display = "none";
            })

    }

    componentDidMount = async () => {
        document.body.classList.add('all-bg');

        var checkusertoken = localStorage.getItem('usertokenShivshahiCustomer');
        // console.log(checkusertoken)
        if (checkusertoken == null) {
            this.props.history.push(`${process.env.PUBLIC_URL}/auth/login-register/:data`)
        } else {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer

            const data = {
                userToken: usertokenShivshahiCustomer,
                isNeededParents: false
            }

            await userApi.fetchUserData(data)
                .then(async res => {
                    if (res.status == 200) {
                        this.setState({
                            normal_wallet: res.data.normal_balance,
                            ultrapremium_wallet: res.data.ultrapremium_balance,
                            premium_wallet: res.data.premium_balance,
                            light_wallet: res.data.light_balance,
                            basic_wallet: res.data.basic_balance,
                        }, () => {
                            this.callfetchRechargeRequests(data)
                        })
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Please Login Again...!')
                        // localStorage.removeItem('usertokenShivshahiCustomer')
                        localStorage.clear();
                        window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                })

        }
    }

    componentWillUnmount() {
        document.body.classList.remove('all-bg');
    }

    handleChangeDefaultIndex = (index) => {

        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";

        const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer

        const data = {
            userToken: usertokenShivshahiCustomer,
        }

        if (index == 0) {
            userApi.fetchRechargeRequests(data)
                .then(async res => {
                    if (res.status == 200) {
                        this.setState({ requests: (res.data).reverse() }, () => {
                            spinner.style.display = "none";
                        })
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Please Login Again...!')
                        // localStorage.removeItem('usertokenShivshahiCustomer')
                        localStorage.clear();
                        window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                })

        } else if (index == 1) {
            userApi.fetchWithdrawlRequests(data)
                .then(async res => {
                    if (res.status == 200) {
                        this.setState({ withdrawlRequests: (res.data).reverse() }, () => {
                            spinner.style.display = "none";
                        })
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Please Login Again...!')
                        // localStorage.removeItem('usertokenShivshahiCustomer')
                        localStorage.clear();
                        window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                })
        }
    }

    callTransferFromRechargeToNormal = (data) => {
        let spinner = document.getElementById("spinneranimid");
        userApi.transferFromRechargeToNormal(data)
            .then(res => {
                if (res.status == 200) {
                    alert('Your Balance is transferred successfully..!')
                    window.location.reload();
                    spinner.style.display = "none";
                } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                    alert('Please Login Again...!')
                    // localStorage.removeItem('usertokenShivshahiCustomer')
                    localStorage.clear();
                    window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                } else {
                    alert('Error Occured.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                }
            })
            .catch((err) => {
                alert('Error Occured.. Please Try after some time')
                window.location.reload();
                spinner.style.display = "none";
            })
    }

    transferBalance = (e) => {
        e.preventDefault();

        if (this.state.transferfrom == '') {
            alert('Please select the wallet for transfer..!')
        } else {

            if (window.confirm("Are you sure, you want to transfer the money..?")) {

                let spinner = document.getElementById("spinneranimid");
                spinner.style.display = "block";

                if (this.state.transferfrom == 'ultrapremium') {
                    let data = {
                        userToken: localStorage.usertokenShivshahiCustomer,
                        transferfrom: this.state.transferfrom,
                        amt: this.state.ultrapremium_wallet
                    }
                    if (this.state.ultrapremium_wallet < 1000 && this.state.ultrapremium_wallet != 0) {
                        this.callTransferFromRechargeToNormal(data)
                    } else {
                        alert('The amount is not acceptable for transfer. It should be below ₹ 1000 and above ₹ 0')
                        spinner.style.display = "none";
                    }
                } else if (this.state.transferfrom == 'premium') {
                    let data = {
                        userToken: localStorage.usertokenShivshahiCustomer,
                        transferfrom: this.state.transferfrom,
                        amt: this.state.premium_wallet
                    }
                    if (this.state.premium_wallet < 1000 && this.state.premium_wallet != 0) {
                        this.callTransferFromRechargeToNormal(data)
                    } else {
                        alert('The amount is not acceptable for transfer. It should be below ₹ 1000 and above ₹ 0')
                        spinner.style.display = "none";
                    }
                } else if (this.state.transferfrom == 'light') {
                    let data = {
                        userToken: localStorage.usertokenShivshahiCustomer,
                        transferfrom: this.state.transferfrom,
                        amt: this.state.light_wallet
                    }
                    if (this.state.light_wallet < 1000 && this.state.light_wallet != 0) {
                        this.callTransferFromRechargeToNormal(data)
                    } else {
                        alert('The amount is not acceptable for transfer. It should be below ₹ 1000 and above ₹ 0')
                        spinner.style.display = "none";
                    }
                } else if (this.state.transferfrom == 'basic') {
                    let data = {
                        userToken: localStorage.usertokenShivshahiCustomer,
                        transferfrom: this.state.transferfrom,
                        amt: this.state.basic_wallet
                    }
                    if (this.state.basic_wallet < 1000 && this.state.basic_wallet != 0) {
                        this.callTransferFromRechargeToNormal(data)
                    } else {
                        alert('The amount is not acceptable for transfer. It should be below ₹ 1000 and above ₹ 0')
                        spinner.style.display = "none";
                    }
                }

            }

        }

    }

    handleChangeDetails = (e) => {
        if (e.target.name == 'accountName') {
            this.setState({ [e.target.name]: e.target.value })
        } else {
            this.setState({ [e.target.name]: e.target.value.replace(new RegExp("\\s*", "gm"), '') })
        }
    }

    withdrawalRequest = (e) => {
        e.preventDefault();

        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";

        var validation = ifsc.validate(this.state.ifscCode);
        if (validation) {
            ifsc.fetchDetails(this.state.ifscCode)
                .then(res => {
                    if (res) {
                        this.setState({
                            bankName: res.BANK,
                            branchName: res.BRANCH,
                        }, () => {

                            if (window.confirm("Are you sure, you want to Withdrawal from Normal Wallet..?")) {

                                if (parseInt(this.state.normal_wallet) > 499) {

                                    const data = {
                                        userToken: localStorage.usertokenShivshahiCustomer,
                                        requestData: {
                                            user_id: '',
                                            user: '',
                                            requested_amount: this.state.amount,
                                            allowed_amount: parseInt(parseInt(this.state.amount) - (parseInt(this.state.amount) * 0.02)),
                                            details: {
                                                bankName: this.state.bankName,
                                                accountName: this.state.accountName,
                                                accountNumber: this.state.accountNumber,
                                                ifscCode: this.state.ifscCode,
                                                branchName: this.state.branchName,
                                                accountType: this.state.accountType,
                                                phone: this.state.phone,
                                            },
                                            date: new Date(),
                                            status: 'Requested'
                                        }
                                    }

                                    userApi.makeWithdrawlRequest(data)
                                        .then(res => {
                                            if (res.status == 200) {
                                                alert('Request Successful..!')
                                                window.location.reload();
                                                spinner.style.display = "none";
                                            } else if (res.status == 401 && res.error == 'Insufficient Funds') {
                                                alert('Insufficient Funds...!')
                                                window.location.reload();
                                                spinner.style.display = "none";
                                            } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                                                alert('Please Login Again...!')
                                                // localStorage.removeItem('usertokenShivshahiCustomer')
                                                localStorage.clear();
                                                window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                                            } else {
                                                alert('Error Occured.. Please Try after some time')
                                                window.location.reload();
                                                spinner.style.display = "none";
                                            }
                                        })
                                        .catch((err) => {
                                            alert('Error Occured.. Please Try after some time')
                                            window.location.reload();
                                            spinner.style.display = "none";
                                        })
                                } else {
                                    alert('For withdrawl, your Normal Balance should be above ₹ 500 /-')
                                    spinner.style.display = "none";
                                }

                            } else {
                                spinner.style.display = "none";
                            }

                        })
                    } else {
                        alert('Error while fetching the bank details. Please verify the details')
                        spinner.style.display = "none";
                    }
                })
                .catch(err => {
                    alert('Error while fetching the bank details. Please verify the details')
                    spinner.style.display = "none";
                })
        } else {
            alert('Please Enter the correct IFSC code...!')
            spinner.style.display = "none";
        }

    }

    viewReceipts = (receipts) => {
        // console.log(JSON.parse(receipts))
        JSON.parse(receipts).map((receipt, index) => {
            window.open(`${process.env.PUBLIC_URL}/assets/images/recharge-receipts/${receipt.filename}`);
        })
    }

    render() {
        const { currency } = this.props;

        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

        return (
            <div className="main">

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="page-content mt-3">
                    <div className="dashboard">
                        <div className="container">

                            <MyAccountHeader />

                            <h3 className='text-center all-font mb-3'>Wallet</h3>

                            <div className='wallet-area'>
                                <div className="wallet">
                                    <div className='left'>
                                        <h5>Normal wallet :</h5>
                                        <div>
                                            <h3><span>{currency.currencySymbol}</span>&nbsp;{(this.state.normal_wallet * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
                                        </div>
                                    </div>
                                    <div className='right'>
                                        <h5>Recharge wallet :</h5>
                                        <div>
                                            <h3><span>{currency.currencySymbol}</span>&nbsp;{((parseInt(this.state.ultrapremium_wallet) + parseInt(this.state.premium_wallet) + parseInt(this.state.light_wallet) + parseInt(this.state.basic_wallet)) * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className='descr mt-4 mb-3'>
                                    <h6>Normal Wallet :</h6>
                                    <p>
                                        Here you will find the amount collected by Profits during purchases. You can use this balance to make purchases from Business Opportunity and Hirkani Plan. You can withdraw this balance to your Bank account, if it is above ₹ 500.
                                    </p>
                                    <h6>Recharge Wallet :</h6>
                                    <p>
                                        Here you will find the amount of recharge plans. You can use this amount to make purchases from Business Opportunity.
                                    </p>
                                    <div className='ml-5 mt-2 mb-2'>
                                        <p>Ultra-Premium : <span>{currency.currencySymbol}&nbsp;{(this.state.ultrapremium_wallet * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                        <p>Premium : <span>{currency.currencySymbol}&nbsp;{(this.state.premium_wallet * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                        <p>Light : <span>{currency.currencySymbol}&nbsp;{(this.state.light_wallet * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                        <p>Basic : <span>{currency.currencySymbol}&nbsp;{(this.state.basic_wallet * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                    </div>

                                    {/* <form onSubmit={this.transferBalance}>
                                        <p>Please select the wallet to transfer the balance.</p>
                                        <div className="details-filter-row details-row-size">
                                            <div className="select-custom">
                                                <select id='wall' className="form-control all-font" style={{ backgroundColor: 'transparent' }} onChange={e => this.setState({ transferfrom: e.target.value })} required>
                                                    <option className="all-font all-bg" value=''>-- Select the Wallet --</option>
                                                    <option className="all-font all-bg" value='ultrapremium'>Ultra-Premium</option>
                                                    <option className="all-font all-bg" value='premium'>Premium</option>
                                                    <option className="all-font all-bg" value='light'>Light</option>
                                                    <option className="all-font all-bg" value='basic'>Basic</option>
                                                </select>
                                            </div>
                                        </div>
                                        <button type='submit' className="btn btn-outline-primary-2"><span>Transfer From Recharge To Normal</span><i className="icon-refresh"></i></button>

                                    </form> */}

                                </div>

                                <Accordion adClass="accordion-plus">
                                    <Card title='Make Withdrawl Request' adClass="card-box card-lg all-bg-light">


                                        <div className="col-12 mb-2" style={{ textAlign: 'center' }}>
                                            <p style={{ color: '#c2c2c2' }}>After request approval, your balance will be transferred to your Bank account within 7 days of approval with 2% tax deduction.</p>
                                        </div>

                                        <form onSubmit={this.withdrawalRequest}>

                                            <div className="col-12">

                                                <div className="form-group mb-0">
                                                    <label className="all-font-dark" htmlFor="amount">Amount *  (Receiving Amount: ₹ {parseInt(this.state.amount - (this.state.amount * 0.02))})</label>
                                                    <input placeholder='Enter the Amount' min={500} max={30000} type="number" className="form-control all-font" id="amount" name="amount" value={this.state.amount} pattern=".*\S+.*" onChange={this.handleChangeDetails} required />
                                                </div>
                                                <div className="form-group mb-0">
                                                    <label className="all-font-dark" htmlFor="accountNumber">Account Number *</label>
                                                    <input placeholder='Enter Your Account Number' type="number" className="form-control all-font" id="accountNumber" name="accountNumber" pattern=".*\S+.*" value={this.state.accountNumber} onChange={this.handleChangeDetails} required />
                                                </div>
                                                <div className="form-group mb-0">
                                                    <label className="all-font-dark" htmlFor="ifscCode">IFSC Code *</label>
                                                    <input placeholder='Enter Your IFSC Code' type="text" className="form-control all-font" id="ifscCode" name="ifscCode" pattern=".*\S+.*" value={this.state.ifscCode} onChange={this.handleChangeDetails} required />
                                                </div>
                                                <div className="form-group mb-0">
                                                    <label className="all-font-dark" htmlFor="accountName">Account Holder *</label>
                                                    <input placeholder='Enter Account Holder Name' type="text" className="form-control all-font" id="accountName" name="accountName" pattern=".*\S+.*" value={this.state.accountName} onChange={this.handleChangeDetails} required />
                                                </div>
                                                <div className="form-group mb-0">
                                                    <label className="all-font-dark" htmlFor="accountType">Account Type *</label>
                                                    <select name="accountType" id="accountType" className="form-control all-font" onChange={this.handleChangeDetails} value={this.state.accountType} required>
                                                        <option className="all-font all-bg" value=''>-- Select Account Type --</option>
                                                        <option className="all-font all-bg" value='Savings'>Savings</option>
                                                        <option className="all-font all-bg" value='Current'>Current</option>
                                                    </select>
                                                </div>
                                                <div className="form-group mb-0">
                                                    <label className="all-font-dark" htmlFor="phone">Phone Number *</label>
                                                    <input placeholder='Enter Phone Number' type="number" className="form-control all-font" id="phone" name="phone" onChange={this.handleChangeDetails} value={this.state.phone} pattern=".*\S+.*" required />
                                                </div>

                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <button type="submit" className="btn btn-outline-primary-2 mt-3 mb-1">
                                                        <span>Withdraw from Normal</span>
                                                        <i className="icon-long-arrow-up"></i>
                                                    </button>
                                                </div>

                                            </div>

                                        </form>

                                    </Card>
                                </Accordion>

                                <Tabs selectedTabClassName="show" onSelect={(index) => this.handleChangeDefaultIndex(index)}>
                                    <div style={{ textAlign: "center" }}>
                                        <h3 style={{ color: '#fff' }}>Requests</h3>
                                    </div>
                                    <TabList className="nav nav-pills nav-fill">
                                        <Tab className="nav-item all-font">
                                            <span className="nav-link">Recharge</span>
                                        </Tab>

                                        <Tab className="nav-item all-font">
                                            <span className="nav-link">Withdrawl</span>
                                        </Tab>
                                    </TabList>

                                    <div className="tab-content">
                                        <TabPanel style={{ paddingTop: "2rem" }}>
                                            <div className="order-list">
                                                {this.state.requests.length > 0 ?
                                                    this.state.requests.map((item, index) =>

                                                        <div key={index}>
                                                            <div className="left">
                                                                <h6>Plan : &nbsp;&nbsp;<span>{item.plan}</span></h6>
                                                                <h6>Date : &nbsp;&nbsp;<span>{days[new Date(item.date).getDay()] + ', ' + monthNames[new Date(item.date).getMonth()] + ' ' + new Date(item.date).getDate() + ', ' + new Date(item.date).getFullYear()}</span></h6>
                                                                <h6>Amount : &nbsp;&nbsp;<span>{currency.currencySymbol}&nbsp;{(item.amount * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></h6>
                                                                <h6 className='small-sizes'>Status: &nbsp;&nbsp;<span style={{ color: item.status == "Approved" ? '#5df542' : item.status == "Requested" ? '#fff' : '#eb4034' }}>{item.status}</span></h6>
                                                                <p className='a-dummy small-sizes' onClick={() => this.viewReceipts(item.receipt)} style={{ color: '#c96' }}>
                                                                    View Receipt(s)
                                                                </ p>
                                                                {/* <button className="btn btn-outline-white" style={{ display: item.status == "Requested" ? '' : 'none' }} onClick={(e) => this.cancelRechargeRequestFn(e, item.id)}><span>Cancel Request</span><i className="icon-long-arrow-right"></i></button> */}
                                                            </div>

                                                            <div className="right">
                                                                <h6>Status: &nbsp;&nbsp;<span style={{ color: item.status == "Approved" ? '#5df542' : item.status == "Requested" ? '#fff' : '#eb4034' }}>{item.status}</span></h6>
                                                                <p className='a-dummy' onClick={() => this.viewReceipts(item.receipt)}>
                                                                    {/* {JSON.parse(item.receipt).map((receipt, index) =>
                                                                     <a key={index} href={`${process.env.PUBLIC_URL}/assets/images/recharge-receipts/${receipt.filename}`} target="_blank" rel="noopener noreferrer"><i className="icon-creative-commons-share" style={{ fontSize: '22px' }} /></a>
                                                                    )} */}
                                                                View Receipt(s)
                                                                </ p>
                                                            </div>
                                                        </div>

                                                    )
                                                    :
                                                    <div className="empty-list">
                                                        <p>No Request for Recharge Plan has been made yet.</p>
                                                        <Link to={`${process.env.PUBLIC_URL}/join-us/noplan`} className="btn btn-outline-primary-2 mt-2"><span>JOIN US</span><i className="icon-long-arrow-right"></i></Link>
                                                    </div>
                                                }
                                            </div>
                                        </TabPanel>

                                        <TabPanel>
                                            <div className="order-list">
                                                {this.state.withdrawlRequests.length > 0 ?
                                                    this.state.withdrawlRequests.map((item, index) =>

                                                        <div key={index}>
                                                            <div className="left">
                                                                <h6>Requested Amount : &nbsp;&nbsp;<span>{currency.currencySymbol}&nbsp;{(item.requested_amount * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></h6>
                                                                <h6>Eligible Amount : &nbsp;&nbsp;<span style={{ color: '#c96' }}>{currency.currencySymbol}&nbsp;{(item.allowed_amount * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></h6>
                                                                <h6>Date : &nbsp;&nbsp;<span>{days[new Date(item.date).getDay()] + ', ' + monthNames[new Date(item.date).getMonth()] + ' ' + new Date(item.date).getDate() + ', ' + new Date(item.date).getFullYear()}</span></h6>
                                                                <h6 className='small-sizes'>Status: &nbsp;&nbsp;<span style={{ color: item.status == "Approved" ? '#5df542' : item.status == "Requested" ? '#fff' : '#eb4034' }}>{item.status}</span></h6>
                                                                <h6 className='small-sizes'>Account No. : &nbsp;&nbsp;<span>{JSON.parse(item.details).accountNumber}</span></h6>
                                                                <h6 className='small-sizes'>IFSC Code : &nbsp;&nbsp;<span>{JSON.parse(item.details).ifscCode}</span></h6>
                                                                {/* <button to='#' className="btn btn-outline-white" style={{ display: item.status == "Requested" ? '' : 'none' }} onClick={(e) => this.cancelWithdrawlRequestFn(e, item.id)}><span>Cancel Request</span><i className="icon-long-arrow-right"></i></button> */}
                                                            </div>

                                                            <div className="right">
                                                                <h6>Status: &nbsp;&nbsp;<span style={{ color: item.status == "Approved" ? '#5df542' : item.status == "Requested" ? '#fff' : '#eb4034' }}>{item.status}</span></h6>
                                                                <p className='mb-0'>Account No. : <span>{JSON.parse(item.details).accountNumber}</span></p>
                                                                <p className='mb-0'>IFSC Code : <span>{JSON.parse(item.details).ifscCode}</span></p>
                                                            </div>
                                                        </div>

                                                    )
                                                    :
                                                    <div className="empty-list">
                                                        <p>No Withdrawl Request has been made yet.</p>
                                                    </div>
                                                }
                                            </div>
                                        </TabPanel>
                                    </div>
                                </Tabs>
                            </div>

                        </div>
                    </div >
                </div >
            </div >
        )
    }
}

export const mapStateToProps = (state) => ({
    currency: state.currencyData,
})

export default connect(mapStateToProps)(Wallet);