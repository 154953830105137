import React, { Component } from 'react';
import { connect } from 'react-redux';

import PageHeader from './../../components/page-header';

import { getCartTotal } from './../../../services';
import { changetQty, removeFromCart } from './../../../actions';

import * as userApi from './../../../api/user'
import * as orderApi from './../../../api/order'
import { getGeoLocation } from './../../../api/other';

class Checkout extends Component {

    constructor(props) {
        super(props)

        this.state = {
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            address: "",
            city: "",
            district: "",
            pincode: "",
            state: "",

            notes: "",
            service: "",

            normal_wallet: 0,
            ultrapremium_wallet: 0,
            premium_wallet: 0,
            light_wallet: 0,
            basic_wallet: 0,

            isFirstOrder: true,

            totalCartQty: 0,
            subtotal: 0,
            shipping: 0,
            total: 0,
            wallet: 0,
            hirkani: 0,
            grandtotal: 0,
            upline: [],

            plan: 'none',
            whichWallet: 'none',

            isRazorPay: false,

            percentageProfit: { self: 10, parent: 7, grandparent: 5, grandparent_big: 0 }
        }
    }

    componentDidMount() {
        const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer

        if (usertokenShivshahiCustomer == null) {
            this.props.history.push(`${process.env.PUBLIC_URL}/auth/login-register/:data`)
        } else if (this.props.cartlist.length == 0) {
            this.props.history.push(`${process.env.PUBLIC_URL}/shop`)
        } else {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const data = {
                userToken: usertokenShivshahiCustomer,
                isNeededParents: true
            }

            userApi.fetchUserData(data)
                .then(async res => {
                    if (res.status == 200) {
                        var totalCartQty = 0
                        if (this.props.cartlist.length > 0) {
                            this.props.cartlist.map((item, index) => {
                                { totalCartQty = parseInt(totalCartQty) + parseInt(item.qty) }
                            })
                        }
                        this.setState({
                            // firstname: res.data.firstname,
                            // lastname: res.data.lastname,
                            // email: res.data.email,
                            // phone: res.data.phone,
                            // address: res.data.address,
                            // city: res.data.city,
                            // district: res.data.district,
                            // pincode: res.data.pincode,
                            // state: res.data.state,
                            isFirstOrder: res.data.isFirstOrder,
                            normal_wallet: res.data.normal_balance,
                            ultrapremium_wallet: res.data.ultrapremium_balance,
                            premium_wallet: res.data.premium_balance,
                            light_wallet: res.data.light_balance,
                            basic_wallet: res.data.basic_balance,
                            upline: res.data.referenceby != '' ? [res.data.parent] : [],
                            totalCartQty: totalCartQty,
                            subtotal: parseInt(this.props.total),
                            grandtotal: parseInt(this.props.total),
                            percentageProfit: res.data.isFirstOrder ? { self: 0, parent: 10, grandparent: 7, grandparent_big: 5 } : { self: 10, parent: 7, grandparent: 5, grandparent_big: 0 },
                            plan: res.data.isFirstOrder ? 'BO' : 'BO' //'none'
                        }, () => {
                            if (this.state.plan == 'BO') {
                                if (this.state.totalCartQty <= 2) {
                                    this.setState({
                                        shipping: 100,
                                        whichWallet: 'normal',
                                        hirkani: 0,
                                    }, () => {
                                        this.setState({
                                            total: this.state.subtotal + this.state.shipping,
                                            wallet: this.state.normal_wallet > (this.state.subtotal + this.state.shipping - this.state.hirkani) ? (this.state.subtotal + this.state.shipping - this.state.hirkani) : this.state.normal_wallet,
                                        }, () => {
                                            this.setState({
                                                grandtotal: this.state.subtotal + this.state.shipping - this.state.wallet - this.state.hirkani
                                            })
                                        })
                                    })
                                } else {
                                    this.setState({
                                        shipping: 100 + ((this.state.totalCartQty - 2) * 50),
                                        whichWallet: 'normal',
                                        hirkani: 0,
                                    }, () => {
                                        this.setState({
                                            total: this.state.subtotal + this.state.shipping,
                                            wallet: this.state.normal_wallet > (this.state.subtotal + this.state.shipping - this.state.hirkani) ? (this.state.subtotal + this.state.shipping - this.state.hirkani) : this.state.normal_wallet,
                                        }, () => {
                                            this.setState({
                                                grandtotal: this.state.subtotal + this.state.shipping - this.state.wallet - this.state.hirkani
                                            })
                                        })
                                    })
                                }
                            }
                            spinner.style.display = "none"
                        })
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Please Login Again...!')
                        // localStorage.removeItem('usertokenShivshahiCustomer')
                        localStorage.clear();
                        window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        spinner.style.display = "none";
                        window.location = process.env.PUBLIC_URL + '/shop/cart'
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                    window.location = process.env.PUBLIC_URL + '/shop/cart'
                })

        }

        if (this.props.cartlist.length > 0) {
            this.props.cartlist.map((item, index) => {
                var productIndex = this.props.products.findIndex((element) => element.id == item.id)
                JSON.parse(this.props.products[productIndex].variants).map((item2, index2) => {
                    if (item2.size == item.size && parseInt(item2.stock) < parseInt(item.qty)) {
                        // console.log(item.id, item.qty, item2.stock)
                        this.props.changetQty(item.id, item2.stock, item2.size);
                    }
                    if (item2.size == item.size && item2.stock == 0) {
                        // console.log(item.id, item.size)
                        this.props.removeFromCart(item.id, item.size)
                    }
                })
            })

            window.onload = function () {
                if (!window.location.hash) {
                    window.location = window.location + '#loaded';
                    window.location.reload();
                }
            }
        }

    }

    handleChange = e => {
        if (e.target.name == 'address' || e.target.name == 'service' || e.target.name == 'notes') {
            this.setState({ [e.target.name]: e.target.value })
        } else {
            this.setState({ [e.target.name]: e.target.value.replace(new RegExp("\\s*", "gm"), '') })
        }

        if (e.target.name == 'pincode' && e.target.value.length == 6) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            getGeoLocation(e.target.value)
                .then(res => {
                    if (res.status == 'success') {
                        this.setState({
                            city: res.data.block,
                            district: res.data.district,
                            state: res.data.state,
                        }, () => {
                            spinner.style.display = "none";
                        })
                    } else {
                        this.setState({
                            pincode: ''
                        }, () => {
                            alert('Please Enter the correct Pincode !')
                            spinner.style.display = "none";
                        })
                    }
                })
                .catch((err) => {
                    this.setState({
                        pincode: ''
                    }, () => {
                        alert('Something went Wrong')
                        spinner.style.display = "none";
                    })
                })
        }
    }

    useNormalWallet = (e) => {
        if (this.state.whichWallet == 'normal') {
            this.setState({
                whichWallet: 'none',
                wallet: 0
            }, () => {
                this.setState({
                    total: this.state.subtotal + this.state.shipping,
                    grandtotal: this.state.subtotal + this.state.shipping - this.state.wallet - this.state.hirkani
                })
            })
        } else {
            this.setState({
                whichWallet: 'normal',
                wallet: this.state.normal_wallet > (this.state.subtotal + this.state.shipping - this.state.hirkani) ? (this.state.subtotal + this.state.shipping - this.state.hirkani) : this.state.normal_wallet,
            }, () => {
                this.setState({
                    total: this.state.subtotal + this.state.shipping,
                    grandtotal: this.state.subtotal + this.state.shipping - this.state.wallet - this.state.hirkani
                })
            })
        }
    }

    onChangePlan = (plan) => {
        this.setState({ plan: plan }, () => {
            if (this.state.plan == 'BO') {
                if (this.state.totalCartQty <= 2) {
                    this.setState({
                        shipping: 100,
                        percentageProfit: this.state.isFirstOrder ? { self: 0, parent: 10, grandparent: 7, grandparent_big: 5 } : { self: 10, parent: 7, grandparent: 5, grandparent_big: 0 },
                        whichWallet: 'none',
                        hirkani: 0,
                        wallet: 0,
                    }, () => {
                        this.setState({
                            total: this.state.subtotal + this.state.shipping,
                            grandtotal: this.state.subtotal + this.state.shipping - this.state.wallet - this.state.hirkani
                        })
                    })
                } else {
                    this.setState({
                        shipping: 100 + ((this.state.totalCartQty - 2) * 50),
                        percentageProfit: this.state.isFirstOrder ? { self: 0, parent: 10, grandparent: 7, grandparent_big: 5 } : { self: 10, parent: 7, grandparent: 5, grandparent_big: 0 },
                        whichWallet: 'none',
                        hirkani: 0,
                        wallet: 0,
                    }, () => {
                        this.setState({
                            total: this.state.subtotal + this.state.shipping,
                            grandtotal: this.state.subtotal + this.state.shipping - this.state.wallet - this.state.hirkani
                        })
                    })
                }
            } else if (this.state.plan == 'HP') {
                this.setState({
                    shipping: this.state.totalCartQty * 30,
                    percentageProfit: { self: 0, parent: 1, grandparent: 0, grandparent_big: 0 },
                    hirkani: 0.22 * this.state.subtotal,
                    whichWallet: 'none',
                    wallet: 0,
                }, () => {
                    this.setState({
                        total: this.state.subtotal + this.state.shipping,
                        grandtotal: this.state.subtotal + this.state.shipping - this.state.wallet - this.state.hirkani
                    })
                })
            } else if (this.state.plan == 'RP_UPW') {
                this.setState({
                    shipping: 0,
                    percentageProfit: { self: 0, parent: 0, grandparent: 0, grandparent_big: 0 },
                    hirkani: 0,
                    whichWallet: 'ultrapremium',
                }, () => {
                    this.setState({
                        wallet: this.state.ultrapremium_wallet > (this.state.subtotal + this.state.shipping) ? (this.state.subtotal + this.state.shipping) : this.state.ultrapremium_wallet,
                    }, () => {
                        this.setState({
                            total: this.state.subtotal + this.state.shipping,
                            grandtotal: this.state.subtotal + this.state.shipping - this.state.wallet - this.state.hirkani
                        })
                    })
                })
            } else if (this.state.plan == 'RP_PW') {
                this.setState({
                    shipping: 0,
                    percentageProfit: { self: 0, parent: 0, grandparent: 0, grandparent_big: 0 },
                    hirkani: 0,
                    whichWallet: 'premium',
                }, () => {
                    this.setState({
                        wallet: this.state.premium_wallet > (this.state.subtotal + this.state.shipping) ? (this.state.subtotal + this.state.shipping) : this.state.premium_wallet,
                    }, () => {
                        this.setState({
                            total: this.state.subtotal + this.state.shipping,
                            grandtotal: this.state.subtotal + this.state.shipping - this.state.wallet - this.state.hirkani
                        })
                    })
                })
            } else if (this.state.plan == 'RP_LW') {
                if (this.state.totalCartQty <= 2) {
                    this.setState({
                        shipping: 50,
                        percentageProfit: { self: 0, parent: 0, grandparent: 0, grandparent_big: 0 },
                        hirkani: 0,
                        whichWallet: 'light',
                    }, () => {
                        this.setState({
                            wallet: this.state.light_wallet > (this.state.subtotal + this.state.shipping) ? (this.state.subtotal + this.state.shipping) : this.state.light_wallet,
                        }, () => {
                            this.setState({
                                total: this.state.subtotal + this.state.shipping,
                                grandtotal: this.state.subtotal + this.state.shipping - this.state.wallet - this.state.hirkani
                            })
                        })
                    })
                } else {
                    this.setState({
                        shipping: 50 + ((this.state.totalCartQty - 2) * 25),
                        percentageProfit: { self: 0, parent: 0, grandparent: 0, grandparent_big: 0 },
                        hirkani: 0,
                        whichWallet: 'light',
                    }, () => {
                        this.setState({
                            wallet: this.state.light_wallet > (this.state.subtotal + this.state.shipping) ? (this.state.subtotal + this.state.shipping) : this.state.light_wallet,
                        }, () => {
                            this.setState({
                                total: this.state.subtotal + this.state.shipping,
                                grandtotal: this.state.subtotal + this.state.shipping - this.state.wallet - this.state.hirkani
                            })
                        })
                    })
                }
            } else if (this.state.plan == 'RP_BW') {
                if (this.state.totalCartQty <= 2) {
                    this.setState({
                        shipping: 100,
                        percentageProfit: { self: 0, parent: 0, grandparent: 0, grandparent_big: 0 },
                        hirkani: 0,
                        whichWallet: 'basic',
                    }, () => {
                        this.setState({
                            wallet: this.state.basic_wallet > (this.state.subtotal + this.state.shipping) ? (this.state.subtotal + this.state.shipping) : this.state.basic_wallet,
                        }, () => {
                            this.setState({
                                total: this.state.subtotal + this.state.shipping,
                                grandtotal: this.state.subtotal + this.state.shipping - this.state.wallet - this.state.hirkani
                            })
                        })
                    })
                } else {
                    this.setState({
                        shipping: 100 + ((this.state.totalCartQty - 2) * 50),
                        percentageProfit: { self: 0, parent: 0, grandparent: 0, grandparent_big: 0 },
                        hirkani: 0,
                        whichWallet: 'basic',
                    }, () => {
                        this.setState({
                            wallet: this.state.basic_wallet > (this.state.subtotal + this.state.shipping) ? (this.state.subtotal + this.state.shipping) : this.state.basic_wallet,
                        }, () => {
                            this.setState({
                                total: this.state.subtotal + this.state.shipping,
                                grandtotal: this.state.subtotal + this.state.shipping - this.state.wallet - this.state.hirkani
                            })
                        })
                    })
                }
            }
        })
    }

    handleSubmit = async e => {
        e.preventDefault();

        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";

        if (this.state.subtotal == 0) {
            alert('Your Cart is Empty..!')
            window.location = `${process.env.PUBLIC_URL}/shop`
        } else if (this.state.plan == 'none') {
            alert('Please select any Plan to Order..!')
            spinner.style.display = "none";
        } else if (this.state.isRazorPay && this.state.grandtotal > 10000) {
            alert('Total amount should be less than ₹ 10000 for RazorPay. Please select the other mode..!')
            spinner.style.display = "none";
        } else if (this.state.plan.includes('RP') && this.state.grandtotal > 0) {
            alert('This Order is not eligible for Recharge Plan. Please recharge first..!')
            spinner.style.display = "none";
        } else if (this.state.plan == 'HP' && this.state.subtotal < 50000) {
            alert('You are not eligible for Hirkani Plan. As your cart total should be ₹ 50000 without shipping and other discounts.')
            spinner.style.display = "none";
        } else {

            var newOrderData = {
                userToken: localStorage.usertokenShivshahiCustomer,
                state: this.state,
                cartlist: this.props.cartlist,
                order_id: '',
                payment_status: 'Pending',
                txn_id: ''
            }

            if (this.state.isRazorPay && this.state.grandtotal != 0) {

                orderApi.razorpayPayment({ amount: this.state.grandtotal })
                    .then(async res => {
                        if (res.status == 200) {

                            var options = {
                                key: "rzp_live_pMBWFgUAAS0pma",
                                // key: "rzp_test_vMQpy4mZD9xUJ0",
                                amount: this.state.grandtotal * 100,
                                currency: "INR",
                                name: "Shivshahi Network",
                                image: `${process.env.PUBLIC_URL}/assets/images/logo-icon.png`,
                                order_id: res.data.id,
                                handler: async (response) => {

                                    newOrderData.order_id = response.razorpay_order_id
                                    newOrderData.payment_status = 'Completed'
                                    newOrderData.txn_id = response.razorpay_payment_id

                                    await orderApi.createOrder(newOrderData)
                                        .then(async (newOrderResRP) => {
                                            if (newOrderResRP.status == 200) {
                                                window.location = process.env.PUBLIC_URL + '/order-success/' + newOrderResRP.data
                                            } else {
                                                alert('Something Went Wrong')
                                                window.location = process.env.PUBLIC_URL + '/shop/cart'
                                            }
                                        })
                                        .catch((err) => {
                                            alert('Something Went Wrong')
                                            window.location = process.env.PUBLIC_URL + '/shop/cart'
                                        })

                                },
                                modal: {
                                    ondismiss: () => {
                                        alert('Payment Cancelled..!')
                                        spinner.style.display = "none";
                                    }
                                },
                                prefill: {
                                    name: this.state.firstname + this.state.lastname,
                                    email: this.state.email,
                                    contact: this.state.phone,
                                },
                            };

                            var rzp1 = new window.Razorpay(options);
                            rzp1.open();

                        } else {
                            alert('Something Went Wrong')
                            window.location.reload();
                        }
                    })
                    .catch((err) => {
                        alert('Something Went Wrong')
                        window.location.reload();
                    })

            } else {
                newOrderData.payment_status = this.state.grandtotal != 0 ? 'Pending' : 'Completed'
                await orderApi.createOrder(newOrderData)
                    .then(newOrderResOP => {
                        if (newOrderResOP.status == 200) {
                            window.location = process.env.PUBLIC_URL + '/order-success/' + newOrderResOP.data
                        } else {
                            alert('Something Went Wrong')
                            window.location = process.env.PUBLIC_URL + '/shop/cart'
                        }
                    })
                    .catch((err) => {
                        alert('Something Went Wrong')
                        window.location = process.env.PUBLIC_URL + '/shop/cart'
                    })
            }



        }
    }

    render() {
        const { cartlist, currency, trackings } = this.props;

        return (
            <div className="main all-bg">
                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>
                <PageHeader title="Checkout" subTitle="Billing" />

                <div className="page-content">
                    <div className="checkout">
                        <div className="container">

                            <p style={{ color: 'red', display: this.state.isFirstOrder ? '' : 'none' }}>*** This is Your First Order...! You won't get any Profit..! Your upline will get 10% Profit for this order.. If you have already made First order, then go to My Account section and enter your first order number to verify it..!</p>
                            <p className='mt-2' style={{ color: 'red', display: this.state.isFirstOrder ? '' : 'none' }}>*** ही तुमची पहिली ऑर्डर आहे...! आपल्याला कोणताही नफा मिळणार नाही..! या ऑर्डरसाठी आपल्या अपलाईनला 10% नफा मिळेल.. जर आपण आधी फर्स्ट ऑर्डर केली असेल, तर My Account मध्ये जा आणि तुमचा पहिला Order Number Verify करा ..!</p>
                            <h3 style={{ color: '#fff' }}>Customer Shipping Address :</h3>
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">

                                    <div className="col-lg-9">
                                        <div className="form-group" style={{ display: 'flex' }}>
                                            <div className="col-sm-6" style={{ paddingLeft: '0px' }}>
                                                <label className="all-font-dark" htmlFor="firstname">Customer First Name *</label>
                                                <input value={this.state.firstname} type="text" className="form-control all-font" id="firstname" name="firstname" pattern=".*\S+.*" onChange={this.handleChange} required />
                                            </div>
                                            <div className="col-sm-6" style={{ paddingRight: '0px' }}>
                                                <label className="all-font-dark" htmlFor="lastname">Customer Last Name *</label>
                                                <input value={this.state.lastname} type="text" className="form-control all-font" id="lastname" name="lastname" pattern=".*\S+.*" onChange={this.handleChange} required />
                                            </div>
                                        </div>

                                        <div className="form-group" style={{ display: 'flex' }}>
                                            <div className="col-sm-6" style={{ paddingLeft: '0px' }}>
                                                <label className="all-font-dark" htmlFor="email">E-mail address *</label>
                                                <input value={this.state.email} type="email" className="form-control all-font" id="email" name="email" pattern=".*\S+.*" onChange={this.handleChange} required />
                                            </div>
                                            <div className="col-sm-6" style={{ paddingRight: '0px' }}>
                                                <label className="all-font-dark" htmlFor="phone">Mobile Number *</label>
                                                <input value={this.state.phone} type="text" pattern="\d*" minLength="10" maxLength="10" className="form-control all-font" id="phone" name="phone" onChange={this.handleChange} required />
                                            </div>
                                        </div>

                                        <div className="form-group" style={{ display: 'flex' }}>
                                            <div className="col-6 pl-0">
                                                <label className="all-font-dark" htmlFor="pincode">Pin Code *</label>
                                                <input value={this.state.pincode} type="text" pattern="\d*" minLength="6" maxLength="6" className="form-control all-font" id="pincode" name="pincode" onChange={this.handleChange} required />
                                            </div>

                                            <div className="col-6 pr-0">
                                                <label className="all-font-dark" htmlFor="city">City *</label>
                                                <input value={this.state.city} type="text" pattern=".*\S+.*" className="form-control all-font" id="city" name="city" onChange={this.handleChange} required />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="all-font-dark" htmlFor="address">Address * (House Number, Lane, Street, Landmark, Alternate Mobile No.)</label>
                                            <textarea style={{ minHeight: 'auto' }} rows={3} value={this.state.address} className="form-control all-font" id="address" name="address" pattern=".*\S+.*" onChange={this.handleChange} required />
                                        </div>

                                        <div className="form-group other-none">
                                            <div className="col-sm-12 col-md-9 col-lg-6 pl-0 pr-0">
                                                <label className="all-font-dark" htmlFor="service">Courier (optional)</label>
                                                <select name="service" id="service" className="form-control all-font mr-4" style={{ maxWidth: '100%', backgroundColor: 'transparent' }} value={this.state.service} onChange={this.handleChange}>
                                                    <option className="all-font all-bg" value=''> -- Select --</option>
                                                    {trackings.map((tracking, index) =>
                                                        <option className="all-font all-bg" key={index} value={tracking.service}>{tracking.service}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="all-font-dark" htmlFor="notes">Other Notes (optional)</label>
                                            <textarea style={{ minHeight: 'auto' }} rows={3} value={this.state.notes} className="form-control all-font" id="notes" name="notes" onChange={this.handleChange} />
                                        </div>
                                    </div>

                                    <aside className="col-lg-3">
                                        <div className="summary all-bg-light">
                                            <h3 className="summary-title all-font-light">Your Order</h3>

                                            <table className="table table-summary">
                                                <thead>
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {cartlist.map((item, index) =>
                                                        <tr key={index}>
                                                            <td className="all-font-dark">{item.name} x{item.qty}</td>
                                                            <td className="all-font-dark">{currency.currencySymbol}&nbsp;{(item.sum * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                        </tr>
                                                    )}
                                                    <tr className="summary-subtotal">
                                                        <td>Subtotal:</td>
                                                        <td>{currency.currencySymbol}&nbsp;{(this.state.subtotal * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="all-font-dark">
                                                            Shipping :
                                                            <div>Hirkani :</div>
                                                            <div>Wallet : <span style={{ color: this.state.whichWallet == 'normal' ? '#30d95d' : this.state.plan == 'RP_UPW' ? '#31dede' : this.state.plan == 'RP_PW' ? '#ded831' : this.state.plan == 'RP_LW' ? '#de31be' : this.state.plan == 'RP_BW' ? '#de7331' : '#626262' }}>({this.state.whichWallet == 'normal' ? 'normal' : this.state.plan == 'RP_UPW' ? 'Ultra-Premium' : this.state.plan == 'RP_PW' ? 'Premium' : this.state.plan == 'RP_LW' ? 'Light' : this.state.plan == 'RP_BW' ? 'Basic' : ''})</span></div>
                                                        </td>
                                                        <td className="all-font-dark">
                                                            + {currency.currencySymbol}&nbsp;{this.state.shipping}
                                                            <div>- {currency.currencySymbol}&nbsp;{this.state.hirkani}</div>
                                                            <div>- {currency.currencySymbol}&nbsp;{this.state.wallet}</div>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ display: this.state.plan == 'BO' || this.state.plan == 'HP' ? '' : 'none' }}>
                                                        <td className="all-font-dark">
                                                            <div className="custom-control custom-checkbox2">
                                                                <input type="checkbox" className="custom-control-input" id="normalwallet" name='walletCheckNormal' onChange={this.useNormalWallet} checked={this.state.whichWallet == 'normal'} />
                                                                <i className="las la-wallet" style={{ color: '#30d95d' }} />&nbsp;&nbsp;<label className="custom-control-label" style={{ color: '#30d95d' }} htmlFor="normalwallet">Normal Wallet</label>
                                                            </div>
                                                        </td>
                                                        <td style={{ color: parseInt(this.state.normal_wallet) > 0 ? '#d6d6d6' : '#626262' }}>
                                                            {currency.currencySymbol}&nbsp;{(this.state.normal_wallet * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                        </td>
                                                    </tr>
                                                    <tr className="summary-total">
                                                        <td>Total:</td>
                                                        <td>{currency.currencySymbol}&nbsp;{(this.state.grandtotal * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <div className="mb-2">

                                                <div style={{ display: this.state.isFirstOrder ? 'none' : '' }} >
                                                    <h5 className="all-font">Scheme :</h5>

                                                    <div className="custom-control custom-radio2">
                                                        <input type="radio" className="custom-control-input" id="BO" name="plan" onChange={(e) => this.onChangePlan("BO")} checked={this.state.plan == 'BO'} />
                                                        <label className="custom-control-label" htmlFor="BO">
                                                            Business Opportunity
                                                        </label>
                                                    </div>

                                                    <div className="custom-control custom-radio2">
                                                        <input type="radio" className="custom-control-input" id="HP" name="plan" onChange={(e) => this.onChangePlan("HP")} checked={this.state.plan == 'HP'} />
                                                        <label className="custom-control-label" htmlFor="HP">
                                                            Hirkani Plan <span style={{ color: '#626262' }}>(min {currency.currencySymbol}&nbsp;{(50000 * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })})</span>
                                                        </label>
                                                    </div>

                                                    <div className="custom-control custom-radio2">
                                                        <input type="radio" className="custom-control-input" id="RP_UPW" name="plan" onChange={(e) => this.onChangePlan("RP_UPW")} checked={this.state.plan == 'RP_UPW'} />
                                                        <label className="custom-control-label" htmlFor="RP_UPW" style={{ color: '#31dede' }}>
                                                            <i className="las la-wallet" />&nbsp;&nbsp;Ultra-Premium <span style={{ color: parseInt(this.state.ultrapremium_wallet) > 0 ? '#d6d6d6' : '#626262' }}>- {currency.currencySymbol}&nbsp;{(this.state.ultrapremium_wallet * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                                        </label>
                                                    </div>
                                                    <div className="custom-control custom-radio2">
                                                        <input type="radio" className="custom-control-input" id="RP_PW" name="plan" onChange={(e) => this.onChangePlan("RP_PW")} checked={this.state.plan == 'RP_PW'} />
                                                        <label className="custom-control-label" htmlFor="RP_PW" style={{ color: '#ded831' }}>
                                                            <i className="las la-wallet" />&nbsp;&nbsp;Premium <span style={{ color: parseInt(this.state.premium_wallet) > 0 ? '#d6d6d6' : '#626262' }}>- {currency.currencySymbol}&nbsp;{(this.state.premium_wallet * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                                        </label>
                                                    </div>
                                                    <div className="custom-control custom-radio2">
                                                        <input type="radio" className="custom-control-input" id="RP_LW" name="plan" onChange={(e) => this.onChangePlan("RP_LW")} checked={this.state.plan == 'RP_LW'} />
                                                        <label className="custom-control-label" htmlFor="RP_LW" style={{ color: '#de31be' }}>
                                                            <i className="las la-wallet" />&nbsp;&nbsp;Light <span style={{ color: parseInt(this.state.light_wallet) > 0 ? '#d6d6d6' : '#626262' }}>- {currency.currencySymbol}&nbsp;{(this.state.light_wallet * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                                        </label>
                                                    </div>
                                                    <div className="custom-control custom-radio2">
                                                        <input type="radio" className="custom-control-input" id="RP_BW" name="plan" onChange={(e) => this.onChangePlan("RP_BW")} checked={this.state.plan == 'RP_BW'} />
                                                        <label className="custom-control-label" htmlFor="RP_BW" style={{ color: '#de7331' }}>
                                                            <i className="las la-wallet" />&nbsp;&nbsp;Basic <span style={{ color: parseInt(this.state.basic_wallet) > 0 ? '#d6d6d6' : '#626262' }}>- {currency.currencySymbol}&nbsp;{(this.state.basic_wallet * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="mb-2" style={{ display: this.state.plan == 'BO' || this.state.plan == 'HP' ? '' : 'none' }}>
                                                <hr />
                                                <h5 className="all-font">Payment Mode :</h5>

                                                {/* <div className="custom-control custom-radio2">
                                                    <input type="radio" className="custom-control-input" id="razorpay" name="paymentmode" onChange={() => this.setState({ isRazorPay: true })} checked={this.state.isRazorPay} />
                                                    <label className="custom-control-label" htmlFor="razorpay">
                                                        G-Pay, PhonePay, Card
                                                    </label>
                                                </div>  */}

                                                <div className="custom-control custom-radio2">
                                                    <input type="radio" className="custom-control-input" id="others1" name="paymentmode" onChange={() => this.setState({ isRazorPay: false })} defaultChecked={!this.state.isRazorPay} />
                                                    <label className="custom-control-label" htmlFor="others1">
                                                        Pay by G-Pay, PhonePay
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-radio2">
                                                    <input type="radio" className="custom-control-input" id="others2" name="paymentmode" onChange={() => this.setState({ isRazorPay: false })} />
                                                    <label className="custom-control-label" htmlFor="others2">
                                                        Pay to Bank Account
                                                    </label>
                                                </div>

                                            </div>

                                            <button type="submit" className="btn btn-outline-primary-2 btn-order btn-block">
                                                <span className="btn-text">Place Order</span>
                                                <span className="btn-hover-text">Place Order</span>
                                            </button>
                                        </div>
                                    </aside>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

}

export const mapStateToProps = (state) => ({
    cartlist: state.cartlist.cart,
    total: getCartTotal(state.cartlist.cart),
    shipping: state.cartlist.shipping,
    currency: state.currencyData,
    products: state.data.products ? state.data.products : [],
    trackings: state.data.trackings ? state.data.trackings : [],
})

export default connect(mapStateToProps, { changetQty, removeFromCart })(Checkout);