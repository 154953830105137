import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import PageHeader from './../../components/page-header';

import { isIEBrowser } from './../../../utils/utils';

class HirkaniPlan extends Component {
    render() {
        return (
            <div className="main">
                <Helmet>
                    <title>Shivshahi Network</title>
                </Helmet>

                <h1 className="d-none">Shivshahi Network</h1>

                <PageHeader title="Hirkani" subTitle="Business Plan" />

                <div className="page-content pt-3 all-bg">
                    <div className="container all-bg-light mb-5">
                        <ul className="pt-3 pr-3 pl-5 all-font">
                            <li>❈&nbsp;&nbsp;सुरुवातीला तुम्हाला कमीत कमी <strong>पन्नास हजार</strong> रुपयाची इन्वेस्टमेंट करून तुम्ही कोणत्याही साड्या घरात ठेवू शकतात.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;कस्टमरला चॉईस मिळेल म्हणजे जे  कस्टमर ऑनलाईन एक ऑर्डर करणार होते ते कस्टमर ऑफलाईन जास्त ही साड्या घेऊ शकते.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;साडीच्या बिजनेस मध्ये ऑनलाइन पेक्षा ऑफलाइन मध्ये जास्त प्रमाणात सेल होतो म्हणजे जिथे तुम्ही एका महिन्यामध्ये ५० जॉइनिंग करणार होतात तिथं १०० जॉइनिंग होऊ शकतात.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;तुमच्याकडे साड्या उपलब्ध असल्या तर तुमच्या कस्टमरला कुरियरचा प्रॉब्लेमच येणार नाही. ते स्वतः समोर बघून लगेच घेऊ शकतात.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;ऑनलाईन बिझनेस वर काही कस्टमर लगेच विश्वास नाही ठेवत, त्यांना तुम्ही समोरासमोर दाखवून लगेच जॉईन करून घेऊ शकता.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;कस्टमरला समोर साडी दिसल्यावर कस्टमर नाराज होण्याचे चान्सेस खूप कमी असतात कारण ते त्यांच्या चॉईसने साड्या घेतात.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;महिनाअखेरीस कंपनी तुमच्या कडील उरलेला 50% स्टॉक एक्सचेंज करून देईल.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;तुम्ही हळूहळू इन्वेस्टमेंट वाढवत वाढवत तुम्ही तुमचे दुकान सुध्दा सुरू करू शकतात.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;शिपिंग चार्जेस प्रत्येक साडीवर 30 रुपये अशी लागेल.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;एका ऑर्डर नंबर खाली तुम्ही लोकांना जॉईन करू शकतात.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;साडी विकली तर त्यावर प्रॉफिट मिळणारच जॉइनिंग करो अथवा ना करो.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;तुम्हाला तुमचा प्रॉफिट महिना ते महिना दिले जाईल.(१ जानेवारी तारीख ला हिरकणी प्लॅन ऍक्टिव्हेट केला तर एक फेब्रुवारीला इतक्या साड्या सेल झाले आहे त्याचा प्रोफेट अकाउंटला जमा होईल)
                            (तेव्हाच एक्सचेंज करायचा असेल तर एक्सचेंज करू शकतात)
                            अशाप्रकारे थोड्याश्या इन्व्हेस्टमेंट मधे तुम्ही तुमचा बिझनेस आणि प्रॉफिट वाढवू शकता.</li>
                        </ul>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Link to={`${process.env.PUBLIC_URL}/shop`} className="btn btn-outline-primary mt-2 mb-4 cust-btn"><span>Get Started</span><i className="icon-long-arrow-right"></i></Link>
                        </div>
                    </div>

                    <div className="container all-bg-light text-center">
                        <h1 className="text-center mb-3 pt-3 all-font-light">Take Action Now</h1>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <ul className="pt-3 pb-3 all-font col-lg-8 col-md-9 col-sm-10">
                                <li>जर तुम्ही विचार करत असाल कि मि हा बिज़नेस माज्या जवळच्या लोकांना दाखवून बगतो/बगते, ते काय म्हणतील. ज्यांना खरंच काही करायचं नाहीये ते पहिले तुम्हाला निगेटिव प्रश्न विचारतील आणि तुम्हाला निगेटिव बनवण्याचा प्रयत्न करतील.</li>
                                <li className="pt-2">तुम्ही अभिमानाने सांगा की नेटवर्क मार्केटिंग बिजनेस आहे. आणि खूप चांगला आहे कारण या बिजनेस ने अमेरिकेमध्ये खूप सारे मिलेनियर, बिलिनिइयर्स बनवले आहे.</li>
                                <li className="pt-2">मग ते तुम्हाला विचारतात की तुम्ही ह्या बिज़नेस मध्ये जॉइन आहेत का?? जर तुम्ही जॉइन नसाल तर तुम्ही काहीच बोलू शकत नाही जर जॉइन असाल तर तुम्ही अभिमानने सांगू शकता कि हो मि जॉइन आहे, मला दर महीना एवढा प्रोफिट होतो,म्हणून आधि तुम्ही या बिज़नेस ला जॉइन असल पाहिजे जर तुम्ही जॉइन असाल तरच तुमच्या समोरच्या व्यक्ति वर इम्प्रेशन पड़ते</li>
                                <li className="pt-2">आणि होय तुम्हाला लाजायची बिलकुल गरज नाही,तुम्ही फक्त प्रोडक्ट विकत नाही आहात. तुम्ही समोरच्याला सेकंडरी इनकम सोर्स तयार करून देत आहात.</li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className="cta cta-display bg-image pt-4 pb-4" style={{ backgroundImage: `url(assets/images/backgrounds/bg-7.jpg)` }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-9 col-xl-7">
                                <div className={`row no-gutters ${isIEBrowser() ? '' : 'flex-column'} flex-sm-row align-items-sm-center`} >
                                    <div className="col">
                                        <h3 className="cta-title text-white">आपल्याकडे अधिक प्रश्न असल्यास</h3>
                                        <p className="cta-desc text-white">आमच्याशी संपर्क साधा मोकळ्या मनाने</p>
                                    </div>

                                    <div className="col-auto">
                                        <Link to={`${process.env.PUBLIC_URL}/faq`} className="btn btn-outline-white"><span>Ask Us</span><i className="icon-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HirkaniPlan;