import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import PageHeader from './../../components/page-header';

import { isIEBrowser } from './../../../utils/utils';

class ShipDeliverPolicy extends Component {
    render() {
        return (
            <div className="main">
                <Helmet>
                    <title>Shivshahi Network</title>
                </Helmet>

                <h1 className="d-none">Shivshahi Network</h1>

                <PageHeader title="Shipping & Delivery" subTitle="Policy" />

                <div className="page-content pt-3 all-bg">
                    <div className="container all-bg-light mb-5">
                        <ul className="pt-3 pr-3 pl-5 pb-3 all-font">
                            <li>❈&nbsp;&nbsp;आर्डर दिल्यानंतर, ती डीसप्याच करण्यासाठी २ ते ३ व्यावसायिक दिवस लागतात. नंतर ते वितरित होण्यासाठी ४ - ५ व्यावसायिक दिवस लागतात.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;तुमची ऑर्डर मिळाल्यानंतर तुम्हाला ३ दिवसानंतर ट्रॅकिंग तपशील प्राप्त होतो.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;ऑर्डर दिल्यानंतर ती पुढची प्रोसेस होण्या आधी ऑर्डर चा पत्ता एडिट करू शकता. किंवा ऑर्डर कॅन्सल करता येते.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;पण एकदा उत्पादन पॅकिंग आणि कुरिअर करण्याच्या प्रक्रियेत गेले की ऑर्डर रद्द केली जाऊ शकत नाही. आणि पत्ता ही बदलता येत नाही.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;ऑर्डर बद्दल कुठलीही माहिती हवी असेल तर खाली दिलेल्या नंबर वर. सकाळी ११ ते रात्री ७ वाजे पर्यंत संपर्क करू शकता.</li>
                        </ul>
                    </div>

                </div>

                <div className="cta cta-display bg-image pt-4 pb-4" style={{ backgroundImage: `url(assets/images/backgrounds/bg-7.jpg)` }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-9 col-xl-7">
                                <div className={`row no-gutters ${isIEBrowser() ? '' : 'flex-column'} flex-sm-row align-items-sm-center`} >
                                    <div className="col">
                                        <h3 className="cta-title text-white">आपल्याकडे अधिक प्रश्न असल्यास</h3>
                                        <p className="cta-desc text-white">आमच्याशी संपर्क साधा मोकळ्या मनाने</p>
                                    </div>

                                    <div className="col-auto">
                                        <Link to={`${process.env.PUBLIC_URL}/faq`} className="btn btn-outline-white"><span>Ask Us</span><i className="icon-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShipDeliverPolicy;