import React, { Component } from 'react';

class ProfileOne extends Component {
    render() {
        const { image, name, title, content } = this.props;

        return (
            <div className="member member-anim text-center">
                <figure className="member-media">
                    <img src={process.env.PUBLIC_URL + '/' + image} alt="member" />

                    <figcaption className="member-overlay">
                        <div className="member-overlay-content">
                            <h3 className="member-title all-font-light">{name}<span>{title}</span></h3>
                            <p style={{color: '#c2c2c2'}}>{content}</p>
                        </div>
                    </figcaption>
                </figure>

                <div className="member-content">
                    <h3 className="member-title all-font">{name}<span>{title}</span></h3>
                </div>
            </div>
        )
    }
}

export default ProfileOne;