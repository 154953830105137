import { ADD_TO_CART, REMOVE_FROM_CART, CHANGE_QTY, CHANGE_SHIPPING, RESET_CARTLIST, REPLACE_CARTLIST } from "../constants/action-types";
import { findIndex } from "../utils/utils";

export default function cartReducer(state = {
    cart: [],
    shipping: "standard",
}, action) {
    switch (action.type) {
        case ADD_TO_CART:
            const productId = action.product.id;
            const Size = action.size;
            const Stock = action.stock

            if (state.cart.some(product => product.id == productId && product.size == Size)) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    // don't use === type problem.
                    if (product.id == productId && product.size == Size) {
                        cartAcc.push({ ...product, size: parseInt(product.size), qty: parseInt(product.qty) + parseInt(action.qty), sum: (product.discount ? product.salePrice : product.price) * (parseInt(product.qty) + parseInt(action.qty)) }) // Increment qty
                    } else {
                        cartAcc.push(product)
                    }
                    return cartAcc
                }, [])
                return { ...state, cart }
            }
            return {
                ...state,
                cart: [
                    ...state.cart,
                    {
                        ...action.product,
                        qty: action.qty,
                        size: action.size,
                        sum: (action.product.discount ? action.product.salePrice : action.product.price) * action.qty
                    }
                ]
            }

        case REMOVE_FROM_CART:
            return {
                ...state,
                cart: state.cart.filter(item => item.id != action.productId ? item.id != action.productId : item.id == action.productId && item.size != action.productSize)
            };

        case CHANGE_QTY:
            const cart = state.cart.reduce((cartAcc, product) => {
                if (product.id == action.productId && product.size == action.size) {
                    cartAcc.push({ ...product, qty: action.qty, sum: (product.discount ? product.salePrice : product.price) * action.qty }) // Increment qty
                } else {
                    cartAcc.push(product)
                }
                return cartAcc
            }, [])

            return { ...state, cart };

        case CHANGE_SHIPPING:
            return { ...state, shipping: action.shipping };

        case RESET_CARTLIST:
            return { cart: [] }

        case REPLACE_CARTLIST:
            return { cart: action.cartlist }

        default:
            return state;
    }
}
