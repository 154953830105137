import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Magnifier } from "react-image-magnifiers";

import SingleProductComponent from './../components/base-detail-product';
import ProductDetail from './../components/productDetail';
// import InnerOverlay from './../components/overlay/inner-overlay';
import QuickView from './../components/sub-components/quickview';
import ErrorPage from './other/404';
// import ProductDetailSticky from '../components/productDetailSticky';

import { addToCart, toggleWishlist, addToCompare, showQuickViewModal, getDetailedProduct } from './../../actions';

class DetailProduct extends SingleProductComponent {

    componentDidMount() {
        getDetailedProduct(this.props.match.params.id);
        document.body.classList.add('all-bg');
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        document.body.classList.remove('all-bg');
    }

    render() {
        const { product, addToCart, currency, toggleWishlist, addToCompare } = this.props;

        if (!product) {
            return (
                <ErrorPage />
            )
        }

        const bigImages = product.lgPictures ? product.lgPictures : JSON.parse(product.pictures);
        const smallImages = product.smPictures ? product.smPictures : JSON.parse(product.pictures);

        var totalStock = 0;

        {
            JSON.parse(product.variants).map((item, index) => (
                totalStock = totalStock + parseInt(item.stock)
            ))
        }

        return (
            <div className="main">

                {/* <InnerOverlay /> */}

                <div className="page-content pt-3">
                    <div className="container">
                        <div className="product-details-top">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="product-gallery product-gallery-vertical">
                                        <div className="row" >
                                        
                                            <figure className="product-main-image" index="0">

                                                {(0 === totalStock) ? <span className="product-label label-out">Out of Stock</span> :
                                                    product.new ? <span className="product-label label-new">New</span> : ''}

                                                {(0 === totalStock) ? '' :
                                                    product.off ? <span className="product-label label-sale">{product.off}% off</span> :
                                                        ''}



                                                <Magnifier
                                                    imageSrc={JSON.parse(product.pictures)[0]}
                                                    imageAlt="Example"
                                                    largeImageSrc={bigImages[0]} // Optional
                                                    dragToMove={false}
                                                    mouseActivation="hover"
                                                    cursorStyleActive="crosshair"
                                                    id="product-zoom"
                                                />

                                                <Link to="#" id="btn-product-gallery" className="btn-product-gallery" onClick={this.openLightBox}>
                                                    <i className="icon-arrows"></i>
                                                </Link>
                                            </figure>
                               

                                            <div id="product-zoom-gallery" className="product-image-gallery">
                                                {
                                                    JSON.parse(product.pictures).map((item, index) =>
                                                        <Link className={`product-gallery-item ${0 === index ? 'active' : ''}`} to="#" data-image={item} data-zoom-image={bigImages[index]} key={product.id + '-' + index}>
                                                            <img src={process.env.PUBLIC_URL + '/' + smallImages[index]} alt="product back"/>
                                                        </Link>
                                                    )
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <ProductDetail
                                        product={product}
                                        currency={currency}
                                        onAddToCartClick={() => addToCart(product, document.querySelector("#qty").value, document.querySelector('#size').value, document.querySelector('#stock').value)}
                                        onToggleWishlistClick={() => toggleWishlist(product)}
                                        onAddToCompareClick={() => addToCompare(product)} />
                                </div>
                            </div>
                        </div>

                        {/* {this.productDetailTab()} */}

                        <hr />

                        <h2 className="title text-center mt-3 mb-4 all-font">You May Also Like</h2>

                        {this.relatedProducts(product, currency)}

                    </div>
                </div>

                {/* <ProductDetailSticky
                    product={product}
                    currency={currency}
                    onAddToCartClick={() => addToCart(product, document.querySelector("#sticky-qty").value, document.querySelector('#sticky-size').value)}
                    onToggleWishlistClick={() => toggleWishlist(product)}
                    onAddToCompareClick={() => addToCompare(product)}
                /> */}

                { this.lightbox()}

                <QuickView />
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const product_id = props.match.params.id;

    return {
        products: state.data.products ? state.data.products : [],
        product: state.data.products.filter(item => item.id == product_id)[0],
        currency: state.currencyData,
    }
}

export default connect(
    mapStateToProps, { addToCart, toggleWishlist, addToCompare, showQuickViewModal, getDetailedProduct }
)(DetailProduct);