import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class Connections extends Component {
    render() {
        const { start, end, show } = this.props;

        return (
            <div className="mb-0">
                <div className="container pt-3">
                    <h2 className="title text-center mb-3 all-font" style={{ fontSize: '50px' }}>Our Offices</h2>
                </div>

                <div className={`bg-image bg-overlay pt-5 pb-6 ${show === 'yes' ? 'mb-6' : 'mb-4'} bg-parallax`} style={{ backgroundImage: 'url(/assets/images/backgrounds/bg-large.jpg)' }}>
                    <div className="container">

                        <div className={`container ${show === 'yes' ? '' : 'mb-5'}`}>
                            <div className="row elements">
                                {this.props.networks.map((item, index) => (
                                    <div className="col-xl-5col col-lg-3 col-md-3 col-sm-4 col-6" key={index} style={{ cursor: 'pointer' }}>
                                        <div className="element" style={{ textAlign: 'center' }}>
                                            <p style={{ color: 'white', fontSize: '130%', textTransform: 'uppercase', fontWeight: '600' }} className="pt-3">{item.name}</p>
                                            <p style={{ color: '#adadad' }} className={`${show === 'no' ? 'pb-2' : 'pt-1 pb-0'}`}>{item.handler}</p>
                                            <div className={`${show === 'no' ? 'pb-2' : 'pb-3'} pt-0`}>
                                                <a href={`tel://${item.contact}`} style={{ display: `${show === 'no' ? 'none' : item.contact ? 'block' : 'none'}` }}>{item.contact}</a>
                                                <a href={`tel://${item.contact2}`} style={{ display: `${show === 'no' ? 'none' : item.contact2 ? 'block' : 'none'}` }}>{item.contact2}</a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="row" style={{ justifyContent: 'center', display: `${show === 'yes' ? 'none' : ''}` }}>
                            <div className="col-6 col-lg-4 col-xl-2">
                                <div className="btn-wrap">
                                    <Link to={`${process.env.PUBLIC_URL}/contact`} className={`${show === 'no' ? 'btn btn-custom' : 'btn btn-primary'}`}>See More...</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        networks: state.data.networks ? state.data.networks : []
    };
}

export default connect(mapStateToProps)(Connections);