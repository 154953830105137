import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { removeFromCart } from './../../../actions';
import { isIEBrowser } from './../../../utils/utils';

import * as orderApi from './../../../api/order'

import Accordion from './../../components/accordion/accordion';
import Card from './../../components/accordion/card';

class OrderSuccess extends Component {

    constructor(props) {
        super(props)

        this.state = {
            display_orderid: "",
            orderdate: "",
            deliverydate: "",
            firstname: "",
            lastname: "",
            address: "",
            city: "",
            district: "",
            state: "",
            pincode: "",
            phone: "",
            email: "",
            subtotal: "",
            shipping: "",
            total: "",
            hirkani: "",
            wallet: "",
            grandtotal: "",
            items: [],
            upline: [],
            profits: [],
            orderStatus: '',
            paymentStatus: '',
            plan: '',
            isgpay: true,
            fileArray: [],
            old_payment_receipts: null
        }
    }

    componentDidMount() {
        var checkusertoken = localStorage.getItem('usertokenShivshahiCustomer');
        // console.log(checkusertoken)
        if (checkusertoken == null) {
            this.props.history.push(`${process.env.PUBLIC_URL}/auth/login-register/:data`)
        } else {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const data = {
                order_id: this.props.match.params.orderid,
                userToken: localStorage.usertokenShivshahiCustomer
            }

            orderApi.fetchSingleOrder(data)
                .then(res => {
                    if (res.status === 200) {
                        const singleOrder = res.data

                        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
                        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
                        var orderDate = new Date(singleOrder.order_date);
                        var deliveryDate = new Date(singleOrder.delivery_date);
                        var orderDateFinal = days[orderDate.getDay()] + ', ' + monthNames[orderDate.getMonth()] + ' ' + orderDate.getDate() + ', ' + orderDate.getFullYear();
                        var deliveryDateFinal = days[deliveryDate.getDay()] + ', ' + monthNames[deliveryDate.getMonth()] + ' ' + deliveryDate.getDate() + ', ' + deliveryDate.getFullYear();

                        this.setState({
                            display_orderid: parseInt(singleOrder.id) + 9999,
                            orderdate: orderDateFinal,
                            deliverydate: deliveryDateFinal,
                            firstname: singleOrder.firstname,
                            lastname: singleOrder.lastname,
                            address: singleOrder.address,
                            city: singleOrder.city,
                            district: singleOrder.district,
                            state: singleOrder.state,
                            pincode: singleOrder.pincode,
                            phone: singleOrder.phone,
                            email: singleOrder.email,
                            subtotal: singleOrder.subtotal,
                            shipping: singleOrder.shipping,
                            total: singleOrder.total,
                            hirkani: singleOrder.hirkani,
                            wallet: singleOrder.wallet,
                            grandtotal: singleOrder.grandtotal,
                            items: JSON.parse(singleOrder.items),
                            upline: JSON.parse(singleOrder.upline),
                            profits: JSON.parse(singleOrder.profits),
                            orderStatus: singleOrder.status,
                            paymentStatus: singleOrder.payment_status,
                            plan: JSON.parse(singleOrder.profits)[0].scheme,
                            old_payment_receipts: singleOrder.payment_receipts
                        }, () => {
                            if (this.props.cartlist.length > 0) {
                                this.props.cartlist.map((item, index) => {
                                    this.props.removeFromCart(item.id, item.size)
                                })
                            }
                            setTimeout(() => {
                                spinner.style.display = "none";
                            }, 500);
                        })

                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Please Login Again...!')
                        // localStorage.removeItem('usertokenShivshahiCustomer')
                        localStorage.clear();
                        window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                    } else {
                        alert('Something Went Wrong')
                        this.props.history.push(`${process.env.PUBLIC_URL}/my-account/orders`)
                    }
                })
                .catch((err) => {
                    alert('Something Went Wrong')
                    this.props.history.push(`${process.env.PUBLIC_URL}/my-account/orders`)
                })

        }

    }

    uploadReceipt = (e) => {
        var files = e.target.files
        var fileArray = [];

        for (var i = 0; i < files.length; i++) {
            fileArray.push(files[i])
            if (
                files[i].type.toLowerCase() != 'image/jpg' &&
                files[i].type.toLowerCase() != 'image/jpeg' &&
                files[i].type.toLowerCase() != 'image/png'
            ) {
                alert('Please upload a valid file..!')
                fileArray = [];
                i = files.length
            }
        }
        this.setState({ fileArray: fileArray })
    }

    uploadPaymentReceipt = e => {
        e.preventDefault();

        if (this.state.fileArray.length <= 0) {
            alert('Please re-upload the files..!')
        } else {
            if (window.confirm('Do you want to Upload the Receipts ?')) {
                let spinner = document.getElementById("spinneranimid");
                spinner.style.display = "block";

                let data = {
                    id: this.state.display_orderid - 9999,
                }

                const formData = new FormData()
                for (let j = 0; j < this.state.fileArray.length; j++) {
                    formData.append('files', this.state.fileArray[j])
                }
                formData.append('data', JSON.stringify(data))

                orderApi.uploadPaymentReceipt(formData)
                    .then(res => {
                        if (res.status == 200) {
                            alert('Thank you..! Payment Receipts are uploaded. Order will be placed after 1-2 hours..!')
                            spinner.style.display = "none";
                            window.location.reload();
                        } else {
                            alert('Something went wrong')
                            spinner.style.display = "none";
                            window.location.reload();
                        }
                    })
                    .catch((err) => {
                        alert('Something went wrong')
                        spinner.style.display = "none";
                        window.location.reload();
                    })
            }

        }
    }

    viewImages = () => {
        if (this.state.old_payment_receipts != null) {
            JSON.parse(this.state.old_payment_receipts).map((image, index) => {
                window.open(`${process.env.PUBLIC_URL}/assets/images/order-payment-receipts/${image}`);
            })
        } else {
            alert('There are no Old receipts uploaded')
        }
    }

    render() {
        const { currency } = this.props;

        return (
            <div className='order-success all-bg'>

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <section className="section-top mt-5 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-10 col-sm-11 col-12 all-bg-light pb-0">
                                <i className="icon icon-check-circle-o" aria-hidden="true" />
                                <h2>Thank you..</h2>
                                <div>Your Order has been placed Successfully</div>
                                <p>Order ID :&nbsp;&nbsp;<span>{this.state.display_orderid}</span></p>
                                <p style={{ display: `${this.state.paymentStatus == 'Completed' ? 'none' : ''}` }}>
                                    <span style={{ color: 'red' }}>Your Payment is Pending.. Please do it within 6 hours</span>

                                    <div>पेमेंट संबंधी काहीही माहिती हवी असेल तर संपर्क साधा. +91 8180070617</div>

                                    <Accordion adClass="accordion-plus">
                                        <Card title='Make Payment and Upload Receipts' adClass="card-box card-sm all-bg">
                                            <small>पेमेंट पावती अपलोड केल्यानंतर, कृपया पेमेंट मंजुरीची प्रतीक्षा करा.ऑर्डर 1-2 तासात Place होईल.</small>
                                            <p>Pending Payment :&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#ff2b2b' }}>{currency.currencySymbol}&nbsp;{(this.state.grandtotal * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                            <div className="custom-control custom-radio2" style={{ textAlign: 'start' }}>
                                                <input type="radio" className="custom-control-input" id="others1" name="paymentmode" onChange={() => this.setState({ isgpay: true })} checked={this.state.isgpay} />
                                                <label className="custom-control-label" htmlFor="others1">
                                                    Pay by G-Pay, PhonePay
                                                </label>
                                            </div>

                                            <div className='ml-5' style={{ display: this.state.isgpay ? '' : 'none', textAlign: 'start' }}>G-Pay : <span style={{ color: '#fff' }}>8446703040</span></div>
                                            <div className='ml-5' style={{ display: this.state.isgpay ? '' : 'none', textAlign: 'start' }}>UPI ID : <span style={{ color: '#fff' }}>shivshahinetwork@okaxis</span></div>
                                            <img className='mt-1 ml-5' src={`${process.env.PUBLIC_URL}/assets/images/qr_code.jpeg`} alt="upi qr" style={{ width: '190px', display: this.state.isgpay ? '' : 'none' }} />

                                            <div className="custom-control custom-radio2" style={{ textAlign: 'start' }}>
                                                <input type="radio" className="custom-control-input" id="others2" name="paymentmode" onChange={() => this.setState({ isgpay: false })} />
                                                <label className="custom-control-label" htmlFor="others2">
                                                    Pay to Bank Account
                                                </label>
                                            </div>

                                            <div className='ml-5' style={{ display: this.state.isgpay ? 'none' : '', textAlign: 'start' }}>Bank Name&emsp;&nbsp;: <span style={{ color: '#fff' }}>ICICI Bank</span></div>
                                            <div className='ml-5' style={{ display: this.state.isgpay ? 'none' : '', textAlign: 'start' }}>A/c Name&emsp;&emsp;: <span style={{ color: '#fff' }}>ShivShahi Network</span></div>
                                            <div className='ml-5' style={{ display: this.state.isgpay ? 'none' : '', textAlign: 'start' }}>A/c Number&emsp;: <span style={{ color: '#fff' }}>376405001968</span></div>
                                            <div className='ml-5' style={{ display: this.state.isgpay ? 'none' : '', textAlign: 'start' }}>IFSC Code&emsp;&emsp;: <span style={{ color: '#fff' }}>icici0003764</span></div>
                                            <div className='ml-5' style={{ display: this.state.isgpay ? 'none' : '', textAlign: 'start' }}>Branch Name&nbsp;: <span style={{ color: '#fff' }}>Yeola</span></div>
                                            <div className='ml-5' style={{ display: this.state.isgpay ? 'none' : '', textAlign: 'start' }}>Account Type&nbsp;: <span style={{ color: '#fff' }}>Current</span></div>

                                            <hr />

                                            <form onSubmit={this.uploadPaymentReceipt}>
                                                <input type="file" multiple="multiple" accept="image/*" id="fileLoader" name="files" title="Load File" onChange={this.uploadReceipt} required />

                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <button type="submit" className="btn btn-outline-primary-2 mt-3 mb-1">
                                                        <span>Upload Receipts</span>
                                                    </button>
                                                </div>
                                            </form>
                                            <div style={{ fontSize: '13px', color: '#40a0a3', cursor: 'pointer' }} onClick={this.viewImages}>View Old Receipts</div>

                                            <div>पेमेंट संबंधी काहीही माहिती हवी असेल तर संपर्क साधा. +91 8180070617</div>

                                        </Card>
                                    </Accordion>

                                </p>
                                <hr />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-middle">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-10 col-sm-11 col-12 all-bg-light pb-0">
                                <h4>Order and Shipping Info</h4>
                                <div>
                                    <div className="left">
                                        <h5>Order Details</h5>
                                        <ul>
                                            <li>Order Date :&nbsp;&nbsp;<span>{this.state.orderdate}</span></li>
                                            <li>Expected Delivery :&nbsp;&nbsp;<span>{this.state.deliverydate}</span></li>
                                            <li>Phone :&nbsp;&nbsp;<span>{this.state.phone}</span></li>
                                            <li>Email :&nbsp;&nbsp;<span>{this.state.email}</span></li>
                                        </ul>
                                    </div>
                                    <div className="right">
                                        <h5>Shipping Address</h5>
                                        <ul>
                                            <li>{this.state.firstname}&nbsp;&nbsp;{this.state.lastname}</li>
                                            <li>{this.state.address},</li>
                                            <li>{this.state.pincode}.</li>
                                            {/* <li>{this.state.address}, {this.state.city}, Dist: {this.state.district},</li>
                                            <li>{this.state.state}, {this.state.pincode}.</li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="section-middle" style={{ display: `${this.state.parent.length ? '' : 'none'}` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-10 col-sm-11 col-12 all-bg-light pb-0">
                                <div>
                                    <div className="left">
                                        <h5>1<sup>st</sup> Upline</h5>
                                        <ul>
                                            <li>ID :&nbsp;&nbsp;<span>{this.state.parent.ownref}</span></li>
                                            <li>Name :&nbsp;&nbsp;<span>{this.state.parent.firstname}&nbsp;{this.state.parent.lastname}</span></li>
                                            <li>Phone :&nbsp;&nbsp;<span>{this.state.parent.phone}</span></li>
                                        </ul>
                                    </div>
                                    <div className="right">
                                        <h5>2<sup>nd</sup> Upline</h5>
                                        <ul>
                                            <li>ID :&nbsp;&nbsp;<span>{this.state.grandparent.ownref}</span></li>
                                            <li>Name :&nbsp;&nbsp;<span>{this.state.grandparent.firstname}&nbsp;{this.state.grandparent.lastname}</span></li>
                                            <li>Phone :&nbsp;&nbsp;<span>{this.state.grandparent.phone}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className='middle-bottom'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-10 col-sm-11 col-12 all-bg-light pb-0">
                                <h5>Scheme : <span>{this.state.plan}</span></h5>
                                <div>
                                    <div>
                                        <ul>
                                            {this.state.profits.map((item, index) =>
                                                index != 0 ?
                                                    // <li key={index}>
                                                    //     <p>
                                                    //         Upline {index} :&nbsp;&nbsp;<span>{currency.currencySymbol}&nbsp;{(item.profit * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span> - ({item.name} - {item.phone})
                                                    //     </p>
                                                    // </li>
                                                    <li key={index}></li>
                                                    :
                                                    <li key={index}>
                                                        <p>
                                                            Your Profit :&nbsp;&nbsp;<span>{currency.currencySymbol}&nbsp;{(item.profit * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span> - ({item.name} - {item.phone})
                                                        </p>
                                                    </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-bottom mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-10 col-sm-11 col-12 all-bg-light pb-0">
                                <hr />
                                <h4>Your Order Details</h4>
                                <div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className='first'>Product</th>
                                                <th className='order-items-mobile'>Price</th>
                                                <th className='order-items-mobile'>Size</th>
                                                <th className='order-items-mobile'>Quantity</th>
                                                <th className='order-items-mobile'>Total</th>
                                                <th className='order-items-mobile-show'>Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.items.map((item, index) =>
                                                <tr key={index}>
                                                    <td className='first'>
                                                        <div className="product all-bg-light" style={{ justifyContent: 'start' }}>
                                                            <figure style={{ width: '50px' }}>
                                                                <img src={process.env.PUBLIC_URL + '/' + item.pictures[0]} alt="Product" style={{ width: '100%' }} />
                                                            </figure>
                                                            <h4 className="product-title pl-5" style={{ color: '#c2c2c2' }}>
                                                                <Link to={`${process.env.PUBLIC_URL}/shop/detail/${item.id}`}>{item.name}</Link> - <div className="color-display" style={{ backgroundColor: item.color }} />
                                                            </h4>
                                                        </div>
                                                    </td>

                                                    <td className='order-items-mobile'>
                                                        {currency.currencySymbol}&nbsp;
                                                            {(item.price * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </td>

                                                    <td className='order-items-mobile'>{item.size} Yards</td>

                                                    <td className='order-items-mobile'>{item.qty}</td>

                                                    <td className='order-items-mobile'>
                                                        <strong>
                                                            {currency.currencySymbol}&nbsp;
                                                             {(item.sum * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                        </strong>
                                                    </td>

                                                    <td className='order-items-mobile-show'>
                                                        <ul>
                                                            <li>
                                                                Price : <span>{currency.currencySymbol}&nbsp;
                                                                        {(item.price * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                                            </li>
                                                            <li>Size : <span>{item.size} Yards</span></li>
                                                            <li>Quantity : <span>{item.qty}</span></li>
                                                            <li>
                                                                Total : <span>{currency.currencySymbol}&nbsp;
                                                                        {(item.sum * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                                            </li>
                                                        </ul>
                                                    </td>

                                                </tr>
                                            )}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th colSpan="5">
                                                    <p>Subtotal :&nbsp;&nbsp;&nbsp;&nbsp;<span>{currency.currencySymbol}&nbsp;{(this.state.subtotal * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                                    <p>Shipping :&nbsp;&nbsp;&nbsp;+&nbsp;<span>{currency.currencySymbol}&nbsp;{(this.state.shipping * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                                    <p>Hirkani :&nbsp;&nbsp;&nbsp;-&nbsp;<span>{currency.currencySymbol}&nbsp;{(this.state.hirkani * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                                    <p>Wallet :&nbsp;&nbsp;&nbsp;-&nbsp;<span>{currency.currencySymbol}&nbsp;{(this.state.wallet * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                                    <p className="last">Total :&nbsp;&nbsp;&nbsp;&nbsp;<span>{currency.currencySymbol}&nbsp;{(this.state.grandtotal * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                                </th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="cta cta-display bg-image pt-4 pb-4" style={{ backgroundImage: `url(assets/images/backgrounds/bg-7.jpg)` }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-9 col-xl-7">
                                <div className={`row no-gutters ${isIEBrowser() ? '' : 'flex-column'} flex-sm-row align-items-sm-center`} >
                                    <div className="col">
                                        <h3 className="cta-title text-white">Want to Look even Gorgeous..?</h3>
                                        <p className="cta-desc text-white">Lets Go for the shopping</p>
                                    </div>

                                    <div className="col-auto">
                                        <Link to={`${process.env.PUBLIC_URL}/shop`} className="btn btn-outline-white"><span>Shop More</span><i className="icon-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        cartlist: state.cartlist.cart,
        currency: state.currencyData,
    };
};


export default connect(mapStateToProps, { removeFromCart })(OrderSuccess);
