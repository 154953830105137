import React, { Component } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import * as authApi from './../../../api/auth'

class LoginRegister extends Component {

    constructor(props) {
        super(props)

        this.state = {
            signemailorphone: "",
            signpass: "",
            passwordhiddenSignIn: true,
            capsSignIn: false,
            regfirstname: "",
            reglastname: "",
            regemail: "",
            regphone: "",
            regcity: "",
            regpass: "",
            regconfpass: "",
            referenceby: "",
            passwordhiddenSignUp: true,
            capsSignUp: false,
            isreferred: 0,
        }
    }

    componentWillMount() {
        var data = this.props.match.params.data;
        if (data != ':data') {
            this.setState({ isreferred: 1 })
        }
    }

    componentDidMount() {
        var data = this.props.match.params.data;
        var checkusertoken = localStorage.getItem('usertokenShivshahiCustomer');

        if (checkusertoken != null) {
            this.props.history.push(`${process.env.PUBLIC_URL}/`)
        } else if (data != ':data') {
            this.setState({ referenceby: data })
        }
    }

    togglePasswordVisibilitySignIN = () => {
        if (this.state.passwordhiddenSignIn === true) {
            this.setState({ passwordhiddenSignIn: false });
        } else {
            this.setState({ passwordhiddenSignIn: true });
        }
    }

    togglePasswordVisibilitySignUP = () => {
        if (this.state.passwordhiddenSignUp === true) {
            this.setState({ passwordhiddenSignUp: false });
        } else {
            this.setState({ passwordhiddenSignUp: true });
        }
    }

    handleChangeSignIN = e => {
        this.setState({ [e.target.name]: e.target.value.replace(new RegExp("\\s*", "gm"), '') });

        //Caps Lock warning
        let signpass = document.getElementById("signpass");

        signpass.addEventListener("keyup", e => {
            if (e.getModifierState("CapsLock")) {
                this.setState({ capsSignIn: true });
            } else {
                this.setState({ capsSignIn: false });
            }
        });
    }

    handleChangeSignUP = e => {
        this.setState({ [e.target.name]: e.target.value.replace(new RegExp("\\s*", "gm"), '') });

        //Caps Lock warning
        let regpass = document.getElementById("regpass");
        let regconfpass = document.getElementById("regconfpass");

        regpass.addEventListener("keyup", e => {
            if (e.getModifierState("CapsLock")) {
                this.setState({ capsSignUp: true });
            } else {
                this.setState({ capsSignUp: false });
            }
        });

        regconfpass.addEventListener("keyup", e => {
            if (e.getModifierState("CapsLock")) {
                this.setState({ capsSignUp: true });
            } else {
                this.setState({ capsSignUp: false });
            }
        });
    }

    handleSubmitLogin = e => {
        e.preventDefault();

        let emailpasswrongalert = document.getElementById('emailpasswrongalert');
        let verificationpendingalert = document.getElementById('verificationpendingalert');
        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";
        emailpasswrongalert.style.display = "none";
        verificationpendingalert.style.display = "none";

        const data = {
            emailorphone: this.state.signemailorphone,
            password: this.state.signpass,
        }

        if (this.state.signemailorphone == '' || this.state.signpass == '') {
            alert('Please enter all the fields..!')
        } else {

            authApi.login(data)
                .then(res => {
                    if (res.status == 200) {
                        localStorage.setItem("usertokenShivshahiCustomer", res.data);
                        window.location = process.env.PUBLIC_URL + '/'
                    } else if (res.status == 405) {
                        emailpasswrongalert.style.display = "block";
                        spinner.style.display = "none";
                    } else if (res.status == 401 && res.error == 'Account Verification Pending') {
                        verificationpendingalert.style.display = "block";
                        spinner.style.display = "none";
                    } else {
                        alert('Something went Wrong')
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Something went Wrong')
                    spinner.style.display = "none";
                })

        }

    }

    handleSubmitReg = e => {
        e.preventDefault();

        let passmissmatchalert = document.getElementById('passmissmatchalert');
        let userexistsalert = document.getElementById('userexistsalert');
        let invalidreferralalert = document.getElementById('invalidreferralalert');
        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";
        passmissmatchalert.style.display = "none";
        userexistsalert.style.display = "none";
        invalidreferralalert.style.display = "none";

        if (this.state.regpass !== this.state.regconfpass) {
            passmissmatchalert.style.display = "block";
            spinner.style.display = "none";
        } else if (
            this.state.regfirstname == '' || this.state.reglastname == '' ||
            this.state.regphone == '' || this.state.regemail == '' ||
            this.state.regphone == 0 || this.state.regemail == 0 ||
            this.state.regpass == '' || this.state.regconfpass == '' ||
            // this.state.referenceby == '' ||
            this.state.regcity == ''
        ) {
            alert('Please enter all the fields..!')
            spinner.style.display = "none";
        } else {
            const newUser = {
                firstname: this.state.regfirstname,
                lastname: this.state.reglastname,
                phone: this.state.regphone,
                email: this.state.regemail,
                password: this.state.regpass,
                referenceby: this.state.referenceby.toLowerCase(),
                city: this.state.regcity,
            }

            authApi.createUser(newUser)
                .then(res => {
                    if (res.status == 200) {
                        this.props.history.push(`${process.env.PUBLIC_URL}/auth/verifyEmail`)
                    } else if (res.status == 405) {
                        if (res.error == 'Email Already Exists' || res.error == 'Phone Already Exists') {
                            userexistsalert.style.display = "block";
                        } else if (res.error == 'Invalid Sponsor ID') {
                            invalidreferralalert.style.display = "block";
                        }
                        spinner.style.display = "none";
                    } else {
                        alert('Something went Wrong. Please check the mobile number and email..!')
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Something went Wrong')
                    spinner.style.display = "none";
                })
        }

    }

    render() {
        return (
            <div className="main">
                <Helmet>
                    <title>Shivshahi Network</title>
                </Helmet>

                <h1 className="d-none">Shivshahi Network</h1>

                <div className="login-page bg-image pt-8 pb-8 pt-md-12 pb-md-12 pt-lg-17 pb-lg-17" style={{ backgroundImage: `url('assets/images/backgrounds/login-bg.jpg')` }}>
                    <div className="container">
                        <div className="form-box all-bg">
                            <div className="form-tab">

                                <div id="spinneranimid" className="overlay-spinner">
                                    <div className="cssload-whirlpool" />
                                </div>

                                <Tabs selectedTabClassName="show" defaultIndex={this.state.isreferred}>
                                    <TabList className="nav nav-pills nav-fill">
                                        <Tab className="nav-item all-font">
                                            <span className="nav-link">Sign In</span>
                                        </Tab>

                                        <Tab className="nav-item all-font">
                                            <span className="nav-link">Register</span>
                                        </Tab>
                                    </TabList>

                                    <div className="tab-content">
                                        <TabPanel style={{ paddingTop: "2rem" }}>

                                            <div className="alert alert-danger mb-2" id="emailpasswrongalert" role="alert">
                                                Email or Password is Incorrect!
                                            </div>

                                            <div className="alert alert-warning mb-2" id="verificationpendingalert" role="alert">
                                                Account Verification Pending, please verify the email first!
                                            </div>

                                            <form onSubmit={this.handleSubmitLogin}>
                                                <div className="form-group">
                                                    <label className="all-font-dark" htmlFor="signemailorphone">E-mail or Phone *</label>
                                                    <input type="text" className="form-control all-font" id="signemailorphone" name="signemailorphone" value={this.state.signemailorphone} pattern=".*\S+.*" onChange={this.handleChangeSignIN} required />
                                                </div>

                                                <div className="form-group">
                                                    <label className="all-font-dark" htmlFor="signpass">Password *</label>
                                                    <div className="input-group">
                                                        <input type={this.state.passwordhiddenSignIn ? "password" : "text"} value={this.state.signpass} className="form-control all-font" id="signpass" name="signpass" pattern=".*\S+.*" onChange={this.handleChangeSignIN} required />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text" id="toggle-visibility-signpass" onClick={this.togglePasswordVisibilitySignIN}><i className="icon-eye" /></span>
                                                        </div>
                                                    </div>
                                                    <div id="capswarningidSignIn" className="capswarn" style={{ display: `${this.state.capsSignIn ? '' : 'none'}` }}>Caps Lock is ON...!!</div>
                                                </div>

                                                <div className="form-footer">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="signremember" />
                                                        <label className="custom-control-label all-font-dark" htmlFor="signremember">Remember Me</label>
                                                    </div>

                                                    <Link className="forgot-link" to={`${process.env.PUBLIC_URL}/auth/forgotPassword`} >Forgot Your Password?</Link>
                                                </div>

                                                <button type="submit" className="btn btn-outline-primary-2">
                                                    <span>LOG IN</span>
                                                    <i className="icon-long-arrow-right"></i>
                                                </button>
                                            </form>
                                        </TabPanel>

                                        <TabPanel>
                                            <div className="alert alert-danger mb-2" id="passmissmatchalert" role="alert">
                                                The Passwords are not Matching..!
                                            </div>

                                            <div className="alert alert-danger mb-2" id="userexistsalert" role="alert">
                                                Email or Phone is already registered. Try Sign In..!
                                            </div>

                                            <div className="alert alert-danger mb-2" id="invalidreferralalert" role="alert">
                                                The Referral Code you entered is invalid..!!
                                            </div>

                                            <form onSubmit={this.handleSubmitReg}>


                                                <div className="form-group">
                                                    <label className="all-font-dark" htmlFor="referenceby">Your Sponsor ID *</label>
                                                    <input type="text" className="form-control all-font" id="referenceby" name="referenceby" value={this.state.referenceby} pattern=".*\S+.*" onChange={this.handleChangeSignUP} required />
                                                </div>

                                                <div className="form-group" style={{ display: 'flex' }}>
                                                    <div className="col-6" style={{ paddingLeft: '0px' }}>
                                                        <label className="all-font-dark" htmlFor="regfirstname">First Name *</label>
                                                        <input type="text" className="form-control all-font" id="regfirstname" name="regfirstname" value={this.state.regfirstname} pattern=".*\S+.*" onChange={this.handleChangeSignUP} required />
                                                    </div>
                                                    <div className="col-6" style={{ paddingRight: '0px' }}>
                                                        <label className="all-font-dark" htmlFor="reglastname">Last Name *</label>
                                                        <input type="text" className="form-control all-font" id="reglastname" value={this.state.reglastname} name="reglastname" pattern=".*\S+.*" onChange={this.handleChangeSignUP} required />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="all-font-dark" htmlFor="regcity">City *</label>
                                                    <input type="text" className="form-control all-font" id="regcity" value={this.state.regcity} name="regcity" pattern=".*\S+.*" onChange={this.handleChangeSignUP} required />
                                                </div>

                                                <div className="form-group">
                                                    <label className="all-font-dark" htmlFor="regphone">Phone Number *</label>
                                                    <input type="text" pattern="\d*" minLength="10" maxLength="10" className="form-control all-font" id="regphone" value={this.state.regphone} name="regphone" onChange={this.handleChangeSignUP} required />
                                                </div>

                                                <div className="form-group">
                                                    <label className="all-font-dark" htmlFor="regemail">E-mail address *</label>
                                                    <input type="email" className="form-control all-font" id="regemail" value={this.state.regemail} name="regemail" pattern=".*\S+.*" onChange={this.handleChangeSignUP} required />
                                                </div>

                                                <div className="form-group">
                                                    <label className="all-font-dark" htmlFor="regpass">Create Password *</label>
                                                    <div className="input-group">
                                                        <input type={this.state.passwordhiddenSignUp ? "password" : "text"} value={this.state.regpass} className="form-control all-font" id="regpass" name="regpass" pattern=".*\S+.*" onChange={this.handleChangeSignUP} required />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text" id="toggle-visibility-regpass" onClick={this.togglePasswordVisibilitySignUP}><i className="icon-eye" /></span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="all-font-dark" htmlFor="regconfpass">Confirm Password *</label>
                                                    <div className="input-group">
                                                        <input type={this.state.passwordhiddenSignUp ? "password" : "text"} value={this.state.regconfpass} className="form-control all-font" id="regconfpass" name="regconfpass" pattern=".*\S+.*" onChange={this.handleChangeSignUP} required />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text" id="toggle-visibility-regconfpass" onClick={this.togglePasswordVisibilitySignUP}><i className="icon-eye" /></span>
                                                        </div>
                                                    </div>
                                                    <div id="capswarningidSignUP" className="capswarn" style={{ display: `${this.state.capsSignUp ? '' : 'none'}` }}>Caps Lock is ON...!!</div>
                                                </div>

                                                <div className="form-footer">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="regpolicy" required />
                                                        <label className="custom-control-label all-font-dark" htmlFor="regpolicy">
                                                            I agree to the <Link to={`${process.env.PUBLIC_URL}/privacy-policy`} target="_blank">privacy policy</Link> *
                                                        </label>
                                                    </div>
                                                </div>

                                                <button type="submit" className="btn btn-outline-primary-2">
                                                    <span>SIGN UP</span>
                                                    <i className="icon-long-arrow-right"></i>
                                                </button>

                                            </form>
                                        </TabPanel>
                                    </div>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoginRegister;