import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { toggleCategoryFilter } from './../../../actions';

class CatagoryGridSingle extends Component {
    render() {
        const { image, category, count, type, filtername } = this.props;

        return (
            <div className="banner banner-cat banner-link-anim">
                <Link to={`${process.env.PUBLIC_URL}/shop`}
                // onClick={(e) => this.props.toggleCategoryFilter(filtername)}
                >
                    <img src={process.env.PUBLIC_URL + '/' + image} alt="Banner" />
                </Link>

                <div className={`banner-content ${type}`}>
                    <h3 className="banner-title" style={{ color: 'white' }}>{category.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</h3>
                    <h4 className="banner-subtitle all-font">{count} collection</h4>
                    <Link
                        to={`${process.env.PUBLIC_URL}/shop`}
                        className="banner-link"
                    // onClick={(e) => this.props.toggleCategoryFilter(filtername)}
                    >
                        Shop Now
                    </Link>
                </div>
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        filters: state.filters,
        products: state.data.products ? state.data.products : [],
        currency: state.currencyData,
    }
}

export default connect(mapStateToProps, { toggleCategoryFilter })(CatagoryGridSingle);