import React, { Component } from 'react';
import { connect } from 'react-redux';

import CatagoryGridSingle from './sub-components/catagoryGridSingle';

import { getCountByCategory } from './../../services';

class CollectionGrid extends Component {

    render() {

        // var catcount1 = getCountByCategory(this.props.products, "KHAN SAREE")
        // var catcount2 = getCountByCategory(this.props.products, "ROYAL BORDER")
        // var catcount3 = getCountByCategory(this.props.products, "Sico Irkal Border Saree")
        // var catcount4 = getCountByCategory(this.props.products, "MUNIYA BORDER")
        // var catcount5 = getCountByCategory(this.props.products, "SICO SILK")
        // var catcount6 = getCountByCategory(this.props.products, "ALL OVER ZARI DESIGN")

        return (
            <div className="container pt-3 mb-2">

                <h2 className=" text-center mb-3 all-font" style={{ fontSize: '50px' }}>Our Products</h2>

                <div className="row justify-content-center">

                    {this.props.catwall.map((catwall, index) =>
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6" key={index}>
                            <CatagoryGridSingle image={`assets/images/catwall/${catwall.image}`} category={catwall.cat} count={getCountByCategory(this.props.products, catwall.cat)} filtername={catwall.cat} type="banner-content-bottom" />
                        </div>
                    )}

                    {/* <div className="col-md-3 col-lg-3 small-hide">
                        <CatagoryGridSingle image="assets/images/category-grid/cat1.jpg" category="KHAN SAREE" count={KhanSaree} filtername='KHAN SAREE' type="banner-content-bottom" />
                    </div>

                    <div className="col-md-6 col-lg-6 small-hide">
                        <div className="row">
                            <div className="col-md-12 col-lg-12">
                                <CatagoryGridSingle image="assets/images/category-grid/cat2.jpg" category="ROYAL BORDER" count={SemiBrocketSaree} filtername='ROYAL BORDER' />
                            </div>

                            <div className="col-md-12 col-lg-12">
                                <CatagoryGridSingle image="assets/images/category-grid/cat3.jpg" category="Sico Irkal Border Saree" count={SicoIrkalBorderSaree} filtername='Sico Irkal Border Saree' />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-lg-3 small-hide">
                        <CatagoryGridSingle image="assets/images/category-grid/cat4.jpg" category="MUNIYA BORDER" count={SicoMuniaBorderSaree} filtername='MUNIYA BORDER' type="banner-content-bottom" />
                    </div>

                    <div className="col-md-6 col-lg-6 small-hide">
                        <CatagoryGridSingle image="assets/images/category-grid/cat5.jpg" category="SICO SILK" count={SicoPaithaniBorderSaree} filtername='SICO SILK' type="banner-content-bottom" />
                    </div>

                    <div className="col-md-6 col-lg-6 small-hide">
                        <CatagoryGridSingle image="assets/images/category-grid/cat6.jpg" category="ALL OVER ZARI DESIGN" count={TempleBorderSaree} filtername='ALL OVER ZARI DESIGN' type="banner-content-bottom" />
                    </div>



                    <div className="col-6 big-hide">
                        <CatagoryGridSingle image="assets/images/category-grid/cat33.jpg" category="Sico Irkal Border Saree" count={SicoIrkalBorderSaree} filtername='Sico Irkal Border Saree' type="banner-content-bottom" />
                    </div>

                    <div className="col-6 big-hide">
                        <CatagoryGridSingle image="assets/images/category-grid/cat11.jpg" category="KHAN SAREE" count={KhanSaree} filtername='KHAN SAREE' type="banner-content-bottom" />
                    </div>

                    <div className="col-6 big-hide">
                        <CatagoryGridSingle image="assets/images/category-grid/cat55.jpg" category="SICO SILK" count={SicoPaithaniBorderSaree} filtername='SICO SILK' type="banner-content-bottom" />
                    </div>

                    <div className="col-6 big-hide">
                        <CatagoryGridSingle image="assets/images/category-grid/cat22.jpg" category="ROYAL BORDER" count={SemiBrocketSaree} filtername='ROYAL BORDER' type="banner-content-bottom" />
                    </div>

                    <div className="col-6 big-hide">
                        <CatagoryGridSingle image="assets/images/category-grid/cat44.jpg" category="MUNIYA BORDER" count={SicoMuniaBorderSaree} filtername='MUNIYA BORDER' type="banner-content-bottom" />
                    </div>

                    <div className="col-6 big-hide">
                        <CatagoryGridSingle image="assets/images/category-grid/cat66.jpg" category="ALL OVER ZARI DESIGN" count={TempleBorderSaree} filtername='ALL OVER ZARI DESIGN' type="banner-content-bottom" />
                    </div> */}

                </div>

            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        products: state.data.products ? state.data.products : [],
        catwall: state.data.catwall ? state.data.catwall : []
    }
}

export default connect(mapStateToProps)(CollectionGrid);