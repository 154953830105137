import React, { Component } from 'react';

import store from './../../store';

import ShopSidebar from './../components/shop-sidebar';
import ProductList from './../components/product-list';

import { innerLoading, outerLoading } from './../../actions';

class ShopGrid extends Component {

    componentDidMount() {
        document.body.classList.add('all-bg');
    }

    componentDidUpdate() {
        store.dispatch(innerLoading());
    }

    componentWillUnmount() {
        store.dispatch(outerLoading());
        document.body.classList.remove('all-bg');
    }

    render() {

        return (
            <div className="main">
                <div className="page-content pt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <ProductList column="3cols" />
                            </div>

                            <div className="col-lg-3 order-lg-first">
                                <ShopSidebar adClass="sidebar sidebar-shop" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ShopGrid;