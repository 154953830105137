import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { isIEBrowser } from './../../../utils/utils';

import { showModal } from './../../../actions';

import * as orderApi from './../../../api/order'
import { getGeoLocation } from './../../../api/other';

import ReturnExchangeModal from './../../components/sub-components/return-exchange-modal';

import Accordion from './../../components/accordion/accordion';
import Card from './../../components/accordion/card';

class OrderDetails extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isOKtoExchangeOrReturn: 0,
            display_orderid: "",
            orderdate: "",
            deliverydate: "",
            firstname: "",
            lastname: "",
            address: "",
            city: "",
            district: "",
            state: "",
            pincode: "",
            phone: "",
            email: "",
            subtotal: "",
            shipping: "",
            total: "",
            hirkani: "",
            wallet: "",
            grandtotal: "",
            status: "",
            items: [],
            upline: [],
            profits: [],
            orderStatus: '',
            paymentStatus: '',
            plan: '',
            trackingid: '',
            service: '',
            orderData: {},
            notes: '',
            isgpay: true,
            fileArray: [],
            old_payment_receipts: null
        }
    }

    componentDidMount() {

        var checkusertoken = localStorage.getItem('usertokenShivshahiCustomer');
        // console.log(checkusertoken)
        if (checkusertoken == null) {
            this.props.history.push(`${process.env.PUBLIC_URL}/auth/login-register/:data`)
        } else {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const data = {
                order_id: this.props.match.params.orderid,
                userToken: localStorage.usertokenShivshahiCustomer
            }

            orderApi.fetchSingleOrder(data)
                .then(res => {
                    if (res.status === 200) {
                        const singleOrder = res.data

                        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
                        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
                        var orderDate = new Date(singleOrder.order_date);
                        var deliveryDate = new Date(singleOrder.delivery_date);
                        var orderDateFinal = days[orderDate.getDay()] + ', ' + monthNames[orderDate.getMonth()] + ' ' + orderDate.getDate() + ', ' + orderDate.getFullYear();
                        var deliveryDateFinal = days[deliveryDate.getDay()] + ', ' + monthNames[deliveryDate.getMonth()] + ' ' + deliveryDate.getDate() + ', ' + deliveryDate.getFullYear();

                        var deliveryDateFC = deliveryDate.getFullYear() + '-' + deliveryDate.getMonth() + '-' + deliveryDate.getDate()
                        var todaysDateFC = new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate()

                        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
                        var a = new Date(deliveryDateFC)
                        var b = new Date(todaysDateFC)
                        var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
                        var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

                        var isOKtoExchangeOrReturn = Math.floor(((utc2 - utc1) / _MS_PER_DAY)) * 24

                        this.setState({
                            orderData: singleOrder,
                            isOKtoExchangeOrReturn: isOKtoExchangeOrReturn,
                            display_orderid: parseInt(singleOrder.id) + 9999,
                            orderdate: orderDateFinal,
                            deliverydate: deliveryDateFinal,
                            firstname: singleOrder.firstname,
                            lastname: singleOrder.lastname,
                            address: singleOrder.address,
                            city: singleOrder.city,
                            district: singleOrder.district,
                            state: singleOrder.state,
                            pincode: singleOrder.pincode,
                            phone: singleOrder.phone,
                            email: singleOrder.email,
                            subtotal: singleOrder.subtotal,
                            shipping: singleOrder.shipping,
                            total: singleOrder.total,
                            hirkani: singleOrder.hirkani,
                            wallet: singleOrder.wallet,
                            grandtotal: singleOrder.grandtotal,
                            status: singleOrder.status,
                            items: JSON.parse(singleOrder.items),
                            upline: JSON.parse(singleOrder.upline),
                            profits: JSON.parse(singleOrder.profits),
                            orderStatus: singleOrder.status,
                            paymentStatus: singleOrder.payment_status,
                            plan: JSON.parse(singleOrder.profits)[0].scheme,
                            trackingid: singleOrder.tracking_id,
                            service: singleOrder.service,
                            notes: singleOrder.notes,
                            old_payment_receipts: singleOrder.payment_receipts
                        }, () => {
                            spinner.style.display = "none";
                        })
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Please Login Again...!')
                        // localStorage.removeItem('usertokenShivshahiCustomer')
                        localStorage.clear();
                        window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        this.props.history.push(`${process.env.PUBLIC_URL}/my-account/orders`)
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    this.props.history.push(`${process.env.PUBLIC_URL}/my-account/orders`)
                })

        }
    }

    printOrder = () => {
        // const printableElements = document.getElementById('printme').innerHTML;
        // const orderHtml = '<html><head><title></title></head><body>' + printableElements + '</body></html>';
        // const oldPage = document.body.innerHTML;
        // document.body.innerHTML = '<html><head><title></title></head><body>' + printableElements + '</body></html>';
        // window.print();
        // document.body.innerHTML = oldPage
        alert('In Process')
    }

    handleChange = e => {
        if (e.target.name == 'address' || e.target.name == 'service' || e.target.name == 'notes') {
            this.setState({ [e.target.name]: e.target.value })
        } else {
            this.setState({ [e.target.name]: e.target.value.replace(new RegExp("\\s*", "gm"), '') })
        }

        if (e.target.name == 'pincode' && e.target.value.length == 6) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            getGeoLocation(e.target.value)
                .then(res => {
                    if (res.status == 'success') {
                        this.setState({
                            city: res.data.block,
                            district: res.data.district,
                            state: res.data.state,
                        }, () => {
                            spinner.style.display = "none";
                        })
                    } else {
                        this.setState({
                            pincode: ''
                        }, () => {
                            alert('Please Enter the correct Pincode !')
                            spinner.style.display = "none";
                        })
                    }
                })
                .catch((err) => {
                    this.setState({
                        pincode: ''
                    }, () => {
                        alert('Something went Wrong')
                        spinner.style.display = "none";
                    })
                })
        }
    }

    updateAddress = (e) => {
        e.preventDefault();

        if (window.confirm('Do you want to Update the Order Address ?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const data = {
                id: this.state.display_orderid - 9999,
                userToken: localStorage.usertokenShivshahiCustomer,
                updateData: {
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    address: this.state.address,
                    city: this.state.city,
                    district: this.state.district,
                    state: this.state.state,
                    pincode: this.state.pincode,
                    phone: this.state.phone,
                    email: this.state.email,
                    service: this.state.service,
                    notes: this.state.notes
                }
            }

            orderApi.updateOrderAddress(data)
                .then(res => {
                    if (res.status == 200) {
                        alert('Order Address Updated')
                        spinner.style.display = "none";
                        window.location.reload();
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Please Login Again...!')
                        // localStorage.removeItem('usertokenShivshahiCustomer')
                        localStorage.clear();
                        window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        window.location.reload();
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    window.location.reload();
                })

        }
    }

    uploadReceipt = (e) => {
        var files = e.target.files
        var fileArray = [];

        for (var i = 0; i < files.length; i++) {
            fileArray.push(files[i])
            if (
                files[i].type.toLowerCase() != 'image/jpg' &&
                files[i].type.toLowerCase() != 'image/jpeg' &&
                files[i].type.toLowerCase() != 'image/png'
            ) {
                alert('Please upload a valid file..!')
                fileArray = [];
                i = files.length
            }
        }
        this.setState({ fileArray: fileArray })
    }

    uploadPaymentReceipt = e => {
        e.preventDefault();

        if (this.state.fileArray.length <= 0) {
            alert('Please re-upload the files..!')
        } else {
            if (window.confirm('Do you want to Upload the Receipts ?')) {
                let spinner = document.getElementById("spinneranimid");
                spinner.style.display = "block";

                let data = {
                    id: this.state.display_orderid - 9999,
                }

                const formData = new FormData()
                for (let j = 0; j < this.state.fileArray.length; j++) {
                    formData.append('files', this.state.fileArray[j])
                }
                formData.append('data', JSON.stringify(data))

                orderApi.uploadPaymentReceipt(formData)
                    .then(res => {
                        if (res.status == 200) {
                            alert('Receipts Uploaded..!')
                            spinner.style.display = "none";
                            window.location.reload();
                        } else {
                            alert('Something went wrong')
                            spinner.style.display = "none";
                            window.location.reload();
                        }
                    })
                    .catch((err) => {
                        alert('Something went wrong')
                        spinner.style.display = "none";
                        window.location.reload();
                    })
            }

        }
    }

    cancelOrder = (e) => {
        e.preventDefault();

        if (window.confirm('Do you want to Cancel the Order ?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const data = {
                id: this.state.display_orderid - 9999,
                userToken: localStorage.usertokenShivshahiCustomer,
                cartlist: this.state.items
            }

            orderApi.cancelOrder(data)
                .then(res => {
                    if (res.status == 200) {
                        alert('Order Cancelled')
                        spinner.style.display = "none";
                        window.location.reload();
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Please Login Again...!')
                        // localStorage.removeItem('usertokenShivshahiCustomer')
                        localStorage.clear();
                        window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        window.location.reload();
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    window.location.reload();
                })

        }
    }

    exchangeReturn = (e) => {
        e.preventDefault();
        this.props.showModal('returnExchange');
        window.location.reload();
    }

    viewImages = () => {
        if (this.state.old_payment_receipts != null) {
            JSON.parse(this.state.old_payment_receipts).map((image, index) => {
                window.open(`${process.env.PUBLIC_URL}/assets/images/order-payment-receipts/${image}`);
            })
        } else {
            alert('There are no Old receipts uploaded')
        }
    }

    // copyID = (e, containerid) => {
    //     e.preventDefault();

    //     for (var i = 0; i < 2; i++) {
    //         if (i == 0) {
    //             if (document.selection) {
    //                 var range = document.body.createTextRange();
    //                 range.moveToElementText(document.getElementById(containerid));
    //                 range.select().createTextRange();
    //                 document.execCommand("copy");
    //             } else if (window.getSelection) {
    //                 var range = document.createRange();
    //                 range.selectNode(document.getElementById(containerid));
    //                 window.getSelection().addRange(range);
    //                 document.execCommand("copy");
    //             }
    //         } else {
    //             if (this.state.service == 'Shree Maruti Courier') {
    //                 var trackingLink = "https://www.shreemaruticourier.com/"
    //             } else if (this.state.service == 'Shree Anjani Courier') {
    //                 var trackingLink = "http://www.shreeanjanicourier.com/"
    //             } else if (this.state.service == 'Professional Courier') {
    //                 var trackingLink = "https://www.tpcindia.com/"
    //             } else if (this.state.service == 'Trackon Courier') {
    //                 var trackingLink = "http://trackoncourier.com/"
    //             } else if (this.state.service == 'Mahavir Courier') {
    //                 var trackingLink = "http://shreemahavircourier.com/"
    //             } else if (this.state.service == 'DTDC') {
    //                 var trackingLink = "https://www.dtdc.in/"
    //             } else if (this.state.service == 'Indian Post') {
    //                 var trackingLink = "https://www.indiapost.gov.in/_layouts/15/dop.portal.tracking/trackconsignment.aspx"
    //             }
    //             window.open(trackingLink)
    //         }
    //     }

    // }

    render() {
        const { currency } = this.props;

        return (
            <div className='order-success all-bg'>

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <section className="section-top mt-5" id="printme">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-10 col-sm-11 col-12 all-bg-light pb-0 pt-2">
                                {/* <div style={{ display: 'inline-flex' }}>
                                        <Logo logo="logo.png" />
                                    </div> */}
                                <div>
                                    <i className="icon icon-heart-o" aria-hidden="true" style={{ display: `${this.state.status == "Cancelled" ? "none" : ""}` }} />
                                    <i className="icon icon-heartbeat" aria-hidden="true" style={{ display: `${this.state.status == "Cancelled" ? "" : "none"}`, color: '#ff6161' }} />
                                    <h2 style={{ display: `${this.state.status == "Cancelled" ? "none" : ""}` }}>Thank you..</h2>
                                    <h2 style={{ display: `${this.state.status == "Cancelled" ? "" : "none"}` }}>Order Cancelled</h2>
                                    <h3 style={{ display: `${this.state.status == "Return in Progress" ? "" : "none"}`, color: '#c96' }}>Return in Progress</h3>
                                    <h3 style={{ display: `${this.state.status == "Exchange in Progress" ? "" : "none"}`, color: '#c96' }}>Exchange in Progress</h3>
                                    <h3 style={{ display: `${this.state.status == "On Hold" ? "" : "none"}`, color: '#c96' }}>Order is On Hold</h3>
                                    <div style={{ display: `${this.state.status == "Delivered" || this.state.status == "Cancelled" ? "none" : ""}` }}>We are OnTo your Order.!</div>
                                    <div style={{ display: `${this.state.status == "Delivered" && this.state.status != "Cancelled" ? "" : "none"}` }}>We hope you like our Product..!</div>
                                    <p>Order ID :&nbsp;&nbsp;<span>{this.state.display_orderid}</span></p>
                                    <p style={{ display: `${this.state.paymentStatus == 'Completed' || this.state.status == "Cancelled" ? 'none' : ''}` }}>
                                        <span style={{ color: 'red' }}>Your Payment is Pending.. Please do it within 6 hours</span>
                                        <div>पेमेंट संबंधी काहीही माहिती हवी असेल तर संपर्क साधा. +91 8180070617</div>

                                        <Accordion adClass="accordion-plus">
                                            <Card title='Make Payment and Upload Receipts' adClass="card-box card-sm all-bg">
                                                <small>पेमेंट पावती अपलोड केल्यानंतर, कृपया पेमेंट मंजुरीची प्रतीक्षा करा.</small>
                                                <p>Pending Payment :&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#ff2b2b' }}>{currency.currencySymbol}&nbsp;{(this.state.grandtotal * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                                <div className="custom-control custom-radio2" style={{ textAlign: 'start' }}>
                                                    <input type="radio" className="custom-control-input" id="others1" name="paymentmode" onChange={() => this.setState({ isgpay: true })} checked={this.state.isgpay} />
                                                    <label className="custom-control-label" htmlFor="others1">
                                                        Pay by G-Pay, PhonePay
                                                    </label>
                                                </div>

                                                <div className='ml-5' style={{ display: this.state.isgpay ? '' : 'none', textAlign: 'start' }}>G-Pay : <span style={{ color: '#fff' }}>8446703040</span></div>
                                                <div className='ml-5' style={{ display: this.state.isgpay ? '' : 'none', textAlign: 'start' }}>UPI ID : <span style={{ color: '#fff' }}>shivshahinetwork@okaxis</span></div>
                                                <img className='mt-1 ml-5' src={`${process.env.PUBLIC_URL}/assets/images/qr_code.jpeg`} alt="upi qr" style={{ width: '190px', display: this.state.isgpay ? '' : 'none' }} />

                                                <div className="custom-control custom-radio2" style={{ textAlign: 'start' }}>
                                                    <input type="radio" className="custom-control-input" id="others2" name="paymentmode" onChange={() => this.setState({ isgpay: false })} />
                                                    <label className="custom-control-label" htmlFor="others2">
                                                        Pay to Bank Account
                                                    </label>
                                                </div>

                                                <div className='ml-5' style={{ display: this.state.isgpay ? 'none' : '', textAlign: 'start' }}>Bank Name&emsp;&nbsp;: <span style={{ color: '#fff' }}>ICICI Bank</span></div>
                                                <div className='ml-5' style={{ display: this.state.isgpay ? 'none' : '', textAlign: 'start' }}>A/c Name&emsp;&emsp;: <span style={{ color: '#fff' }}>ShivShahi Network</span></div>
                                                <div className='ml-5' style={{ display: this.state.isgpay ? 'none' : '', textAlign: 'start' }}>A/c Number&emsp;: <span style={{ color: '#fff' }}>376405001968</span></div>
                                                <div className='ml-5' style={{ display: this.state.isgpay ? 'none' : '', textAlign: 'start' }}>IFSC Code&emsp;&emsp;: <span style={{ color: '#fff' }}>icici0003764</span></div>
                                                <div className='ml-5' style={{ display: this.state.isgpay ? 'none' : '', textAlign: 'start' }}>Branch Name&nbsp;: <span style={{ color: '#fff' }}>Yeola</span></div>
                                                <div className='ml-5' style={{ display: this.state.isgpay ? 'none' : '', textAlign: 'start' }}>Account Type&nbsp;: <span style={{ color: '#fff' }}>Current</span></div>

                                                <hr />

                                                <form onSubmit={this.uploadPaymentReceipt}>
                                                    <input type="file" multiple="multiple" accept="image/*" id="fileLoader" name="files" title="Load File" onChange={this.uploadReceipt} required />

                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <button type="submit" className="btn btn-outline-primary-2 mt-3 mb-1">
                                                            <span>Upload Receipts</span>
                                                        </button>
                                                    </div>
                                                </form>
                                                <div style={{ fontSize: '13px', color: '#40a0a3', cursor: 'pointer' }} onClick={this.viewImages}>View Old Receipts</div>

                                                <div>पेमेंट संबंधी काहीही माहिती हवी असेल तर संपर्क साधा. +91 8180070617</div>

                                            </Card>
                                        </Accordion>

                                    </p>

                                    <div className="flex-parent pt-2" style={{ display: `${this.state.status == "Cancelled" || this.state.status == "Return in Progress" || this.state.status == "Exchange in Progress" || this.state.status == "On Hold" ? "none" : ""}` }}>
                                        <div className="input-flex-container">
                                            <div className={`input ${this.state.status == "Placed" ? "active" : ""}`}>
                                                <span data-icon="&#x1F4DC;" data-info="Placed"></span>
                                            </div>
                                            <div className={`input ${this.state.status == "Processed" ? "active" : ""}`}>
                                                <span data-icon="&#x1F4E6;" data-info="Processed"></span>
                                            </div>
                                            <div className={`input ${this.state.status == "Packed" ? "active" : ""}`}>
                                                <span data-icon="&#127873;" data-info="Packed"></span>
                                            </div>
                                            <div className={`input ${this.state.status == "Shipped" ? "active" : ""}`}>
                                                <span data-icon="&#x26DF;" data-info="Shipped"></span>
                                            </div>
                                            <div className={`input ${this.state.status == "Delivered" ? "active" : ""}`}>
                                                <span data-icon="&#x1F389;" data-info="Delivered"></span>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <hr />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-middle">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-10 col-sm-11 col-12 all-bg-light pb-0">
                                <h4>Order and Shipping Info</h4>
                                <div>
                                    <div className="left">
                                        <h5>Order Details</h5>
                                        <ul>
                                            <li>Order Date :&nbsp;&nbsp;<span>{this.state.orderdate}</span></li>
                                            <li style={{ display: `${this.state.status == 'Delivered' || this.state.status == "Cancelled" ? 'none' : ''}` }}>Expected Delivery :&nbsp;&nbsp;<span>{this.state.deliverydate}</span></li>
                                            <li style={{ display: `${this.state.status == 'Delivered' && this.state.status != "Cancelled" ? '' : 'none'}` }}>Delivery Date:&nbsp;&nbsp;<span>{this.state.deliverydate}</span></li>
                                            <li>Phone :&nbsp;&nbsp;<span>{this.state.phone}</span></li>
                                            <li>Email :&nbsp;&nbsp;<span>{this.state.email}</span></li>
                                        </ul>
                                    </div>
                                    <div className="right">
                                        <h5>Shipping Address</h5>
                                        <ul>
                                            <li>{this.state.firstname}&nbsp;&nbsp;{this.state.lastname}</li>
                                            <li>{this.state.address},</li>
                                            <li>{this.state.pincode}.</li>
                                            {/* <li>{this.state.address}, {this.state.city}, Dist: {this.state.district},</li>
                                            <li>{this.state.state}, {this.state.pincode}.</li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="section-middle" style={{ display: `${this.state.upline.length ? '' : 'none'}` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-10 col-sm-11 col-12 all-bg-light pb-0">
                                <div>
                                    <div className="left">
                                        <h5>1<sup>st</sup> Upline</h5>
                                        <ul>
                                            <li>ID :&nbsp;&nbsp;<span>{this.state.parent.ownref}</span></li>
                                            <li>Name :&nbsp;&nbsp;<span>{this.state.parent.firstname}&nbsp;{this.state.parent.lastname}</span></li>
                                            <li>Phone :&nbsp;&nbsp;<span>{this.state.parent.phone}</span></li>
                                        </ul>
                                    </div>
                                    <div className="right">
                                        <h5>2<sup>nd</sup> Upline</h5>
                                        <ul>
                                            <li>ID :&nbsp;&nbsp;<span>{this.state.grandparent.ownref}</span></li>
                                            <li>Name :&nbsp;&nbsp;<span>{this.state.grandparent.firstname}&nbsp;{this.state.grandparent.lastname}</span></li>
                                            <li>Phone :&nbsp;&nbsp;<span>{this.state.grandparent.phone}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className='middle-bottom'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-10 col-sm-11 col-12 all-bg-light pb-0">
                                <h5>Scheme : <span>{this.state.plan}</span></h5>
                                <div>
                                    <div>
                                        <ul>
                                            {this.state.profits.map((item, index) =>
                                                index != 0 ?
                                                    // <li key={index}>
                                                    //     <p>
                                                    //         Upline {index} :&nbsp;&nbsp;<span>{currency.currencySymbol}&nbsp;{(item.profit * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span> - ({item.name} - {item.phone})
                                                    //     </p>
                                                    // </li>
                                                    <li key={index}></li>
                                                    :
                                                    <li key={index}>
                                                        <p>
                                                            Your Profit :&nbsp;&nbsp;<span>{currency.currencySymbol}&nbsp;{(item.profit * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span> - ({item.name} - {item.phone})
                                                        </p>
                                                    </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-10 col-sm-11 col-12 all-bg-light pb-0">
                                <hr />
                                <h4>Your Order Details</h4>
                                <div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className='first'>Product</th>
                                                <th className='order-items-mobile'>Price</th>
                                                <th className='order-items-mobile'>Size</th>
                                                <th className='order-items-mobile'>Quantity</th>
                                                <th className='order-items-mobile'>Total</th>
                                                <th className='order-items-mobile-show'>Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.items.map((item, index) =>
                                                <tr key={index}>
                                                    <td className='first'>
                                                        <div className="product all-bg-light" style={{ justifyContent: 'start' }}>
                                                            <figure style={{ width: '50px' }}>
                                                                <img src={process.env.PUBLIC_URL + '/' + item.pictures[0]} alt="Product" style={{ width: '100%' }} />
                                                            </figure>
                                                            <h4 className="product-title pl-5" style={{ color: '#c2c2c2' }}>
                                                                <Link to={`${process.env.PUBLIC_URL}/shop/detail/${item.id}`}>{item.name}</Link> - <div className="color-display" style={{ backgroundColor: item.color }} />
                                                            </h4>
                                                        </div>
                                                    </td>

                                                    <td className='order-items-mobile'>
                                                        {currency.currencySymbol}&nbsp;
                                                            {(item.price * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </td>

                                                    <td className='order-items-mobile'>{item.size} Yards</td>

                                                    <td className='order-items-mobile'>{item.qty}</td>

                                                    <td className='order-items-mobile'>
                                                        <strong>
                                                            {currency.currencySymbol}&nbsp;
                                                             {(item.sum * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                        </strong>
                                                    </td>

                                                    <td className='order-items-mobile-show'>
                                                        <ul>
                                                            <li>
                                                                Price : <span>{currency.currencySymbol}&nbsp;
                                                                        {(item.price * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                                            </li>
                                                            <li>Size : <span>{item.size} Yards</span></li>
                                                            <li>Quantity : <span>{item.qty}</span></li>
                                                            <li>
                                                                Total : <span>{currency.currencySymbol}&nbsp;
                                                                        {(item.sum * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                                            </li>
                                                        </ul>
                                                    </td>

                                                </tr>
                                            )}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th colSpan="5">
                                                    <p>Subtotal :&nbsp;&nbsp;&nbsp;&nbsp;<span>{currency.currencySymbol}&nbsp;{(this.state.subtotal * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                                    <p>Shipping :&nbsp;&nbsp;&nbsp;+&nbsp;<span>{currency.currencySymbol}&nbsp;{(this.state.shipping * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                                    <p>Hirkani :&nbsp;&nbsp;&nbsp;-&nbsp;<span>{currency.currencySymbol}&nbsp;{(this.state.hirkani * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                                    <p>Wallet :&nbsp;&nbsp;&nbsp;-&nbsp;<span>{currency.currencySymbol}&nbsp;{(this.state.wallet * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                                    <p className="last">Total :&nbsp;&nbsp;&nbsp;&nbsp;<span>{currency.currencySymbol}&nbsp;{(this.state.grandtotal * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                                </th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-btn">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-10 col-sm-11 col-12 all-bg-light pb-0">
                                <hr />
                                <Link to='#'
                                    className="btn btn-outline-primary ml-3 mr-3 mt-1 mb-1"
                                    onClick={this.printOrder}
                                >
                                    <span>Print Invoice</span><i className="icon-bookmark-o"></i>
                                </Link>
                                <Link to='#'
                                    className="btn btn-outline-primary ml-3 mr-3 mt-1 mb-1"
                                    onClick={this.cancelOrder}
                                    style={{ display: `${this.state.status == 'Placed' ? '' : 'none'}` }}
                                >
                                    <span>Cancel Order</span><i className="icon-close"></i>
                                </Link>
                                <Link to='#'
                                    className="btn btn-outline-primary ml-3 mr-3 mt-1 mb-1"
                                    onClick={this.exchangeReturn}
                                    style={{ display: `${this.state.status == 'Delivered' && this.state.isOKtoExchangeOrReturn <= 72 ? '' : 'none'}` }}
                                >
                                    <span>Exchange</span><i className="icon-rev"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-tracking mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-10 col-sm-11 col-12 all-bg-light pb-3">
                                <hr />
                                {this.state.trackingid != '' && this.state.service != '' ?
                                    <div>
                                        <ul>
                                            <li>Tracking Id : <span id='trackingid'>{this.state.trackingid}</span></li>
                                            <li>Tracking Service : <span>{this.state.service}</span></li>
                                        </ul>
                                        {this.props.trackings.map((trackinglist, index) =>
                                            <a key={index} href={trackinglist.link} target="_blank" rel="noopener noreferrer" style={{ display: this.state.service == trackinglist.service ? '' : 'none', fontSize: '15px', color: '#c96' }} className="btn btn-outline-primary btn-custom"><span>Track Your Shipment</span><i className="icon-long-arrow-right"></i></a>
                                        )}
                                        {/* <Link to='#' className="btn btn-outline-primary" onClick={(e) => this.copyID(e, 'trackingid')}><span>Track Your Shipment</span><i className="icon-long-arrow-right"></i></Link> */}
                                    </div>
                                    :
                                    <div style={{ display: this.state.status == 'Placed' ? '' : 'none' }}>
                                        <div className='pr-3 pl-3'>
                                            <Accordion adClass="accordion-plus">
                                                <Card title='Edit Order Address' adClass="card-box card-lg all-bg">

                                                    <form onSubmit={this.updateAddress}>

                                                        <div className="col-12">

                                                            <div className="form-group" style={{ display: 'flex' }}>
                                                                <div className="col-sm-6 pl-0" style={{ textAlign: 'start' }}>
                                                                    <label className="all-font-dark mb-0" htmlFor="firstname">First Name *</label>
                                                                    <input value={this.state.firstname} type="text" className="form-control all-font" id="firstname" name="firstname" pattern=".*\S+.*" onChange={this.handleChange} required />
                                                                </div>
                                                                <div className="col-sm-6 pr-0" style={{ textAlign: 'start' }}>
                                                                    <label className="all-font-dark mb-0" htmlFor="lastname">Last Name *</label>
                                                                    <input value={this.state.lastname} type="text" className="form-control all-font" id="lastname" name="lastname" pattern=".*\S+.*" onChange={this.handleChange} required />
                                                                </div>
                                                            </div>

                                                            <div className="form-group" style={{ display: 'flex' }}>
                                                                <div className="col-sm-6 pl-0" style={{ textAlign: 'start' }}>
                                                                    <label className="all-font-dark mb-0" htmlFor="email">E-mail address *</label>
                                                                    <input value={this.state.email} type="email" className="form-control all-font" id="email" name="email" pattern=".*\S+.*" onChange={this.handleChange} required />
                                                                </div>
                                                                <div className="col-sm-6 pr-0" style={{ textAlign: 'start' }}>
                                                                    <label className="all-font-dark mb-0" htmlFor="phone">Mobile Number *</label>
                                                                    <input value={this.state.phone} type="text" pattern="\d*" minLength="10" maxLength="10" className="form-control all-font" id="phone" name="phone" onChange={this.handleChange} required />
                                                                </div>
                                                            </div>

                                                            <div className="form-group" style={{ display: 'flex' }}>
                                                                <div className="col-6 pl-0" style={{ textAlign: 'start' }}>
                                                                    <label className="all-font-dark mb-0" htmlFor="pincode">Pin Code *</label>
                                                                    <input value={this.state.pincode} type="text" pattern="\d*" minLength="6" maxLength="6" className="form-control all-font" id="pincode" name="pincode" onChange={this.handleChange} required />
                                                                </div>

                                                                <div className="col-6 pr-0" style={{ textAlign: 'start' }}>
                                                                    <label className="all-font-dark mb-0" htmlFor="city">City *</label>
                                                                    <input value={this.state.city} type="text" pattern=".*\S+.*" className="form-control all-font" id="city" name="city" onChange={this.handleChange} required />
                                                                </div>
                                                            </div>

                                                            <div className="form-group" style={{ textAlign: 'start' }}>
                                                                <label className="all-font-dark mb-0" htmlFor="address">Address * (House Number, Lane, Street, Landmark )</label>
                                                                <textarea style={{ minHeight: 'auto' }} rows={3} value={this.state.address} className="form-control all-font" id="address" name="address" pattern=".*\S+.*" onChange={this.handleChange} required />
                                                            </div>

                                                            <div className="form-group other-none">
                                                                <div className="col-sm-12 col-md-9 col-lg-6 pl-0 pr-0" style={{ textAlign: 'start' }}>
                                                                    <label className="all-font-dark mb-0" htmlFor="service">Courier (optional)</label>
                                                                    <select name="service" id="service" className="form-control all-font mr-4" style={{ maxWidth: '100%', backgroundColor: 'transparent' }} value={this.state.service} onChange={this.handleChange}>
                                                                        <option className="all-font all-bg" value=''> -- Select --</option>
                                                                        {this.props.trackings.map((tracking, index) =>
                                                                            <option className="all-font all-bg" key={index} value={tracking.service}>{tracking.service}</option>
                                                                        )}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="form-group" style={{ textAlign: 'start' }}>
                                                                <label className="all-font-dark mb-0" htmlFor="notes">Other Notes (optional)</label>
                                                                <textarea style={{ minHeight: 'auto' }} rows={3} value={this.state.notes} className="form-control all-font" id="notes" name="notes" onChange={this.handleChange} />
                                                            </div>

                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <button type="submit" className="btn btn-outline-primary-2 mt-3 mb-1">
                                                                    <span>Update Address</span>
                                                                    <i className="icon-long-arrow-up"></i>
                                                                </button>
                                                            </div>

                                                        </div>

                                                    </form>

                                                </Card>
                                            </Accordion>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>

                <div className="cta cta-display bg-image pt-4 pb-4" style={{ backgroundImage: `url(assets/images/backgrounds/bg-7.jpg)` }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-9 col-xl-7">
                                <div className={`row no-gutters ${isIEBrowser() ? '' : 'flex-column'} flex-sm-row align-items-sm-center`} >
                                    <div className="col">
                                        <h3 className="cta-title text-white">Want to Look even Gorgeous..?</h3>
                                        <p className="cta-desc text-white">Lets Go for the shopping</p>
                                    </div>

                                    <div className="col-auto">
                                        <Link to={`${process.env.PUBLIC_URL}/shop`} className="btn btn-outline-white"><span>Shop More</span><i className="icon-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ReturnExchangeModal items={this.state.items} order={this.state.orderData} />

            </div >
        )
    }
}
const mapStateToProps = state => {
    return {
        currency: state.currencyData,
        trackings: state.data.trackings,
    };
};


export default connect(mapStateToProps, { showModal })(OrderDetails);
