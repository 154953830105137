import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { mobileMenu } from './../../../utils/utils';
import { getCartCount } from './../../../services'

import { toggleCategoryFilter } from './../../../actions';
import { setCurrency } from './../../../actions/currencyAction';

const MobileMainNav = ({
    currency,
    setCurrency,
    currentLanguageCode,
    dispatch,
    wishlist,
    cartlist,
    toggleCategoryFilter,
    cats
} = this.props) => {

    useEffect(() => {
        mobileMenu();
    }, []);

    const changeLanguageTrigger = e => {
        // const languageCode = e.target.value;
        // dispatch(changeLanguage(languageCode));
    };

    const setCurrencyTrigger = (e) => {
        var currencyName = e.target.value;
        // console.log(currencyName)
        setCurrency(currencyName);
    };

    const doNothing = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (

        <nav className="mobile-nav mt-1">
            <ul className="mobile-menu">
                <li><Link to={`${process.env.PUBLIC_URL}/`}>Home</Link></li>

                <li>
                    <Link to={`${process.env.PUBLIC_URL}/shop`}>Shop</Link>
                    <ul>
                        {cats.map((item, index) =>
                            <li key={index}><Link to={`${process.env.PUBLIC_URL}/shop`} onClick={(e) => toggleCategoryFilter(item.category)}>{item.category}</Link></li>
                        )}
                    </ul>
                </li>

                <li>
                    <Link to={`${process.env.PUBLIC_URL}/business-opportunity`} className="sf-with-ul">Business&nbsp;&nbsp;Opportunity</Link>
                    <ul>
                        <li><Link to={`${process.env.PUBLIC_URL}/business-opportunity`}>Business Opportunity</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/hirkani-plan`}>Hirakani Plan</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/recharge-plan`}>Rechange Plan</Link></li>
                    </ul>
                </li>

                <li>
                    <Link to={`${process.env.PUBLIC_URL}/join-us/noplan`}>Recharge</Link>
                </li>
                <li>
                    <Link to={`${process.env.PUBLIC_URL}/links`}>Links</Link>
                </li>

                {/* <li>
                    <Link className='mobile-special' to={`${process.env.PUBLIC_URL}/my-account/dashboard`} className="sf-with-ul"><span><i className="icon-user" />&nbsp;&nbsp;My Account</span></Link>
                    <ul>
                        <li><Link to={`${process.env.PUBLIC_URL}/my-account/dashboard`}>&nbsp;&nbsp;<i className="las la-chalkboard" />&nbsp;Dashboard</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/my-account/about`}>&nbsp;&nbsp;<i className="las la-edit" />&nbsp;Edit Profile</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/my-account/wallet`}>&nbsp;&nbsp;<i className="las la-wallet" />&nbsp;Wallet</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/my-account/orders`}>&nbsp;&nbsp;<i className="las la-file-alt" />&nbsp;Orders</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/my-account/transactions`}>&nbsp;&nbsp;<i class="las la-exchange-alt" />&nbsp;Transactions</Link></li>
                    </ul>
                </li> */}

                <li>
                    <Link className='mobile-special' to='#' onClick={e => { window.location = `${process.env.PUBLIC_URL}/wishlist` }}><span><i className="icon-heart-o" />&nbsp;&nbsp;Wishlist (<span>{wishlist.length}</span>)</span></Link>
                </li>
                <li>
                    <Link className='mobile-special' to='#' onClick={e => { window.location = `${process.env.PUBLIC_URL}/shop/cart` }}><span><i className="icon-shopping-cart" />&nbsp;&nbsp;Cart (<span>{getCartCount(cartlist)}</span>)</span></Link>
                </li>
                <li>
                    <Link to='#' className="sf-with-ul" onClick={doNothing}>Information</Link>
                    <ul>
                        <li><Link to={`${process.env.PUBLIC_URL}/about`}>About Us</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/contact`}>Contact us</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/termsofservice`}>Terms of Service</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/faq`}>FAQs</Link></li>
                    </ul>
                </li>
                <li>
                    <Link to='#' className="sf-with-ul" onClick={doNothing}>Policies</Link>
                    <ul>
                        <li><Link to={`${process.env.PUBLIC_URL}/ship&deliver-policy`}>Shipping & Delivery</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/return&exchange-policy`}>Return & Exchange</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/privacy-policy`}>Privacy</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/cancel&refund-policy`}>Cancellation & Refund</Link></li>
                    </ul>
                </li>
            </ul>

            <div className="mt-2" style={{ display: 'flex', justifyContent: 'center' }}>
                <select name="currencyChange" id="currencyChange" className="form-control all-font" value={currency.currencyName} onChange={setCurrencyTrigger} style={{ maxWidth: '150px' }}>
                    <option className="all-font all-bg" value="INR">INR&nbsp;₹</option>
                    <option className="all-font all-bg" value="USD">USD&nbsp;$</option>
                    <option className="all-font all-bg" value="EUR">EUR&nbsp;€</option>
                    <option className="all-font all-bg" value="GBP">GBP&nbsp;£</option>
                </select>
            </div>

            <div className="mt-0" style={{ display: 'flex', justifyContent: 'center' }}>
                <select name="languageChange" id="languageChange" className="form-control all-font" onChange={changeLanguageTrigger} style={{ maxWidth: '150px' }}>
                    <option className="all-font all-bg" value="mar">मराठी</option>
                    <option className="all-font all-bg" value="en">English</option>
                </select>
            </div>

            <div className='mt-0' style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} alt="Shivshahi Logo" width={205} height={'auto'} />
            </div>

            <div className="footer-bottom mt-2">
                <div className="container pt-1 pb-0" style={{ borderColor: '#6b6b6b' }}>
                    <p className="footer-copyright">Copyright © {(new Date()).getFullYear()} Shivshahi Network. All Rights Reserved.</p>
                </div>
                <hr style={{ borderColor: '#6b6b6b', width: '90%', marginBottom: '0px', marginTop: '10px' }} />
                <div className="reecraft-footer col-12">
                    <p className="footer-copyright">Designed and maintained by <a href="https://www.reecraft.com" target="_blank" rel="noopener noreferrer">reecraft</a></p>
                </div>
            </div>

        </nav >

    );
};

const mapStateToProps = state => {
    return {
        filters: state.filters,
        products: state.data.products ? state.data.products : [],
        currency: state.currencyData,
        wishlist: state.wishlist.list,
        cartlist: state.cartlist.cart ? state.cartlist.cart : [],
        cats: state.data.cats,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrency: currencyName => {
            dispatch(setCurrency(currencyName));
        },
        toggleCategoryFilter: (categoryInput) => dispatch(toggleCategoryFilter(categoryInput))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MobileMainNav);