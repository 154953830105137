import React, { Component } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { Helmet } from 'react-helmet';

import { sendPassResetLink } from "./../../../api/auth";

class ForgetPassword extends Component {

    constructor(props) {
        super(props)

        this.state = {
            emailorphone: "",
        }
    }

    componentDidMount() {
        var checkusertoken = localStorage.getItem('usertokenShivshahiCustomer');
        if (checkusertoken !== null) {
            this.props.history.push(`${process.env.PUBLIC_URL}/`)
        }
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value.replace(new RegExp("\\s*", "gm"), '') });
    }

    handleSubmit = e => {
        e.preventDefault();

        let linksentalert = document.getElementById('linksentalert');
        let invalidemailalert = document.getElementById('invalidemailalert');
        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";
        linksentalert.style.display = "none";
        invalidemailalert.style.display = "none";

        const user = {
            emailorphone: this.state.emailorphone,
        }

        sendPassResetLink(user)
            .then(res => {
                if (res.status == 200) {
                    linksentalert.style.display = "block";
                    spinner.style.display = "none";
                } else if (res.status == 405 && res.error == 'Account Does Not Exists') {
                    invalidemailalert.style.display = "block";
                    spinner.style.display = "none";
                } else {
                    alert('Something went Wrong. Please Contact Customer care..!')
                    spinner.style.display = "none";
                }
            })
            .catch((err) => {
                alert('Something went Wrong')
                spinner.style.display = "none";
            })
    }

    render() {
        return (
            <div className="main">
                <Helmet>
                    <title>Shivshahi Network</title>
                </Helmet>

                <h1 className="d-none">Shivshahi Network</h1>

                <div className="login-page bg-image pt-8 pb-8 pt-md-12 pb-md-12 pt-lg-17 pb-lg-17" style={{ backgroundImage: `url('assets/images/backgrounds/login-bg.jpg')` }}>
                    <div className="container">
                        <div className="form-box all-bg">
                            <div className="form-tab">

                                <div id="spinneranimid" className="overlay-spinner">
                                    <div className="cssload-whirlpool" />
                                </div>

                                <Tabs selectedTabClassName="show" defaultIndex={0}>
                                    <TabList className="nav nav-pills nav-fill">
                                        <Tab className="nav-item all-font">
                                            <span className="nav-link">Forget Password</span>
                                        </Tab>
                                    </TabList>

                                    <div className="tab-content">
                                        <TabPanel style={{ paddingTop: "2rem" }}>

                                            <div className="alert alert-success mb-2" id="linksentalert" role="alert">
                                                The Password Reset link has been sent to your registered Email address and Mobile Number..! Please Check..!
                                                <br /><strong>The link will be valid for 10 minutes</strong>
                                            </div>

                                            <div className="alert alert-danger mb-2" id="invalidemailalert" role="alert">
                                                Entered Email or Phone is not Registered..!
                                            </div>

                                            <form onSubmit={this.handleSubmit}>
                                                <div className="form-group">
                                                    <label className="all-font-dark" htmlFor="emailorphone">Registered E-mail or Phone *</label>
                                                    <input type="text" value={this.state.emailorphone} className="form-control all-font" id="emailorphone" name="emailorphone" onChange={this.handleChange} required />
                                                </div>

                                                <button type="submit" className="btn btn-outline-primary-2 mt-3">
                                                    <span>SEND LINK</span>
                                                    <i className="icon-long-arrow-right"></i>
                                                </button>
                                            </form>

                                            <div >

                                            </div>
                                        </TabPanel>
                                    </div>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ForgetPassword;