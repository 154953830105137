import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { getCartCount } from './../../../services'
import { removeFromCart } from './../../../actions';
import { safeContent } from './../../../utils/utils';

import * as userApi from './../../../api/user'

class CartMenu extends Component {

    removeFromCartHandler = (e, product) => {
        e.preventDefault();
        const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer
        if (usertokenShivshahiCustomer != null) {

            var data = {
                userToken: usertokenShivshahiCustomer,
                cartlistItem: product
            }
            userApi.removeFromCartlist(data)
                .then(async res => {
                    if (res.status == 200) {
                        this.props.removeFromCart(product.id, product.size);
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Session Expired..Please Login Again..!')
                        localStorage.clear()
                        window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    } else {
                        alert('Something Went Wrong')
                        localStorage.clear()
                        window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    }
                })
                .catch((err) => {
                    alert('Something Went Wrong')
                    localStorage.clear()
                    window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                })
        }
    }

    render() {
        const { cartlist, currency } = this.props;
        return (
            // default
            <div className="dropdown cart-dropdown">
                <Link to='#' onClick={e => { window.location = `${process.env.PUBLIC_URL}/shop/cart` }} className="dropdown-toggle icon-custom-hover" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                    <div className="icon">
                        <i className="icon-shopping-cart all-font-hover"></i>
                        <span className="cart-count">{getCartCount(cartlist)}</span>
                    </div>
                    <p>Cart</p>
                </Link>

                <div className={`dropdown-menu dropdown-menu-right ${cartlist.length === 0 ? 'text-center' : ''}`} style={cartlist.length === 0 ? { width: '200px', backgroundColor: '#212121' } : { backgroundColor: '#212121' }}>
                    <div className="dropdown-cart-products" style={{ display: window.location.pathname == '/shop/checkout' ? 'none' : '' }}>
                        {cartlist.map((item, index) => (
                            <div className="product all-bg" key={index}>
                                <div className="product-cart-details">
                                    <h4 className="product-title">
                                        <Link to={`${process.env.PUBLIC_URL}/shop/detail/${item.id}`} dangerouslySetInnerHTML={safeContent(item.name)}></Link>
                                        {/* {item.name} */}
                                    </h4>

                                    <span className="cart-product-info">
                                        {/* <span className="cart-product-qty">{item.size} Yds - </span> */}
                                        <span className="cart-product-qty">{item.qty}</span>
                                        x {currency.currencySymbol}&nbsp;{item.discount ? (item.salePrice * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : (item.price * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </span>
                                </div>

                                <figure className="product-image-container">
                                    <Link to={`${process.env.PUBLIC_URL}/shop/detail/${item.id}`} className="product-image">
                                        <img src={process.env.PUBLIC_URL + '/' + JSON.parse(item.pictures)[0]} data-oi={process.env.PUBLIC_URL + '/' + JSON.parse(item.pictures)[0]} alt="product" />
                                    </Link>
                                </figure>
                                <button className="btn-remove" title="Remove Product" onClick={(e) => this.removeFromCartHandler(e, item)}><i className="icon-close"></i></button>
                            </div>
                        ))}
                    </div>

                    <p style={{ display: window.location.pathname == '/shop/checkout' ? '' : 'none' }}>Not visible at Checkout Page..! And if you are not on Checkout Page, Please REFRESH..!</p>

                    {cartlist.length === 0 ?
                        <p>Your Cart is Empty.</p> : ''
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        cartlist: state.cartlist.cart ? state.cartlist.cart : [],
        currency: state.currencyData,
    }
}

export default connect(mapStateToProps, { removeFromCart })(CartMenu);