import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import { matchPath } from 'react-router-dom';
import { FileText, Home, ShoppingBag, User } from 'react-feather';

import store from './../store';

// import Custom Components
import Header from './components/header';
import Footer from './components/footer';
import MobileMenu from './components/mobileMenu';
import OuterOverlay from './components/overlay/outer-overlay';

import Error from './pages/other/404';

// import Utils
import { initSettings } from './../utils/utils';

// import Actions
import { outerLoading, closeQuickViewModal } from './../actions';

import './app.scss';

class App extends Component {

    componentDidMount() {
        initSettings();
        store.dispatch(outerLoading());
    }


    componentDidUpdate() {
        if (store.getState() && store.getState().overlay.type === 'outer') {
            store.dispatch(outerLoading());
        }

        if (store.getState() && store.getState().data.quickView) {
            store.dispatch(closeQuickViewModal());
        }
    }

    render() {
        let flag = false, i = 0;

        while (i < this.props.children.length && !matchPath(window.location.pathname, { path: this.props.children[i].props.path, exact: true })) {
            i++;
        }

        if (i < this.props.children.length) {
            flag = true;
        }

        return (
            <React.Fragment>
                <OuterOverlay />
                <div className="page-wrapper">
                    <Header />
                    {flag ?
                        this.props.children :
                        <Error />
                    }
                    <Footer />
                    <ToastContainer autoClose={3000} className="toast-container" />
                </div>
                <div className='sticky-footer-nav-bar desktop-hide-bnb'>
                    <div className={`sticky-footer-nav-bar-option ${window.location.pathname === '/' ? 'opt-color' : ''}`} onClick={e => window.location = process.env.PUBLIC_URL + "/"}>
                        <div><Home /></div>
                        <div className='option-name'>Home</div>
                    </div>
                    <div className={`sticky-footer-nav-bar-option ${window.location.pathname === '/shop' ? 'opt-color' : ''}`} onClick={e => window.location = process.env.PUBLIC_URL + "/shop"}>
                        <div><ShoppingBag /></div>
                        <div className='option-name'>Shop</div>
                    </div>
                    <div className={`sticky-footer-nav-bar-option ${window.location.pathname === '/gallery' ? 'opt-color' : ''}`} onClick={e => window.location = process.env.PUBLIC_URL + "/gallery"}>
                        <div><i className="las la-image" style={{ fontSize: '20px' }} /></div>
                        <div className='option-name'>Gallery</div>
                    </div>
                    <div className={`sticky-footer-nav-bar-option ${window.location.pathname === '/my-account/orders' ? 'opt-color' : ''}`} onClick={e => window.location = process.env.PUBLIC_URL + "/my-account/orders"}>
                        <div><FileText /></div>
                        <div className='option-name'>Orders</div>
                    </div>
                    <div className={`sticky-footer-nav-bar-option ${window.location.pathname === '/my-account/wallet' ? 'opt-color' : ''}`} onClick={e => window.location = process.env.PUBLIC_URL + "/my-account/wallet"}>
                        <div><i className="las la-wallet" style={{ fontSize: '20px' }} /></div>
                        <div className='option-name'>Wallet</div>
                    </div>
                </div>
                <MobileMenu />
            </React.Fragment>
        );
    }
}


export default App;