import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import PageHeader from './../../components/page-header';
import Accordion from './../../components/accordion/accordion';
import Card from './../../components/accordion/card';

import { isIEBrowser } from './../../../utils/utils';

import faq_data from './../../../data/faqs.json'

class FAQ extends Component {
    render() {
        return (
            <div className="main">
                <Helmet>
                    <title>Shivshahi Network</title>
                </Helmet>

                <h1 className="d-none">Shivshahi Network</h1>

                <PageHeader title="FAQs" subTitle="Ask Us" />

                <div className="page-content pt-3 all-bg">
                    <div className="container">

                        <h2 className="title text-center mb-3 all-font">Network Business</h2>

                        <Accordion adClass="accordion-plus">
                            {faq_data.faqs.map((faq, index) =>
                                <Card title={faq.question} adClass="card-box card-sm all-bg-light">{faq.answer}</Card>
                            )}
                        </Accordion>

                    </div>
                </div>

                <div className="cta cta-display bg-image pt-4 pb-4" style={{ backgroundImage: `url(assets/images/backgrounds/bg-7.jpg)` }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-9 col-xl-7">
                                <div className={`row no-gutters ${isIEBrowser() ? '' : 'flex-column'} flex-sm-row align-items-sm-center`} >
                                    <div className="col">
                                        <h3 className="cta-title text-white">आपल्याकडे अधिक प्रश्न असल्यास</h3>
                                        <p className="cta-desc text-white">आमच्याशी संपर्क साधा मोकळ्या मनाने</p>
                                    </div>

                                    <div className="col-auto">
                                        <Link to={`${process.env.PUBLIC_URL}/contact`} className="btn btn-outline-white"><span>CONTACT US</span><i className="icon-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FAQ;