import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import PageHeader from './../../components/page-header';

import { isIEBrowser } from './../../../utils/utils';

class ReturnExchangePolicy extends Component {
    render() {
        return (
            <div className="main">
                <Helmet>
                    <title>Shivshahi Network</title>
                </Helmet>

                <h1 className="d-none">Shivshahi Network</h1>

                <PageHeader title="Return & Exchange" subTitle="Policy" />

                <div className="page-content pt-3 all-bg">
                    <div className="container all-bg-light mb-5">
                        <ul className="pt-3 pr-3 pl-5 pb-3 all-font">
                            <li>❈&nbsp;&nbsp;आमच्या वेबसाइटवरील प्रत्येक व्यवहार हा अचुक व व्यवस्थितरित्या पार पाडण्याचा शिवशाही नेहमीच प्रयत्न करते.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;आम्ही आमची उत्पादने वितरीत करताना खूप काळजी घेतो. (साडी 3-4 वेळा चेक केली जाते.) आणि उच्च दर्जाची पॅकिंग साडी वर असते.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;पॉलिसी म्हणून, तुम्हीं दिलेल्या ऑर्डरनुसार परिपूर्ण स्थितीत वितरित केलेल्या उत्पादनांवर परतावा किंवा एक्सचेंज ऑफर करत नाही.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;तथापि, जर साडी किंवा इतर उत्पादन चुकीच्या पद्धतीने वितरित केले गेले असेल. (म्हणजे जर साडी किंवा इतर उत्पादन तुम्हीं ऑर्डर केलेल्या साडी शी जुळत नसेल) तर आम्ही ते मान्य करू. दोन्ही बाजूचे कुरिअर चार्जेस कंपनी भरेल. पण त्यासाठी ही तुम्हाला opening video हा द्यावा लागेल. ज्यात काहीही एडीट केलेला नको. पार्सल फोडण्या पासुन व्हिडीओ काढावा लागेल. </li>
                            <li className="pt-1">❈&nbsp;&nbsp;लहान.फास्ट.आणि एडिट केलेले व्हिडिओ वरून तक्रार स्वीकारल्या जाणार नाही.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;तक्रार करण्यास जर तुमच्या कडे व्हिडिओ नसेल तर ती मान्य होणार नाही.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;एखादे उत्पादन परत करण्यासाठी किंवा देवाणघेवाण करण्यासाठी किंवा ट्रांझिटमध्ये झालेल्या नुकसानीची तक्रार करण्यासाठी कृपया डिलिव्हरीच्या 24 तासांच्या आत +917447751617 येथे सकाळी 10.30 ते संध्याकाळी 6.30 या दरम्यान आमच्या ग्राहक सेवाशी संपर्क साधा किंवा आम्हाला shivshahinetwork@gmail.com वर ईमेल करा. ऑर्डर क्रमांक, व्यवहाराची तारीख, डिलिव्हरीचा पत्ता आणि चुकीच्या पद्धतीने वितरित केलेल्या किंवा सदोष वस्तूची प्रतिमा.  आमची कस्टमर केअर टीम या समस्येकडे लक्ष देईल आणि 48 कामकाजाच्या तासांमध्ये परत येईल.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;आमच्‍या उत्‍पादनांचे स्‍वरूप पाहता, आम्‍हाला योग्य वाटल्‍यानुसार कोणत्याही परिस्थितीत रिझोल्यूशन प्रदान करण्‍याचा पूर्ण अधिकार आम्‍ही राखून ठेवतो. प्रत्येक रिटर्न किंवा एक्सचेंज विनंती प्रत्येक केसच्या आधारावर हाताळली जाते आणि आम्ही तुम्हाला विनंती करतो की त्वरित निराकरणासाठी आमच्या टीमशी संपर्क साधावा. </li>
                            <li className="pt-1">❈&nbsp;&nbsp;ग्राहकाने चुकीचा किंवा अपूर्ण शिपिंग पत्ता प्रदान केला असल्यास, आमच्या शिपिंग एजन्सीद्वारे एक अयशस्वी वितरण प्रयत्न केला गेला असेल आणि/किंवा प्राप्तकर्त्याने पॅकेज नाकारले असेल तर कोणताही परतावा/एक्सचेंज दिले जाणार नाही.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;शिपिंग पत्ता हा वितरक कंपनी च्या Non zon एरिया मध्ये येत असल्यास. कंपनी काही करू शकत नाही.. तुम्हाला कुरिअर कंपनी च्या ऑफिस वरून parcel collect करावे लागेल..किंवा parcel दुसऱ्या कंपनी ने पाठवे पर्यंत वाट पाहावी लागेल.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;पॅकेज एकदा शिप झाले की आर्डर कॅन्सल होणार नाही.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;आंतरराष्ट्रीय ऑर्डरच्या बाबतीत, जर ग्राहकाने संबंधित देशाने शिपमेंटच्या आदेशानुसार ड्युटी भरण्यास नकार दिला आणि शिपिंग एजन्सी (जसे की DHL, Fedex इ.) कडून त्याची/तिची ऑर्डर गोळा केली नाही, तर शिवशाही यासाठी जबाबदार राहणार नाही  अशा ऑर्डरसाठी कोणतीही रक्कम परत करा.  ऑनलाइन खरेदी केलेल्या उत्पादनांची देवाणघेवाण किंवा स्टोअरमध्ये परत केली जाऊ शकत नाही.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;अस्वीकरण: सर्व धोरणे पूर्व सूचना न देता बदलू शकतात.  कोणत्याही संघर्षाच्या बाबतीत अटी व शर्ती धोरण प्रचलित होईल.</li>
                        </ul>
                    </div>

                    <div className="container all-bg-light text-center pt-3">
                        <h4 className="mb-3 pt-3 all-font-light">फक्त ₹ १००० च्या साडी साठी सूचना</h4>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <ul className="pt-3 pb-3 all-font col-lg-8 col-md-9 col-sm-10">
                                <li>ही साडी ऑटोमॅटिक जकार्डमशीन वर बनलेली आहे. त्यामुळे तिची किंमत हजार रुपये पर्यंत असते. आणि ह्या सारी मध्ये छोटे-छोटे प्रॉब्लेम येतातच. जे प्रत्येक साडीला असतात. अशा प्रकारचे छोट्या गोष्टी आढळल्यास साडी एक्सचेंज करून मिळणार नाही.</li>
                            </ul>
                        </div>
                        <div className="pb-5" style={{ display: 'flex', justifyContent: 'start', width: '100%', overflow: 'auto' }}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/policies/Untitled-design-6.jpg`} alt="" style={{ width: '250px' }} className="mr-2 ml-2" />
                            <img src={`${process.env.PUBLIC_URL}/assets/images/policies/Untitled-design-3.jpg`} alt="" style={{ width: '250px' }} className="mr-2 ml-2" />
                            <img src={`${process.env.PUBLIC_URL}/assets/images/policies/Untitled-design-7.jpg`} alt="" style={{ width: '250px' }} className="mr-2 ml-2" />
                            <img src={`${process.env.PUBLIC_URL}/assets/images/policies/Untitled-design-8.jpg`} alt="" style={{ width: '250px' }} className="mr-2 ml-2" />
                            <img src={`${process.env.PUBLIC_URL}/assets/images/policies/Untitled-design-9.jpg`} alt="" style={{ width: '250px' }} className="mr-2 ml-2" />
                        </div>
                    </div>
                </div>

                <div className="cta cta-display bg-image pt-4 pb-4" style={{ backgroundImage: `url(assets/images/backgrounds/bg-7.jpg)` }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-9 col-xl-7">
                                <div className={`row no-gutters ${isIEBrowser() ? '' : 'flex-column'} flex-sm-row align-items-sm-center`} >
                                    <div className="col">
                                        <h3 className="cta-title text-white">आपल्याकडे अधिक प्रश्न असल्यास</h3>
                                        <p className="cta-desc text-white">आमच्याशी संपर्क साधा मोकळ्या मनाने</p>
                                    </div>

                                    <div className="col-auto">
                                        <Link to={`${process.env.PUBLIC_URL}/faq`} className="btn btn-outline-white"><span>Ask Us</span><i className="icon-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReturnExchangePolicy;