import React, { useState } from 'react';
import Modal from 'react-modal';
import Lightbox from 'react-image-lightbox';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import OwlCarousel from './../owl-carousel';

import { addToCart, toggleWishlist, addToCompare, closeQuickViewModal } from './../../../actions';
import { findIndex, quantityInputs, isIEBrowser, safeContent } from './../../../utils/utils';

import * as userApi from './../../../api/user'

const customStyles = {
    content: {
        top: '50%',
        transform: 'translateY(-50%)'
    },
    overlay: {
        backgroundColor: 'rgba(51,51,51,0.6)',
        zIndex: '10000'
    }
};

Modal.setAppElement('#root');

const QuickView = ({
    showModal,
    productDetail,
    addToCart,
    currency,
    toggleWishlist,
    wishlist,
    closeQuickViewModal
} = this.props) => {
    const [selectedProductSize, setSelectedProductSize] = useState(
        productDetail.variants ? JSON.parse(productDetail.variants)[0].size : ""
    );
    const [selectedProductStock, setSelectedProductStock] = useState(
        productDetail.variants ? JSON.parse(productDetail.variants)[0].stock : ""
    );
    const [quantityCount, setQuantityCount] = useState(1);

    const [photoIndex, setphotoIndex] = useState(0);
    const [isOpen, setisOpen] = useState(false);

    const openLightBox = (e, index) => {
        setisOpen(true);
        setphotoIndex(index)
        e.preventDefault();
    }

    const toggleWishListHandler = (e) => {
        e.preventDefault();

        const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer
        if (usertokenShivshahiCustomer != null) {

            var data = {
                userToken: usertokenShivshahiCustomer,
                wishlistItem: productDetail
            }

            userApi.addToWishlist(data)
                .then(async res => {
                    if (res.status == 200) {
                        // if (wishlist) {
                        //     window.location = '/wishlist';
                        // } else {
                        toggleWishlist(productDetail, wishlist)
                        // }
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Session Expired..Please Login Again..!')
                        localStorage.clear()
                        window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    } else {
                        alert('Something Went Wrong')
                        localStorage.clear()
                        window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    }
                })
                .catch((err) => {
                    alert('Something Went Wrong')
                    localStorage.clear()
                    window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                })
        } else {
            window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
        }

    }

    const addToCartHandler = (e) => {
        e.preventDefault();
        const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer
        if (usertokenShivshahiCustomer != null) {

            var data = {
                userToken: usertokenShivshahiCustomer,
                cartlistItem: productDetail
            }
            data.cartlistItem.size = 6
            data.cartlistItem.qty = parseInt(document.querySelector("#qty1").value)
            data.cartlistItem.sum = parseInt(document.querySelector("#qty1").value) * parseInt(productDetail.price)

            userApi.addToCartlist(data)
                .then(async res => {
                    if (res.status == 200) {
                        addToCart(productDetail, document.querySelector("#qty1").value, 6)
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Session Expired..Please Login Again..!')
                        localStorage.clear()
                        window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    } else {
                        // alert('Something Went Wrong')
                        // localStorage.clear()
                        // window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    }
                })
                .catch((err) => {
                    // alert('Something Went Wrong')
                    // localStorage.clear()
                    // window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                })
        } else {
            window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
        }
    }

    const afterOpenModal = () => {
        quantityInputs();
    }

    const onSizeChange = (e) => {
        setSelectedProductSize(e.target.value)
        setSelectedProductStock(JSON.parse(productDetail.variants)[e.target.options.selectedIndex].stock)
        setQuantityCount(1)
    };

    // console.log(selectedProductStock)

    var totalStock = 0;

    // if (productDetail.length !== 0) {
    //     {
    //         productDetail.variants.map((item, index) => (
    //             totalStock = totalStock + parseInt(item.stock)
    //         ))

    //     }
    // }

    if (!productDetail || !productDetail.name)
        return ('');

    let ratings = productDetail.ratings * 20;
    let images = productDetail.lgPictures ? productDetail.lgPictures : productDetail.pictures;

    return (

        <Modal isOpen={showModal}
            contentLabel="QuickView"
            onAfterOpen={afterOpenModal}
            onRequestClose={closeQuickViewModal}
            shouldFocusAfterRender={false}
            style={customStyles}
            className="container quickView-container" id="product-quickview">

            <div className="quickView-content" >
                <div className="row">
                    <div className="col-lg-7">
                        <div className="row">
                            <div style={{ display: 'none' }}>
                                {
                                    JSON.parse(productDetail.variants).map((item, index) => (
                                        totalStock = totalStock + parseInt(item.stock)
                                    ))
                                }
                            </div>

                            <div className="product-left" id="owl-dots">
                                {
                                    JSON.parse(productDetail.pictures).map((item, index) => (
                                        <button className={`carousel-dot ${0 === index ? 'active' : ''}`} key={index}>
                                            <img src={process.env.PUBLIC_URL + '/' + item} alt="dot" style={{ height: '17.1vh', width: '100%' }} />
                                        </button>
                                    ))
                                }
                            </div>

                            <div className="product-right">
                                <OwlCarousel adClass="owl-quickview owl-carousel owl-theme owl-nav-inside owl-light mb-0 owl-loaded owl-drag" carouselOptions="{dots: false,nav:false,dotsContainer: #owl-dots, responsive:{900:{nav:true,dots:true}} " id="owl_quickview">
                                    {
                                        JSON.parse(productDetail.pictures).map((item, index) => (
                                            <div className="intro-slide" key={index}>
                                                <img src={process.env.PUBLIC_URL + '/' + item} alt="Desc" />
                                                <Link to="popup/fullscreen.html" className="btn-fullscreen" onClick={(e) => openLightBox(e, parseInt(index))}>
                                                    <i className="icon-arrows"></i>
                                                </Link>
                                            </div>
                                        ))
                                    }
                                </OwlCarousel>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-5">

                        <h2 className="product-title" dangerouslySetInnerHTML={safeContent(productDetail.name)}></h2>

                        <h3 className="font-primary">{currency.currencySymbol}&nbsp;{(productDetail.price * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>

                        <div className="ratings-container">
                            <div className="ratings">
                                <div className="ratings-val" style={{ width: ratings + '%' }}></div>
                            </div>
                            {/* <span className="ratings-text">({productDetail.reviews} Reviews )</span> */}
                        </div>

                        <p className="product-txt">{productDetail.shortDesc}</p>

                        <div className="details-filter-row details-row-size" style={{ display: `${totalStock ? '' : 'none'}` }}>
                            <label htmlFor="size">Size:</label>
                            <div className="select-custom">
                                <select name="size" id='size1' className="form-control" onChange={onSizeChange}>
                                    {JSON.parse(productDetail.variants).map((item, index) => (
                                        <option value={item.size} key={index}> {item.size} Yards</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="details-filter-row details-row-size mb-0" style={{ display: `${totalStock ? `${selectedProductStock ? '' : 'none'}` : `none`}` }}>
                            <label htmlFor="qty">Qty:</label>
                            <div className="product-details-quantity">
                                <div className="input-group input-spinner">
                                    <div className="input-group-prepend">
                                        <button style={{ minWidth: '26px' }} className="btn btn-decrement btn-spinner" type="button"
                                            onClick={() =>
                                                setQuantityCount(quantityCount > 1 ? quantityCount - 1 : 1)
                                            }
                                        >
                                            <i className="icon-minus"></i>
                                        </button>
                                    </div>
                                    <input type="text" id='qty1' style={{ textAlign: 'center' }} className="form-control " placeholder="" readOnly disabled value={quantityCount} />
                                    <div className="input-group-append">
                                        <button style={{ minWidth: '26px' }} className="btn btn-increment btn-spinner" type="button"
                                            onClick={() =>
                                                setQuantityCount(quantityCount < selectedProductStock && selectedProductStock !== 0 ? quantityCount + 1 : quantityCount)
                                            }
                                        >
                                            <i className="icon-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p style={{ color: '#f54545' }}>Only <strong>{selectedProductStock}</strong> left in stock!</p>

                        <div className="details-filter-row details-row-size">
                            <label htmlFor="qty">Color:</label>
                            <div className="color-display" style={{ backgroundColor: productDetail.color }} />
                        </div>

                        <div className="product-details-action">
                            <div className="details-action-wrapper">

                                <button className={`btn-product btn-wishlist ${wishlist ? 'added-to-wishlist' : ''}`}
                                    onClick={toggleWishListHandler}
                                    title={wishlist ? "Remove from wishlist" : "Add to wishlist"}
                                    style={{ minHeight: isIEBrowser() ? '20px' : 'auto' }}
                                >
                                    <span>{wishlist ? "remove from wishlist" : "add to wishlist"}</span>
                                </button>

                            </div>
                            <button className="btn-product btn-cart" style={{ minHeight: isIEBrowser() ? '44px' : 'auto', display: `${totalStock ? `${selectedProductStock ? '' : 'none'}` : `none`}` }} onClick={addToCartHandler}><span>add to cart</span></button>
                        </div>
                    </div>
                </div>
            </div>

            <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => closeQuickViewModal()}><span>×</span></button>

            {isOpen && (
                <Lightbox
                    discourageDownloads={false}
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setisOpen(false)}
                    onMovePrevRequest={() => setphotoIndex((photoIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setphotoIndex((photoIndex + 1) % images.length)}
                />
            )}

        </Modal>

    );
}

const mapStateToProps = (state, ownprops) => {
    let wishlist;

    if (findIndex(state.wishlist.list, item => item.id === state.data.productDetail.id) !== -1) {
        wishlist = true;
    } else {
        wishlist = false;
    }

    return {
        showModal: state.data.quickView,
        productDetail: state.data.productDetail,
        wishlist: wishlist,
        currency: state.currencyData,
    };
}

export default connect(mapStateToProps, { addToCart, toggleWishlist, addToCompare, closeQuickViewModal })(QuickView);