import React, { Component } from 'react';
import { Download, Edit, Edit2, Trash2 } from 'react-feather'
import { connect } from 'react-redux';

import { deleteGalleryPost, likeGalleryPost, updateGalleryPostStatus } from '../../../api/other';
import { togglePostLike } from '../../../actions';
import GalleryProductSuggestions from './gallery-product-suggestions';

var dateOptions = {
    year: "2-digit",
    month: "short",
    day: "numeric"
};

const loadCount = 30;

class GalleryPostSingle extends Component {

    constructor(props) {
        super(props)

        this.state = {
            readMoreIndex: -1,
            editStatusIndex: -1,
            loadedCount: loadCount,
            hasMore: true,
            content: '',
            stars: [],
            selectedIcon: "★",
            deselectedIcon: "☆"
        }

        let outOf = props.outOf ? props.outOf : 5;

        for (var i = 0; i < outOf; i++) {
            this.state.stars.push(i + 1);
        }
    }

    loadMore = () => {
        this.setState({ loadedCount: this.state.loadedCount + loadCount })
    }

    toggleReadMore = (e, index) => {
        if (index === this.state.readMoreIndex) {
            this.setState({ readMoreIndex: -1 })
        } else {
            this.setState({ readMoreIndex: index })
        }
    };

    toggleEditStatus = (e, index, item) => {
        if (index === this.state.editStatusIndex) {
            this.setState({ editStatusIndex: -1, content: item.content })
        } else {
            this.setState({ editStatusIndex: index, content: item.content })
        }
    };

    deletePost = (e, item) => {

        if (window.confirm('Do you want to delete this post..? You can not revert it back.')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer

            const data = {
                userToken: usertokenShivshahiCustomer,
                id: item.postId,
            }

            deleteGalleryPost(data)
                .then(res => {
                    if (res.status == 200) {
                        alert("Post Deleted.!")
                        window.location.reload();
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                })
        }
    }

    likePost = (e, item) => {

        const usertokenShivshahiCustomer = localStorage.getItem('usertokenShivshahiCustomer')
        if (usertokenShivshahiCustomer != null) {
            const data = {
                userToken: usertokenShivshahiCustomer,
                id: item.postId,
                isLiked: item.isLikedByUser == 1 ? true : false
            }

            likeGalleryPost(data)
                .then(res => {
                    if (res.status != 200) {
                        alert('Error Occured.. Please Try after some time')
                    } else {
                        this.props.togglePostLike(item)
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                })
        } else {
            window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
        }

    }

    onRequestUpdateStatus = (e, item) => {
        e.preventDefault();

        if (window.confirm('Do you want update the status..?')) {
            const usertokenShivshahiCustomer = localStorage.getItem('usertokenShivshahiCustomer')
            if (usertokenShivshahiCustomer != null) {
                let spinner = document.getElementById("spinneranimid");
                spinner.style.display = "block";

                const data = {
                    userToken: usertokenShivshahiCustomer,
                    id: item.postId,
                    content: this.state.content,
                }

                updateGalleryPostStatus(data)
                    .then(res => {
                        if (res.status != 200) {
                            alert('Error Occured.. Please Try after some time')
                            spinner.style.display = "none";
                        } else {
                            alert('Status updated Successfully..!')
                            window.location.reload();
                        }
                    })
                    .catch((err) => {
                        alert('Error Occured.. Please Try after some time')
                        spinner.style.display = "none";
                    })
            } else {
                window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
            }
        }
    }

    render() {
        const { data, calledFor, galleryProductSuggestions } = this.props;
        const modValue = 3;

        var lastStartIndex = 0;
        var lastEndIndex = 2;

        return (
            <div className='container mt-2' style={{ display: 'flex', justifyContent: 'center' }}>
                {data.length ?
                    <div className='col-xl-6 col-lg-6 col-md-8 col-sm-9 col-12 gallery-card-container'>
                        {data.slice(0, this.state.loadedCount).map((item, index) => {
                            if ((index + 1) % modValue == 0 && index !== 2) {
                                lastStartIndex = lastStartIndex + 2;
                                lastEndIndex = lastEndIndex + 2;
                            }

                            return (
                                <div key={index}>
                                    <article>
                                        <div className="gallery-card-header">
                                            <div className='user-data'>
                                                <div className='user-profile-pic'>
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/profile_pictures/${item.profile_pic === null ? 'dummy.jpg' : item.profile_pic}`} alt='dp' />
                                                </div>
                                                <div className='user-details'>
                                                    <div className='header-name'>{item.firstname}&nbsp;{item.lastname}</div>
                                                    <div className='header-city'>{item.city}</div>
                                                </div>
                                            </div>
                                            <div className='uploaded-at'>
                                                {
                                                    calledFor === 'gallery'
                                                        ? new Date(item.createdAt).toLocaleDateString("en", dateOptions)
                                                        : <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                            <div onClick={e => this.deletePost(e, item)}>
                                                                <Trash2 width={20} />
                                                            </div>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <div onClick={e => this.toggleEditStatus(e, index, item)}>
                                                                <Edit width={20} />
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>

                                        <div className='gallery-card-image' > {/*style={{ aspectRatio: item.isVerticle ? '1/1' : '0' }} */}
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/gallery_images/${item.image.split(".")[0]}.webp`} alt='dp' style={{ height: 'auto', width: '100%' }} /> {/*style={{ height: item.isVerticle ? '100%' : 'auto', width: item.isVerticle ? 'auto' : '100%' }}*/}
                                        </div>

                                        <div className='gallery-card-options'>
                                            <div className='shop-link' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <a href={`${process.env.PUBLIC_URL}/shop`} target="_blank" rel="noopener noreferrer">
                                                    Shop Products
                                                </a>
                                                <a href={`${process.env.PUBLIC_URL}/assets/images/gallery_images_original/${item.image}`} download={item.image} target="_blank" rel="noopener noreferrer">
                                                    Download <Download width={18} />
                                                </a>
                                            </div>
                                            <div className='bottom-options pb-0'>
                                                <div className='left-part'>
                                                    {/* <div className='mood-container' style={{ alignItems: 'center' }}>
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/emojis/${item.mood}.png`} alt="icon" style={{ width: "32px", height: 'auto' }} />
                                                    </div> */}
                                                    <div className="like-btn" onClick={e => this.likePost(e, item)}>
                                                        <i className={item.isLikedByUser === 0 || item.isLikedByUser === null || item.isLikedByUser === undefined ? "icon-heart-o" : "icon-heart"} style={{ color: item.isLikedByUser === 0 || item.isLikedByUser === null || item.isLikedByUser === undefined ? '#fff' : '#c96' }} />
                                                        <span style={{ color: item.isLikedByUser === 0 || item.isLikedByUser === null || item.isLikedByUser === undefined ? '#c2c2c2' : '#c96' }}>{item.isLikedByUser === 0 || item.isLikedByUser === null || item.isLikedByUser === undefined ? "like" : "liked"}</span>
                                                    </div>
                                                </div>
                                                <div className='total-likes'>
                                                    <span>{item.totalLikes}</span> likes
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '10px' }}>
                                                {this.state.stars.map(star =>
                                                    <span
                                                        style={{
                                                            color: parseInt(item.rating) < star ? "#666" : "#c96",
                                                            cursor: 'pointer',
                                                            fontSize: "15px",
                                                            marginLeft: "2px",
                                                            marginRight: "2px"
                                                        }}
                                                    >
                                                        {parseInt(item.rating) < star ?
                                                            this.state.deselectedIcon : this.state.selectedIcon
                                                        }
                                                    </span>
                                                )}
                                            </div>

                                            <p className='gallery-card-content all-font' style={{ display: this.state.editStatusIndex === index ? 'none' : '' }}>
                                                {this.state.readMoreIndex === index ? item.content : item.content.slice(0, 70)}
                                                {item.content.length > 70 &&
                                                    <span onClick={e => this.toggleReadMore(e, index)}>
                                                        {this.state.readMoreIndex === index ? '...show less' : '...read more'}
                                                    </span>
                                                }
                                            </p>

                                            <div style={{ display: this.state.editStatusIndex === index ? '' : 'none' }}>
                                                <form onSubmit={e => this.onRequestUpdateStatus(e, item)}>
                                                    <div className="form-group" style={{ padding: '10px' }}>
                                                        <textarea style={{ minHeight: 'auto' }} rows={3} value={this.state.content} className="form-control all-font" id="content" name="content" pattern=".*\S+.*" onChange={e => this.setState({ content: e.target.value })} required={this.state.editStatusIndex === index} />
                                                    </div>

                                                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px' }}>
                                                        <button type="submit" className="btn btn-outline-primary-2">
                                                            <span>Save Changes</span>
                                                            <i className="icon-long-arrow-up"></i>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </article>
                                    <div style={{ display: ((index + 1) % modValue == 0) && calledFor === 'gallery' ? '' : 'none' }}>
                                        <GalleryProductSuggestions column="2cols" galleryProductSuggestions={galleryProductSuggestions} startNumber={((index + 1) % modValue == 0) ? lastStartIndex : 0} endNumber={((index + 1) % modValue == 0) ? lastEndIndex : 0} />
                                    </div>
                                </div>
                            )
                        })}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button type='btn' className='btn btn-outline-primary btn-load-more' onClick={this.loadMore} style={{ display: this.state.loadedCount < data.length ? '' : 'none' }}>
                                Load More !
                            </button>
                        </div>
                    </div>
                    :
                    <div className="col-12 all-bg-light mb-5">
                        No Posts Yet..!
                    </div>
                }
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        data: state.posts.galleryPosts ? state.posts.galleryPosts : []
    };
}

export default connect(mapStateToProps, { togglePostLike })(GalleryPostSingle);