import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import Connections from './../../components/connections'

class Contact extends Component {

    constructor(props) {
        super(props)

        this.state = {
            cname: "",
            cemail: "",
            cphone: "",
            csubject: "",
            cmessage: "",
        }
    }

    componentDidMount() {
        document.body.classList.add('all-bg');
    }

    componentWillUnmount() {
        document.body.classList.remove('all-bg');
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = e => {
        e.preventDefault();
        alert('This form is in progress..! Please contact directly on +91 8180070617')
    }

    render() {
        return (
            <div className="main">
                <Helmet>
                    <title>Shivshahi Network</title>
                </Helmet>

                <h1 className="d-none">Shivshahi Network</h1>

                <div className="container pt-4">
                    <div className="page-header page-header-big text-center" style={{ backgroundImage: `url('assets/images/backgrounds/contact-header-bg.jpg')` }}>
                        <h1 className="page-title text-white">Contact us<span className="text-white">keep in touch with us</span></h1>
                    </div>
                </div>

                <div className="page-content pb-0">
                    <div className="container">
                        {/* <div className="row">
                            <div className="col-lg-6 mb-2 mb-lg-0">
                                <h2 className="title mb-1 all-font-light">Shivshahi Network</h2>
                                <p className="mb-3">Interested in business with us ? Want to start something of your own ? And don't want to spend anything ? Then you are at right place..!</p>
                                <div className="row">
                                    <div className="col-sm-7">
                                        <div className="contact-info">
                                            <h3 className="all-font">Contact Info</h3>

                                            <ul className="contact-list">
                                                <li>
                                                    <i className="icon-map-marker"></i>
                                                    Shivshahi Paithani, Kukkar Lane, Yeola, Nashik, Maharashtra, 423401.
                                                </li>
                                                <li>
                                                    <i className="icon-phone"></i>
                                                    <a href="tel://+918180070617">+91 8180070617</a>
                                                </li>
                                                <li>
                                                    <i className="icon-envelope"></i>
                                                    <a href="mailto://contact@shivshahinetwork.com">contact@shivshahinetwork.com</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-sm-5">
                                        <div className="contact-info">
                                            <h3 className="all-font">Timings</h3>

                                            <ul className="contact-list">
                                                <li>
                                                    <i className="icon-clock-o"></i>
                                                    <span className="text-light all-font">Monday-Saturday</span> <br />11am-7pm IST
                                                </li>
                                                <li>
                                                    <i className="icon-calendar"></i>
                                                    <span className="text-light all-font">Sunday</span> <br />11am-6pm IST
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <h2 className="title mb-1 all-font">Got Any Questions?</h2>
                                <p className="mb-2">Use the form below to get in touch with Us</p>

                                <form onSubmit={this.handleSubmit} className="contact-form mb-3">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label htmlFor="cname" className="sr-only">Name</label>
                                            <input type="text" className="form-control all-bg" id="cname" name="cname" placeholder="Name *" onChange={this.handleChange} required />
                                        </div>

                                        <div className="col-sm-6">
                                            <label htmlFor="cemail" className="sr-only">E-mail</label>
                                            <input type="email" className="form-control" id="cemail" name="cemail" placeholder="E-mail *" onChange={this.handleChange} required />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label htmlFor="cphone" className="sr-only">Phone</label>
                                            <input type="number" className="form-control" id="cphone" name="cphone" placeholder="Phone" onChange={this.handleChange} required />
                                        </div>

                                        <div className="col-sm-6">
                                            <label htmlFor="csubject" className="sr-only">Subject</label>
                                            <input type="text" className="form-control" id="csubject" name="csubject" placeholder="Subject" onChange={this.handleChange} required />
                                        </div>
                                    </div>

                                    <label htmlFor="cmessage" className="sr-only">Message</label>
                                    <textarea className="form-control" cols="30" rows="4" id="cmessage" name="cmessage" placeholder="Message *" onChange={this.handleChange} required />

                                    <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm">
                                        <span>SUBMIT</span>
                                        <i className="icon-long-arrow-right"></i>
                                    </button>
                                </form>
                            </div>
                        </div>

                        <hr className="mt-4 mb-5" /> */}

                        <div className="stores mb-4 mb-lg-5">
                            {/* <h2 className="title text-center mb-3 all-font">Our Stores</h2> */}

                            <div className="row">
                                <div className="col-lg-4" style={{ textAlign: 'center' }}>
                                    <div className="store">
                                        <figure className="store-media mb-2 mb-lg-0" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/stores/yeola_shop.jpg`} alt="desc" />
                                        </figure>

                                        <div className="store-content pt-2">
                                            <h3 className="store-title all-font-light">Shivshahi Paithani, Yeola</h3>
                                            <address>Shivshahi Paithani, Kukkar Lane, Yeola, Nashik, Maharashtra, 423401.</address>
                                            <p className="all-font">Contact: <strong>+91 8446703040</strong></p>
                                            <p className="all-font">Timing: 10:00 AM - 8:00 AM (Open All Days)</p>
                                            <a href="https://goo.gl/maps/gQt91rt2EpzcUoHZ6" className="btn btn-link" target="_blank" rel="noopener noreferrer"><span>View on Map</span><i className="icon-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4" style={{ textAlign: 'center' }}>
                                    <div className="store">
                                        <figure className="store-media mb-2 mb-lg-0" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/stores/thane_shop.jpg`} alt="desc" />
                                        </figure>

                                        <div className="store-content pt-2">
                                            <h3 className="store-title all-font-light">Shivshahi Paithani, Thane</h3>
                                            <address>Ram Maruti Rd, Opposite ICICI bank, near Gajanan Maharaj Mandir, Naupada, Thane West, Thane, Maharashtra, 400602.</address>
                                            <p className="all-font">Contact: <strong>+91 8208238376</strong></p>
                                            <p className="all-font">Timing: 10:00 AM - 8:00 AM (Open All Days)</p>
                                            <a href="https://g.page/ShivshahiPaithani?share" className="btn btn-link" target="_blank" rel="noopener noreferrer"><span>View on Map</span><i className="icon-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4" style={{ textAlign: 'center' }}>
                                    <div className="store">
                                        <figure className="store-media mb-2 mb-lg-0" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/stores/vasai_shop.jpg`} alt="desc" />
                                        </figure>

                                        <div className="store-content pt-2">
                                            <h3 className="store-title all-font-light">Shivshahi Paithani, Vasai</h3>
                                            <address>Sai Nagar, Vasai West, Navghar-Manikpur, Maharashtra, 401202.</address>
                                            <p className="all-font">Contact: <strong>+91 8208238376</strong></p>
                                            <p className="all-font">Timing: 10:00 AM - 8:00 AM (Open All Days)</p>
                                            <a href="https://goo.gl/maps/6RnsMDw32gJdYyxM6" className="btn btn-link" target="_blank" rel="noopener noreferrer"><span>View on Map</span><i className="icon-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4" style={{ textAlign: 'center' }}>
                                    <div className="store">
                                        <figure className="store-media mb-2 mb-lg-0" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/stores/vashi_shop.jpg`} alt="desc" />
                                        </figure>

                                        <div className="store-content pt-2">
                                            <h3 className="store-title all-font-light">Shivshahi Paithani, Vashi</h3>
                                            <address>Chatrapati Shivaji Maharaj Chowk, Sector 17, Vashi, Navi Mumbai, Maharashtra, 400703.</address>
                                            <p className="all-font">Contact: <strong>+91 9833024555</strong></p>
                                            <p className="all-font">Timing: 10:00 AM - 8:00 AM (Open All Days)</p>
                                            <a href="https://goo.gl/maps/GPEX2DW1C3DNvPR88" className="btn btn-link" target="_blank" rel="noopener noreferrer"><span>View on Map</span><i className="icon-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4" style={{ textAlign: 'center' }}>
                                    <div className="store">
                                        <figure className="store-media mb-2 mb-lg-0" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/stores/dadar_shop.jpg`} alt="desc" />
                                        </figure>

                                        <div className="store-content pt-2">
                                            <h3 className="store-title all-font-light">Shivshahi Paithani, Dadar</h3>
                                            <address>Nakshatra Mall, Shop no. 44 First Floor, Ranade Road, Dadar West, Mumbai, Maharashtra, 400028.</address>
                                            <p className="all-font">Contact: <strong>+91 9987487561</strong></p>
                                            <p className="all-font">Timing: 10:00 AM - 8:00 AM (Open All Days)</p>
                                            <a href="https://goo.gl/maps/pSqSATE6rWnhXdLR7" className="btn btn-link" target="_blank" rel="noopener noreferrer"><span>View on Map</span><i className="icon-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4" style={{ textAlign: 'center' }}>
                                    <div className="store">
                                        <figure className="store-media mb-2 mb-lg-0" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/stores/kalyan_shop.jpg`} alt="desc" />
                                        </figure>

                                        <div className="store-content pt-2">
                                            <h3 className="store-title all-font-light">Shivshahi Paithani, Kalyan</h3>
                                            <address>Shop No.1, Shree Suprata Apartment, Ganpati Chowk, Joshi Compound Agra Road, Kalyan (West), Mumbai, Maharashtra, 421301.</address>
                                            <p className="all-font">Contact: <strong>+91 7208603285</strong></p>
                                            <p className="all-font">Timing: 10:00 AM - 8:00 AM (Open All Days)</p>
                                            <a href="https://goo.gl/maps/bXJ5mCYj78JWGXieA" className="btn btn-link" target="_blank" rel="noopener noreferrer"><span>View on Map</span><i className="icon-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="mt-0 mb-2" />

                {/* <Connections start={1} end={80} show='yes' /> */}
                <Connections show='yes' />

            </div>
        )
    }
}

export default Contact;