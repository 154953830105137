import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Share2 } from 'react-feather'

import Accordion from './../../components/accordion/accordion';
import Card from './../../components/accordion/card';

import * as userApi from './../../../api/user'

import MyAccountHeader from '../../components/my-account-header';

class Dashboard extends Component {

    constructor(props) {
        super(props)

        this.state = {
            firstname: "",
            lastname: "",
            joinDate: '',
            ownref: "",
            referenceby: "",
            parent: [],
            grandparent: [],
            grandparent_big: [],
            isFirstOrder: true,

            referenceTree: [],
            isOKtoshowRT: false,

            lvlOneOwnref: '',
            lvlTwoOwnref: '',

            cfoOrderNumber: '',
            isDelete: 'false',
            deleteStatus: ''
        }
    }

    componentDidMount = async () => {
        document.body.classList.add('all-bg');

        var checkusertoken = localStorage.getItem('usertokenShivshahiCustomer');
        if (checkusertoken == null) {
            this.props.history.push(`${process.env.PUBLIC_URL}/auth/login-register/:data`)
        } else {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer

            const data = {
                userToken: usertokenShivshahiCustomer,
                isNeededParents: true
            }

            await userApi.fetchUserData(data)
                .then(async res => {
                    if (res.status == 200) {
                        this.setState({
                            firstname: res.data.firstname,
                            lastname: res.data.lastname,
                            joinDate: res.data.created,
                            ownref: res.data.ownref,
                            referenceby: res.data.referenceby,
                            isFirstOrder: res.data.isFirstOrder
                        }, async () => {
                            if (this.state.referenceby != '') {
                                this.setState({ parent: res.data.parent }, () => {
                                    if (this.state.parent.referenceby != '') {
                                        this.setState({ grandparent: res.data.parent.parent }, () => {
                                            if (this.state.grandparent.referenceby != '') {
                                                this.setState({ grandparent_big: res.data.parent.parent.parent })
                                            }
                                        })
                                    }
                                })
                            }
                            await this.getDeleteStatus()
                            spinner.style.display = "none";
                        })
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Please Login Again...!')
                        // localStorage.removeItem('usertokenShivshahiCustomer')
                        localStorage.clear();
                        window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                })
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('all-bg');
    }

    share = (e) => {
        e.preventDefault();
        window.open(
            `https://wa.me/?text=Join to our Network by clicking on the link below: https://shivshahinetwork.com/auth/login-register/${this.state.ownref}`, "_blank"
        );
    }

    onHandleClickFirstDownlines = (e) => {
        e.stopPropagation();
        if (e.target.classList[1] == 'collapsed') {
            this.setState({ lvlOneOwnref: e.target.innerHTML.split("- ").pop() }, () => {
                this.fetchDownlines('second', this.state.lvlOneOwnref)
            })
        }
    }

    onHandleClickSecondDownlines = (e) => {
        e.stopPropagation();
        if (e.target.classList[1] == 'collapsed') {
            this.setState({ lvlTwoOwnref: e.target.innerHTML.split("- ").pop() }, () => {
                this.fetchDownlines('third', this.state.lvlTwoOwnref)
            })
        }
    }

    onHandleClickThirdDownlines = (e) => {
        e.stopPropagation();
    }

    onDeleteRequestClick = async (e, isDelete) => {
        e.stopPropagation();

        if (isDelete) {
            console.log("Cancel Account Delete Request");

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            await this.cancelAccountDeleteRequest()
            spinner.style.display = "none";
        } else {
            console.log("Request delete...");
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            await this.requestAccountDelete()
            spinner.style.display = "none";
        }
    }

    fetchDownlines = (downlineLevel, targetOwnref) => {
        // e.preventDefault();

        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";

        var refTree = this.state.referenceTree

        const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer

        const data = {
            userToken: usertokenShivshahiCustomer,
            downlineLevel: downlineLevel,
            targetOwnref: targetOwnref,
        }

        userApi.fetchDownlinesStr(data)
            .then(async res => {
                if (res.status == 200) {
                    if (downlineLevel == 'first') {
                        refTree = res.data.downlines
                    } else if (downlineLevel == 'second') {
                        var index = refTree.findIndex(item => item.ownref == targetOwnref)
                        if (index != -1) {
                            refTree[index].children = res.data.downlines
                        }
                    } else if (downlineLevel == 'third') {
                        var index2 = refTree.findIndex(item => item.ownref == this.state.lvlOneOwnref)
                        if (index2 != -1) {

                            var index3 = refTree[index2].children.findIndex(item => item.ownref == targetOwnref)
                            if (index3 != -1) {
                                refTree[index2].children[index3].children = res.data.downlines
                            }
                        }
                    }
                    this.setState({ referenceTree: refTree, isOKtoshowRT: true }, () => {
                        spinner.style.display = "none";
                    })
                } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                    alert('Please Login Again...!')
                    // localStorage.removeItem('usertokenShivshahiCustomer')
                    localStorage.clear();
                    window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                } else {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                }
            })
            .catch((err) => {
                alert('Error Occured.. Please Try after some time')
                spinner.style.display = "none";
            })
    }

    checkFirstOrder = (e) => {
        e.preventDefault();

        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";

        const data = {
            userToken: localStorage.usertokenShivshahiCustomer,
            id: parseInt(this.state.cfoOrderNumber) - 9999,
            parent_user_id: this.state.parent.id,
        }

        userApi.verifyFirstOrder(data)
            .then(res => {
                if (res.status == 200) {
                    alert('Order Number Verified..!')
                    window.location.reload();
                } else if (res.status == 405 && res.error == 'Invalid First Order Id') {
                    alert('Your First Order Id is not Valid..!')
                    window.location.reload();
                } else {
                    alert('Error Occured.. Please Try after some time')
                    window.location.reload();
                }
            })
            .catch((err) => {
                alert('Error Occured.. Please Try after some time')
                window.location.reload();
            })
    }

    getDeleteStatus = () => {
        return new Promise(async (resolve, reject) => {
            const data = { userToken: localStorage.usertokenShivshahiCustomer }

            await userApi.getDeleteRequestStatus(data)
                .then(async res => {
                    if (res.status == 200) {
                        this.setState({
                            isDelete: res.data.isDelete,
                        }, () => {
                            resolve(true)
                        })
                    } else {
                        alert('Error Occured.. Please try after some time')
                        reject(false)
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please try after some time')
                    reject(false)
                })
        })
    }

    requestAccountDelete = () => {
        return new Promise(async (resolve, reject) => {
            const data = { userToken: localStorage.usertokenShivshahiCustomer }

            await userApi.requestDeleteUser(data)
                .then(async res => {
                    console.log(res);
                    if (res.status == 200) {
                        await this.getDeleteStatus()
                        resolve(true)
                    } else {
                        alert('Error Occured.. Please try after some time')
                        reject(false)
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please try after some time')
                    reject(false)
                })
        })
    }

    cancelAccountDeleteRequest = () => {
        return new Promise(async (resolve, reject) => {
            const data = { userToken: localStorage.usertokenShivshahiCustomer }

            await userApi.cancelDeleteRequest(data)
                .then(async res => {
                    console.log(res);
                    if (res.status == 200) {
                        await this.getDeleteStatus()
                        resolve(true)
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        reject(false)
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    reject(false)
                })
        })
    }

    render() {
        const { currency } = this.props;

        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

        return (
            <div className="main">

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="page-content mt-3">
                    <div className="dashboard">
                        <div className="container">

                            <MyAccountHeader />

                            <h3 className='text-center all-font mb-3'>Dashboard</h3>

                            <div className='ds-certificate' style={{ display: this.state.isFirstOrder ? 'none' : '' }}>
                                <div>
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/ds_certificate.jpg`} alt="" />
                                    <h5><span>Welcome</span> " {this.state.firstname.toUpperCase()} {this.state.lastname.toUpperCase()} "</h5>
                                    <h2>{this.state.ownref}</h2>
                                    <h6>{monthNames[new Date(this.state.joinDate).getMonth()] + ' ' + new Date(this.state.joinDate).getDate() + ', ' + new Date(this.state.joinDate).getFullYear()}</h6>
                                </div>
                            </div>

                            <p style={{ color: 'red', display: this.state.isFirstOrder ? '' : 'none' }}>*** This is Your First Order...! You won't get any Profit..! Your upline will get 10% Profit for this order.. If you have already made First order, then enter your first order number to verify it..!</p>
                            <p className='mt-2' style={{ color: 'red', display: this.state.isFirstOrder ? '' : 'none' }}>*** ही तुमची पहिली ऑर्डर आहे...! आपल्याला कोणताही नफा मिळणार नाही..! या ऑर्डरसाठी आपल्या अपलाईनला 10% नफा मिळेल.. जर आपण आधी फर्स्ट ऑर्डर केली असेल, तर तुमचा पहिला Order Number Verify करा ..!</p>

                            <div className="mt-3" style={{ display: this.state.isFirstOrder ? '' : 'none' }}>
                                <form onSubmit={this.checkFirstOrder}>
                                    <label htmlFor='cfoOrderNumber'>Enter the Order Number</label>
                                    <div className="input-group">
                                        <input type="number" className="form-control" id='cfoOrderNumber' value={this.state.cfoOrderNumber} aria-label="cfoOrderNumber" aria-describedby="cfoOrderNumber" onChange={e => this.setState({ cfoOrderNumber: e.target.value })} />
                                        <div className="input-group-append">
                                            <button className="btn btn-primary" type='submit'><span>Verify First Order</span></button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="shareboar mt-3" style={{ display: this.state.isFirstOrder ? 'none' : '' }}>
                                <div className="input-group">
                                    <input type="email" className="form-control" value={`https://shivshahinetwork.com/auth/login-register/${this.state.ownref}`} aria-label="Email Adress" aria-describedby="newsletter-btn" />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" onClick={this.share} id="newsletter-btn"><span>Share <Share2 /></span></button>
                                    </div>
                                </div>
                            </div>


                            {this.state.parent.length != 0 ?
                                <div>
                                    <h3 className="all-font pt-3 pb-1" style={{ textAlign: 'center' }}>Upline Structure</h3>
                                    <Accordion adClass="accordion-plus">
                                        <Card title={`${this.state.parent.firstname}&nbsp;${this.state.parent.lastname} - <strong>${this.state.parent.ownref}</strong> (1<sup>st</sup> Upline)`} adClass="card-box card-sm all-bg-light">
                                            <div>
                                                <h6 className="all-font">Phone: <strong>{this.state.parent.phone}</strong></h6>
                                                <h6 className="all-font">Email: <strong>{this.state.parent.email}</strong></h6>

                                                {/* {this.state.grandparent.length != 0 ?
                                                                            <Accordion adClass="accordion-plus">
                                                                                <Card title={`${this.state.grandparent.firstname}&nbsp;${this.state.grandparent.lastname} - <strong>${this.state.grandparent.ownref}</strong> (2<sup>nd</sup> Upline)`} adClass="card-box card-sm all-bg">
                                                                                    <div>
                                                                                        <h6 className="all-font">Phone: <strong>{this.state.grandparent.phone}</strong></h6>
                                                                                        <h6 className="all-font">Email: <strong>{this.state.grandparent.email}</strong></h6>

                                                                                        {this.state.grandparent_big.length != 0 ?
                                                                                            <Accordion adClass="accordion-plus">
                                                                                                <Card title={`${this.state.grandparent_big.firstname}&nbsp;${this.state.grandparent_big.lastname} - <strong>${this.state.grandparent_big.ownref}</strong> (3<sup>rd</sup> Upline)`} adClass="card-box card-sm all-bg-light">
                                                                                                    <div>
                                                                                                        <h6 className="all-font">Phone: <strong>{this.state.grandparent_big.phone}</strong></h6>
                                                                                                        <h6 className="all-font">Email: <strong>{this.state.grandparent_big.email}</strong></h6>
                                                                                                    </div>
                                                                                                </Card>
                                                                                            </Accordion>
                                                                                            :
                                                                                            <h6 className="font-primary pt-3 pb-3">There are no further referred by</h6>
                                                                                        }

                                                                                    </div>
                                                                                </Card>
                                                                            </Accordion>
                                                                            :
                                                                            <h6 className="font-primary pt-3 pb-3">There are no further referred by</h6>
                                                                        } */}
                                            </div>
                                        </Card>
                                    </Accordion>
                                </div>
                                :
                                <div>
                                    <h3 className="all-font pt-3 pb-1" style={{ textAlign: 'center' }}>Upline Structure</h3>
                                    <h6 className="all-font pt-1 pb-2" style={{ textAlign: 'center', color: '#c96' }}>You don't have any Upline</h6>
                                </div>
                            }

                            <h3 className="all-font pt-2 pb-1" style={{ textAlign: 'center' }}>Downline Structure</h3>

                            <div style={{ display: this.state.isOKtoshowRT ? '' : 'none' }}>

                                {this.state.referenceTree.length != 0 ?
                                    <div>
                                        <Accordion adClass="accordion-plus" type="first_downlines" onHandleClickFirstDownlines={this.onHandleClickFirstDownlines}>
                                            {this.state.referenceTree.map((child, index) =>
                                                <Card key={index} title={`${child.firstname}&nbsp;${child.lastname} - ${child.ownref}`} adClass="card-box card-sm all-bg-light accordians-cust">
                                                    <div style={{ display: "flex", justifyContent: 'start' }}>
                                                        <div style={{ width: '70%' }}>
                                                            <h6 className="all-font accordians-cust">Phone: <strong>{child.phone}</strong></h6>
                                                            <h6 className="all-font accordians-cust">Email: <strong>{child.email}</strong></h6>
                                                        </div>
                                                        <div style={{ width: '30%' }}>
                                                            <h4 className='accordians-cust' style={{ color: '#c96' }}>{currency.currencySymbol}&nbsp;{(child.parentprofit * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h4>
                                                        </div>
                                                    </div>
                                                    {child.children.length != 0 ?
                                                        <Accordion adClass="accordion-plus" type="second_downlines" onHandleClickSecondDownlines={this.onHandleClickSecondDownlines}>
                                                            {child.children.map((grandchild, index2) =>
                                                                <Card key={index2} title={`${grandchild.firstname}&nbsp;${grandchild.lastname} - ${grandchild.ownref}`} adClass="card-box card-sm all-bg accordians-cust">
                                                                    <div style={{ display: "flex", justifyContent: 'start' }}>
                                                                        <div style={{ width: '70%' }}>
                                                                            <h6 className="all-font accordians-cust">Phone: <strong>{grandchild.phone}</strong></h6>
                                                                            <h6 className="all-font accordians-cust">Email: <strong>{grandchild.email}</strong></h6>
                                                                        </div>
                                                                        <div style={{ width: '30%' }}>
                                                                            <h4 className='accordians-cust' style={{ color: '#c96' }}>{currency.currencySymbol}&nbsp;{(grandchild.parentprofit * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h4>
                                                                        </div>
                                                                    </div>
                                                                    {grandchild.children.length != 0 ?
                                                                        <Accordion adClass="accordion-plus" type="third_downlines" onHandleClickThirdDownlines={this.onHandleClickThirdDownlines}>
                                                                            {grandchild.children.map((grand_grandchild, index3) =>
                                                                                <Card key={index3} title={`${grand_grandchild.firstname}&nbsp;${grand_grandchild.lastname} - ${grand_grandchild.ownref}`} adClass="card-box card-sm all-bg-light accordians-cust">
                                                                                    <div style={{ display: "flex", justifyContent: 'start' }}>
                                                                                        <div style={{ width: '70%' }}>
                                                                                            <h6 className="all-font accordians-cust">Phone: <strong>{grand_grandchild.phone}</strong></h6>
                                                                                            <h6 className="all-font accordians-cust">Email: <strong>{grand_grandchild.email}</strong></h6>
                                                                                        </div>
                                                                                        <div style={{ width: '30%' }}>
                                                                                            <h4 className='accordians-cust' style={{ color: '#c96' }}>{currency.currencySymbol}&nbsp;{(grand_grandchild.parentprofit * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h4>
                                                                                        </div>
                                                                                    </div>
                                                                                </Card>
                                                                            )}
                                                                        </Accordion>
                                                                        :
                                                                        <h6 className="font-primary pt-3 pb-3 accordians-cust">There are no further references</h6>
                                                                    }
                                                                </Card>
                                                            )}
                                                        </Accordion>
                                                        :
                                                        <h6 className="font-primary pt-3 pb-3 accordians-cust">There are no further references</h6>
                                                    }
                                                </Card>
                                            )}
                                        </Accordion>
                                    </div>
                                    :
                                    <div>
                                        <h3 className="all-font pt-3 pb-1" style={{ textAlign: 'center' }}>Downline Structure</h3>
                                        <h6 className="all-font pt-1 pb-2" style={{ textAlign: 'center', color: '#c96' }}>You don't have any Downline</h6>
                                    </div>
                                }

                            </div>

                            <div style={{ display: this.state.isOKtoshowRT ? 'none' : 'flex', justifyContent: 'center' }}>
                                <button type="btn" className="btn btn-outline-primary-2 mt-1 mb-1" onClick={(e) => this.fetchDownlines('first', '')}>
                                    <span>Show Downlines</span>
                                    <i className="icon-long-arrow-down"></i>
                                </button>
                            </div>
                            <hr />
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                                    <button type="btn" className="btn btn-outline-primary-2 mt-1 mb-1" onClick={(e) => this.onDeleteRequestClick(e, this.state.isDelete)}>
                                        {this.state.isDelete ? <span>Cancel Account Delete Request</span> : <span>Request Account Delete</span>}
                                        < i class="icon-long-arrow-right"></i>
                                    </button>
                                </div>
                                <h6 style={{ marginTop: '10px', display: this.state.isDelete ? "block" : 'none' }} className="all-font">Status: <strong>Account deletion is in progress, it will take 7-10 days to complete.</strong></h6>
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        )
    }
}

export const mapStateToProps = (state) => ({
    currency: state.currencyData,
})

export default connect(mapStateToProps)(Dashboard);