import React, { Component } from 'react';

class PageHeader extends Component {
    render() {
        const { title, subTitle, name } = this.props;
        return (
            <div className="page-header text-center">
                <div className="container">
                    <h1 className="page-title" style={{ color: '#ebebeb' }}>{title}<span>{name ? name : subTitle}</span></h1>
                </div>
            </div>
        );
    }
}

export default PageHeader;