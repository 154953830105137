import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { multilanguage } from "redux-multilanguage";
import jwt_decode from 'jwt-decode'

import Logo from './sub-components/logo';
import MainMenu from './sub-components/mainManu';
import CartMenu from './sub-components/cartMenu';
// import SearchToggle from './sub-components/search-toggle';
import TopHeader from './sub-components/topHeader'
// import ReturnExchangeModal from './sub-components/return-exchange-modal';

// import { showModal } from './../../actions';
import { setCurrency } from './../../actions/currencyAction';
import { stickyHeaderHandler } from './../../utils/utils';

const Header = ({
    currency,
    setCurrency,
    currentLanguageCode,
    dispatch,
    wishlist,
    showModal,
    products
} = this.props) => {

    const [image, setimage] = useState(null)

    useEffect(() => {
        window.addEventListener('scroll', stickyHeaderHandler);

        var checkusertoken = localStorage.getItem('usertokenShivshahiCustomer');
        if (checkusertoken == null) {
            var dp = `${process.env.PUBLIC_URL}/assets/images/profile_pictures/dummy.jpg`
            setimage(dp)
        } else {
            const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer
            const decoded = jwt_decode(usertokenShivshahiCustomer).data
            var dp = decoded.profile_pic != null ? `${process.env.PUBLIC_URL}/assets/images/profile_pictures/${decoded.profile_pic}` : `${process.env.PUBLIC_URL}/assets/images/profile_pictures/dummy.jpg`
            setimage(dp)
        }

        return () => {
            window.removeEventListener('scroll', stickyHeaderHandler);
        };
    }, []);

    // const openLoginModal = e => {
    //     showModal('returnExchange');
    //     e.preventDefault();
    // }
    return (
        <div>

            <div className="header-custom-mobile">
                <div className='left'>
                    <Logo logo="logo.png" />
                </div>
                <div className='right'>
                    <button style={{ display: localStorage.getItem('usertokenShivshahiCustomer') == null ? '' : 'none', fontSize: '2.8rem' }} onClick={() => window.location = process.env.PUBLIC_URL + '/my-account/dashboard'}>
                        <i className="icon-user" style={{ color: '#fff' }} />
                    </button>
                    <button className="acc-cust mr-2" onClick={() => window.location = process.env.PUBLIC_URL + '/my-account/dashboard'} style={{ display: localStorage.getItem('usertokenShivshahiCustomer') == null ? 'none' : '' }}>
                        <div style={{ width: '40px', height: '40px', overflow: 'hidden', borderRadius: '100%', boxShadow: '0 5px 5px rgba(0, 0, 0, 0.4)' }}>
                            <img src={image} alt="dp" />
                        </div>
                    </button>
                    <button className="mobile-menu-toggler">
                        <span className="sr-only">Toggle mobile menu</span>
                        <i className="icon-bars" style={{ color: '#fff' }} />
                    </button>
                </div>
            </div>

            <header className="header header-2 header-intro-clearance all-bg mobile-none">

                <TopHeader
                    currency={currency}
                    setCurrency={setCurrency}
                    currentLanguageCode={currentLanguageCode}
                    dispatch={dispatch}
                    products={products}
                />

                <div className="header-middle sticky-header" style={{ backgroundColor: '#333333' }}>
                    <div className="container">
                        <div className="header-left">

                            <Logo logo="logo.png" />
                            <MainMenu />

                        </div>

                        <div className="header-right">
                            {/* <SearchToggle /> */}
                            {/* <Link to="#signin-modal" data-toggle="modal" onClick={openLoginModal}>Sign in / Sign up</Link> */}
                            <div style={{ cursor: 'pointer', width: '50px', height: '50px', overflow: 'hidden', borderRadius: '100%', boxShadow: '0 5px 5px rgba(0, 0, 0, 0.4)', display: localStorage.getItem('usertokenShivshahiCustomer') == null ? 'none' : '' }} onClick={() => window.location = process.env.PUBLIC_URL + '/my-account/dashboard'}>
                                <img src={image} alt="dp" />
                            </div>
                            <div className="account" style={{ display: localStorage.getItem('usertokenShivshahiCustomer') == null ? '' : 'none' }}>
                                <Link to={`${process.env.PUBLIC_URL}/my-account/dashboard`} className="icon-custom-hover" title="My account">
                                    <div className="icon">
                                        <i className="icon-user all-font all-font-hover"></i>
                                    </div>
                                    <p>Sign In</p>
                                </Link>
                            </div>
                            <div className="wishlist">
                                <Link to='#' onClick={e => { window.location = `${process.env.PUBLIC_URL}/wishlist` }} className="icon-custom-hover" title="Wishlist">
                                    <div className="icon">
                                        <i className="icon-heart-o all-font-hover"></i>
                                        <span className="wishlist-count badge" style={{ backgroundColor: '#c96' }}>{wishlist.length}</span>
                                    </div>
                                    <p>Wishlist</p>
                                </Link>
                            </div>
                            <CartMenu />

                        </div>
                        <button className="mobile-menu-toggler">
                            <span className="sr-only">Toggle mobile menu</span>
                            <i className="icon-bars" style={{ color: '#fff' }} />
                        </button>
                    </div>
                </div>
                {/* <ReturnExchangeModal /> */}
            </header>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        wishlist: state.wishlist.list,
        currency: state.currencyData,
        products: state.data.products ? state.data.products : []
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrency: currencyName => {
            dispatch(setCurrency(currencyName));
        },
        // showModal: (modal) => dispatch(showModal(modal))

    };
};

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps,
//     { showModal }
// )(multilanguage(Header));

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Header);