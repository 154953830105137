import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import BaseMenu from './baseMenu';

import { toggleCategoryFilter } from './../../../actions';

class MainMenu extends BaseMenu {

    render() {
        // const { demo } = this.props;
        return (
            <nav className="main-nav" onClick={this.activeNav}>
                <ul className="menu sf-arrows">

                    {/* <li style={{ width: '85px' }}>
                        <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                    </li> */}

                    <li>
                        <Link to={`${process.env.PUBLIC_URL}/shop`} className="sf-with-ul">Shop</Link>

                        <div className="megamenu megamenu-sm">
                            <div className="row no-gutters all-bg">
                                <div className="col-md-7">
                                    <div className="menu-col">
                                        <ul>
                                            {this.props.cats.map((item, index) =>
                                                <li key={index} className="all-bg"><Link to={`${process.env.PUBLIC_URL}/shop`} onClick={(e) => this.props.toggleCategoryFilter(item.category)}>{item.category}</Link></li>
                                            )}
                                            {/* <li className="all-bg"><Link to={`${process.env.PUBLIC_URL}/shop`} onClick={(e) => this.props.toggleCategoryFilter('Khan Saree')}>Khan Sarees</Link></li>
                                            <li className="all-bg"><Link to={`${process.env.PUBLIC_URL}/shop`} onClick={(e) => this.props.toggleCategoryFilter('Sico Paithani Border Saree')}>Semi Paithani</Link></li>
                                            <li className="all-bg"><Link to={`${process.env.PUBLIC_URL}/shop`} onClick={(e) => this.props.toggleCategoryFilter('Semi Brocket Border Saree')}>Semi All Over</Link></li>
                                            <li className="all-bg"><Link to={`${process.env.PUBLIC_URL}/shop`} onClick={(e) => this.props.toggleCategoryFilter('Sico Irkal Border Saree')}>Sico Irkal Border Sarees</Link></li>
                                            <li className="all-bg"><Link to={`${process.env.PUBLIC_URL}/shop`} onClick={(e) => this.props.toggleCategoryFilter('Semi Brocket Border Saree')}>Semi Brocket Sarees</Link></li>
                                            <li className="all-bg"><Link to={`${process.env.PUBLIC_URL}/shop`} onClick={(e) => this.props.toggleCategoryFilter('Sico Munia Border Saree')}>Sico Munia Border Sarees</Link></li>
                                            <li className="all-bg"><Link to={`${process.env.PUBLIC_URL}/shop`} onClick={(e) => this.props.toggleCategoryFilter('Sico Paithani Border Saree')}>Sico Paithani Border Sarees</Link></li>
                                            <li className="all-bg"><Link to={`${process.env.PUBLIC_URL}/shop`} onClick={(e) => this.props.toggleCategoryFilter('Temple Border Saree')}>Sico Temple Border Sarees</Link></li> */}
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-md-5">
                                    <div className="banner banner-overlay">
                                        <Link to={`${process.env.PUBLIC_URL}/shop`}>
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/menu-1.jpg`} alt="Banner" style={{ height: '100%' }} />

                                            <div className="banner-content banner-content-bottom">
                                                <div className="banner-title text-white">New Trends<br /><span><strong>Season {(new Date()).getFullYear()}</strong></span></div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li style={{ width: '215px' }}>
                        <Link to={`${process.env.PUBLIC_URL}/business-opportunity`} className="sf-with-ul">Business&nbsp;&nbsp;Opportunity&nbsp;</Link>
                        <ul style={{ backgroundColor: '#212121' }}>
                            <li className="all-bg"><Link to={`${process.env.PUBLIC_URL}/business-opportunity`}>Business Opportunity</Link></li>
                            <li className="all-bg"><Link to={`${process.env.PUBLIC_URL}/hirkani-plan`}>Hirakani Plan</Link></li>
                            <li className="all-bg"><Link to={`${process.env.PUBLIC_URL}/recharge-plan`}>Rechange Plan</Link></li>
                        </ul>
                    </li>

                    {/* <li style={{ width: '80px' }}>
                        <Link to={`${process.env.PUBLIC_URL}/about`}>About</Link>
                    </li> */}

                    <li>
                        <Link to={`${process.env.PUBLIC_URL}/join-us/noplan`}>Recharge</Link>
                    </li>

                    <li>
                        <Link to={`${process.env.PUBLIC_URL}/gallery`}>Gallery</Link>
                    </li>

                </ul>
            </nav>
        );
    }
}

export const mapStateToProps = (state) => ({
    demo: state.demo.current,
    filters: state.filters,
    products: state.data.products ? state.data.products : [],
    currency: state.currencyData,
    cats: state.data.cats,
})

export default connect(mapStateToProps, { toggleCategoryFilter })(MainMenu);