import React, { Component } from 'react';
import { connect } from 'react-redux';

import MobileMainNav from './sub-components/mobileNavMenu';

class MobileMenu extends Component {

    constructor(props) {
        super(props)

        this.state = {
            sku: ''
        }
    }

    onSubmitSearch = (e) => {
        e.preventDefault();

        let productFound = this.props.products.find(item => item.sku.toLowerCase() == this.state.sku.toLowerCase())
        if (productFound != undefined) {
            window.location = process.env.PUBLIC_URL + '/shop/detail/' + productFound.id
        } else {
            alert('There is no such product of given sku..!')
        }

    }

    render() {
        const { adClass } = this.props;

        return (

            <div className={`mobile-menu-container ${adClass}`}>
                <div className="mobile-menu-wrapper">
                    <span className="mobile-menu-close"><i className="icon-close"></i></span>

                    <form onSubmit={this.onSubmitSearch} className="mobile-search">
                        <label htmlFor="mobile-search" className="sr-only">Search</label>
                        <input type="search" className="form-control" value={this.state.sku} name="mobile-search" id="mobile-search" placeholder="Search by sku..." required pattern=".*\S+.*" onChange={e => this.setState({ sku: e.target.value.replace(new RegExp("\\s*", "gm"), '') })} />
                        <button className="btn btn-primary" type="submit"><i className="icon-search"></i></button>
                    </form>

                    <nav className="mobile-nav">
                        <MobileMainNav />
                    </nav>
                   
                </div>
            </div>
        )
    }
}

export const mapStateToProps = (state) => {
    return {
        products: state.data.products ? state.data.products : []
    };
}
export default connect(mapStateToProps, {})(MobileMenu);