import axios from 'axios';

export const fetchOrders = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/fetchorders`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchReturnExchangeRequests = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/fetchreturnexchangerequests`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchTransactions = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/fetchtransactions`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const createOrder = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/createorder`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const razorpayPayment = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/razorpaypayment`, data)
        .then(res => { return res; })
        .catch(err => { return err; })
};

export const updateOrderOnSuccess = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/updateorderonsuccess`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchSingleOrder = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/fetchsingleorder`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const updateOrderAddress = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/updateorderaddress`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const uploadPaymentReceipt = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/uploadpaymentreceipt`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const cancelOrder = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/cancelorder`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const makeReturnExchangeRequest = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/makereturnexchangerequest`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchSingleReturnExchangeRequest = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/fetchsinglereturnexchangerequest`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const cancelReturnExchangeRequest = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/cancelreturnexchangerequest`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};
