import React, { Component } from 'react';
import { connect } from 'react-redux';

import ProductListGrid from './../sub-components/product-list-grid';

import { innerLoading, addToCart, toggleWishlist, addToCompare, showQuickViewModal, getDetailedProduct, filterSort } from '../../../actions';
import { getVisibleProducts } from './../../../services';

class GalleryProductSuggestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start: 0,
            cols: this.props.column
        }
        this.changePos = this.changePos.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (this.props.column !== nextProps.column) {
            this.setState({ cols: nextProps.column });
        }
    }

    changePos(pos) {
        this.props.innerLoading();
        this.setState({ start: pos });
        window.scrollTo({
            top: 0
        });
    }

    changeGrid(grid) {
        this.props.innerLoading();
        this.setState({ cols: grid });
    }

    changeFilter(e) {
        this.props.filterSort(e.target.value);
        this.setState({ start: 0 });
    }

    getSuggestedProducts = (filteredProducts) => {
        if (this.props.galleryProductSuggestions.length) {
            let xArr = [];
            for (var i = 0; i < this.props.galleryProductSuggestions.length; i++) {
                var xIndex = filteredProducts.findIndex(xProd => xProd.id == this.props.galleryProductSuggestions[i].productId);
                if (xIndex !== -1) {
                    xArr.push(filteredProducts[xIndex])
                }
            }
            return xArr;
        } else {
            return filteredProducts;
        }
    }

    render() {
        let { products, filters, currency, addToCart, toggleWishlist, addToCompare, showQuickViewModal, getDetailedProduct, startNumber, endNumber } = this.props;
        const grid = { "2cols": "col-6", "3cols": "col-6 col-md-4 col-lg-4", "4cols": "col-6 col-md-4 col-lg-4 col-xl-3" };

        products = getVisibleProducts(products, filters);
        var newProducts = this.getSuggestedProducts(products);
        return (
            <React.Fragment>
                <div className="products mb-3" >
                    <div className="row">
                        {newProducts.slice(startNumber, endNumber).map((item, index) =>
                            <div className={grid[this.state.cols]} key={item.id}>
                                <ProductListGrid adClass="text-center"
                                    product={item}
                                    currency={currency}
                                    onAddToCartClick={() => addToCart(item, 1, JSON.parse(item.variants)[JSON.parse(item.variants).findIndex(stockItem => stockItem.stock != 0)].size)}
                                    onToggleWishlistClick={() => toggleWishlist(item)}
                                    onAddToCompareClick={() => addToCompare(item)}
                                    showQuickViewModal={() => showQuickViewModal(item.id)}
                                    getDetailedProduct={() => getDetailedProduct(item.id)} />
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export const mapStateToProps = (state) => {
    return {
        filters: state.filters,
        currency: state.currencyData,
        products: state.data.products ? state.data.products : []
    };
}
export default connect(mapStateToProps, { innerLoading, addToCart, toggleWishlist, addToCompare, showQuickViewModal, getDetailedProduct, filterSort })(GalleryProductSuggestions);