import React, { Component } from 'react';

import { safeContent } from './../../../utils/utils';

class TestimonialSingle extends Component {
    render() {
        const { image, content, name, job, color, lead } = this.props;

        return (
            <blockquote className={`all-bg-light testimonial ${image ? '' : 'testimonial-icon'} text-center ${'white' === color ? 'text-white' : ''}`}>
                {image ? <img src={process.env.PUBLIC_URL + '/assets/images/testimonials/' + image} alt="user" /> : ''}
                {lead ? <p className="lead">{lead}</p> : ''}
                <p dangerouslySetInnerHTML={safeContent(content)} className="all-font pb-2"></p>
                <cite>
                    <span className='all-font-light pb-1' style={{ fontSize: 'large' }}>{name}</span>
                    <span><p>{job}</p></span>
                </cite>
            </blockquote>
        );
    }
}

export default TestimonialSingle;