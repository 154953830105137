import React from 'react';
import { connect } from 'react-redux';

import BaseProduct from './base-product';

import { findIndex, isIEBrowser } from './../../../utils/utils';

class ProductListList extends BaseProduct {

    render() {
        const { product, currency, adClass, toggleWishlist, productDetail } = this.props;
        let btnClass = product.stock == 0 ? 'disabled' : '';
        btnClass = btnClass + ' btn-product btn-cart';

        var totalStock = 0;

        {
            JSON.parse(product.variants).map((item, index) => (
                totalStock = totalStock + parseInt(item.stock)
            ))
        }

        return (
            product ?
                <div className={`product product-list ${adClass} all-bg`}>
                    <div className="row">
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3">
                            <figure className="product-media">

                                {(0 === totalStock) ? <span className="product-label label-out">Out of Stock</span> :
                                    product.new ? <span className="product-label label-new">New</span> : ''}

                                {(0 === totalStock) ? '' :
                                    product.off != 0 ? <span className="product-label label-sale">{product.off}% off</span> :
                                        ''}

                                {this.showProductImgSection()}
                            </figure>
                        </div>

                        <div className="col-6 col-sm-4 col-lg-3 order-lg-last">
                            <div className="product-list-action">
                                {this.showProductPrice('', '', currency)}
                                {this.showProductRating()}

                                <div className="product-action all-bg" style={{ justifyContent: 'center' }}>
                                    {this.showQuickViewBtnWithIcon()}
                                </div>

                                <a href="#cart" className={btnClass} onClick={this.addToCart} style={{display : `${totalStock ? '' : 'none'}`}}>
                                    <span>add to cart</span>
                                </a>
                            </div>
                        </div>

                        <div className="col-sm-5 col-md-4 col-lg-6">
                            <div className="product-body product-action-inner all-bg pb-2">
                                {this.showToggleWishlistBtn('btn-product btn-wishlist')}
                            </div>

                            {this.showProductName()}

                            <div className="product-content">
                                <p>{product.shortDesc}</p>
                            </div>

                            {this.showProductVariants('model')}
                        </div>
                    </div>
                </div> : ''
        )
    }
}

export const mapStateToProps = (state, ownprops) => {
    let wishlist = false;
    if (findIndex(state.wishlist.list, item => item.id == ownprops.product.id) !== -1)
        wishlist = true;
    return {
        wishlist: wishlist
    };
}

export default connect(mapStateToProps)(ProductListList);