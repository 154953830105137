import React, { Component } from 'react';
import { connect } from 'react-redux';

import MyAccountHeader from './../../components/my-account-header'
import GalleryPostSingle from '../../components/sub-components/single-gallery-post';
import PostCreateModal from '../../components/sub-components/post-create-modal';

import { showModal } from '../../../actions';

class MyPosts extends Component {

    constructor(props) {
        super(props)

        this.state = {
            posts: [],
        }
    }

    componentDidMount = async () => {
        document.body.classList.add('all-bg');

        var checkusertoken = localStorage.getItem('usertokenShivshahiCustomer');
        // console.log(checkusertoken)
        if (checkusertoken == null) {
            this.props.history.push(`${process.env.PUBLIC_URL}/auth/login-register/:data`)
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('all-bg');
    }

    createPost = (e) => {
        e.preventDefault();
        this.props.showModal('postCreate');
        // window.location.reload();
    }

    render() {
        return (
            <div className="main">

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="page-content mt-3">
                    <div className="dashboard">
                        <div className="container">

                            <MyAccountHeader />

                            {/* <h3 className='text-center all-font mb-3'>Create a Post</h3> */}
                            <div className='container mt-2' style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className='col-xl-6 col-lg-6 col-md-8 col-sm-9 col-12 gallery-card-container'>
                                    <button type="btn" className="btn btn-primary" onClick={this.createPost}>
                                        <span>Create new Post</span>
                                        <i className="icon-instagram" />
                                    </button>
                                    <hr />
                                </div>
                            </div>

                            {/* <h3 className='text-center all-font mb-3'>My Posts</h3> */}

                            <GalleryPostSingle data={this.state.posts} calledFor="myAccount" galleryProductSuggestions={[]} />

                        </div>
                    </div >
                </div >
                <PostCreateModal />
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        currency: state.currencyData,
        trackings: state.data.trackings,
    };
};

export default connect(mapStateToProps, { showModal })(MyPosts);