import React, { Component } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import jwt_decode from 'jwt-decode';

import { closeModal } from './../../../actions';
import { quantityInputs } from './../../../utils/utils';

import { makeReturnExchangeRequest } from './../../../api/order'

const customStyles = {
    content: {
        top: '50%',
        transform: 'translateY(-50%)'
    },
    overlay: {
        backgroundColor: 'rgba(77,77,77,0.6)',
        zIndex: '10000'
    }
};

// Modal.setAppElement('#root');
var tryme = 1;
export class ReturnExchangeModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            request: 'Exchange',
            totalItems: [],
            images: '',
            fileArray: [],
        }
    }

    componentDidUpdate() {
        quantityInputs();

        if (tryme == 1) {
            var totalItemsArray = this.props.items
            for (var j = 0; j < totalItemsArray.length; j++) {
                totalItemsArray[j].reQty = totalItemsArray[j].qty
                totalItemsArray[j].isReq = false
            }
            this.setState({ totalItems: totalItemsArray }, () => tryme = tryme + 1)
        }
    }

    closeModal = () => {
        document.getElementById("returnExchange-modal").classList.remove("ReactModal__Content--after-open");

        setTimeout(() => {
            this.props.closeModal('returnExchange');
        }, 200);
    }

    uploadImages = (e) => {
        var loader = document.getElementById("fileLoader")
        var fileArray = [];

        loader.click();
        loader.onchange = () => {

            for (var i = 0; i < loader.files.length; i++) {
                fileArray.push(loader.files[i])
                if (
                    loader.files[i].type.toLowerCase() != 'image/jpg' &&
                    loader.files[i].type.toLowerCase() != 'image/jpeg' &&
                    loader.files[i].type.toLowerCase() != 'image/png'
                ) {
                    this.setState({ images: '' }, () => {
                        alert('Please upload a valid file..!')
                        fileArray = [];
                        i = loader.files.length
                    })
                } else {
                    this.setState({ images: `${loader.files.length}  images(s)` })
                }
            }
            this.setState({ fileArray: fileArray, images: loader.files.length == 0 ? '' : this.state.images })

        };
    }

    onChangeQtyOfRequest = (e, index) => {
        e.preventDefault();

        var totalItemsArray1 = this.state.totalItems
        totalItemsArray1[index].reQty = e.currentTarget.querySelector('input[type="number"]').value
        this.setState({ totalItems: totalItemsArray1 })
    }

    // onTickItem = (e, index) => {
    //     // e.preventDefault();
    //     var totalItemsArray2 = this.state.totalItems
    //     totalItemsArray2[index].isReq = e.currentTarget.checked
    //     this.setState({ totalItems: totalItemsArray2 })
    // }

    onRequestSubmit = (e) => {
        e.preventDefault();

        var requestItems = this.state.totalItems.filter(item => item.reQty > 0)
        if (requestItems.length == 0) {
            alert('Please select items for request')
        } else if (this.state.fileArray.length == 0) {
            alert('Please upload the Image file..')
        } else {
            let spinner = document.getElementById("spinneranimid2");
            spinner.style.display = "block";

            const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer
            const decoded = jwt_decode(usertokenShivshahiCustomer)

            let dataToUpload = {
                user_id: decoded.data.id,
                user: {
                    id: decoded.data.id,
                    firstname: decoded.data.firstname,
                    lastname: decoded.data.lastname,
                    email: decoded.data.email,
                    phone: decoded.data.phone,
                    city: decoded.data.city,
                },
                date: new Date(),
                order_id: parseInt(this.props.order.id) + 9999,
                // order_details: {
                //     id: this.props.order.id,
                //     order_date: this.props.order.order_date,
                //     firstname: this.props.order.firstname,
                //     lastname: this.props.order.lastname,
                //     address: this.props.order.address,
                //     city: this.props.order.city,
                //     district: this.props.order.district,
                //     state: this.props.order.state,
                //     pincode: this.props.order.pincode,
                //     phone: this.props.order.phone,
                //     email: this.props.order.email,
                //     items: this.props.order.items,
                //     subtotal: this.props.order.subtotal,
                //     shipping: this.props.order.shipping,
                //     total: this.props.order.total,
                //     wallet: this.props.order.wallet,
                //     hirkani: this.props.order.hirkani,
                //     grandtotal: this.props.order.grandtotal,
                // },
                order_details: this.props.order,
                request: this.state.request,
                items: requestItems,
                status: 'Requested'
            }

            const data = new FormData()
            for (let r = 0; r < this.state.fileArray.length; r++) {
                data.append('files', this.state.fileArray[r])
            }
            data.append('dataToUpload', JSON.stringify(dataToUpload))

            makeReturnExchangeRequest(data)
                .then(res => {
                    if (res.status == 200) {
                        alert('Request Successful')
                        spinner.style.display = "none";
                        document.getElementById("returnExchange-modal").classList.remove("ReactModal__Content--after-open");

                        // setTimeout(() => {
                        this.props.closeModal('returnExchange');
                        // }, 10);
                        window.location.reload();
                    } else if (res.status == 405 && res.error == 'Request Already Made') {
                        alert('Request Already made..!')
                        spinner.style.display = "none";
                        document.getElementById("returnExchange-modal").classList.remove("ReactModal__Content--after-open");
                        // setTimeout(() => {
                        this.props.closeModal('returnExchange');
                        // }, 10);
                        window.location.reload();
                    } else {
                        alert('Something went wrong')
                        spinner.style.display = "none";
                        document.getElementById("returnExchange-modal").classList.remove("ReactModal__Content--after-open");

                        // setTimeout(() => {
                        this.props.closeModal('returnExchange');
                        // }, 10);
                        window.location.reload();
                    }
                })
                .catch((err) => {
                    alert('Something went wrong')
                    spinner.style.display = "none";
                    document.getElementById("returnExchange-modal").classList.remove("ReactModal__Content--after-open");

                    // setTimeout(() => {
                    this.props.closeModal('returnExchange');
                    // }, 50);
                    // window.location.reload();
                })
        }

    }

    render() {
        const { showModal, modal, items, currency } = this.props;

        return (
            <Modal
                isOpen={showModal && 'returnExchange' == modal}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="returnExchange Modal"
                className="modal-dialog modal-dialog-centered"
                id="returnExchange-modal"
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEsc={false}
            >
                <div className="modal-content all-bg">
                    <div id="spinneranimid2" className="overlay-spinner" style={{ zIndex: 9999999999999999999999 }}>
                        <div className="cssload-whirlpool" />
                    </div>
                    <div className="modal-body" style={{ overflowY: 'auto', height: '80vh' }}>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                            <span aria-hidden="true"><i className="icon-close"></i></span>
                        </button>
                        <div className="form-box all-bg">
                            <div className="form-tab">
                                <Tabs selectedTabClassName="show" defaultIndex={0}>
                                    <TabList className="nav nav-pills nav-fill">
                                        <Tab className="nav-item all-font">
                                            <span className="nav-link">Exchange Products</span>
                                        </Tab>
                                    </TabList>

                                    <div className="tab-content">
                                        <TabPanel style={{ paddingTop: "2rem" }}>
                                            <div>
                                                <form onSubmit={this.onRequestSubmit}>

                                                    {/* <div className="form-group">
                                                        <label htmlFor="request">Select the Request *</label>
                                                        <select name="request" id="request" className="form-control all-font" style={{ width: 'auto', backgroundColor: 'transparent' }} onChange={(e) => this.setState({ request: e.target.value })} required>
                                                            <option className="all-font all-bg" value=''>--Select the Request--</option>
                                                            <option className="all-font all-bg" value='Return'>Return Request</option>
                                                            <option className="all-font all-bg" value='Exchange'>Exchange Request</option>
                                                        </select>
                                                    </div>

                                                    <hr style={{ margin: '1.5rem 0rem 1.5rem 0rem' }} /> */}

                                                    <div className="form-group" style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <table className='return-exchange-table'>
                                                            <thead>
                                                                <tr>
                                                                    <th>Product</th>
                                                                    <th>Details</th>
                                                                    <th>Quantity</th>
                                                                    {/* <th>Selection</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {items.map((item, index) =>
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                <img src={process.env.PUBLIC_URL + '/' + item.pictures[0]} alt="Product" style={{ width: '80px' }} />
                                                                            </div>
                                                                        </td>

                                                                        <td className='pl-5 pr-5'>
                                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                <ul>
                                                                                    <li>Name:&nbsp;<span>{item.name}</span></li>
                                                                                    <li>Size:&nbsp;<span>{item.size}&nbsp;Yards</span></li>
                                                                                    <li>Price:&nbsp;<span>{currency.currencySymbol}&nbsp;{(item.price * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></li>
                                                                                    <li>Quantity:&nbsp;<span>{item.qty}</span></li>
                                                                                    <li>Total:&nbsp;<span>{currency.currencySymbol}&nbsp;{(item.sum * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></li>
                                                                                </ul>
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <div className="cart-product-quantity" onClick={(e) => this.onChangeQtyOfRequest(e, index)}>
                                                                                <input type="number"
                                                                                    className="form-control"
                                                                                    defaultValue={item.qty}
                                                                                    min="0"
                                                                                    max={item.qty}
                                                                                    step="1"
                                                                                    data-decimals="0"
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </td>

                                                                        {/* <td>
                                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                <input type="checkbox" style={{ width: '25px', height: '25px', cursor: 'pointer' }} onChange={(e) => this.onTickItem(e, index)} />
                                                                            </div>
                                                                        </td> */}

                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="all-font-dark" htmlFor="images">Upload the Images *</label>
                                                        <div className="input-group">
                                                            <input type="text" value={this.state.images} className="form-control all-font" id="images" name="images" style={{ backgroundColor: '#212121' }} readOnly />
                                                            <div className="input-group-append" title='click to upload' onClick={(e) => this.uploadImages(e)}>
                                                                <span className="input-group-text"><i className="icon-creative-commons-share" /></span>
                                                            </div>
                                                        </div>
                                                        <input type="file" multiple="multiple" accept="image/*" id="fileLoader" name="files" title="Load File" style={{ display: 'none' }} />
                                                    </div>

                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <button type="submit" className="btn btn-outline-primary-2">
                                                            <span>Request</span>
                                                            <i className="icon-long-arrow-up"></i>
                                                        </button>
                                                    </div>

                                                </form>

                                            </div>
                                        </TabPanel>

                                    </div>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )

    }

}

const mapStateToProps = state => {
    return {
        showModal: state.demo.showModal,
        modal: state.demo.modal,
        currency: state.currencyData,
    }
}

export default connect(mapStateToProps, { closeModal })(ReturnExchangeModal);

