import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { LogOut } from 'react-feather'
import jwt_decode from 'jwt-decode'

class MyAccountHeader extends Component {

    constructor(props) {
        super(props)

        this.state = {
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            address: "",
            city: "",
            district: "",
            pincode: "",
            state: "",
            profile_pic_img: null,
        }
    }

    componentDidMount = () => {
        var checkusertoken = localStorage.getItem('usertokenShivshahiCustomer');
        if (checkusertoken != null) {
            const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer
            const decoded = jwt_decode(usertokenShivshahiCustomer).data

            this.setState({
                firstname: decoded.firstname,
                lastname: decoded.lastname,
                email: decoded.email,
                phone: decoded.phone,
                address: decoded.address,
                city: decoded.city,
                district: decoded.district,
                pincode: decoded.pincode,
                state: decoded.state,
                profile_pic_img: decoded.profile_pic != null ? `${process.env.PUBLIC_URL}/assets/images/profile_pictures/${decoded.profile_pic}` : `${process.env.PUBLIC_URL}/assets/images/profile_pictures/dummy.jpg`,
            })
        }
    }

    logout = () => {
        localStorage.removeItem('usertokenShivshahiCustomer')
        window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
    }

    render() {

        return (
            <div>

                <div className="row">
                    <div className="col-md-4 col-lg-3 col-sm-4 col-12">
                        <div className='profile-pic'>
                            <div className='cust-pro'>
                                <img src={this.state.profile_pic_img} alt="profile_pic" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-8 col-lg-9 col-sm-8 col-12">
                        <div className='mt-3'>
                            <h6 style={{ color: '#757575' }}>Name : <span className='all-font'>{`${this.state.firstname} ${this.state.lastname}`}</span></h6>
                            <h6 style={{ color: '#757575' }}>Email ID : <span className='all-font'>{`${this.state.email}`}</span></h6>
                            <h6 style={{ color: '#757575' }}>Phone No. : <span className='all-font'>{`${this.state.phone}`}</span></h6>
                            <h6 style={{ color: '#757575' }}>Address :
                                <span className='all-font pl-2'>
                                    {this.state.address},<br />
                                    {this.state.city}, {this.state.district},<br />
                                    {this.state.state},{this.state.pincode}.
                                </span>
                            </h6>
                        </div>

                        <div className='cust-links mt-3'>
                            <div className='left'>
                                <Link to={`${process.env.PUBLIC_URL}/my-account/dashboard`}><i className="las la-chalkboard" />&nbsp;<span>Dashboard</span></Link>&nbsp;&nbsp;&bull;&nbsp;&nbsp;
                                <Link to={`${process.env.PUBLIC_URL}/my-account/wallet`}><i className="las la-wallet" />&nbsp;<span>Wallet</span></Link>&nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;
                                <Link to={`${process.env.PUBLIC_URL}/my-account/orders`}><i className="las la-file-alt" />&nbsp;<span>Orders</span></Link>&nbsp;&nbsp;&bull;&nbsp;&nbsp;
                                <Link to={`${process.env.PUBLIC_URL}/my-account/transactions`}><i className="las la-exchange-alt" />&nbsp;<span>Transactions</span></Link>&nbsp;&nbsp;&bull;&nbsp;&nbsp;
                                <Link to={`${process.env.PUBLIC_URL}/my-account/about`}><i className="las la-edit" />&nbsp;<span>Edit&nbsp;Info</span></Link>&nbsp;&nbsp;&bull;&nbsp;&nbsp;
                                <Link to={`${process.env.PUBLIC_URL}/my-account/my-posts`}><i className="las la-image" />&nbsp;<span>My&nbsp;Posts</span></Link>
                            </div>
                            <div className='right'>
                                <button type='btn' onClick={this.logout}><span>Sign Out</span>&nbsp;&nbsp;<LogOut /></button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div >
        )
    }
}

export default MyAccountHeader;