import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { isIEBrowser } from './../../../utils/utils';

import * as orderApi from './../../../api/order'

class RequestDetails extends Component {

    constructor(props) {
        super(props)

        this.state = {
            images: [],
            items: [],
            order_details: "",
            order_id: "",
            request: "",
            status: "",
            user: "",
            date: '',
        }
    }

    componentDidMount() {

        var checkusertoken = localStorage.getItem('usertokenShivshahiCustomer');
        // console.log(checkusertoken)
        if (checkusertoken == null) {
            this.props.history.push(`${process.env.PUBLIC_URL}/auth/login-register/:data`)
        } else {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const data = {
                userToken: localStorage.usertokenShivshahiCustomer,
                id: this.props.location.state.id,
            }

            orderApi.fetchSingleReturnExchangeRequest(data)
                .then(res => {
                    if (res.status == 200) {
                        const singleRequest = res.data

                        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
                        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
                        var date = new Date(singleRequest.date);
                        var dateFinal = days[date.getDay()] + ', ' + monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();

                        this.setState({
                            date: dateFinal,
                            images: JSON.parse(singleRequest.images),
                            items: JSON.parse(singleRequest.items),
                            order_details: JSON.parse(singleRequest.order_details),
                            order_id: singleRequest.order_id,
                            request: singleRequest.request,
                            status: singleRequest.status,
                            user: JSON.parse(singleRequest.user),
                        }, () => {
                            spinner.style.display = "none";
                        })
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Please Login Again...!')
                        // localStorage.removeItem('usertokenShivshahiCustomer')
                        localStorage.clear();
                        window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        this.props.history.push(`${process.env.PUBLIC_URL}/my-account/orders`)
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    this.props.history.push(`${process.env.PUBLIC_URL}/my-account/orders`)
                })

        }
    }


    cancelRequest = (e) => {
        e.preventDefault();

        if (window.confirm('Do you want to Cancel the Request ?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const data = {
                id: this.props.location.state.id,
                order_id: this.state.order_id,
                userToken: localStorage.usertokenShivshahiCustomer
            }

            orderApi.cancelReturnExchangeRequest(data)
                .then(res => {
                    if (res.status == 200) {
                        alert('Request Cancelled')
                        spinner.style.display = "none";
                        window.location.reload();
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Please Login Again...!')
                        // localStorage.removeItem('usertokenShivshahiCustomer')
                        localStorage.clear();
                        window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        window.location.reload();
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    window.location.reload();
                })

        }
    }

    render() {
        const { currency } = this.props;

        return (
            <div className='order-success all-bg'>

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <section className="section-top mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-10 col-sm-11 col-12 all-bg-light pb-0 pt-2">
                                <div>
                                    <h3 style={{ color: '#c96' }}>{this.state.request} Request</h3>
                                    <p>Order ID :&nbsp;&nbsp;<span>{parseInt(this.state.order_details.id) + 9999}</span></p>
                                    <p>Date :&nbsp;&nbsp;<span>{this.state.date}</span></p>
                                    <p>Status :&nbsp;&nbsp;<span style={{ color: '#c96' }}>{this.state.status}</span></p>
                                </div>
                                <hr />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-10 col-sm-11 col-12 all-bg-light pb-0">
                                <h4>Requested Items</h4>
                                <div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className='first'>Product</th>
                                                <th className='order-items-mobile'>Price</th>
                                                <th className='order-items-mobile'>Size</th>
                                                <th className='order-items-mobile'>Quantity</th>
                                                <th className='order-items-mobile-show'>Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.items.map((item, index) =>
                                                <tr key={index}>
                                                    <td className='first'>
                                                        <div className="product all-bg-light" style={{ justifyContent: 'start' }}>
                                                            <figure style={{ width: '70px' }}>
                                                                <img src={process.env.PUBLIC_URL + '/' + item.pictures[0]} alt="Product" style={{ width: '100%' }} />
                                                            </figure>
                                                            <h4 className="product-title pl-5" style={{ color: '#c2c2c2' }}>
                                                                {item.name} - <div className="color-display" style={{ backgroundColor: item.color }} />
                                                            </h4>
                                                        </div>
                                                    </td>

                                                    <td className='order-items-mobile'>
                                                        {currency.currencySymbol}&nbsp;
                                                            {(item.price * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </td>

                                                    <td className='order-items-mobile'>{item.size} Yards</td>

                                                    <td className='order-items-mobile'>{item.reQty}</td>

                                                    <td className='order-items-mobile-show'>
                                                        <ul>
                                                            <li>
                                                                Price : <span>{currency.currencySymbol}&nbsp;
                                                                        {(item.price * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                                            </li>
                                                            <li>Size : <span>{item.size} Yards</span></li>
                                                            <li>Quantity : <span>{item.reQty}</span></li>
                                                        </ul>
                                                    </td>

                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-btn mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-10 col-sm-11 col-12 all-bg-light pt-3">
                                <h4 style={{ color: '#fff' }}>Defective Images</h4>
                                <div className="pt-2 pb-3" style={{ display: 'flex', justifyContent: 'start', width: '100%', overflow: 'auto' }}>
                                    {this.state.images.map((image, index) =>
                                        <img key={index} src={`${process.env.PUBLIC_URL}/assets/images/return-exchange-images/${image}`} alt="" style={{ width: '250px' }} className="mr-2 ml-2" />
                                    )}
                                </div>

                                <Link to='#'
                                    className="btn btn-outline-primary mt-2 mb-2"
                                    onClick={this.cancelRequest}
                                    style={{ display: `${this.state.status == 'Requested' ? '' : 'none'}` }}
                                >
                                    <span>Cancel Request</span><i className="icon-close"></i>
                                </Link>
                                <h5 className="mt-2 mb-2" style={{ color: '#e83f4a', display: `${this.state.status == 'Cancelled' ? '' : 'none'}` }}>This request has been Cancelled..!</h5>
                                <h5 className="mt-2 mb-2" style={{ color: '#e83f4a', display: `${this.state.status == 'Rejected' ? '' : 'none'}` }}>This request has been Rejected. Please contact the support team for details..!</h5>
                                <p className="mt-2 mb-2" style={{ display: `${this.state.status == 'Accepted' ? '' : 'none'}` }}>
                                    You can send your items by courier on following address. <br />
                                    <strong style={{ color: '#fff' }}>
                                        SHIVSHAHI NETWORK <br />
                                        Kukkar Lane, Yeola,<br />
                                        Dist. Nashik, 423401.
                                    </strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="cta cta-display bg-image pt-4 pb-4" style={{ backgroundImage: `url(assets/images/backgrounds/bg-7.jpg)` }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-9 col-xl-7">
                                <div className={`row no-gutters ${isIEBrowser() ? '' : 'flex-column'} flex-sm-row align-items-sm-center`} >
                                    <div className="col">
                                        <h3 className="cta-title text-white">Want to Look even Gorgeous..?</h3>
                                        <p className="cta-desc text-white">Lets Go for the shopping</p>
                                    </div>

                                    <div className="col-auto">
                                        <Link to={`${process.env.PUBLIC_URL}/shop`} className="btn btn-outline-white"><span>Shop More</span><i className="icon-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        currency: state.currencyData,
    };
};


export default connect(mapStateToProps)(RequestDetails);
