import { RECEIVE_PRODUCTS, SHOW_QUICKVIEW, CLOSE_QUICKVIEW, GET_DETAILED_PRODUCT, RECEIVE_CATS, RECEIVE_NETWORK, RECEIVE_TRACKINGS, RECEIVE_CLIENT_TESTIMONIALS, RECEIVE_INTRO_SLIDERS, RECEIVE_TEAM_MEMBERS, RECEIVE_CAT_WALL } from "../constants/action-types";
import { findIndex } from "../utils/utils";


const initialState = {
    products: [],
    productDetail: [],
    quickView: false,
    cats: [],
    networks: [],
    trackings: [],
    sliders: [],
    testimonials: [],
    members: [],
    catwall: [],
};

const productReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_DETAILED_PRODUCT:
            let detailedProductIndex = findIndex(state.products, product => product.id === action.productId);
            // console.log(detailedProductIndex)
            if (-1 !== detailedProductIndex) {
                const detailedProduct = state.products[detailedProductIndex];
                return {
                    ...state,
                    detailedProduct: detailedProduct
                }
            }
            break;

        case RECEIVE_PRODUCTS:
            return {
                ...state,
                products: action.products
            };

        case RECEIVE_CATS:
            return {
                ...state,
                cats: action.categories
            };

        case RECEIVE_NETWORK:
            return {
                ...state,
                networks: action.networks
            };

        case RECEIVE_TRACKINGS:
            return {
                ...state,
                trackings: action.trackings
            };

        case RECEIVE_INTRO_SLIDERS:
            return {
                ...state,
                sliders: action.sliders
            };

        case RECEIVE_CLIENT_TESTIMONIALS:
            return {
                ...state,
                testimonials: action.testimonials
            };

        case RECEIVE_TEAM_MEMBERS:
            return {
                ...state,
                members: action.members
            };

        case RECEIVE_CAT_WALL:
            return {
                ...state,
                catwall: action.catwall
            };

        case SHOW_QUICKVIEW:
            let index = findIndex(state.products, product => product.id === action.productId);
            if (-1 !== index) {
                const item = state.products[index];
                return {
                    ...state,
                    quickView: true,
                    productDetail: item
                };
            }
            break;

        case CLOSE_QUICKVIEW:
            return { ...state, quickView: false };

        default:
            return state;
    }
};
export default productReducer;