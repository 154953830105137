import React, { Component } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { Helmet } from 'react-helmet';

import { resetPassword } from "./../../../api/auth";

class ResetPassword extends Component {

    constructor(props) {
        super(props)

        this.state = {
            pass: "",
            confpass: "",
            passwordhidden: true,
            caps: false,
            passresettoken: '',
        }
    }

    componentDidMount() {
        let passresettoken = this.props.match.params.token;
        var checkusertoken = localStorage.getItem('usertokenShivshahiCustomer');
        if (checkusertoken !== null) {
            this.props.history.push(`${process.env.PUBLIC_URL}/`)
        } else if (passresettoken == ":token") {
            this.props.history.push(`${process.env.PUBLIC_URL}/auth/login-register/:data`)
        } else {
            this.setState({ passresettoken: passresettoken })
        }
    }

    togglePasswordVisibility = () => {
        if (this.state.passwordhidden === true) {
            this.setState({ passwordhidden: false });
        } else {
            this.setState({ passwordhidden: true });
        }
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value.replace(new RegExp("\\s*", "gm"), '') });

        //Caps Lock warning
        let pass = document.getElementById("pass");
        let confpass = document.getElementById("confpass");

        pass.addEventListener("keyup", e => {
            if (e.getModifierState("CapsLock")) {
                this.setState({ caps: true });
            } else {
                this.setState({ caps: false });
            }
        });

        confpass.addEventListener("keyup", e => {
            if (e.getModifierState("CapsLock")) {
                this.setState({ caps: true });
            } else {
                this.setState({ caps: false });
            }
        });
    }

    handleSubmit = e => {
        e.preventDefault();

        let passmissmatchalert = document.getElementById('passmissmatchalert');
        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";
        passmissmatchalert.style.display = "none";

        if (this.state.pass !== this.state.confpass) {
            passmissmatchalert.style.display = "block";
            spinner.style.display = "none";
        } else {
            const User = {
                password: this.state.pass,
                passresettoken: this.state.passresettoken,
            }

            resetPassword(User)
                .then(res => {
                    if (res.status == 200) {
                        alert('Your Password has been successfully Changed.. Please Log In now!')
                        this.props.history.push(`${process.env.PUBLIC_URL}/auth/login-register/:data`)
                    } else {
                        alert('Token is expired..! Please try for reset password again..!')
                        this.props.history.push(`${process.env.PUBLIC_URL}/auth/forgotPassword`)
                    }
                })
                .catch((err) => {
                    alert('Something went Wrong')
                    spinner.style.display = "none";
                })
        }

    }

    render() {
        return (
            <div className="main">
                <Helmet>
                    <title>Shivshahi Network</title>
                </Helmet>

                <h1 className="d-none">Shivshahi Network</h1>

                <div className="login-page bg-image pt-8 pb-8 pt-md-12 pb-md-12 pt-lg-17 pb-lg-17" style={{ backgroundImage: `url('assets/images/backgrounds/login-bg.jpg')` }}>
                    <div className="container">
                        <div className="form-box all-bg">
                            <div className="form-tab">

                                <div id="spinneranimid" className="overlay-spinner">
                                    <div className="cssload-whirlpool" />
                                </div>

                                <Tabs selectedTabClassName="show" defaultIndex={0}>
                                    <TabList className="nav nav-pills nav-fill">
                                        <Tab className="nav-item all-font">
                                            <span className="nav-link">Reset Password</span>
                                        </Tab>
                                    </TabList>

                                    <div className="tab-content">

                                        <TabPanel style={{ paddingTop: "2rem" }}>
                                            <div className="alert alert-danger mb-2" id="passmissmatchalert" role="alert">
                                                The Passwords are not Matching..!
                                            </div>

                                            <form onSubmit={this.handleSubmit}>

                                                <div className="form-group">
                                                    <label className="all-font-dark" htmlFor="pass">Password *</label>
                                                    <div className="input-group">
                                                        <input type={this.state.passwordhidden ? "password" : "text"} className="form-control all-font" id="pass" name="pass" value={this.state.pass} onChange={this.handleChange} required />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text" id="toggle-visibility-pass" onClick={this.togglePasswordVisibility}><i className="icon-eye" /></span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="all-font-dark" htmlFor="confpass">Confirm Password *</label>
                                                    <div className="input-group">
                                                        <input type={this.state.passwordhidden ? "password" : "text"} className="form-control all-font" id="confpass" name="confpass" value={this.state.confpass} onChange={this.handleChange} required />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text" id="toggle-visibility-confpass" onClick={this.togglePasswordVisibility}><i className="icon-eye" /></span>
                                                        </div>
                                                    </div>
                                                    <div id="capswarningid" className="capswarn" style={{ display: `${this.state.caps ? '' : 'none'}` }}>Caps Lock is ON...!!</div>
                                                </div>

                                                <button type="submit" className="btn btn-outline-primary-2 mt-3">
                                                    <span>Reset</span>
                                                    <i className="icon-refresh"></i>
                                                </button>

                                            </form>
                                        </TabPanel>
                                    </div>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResetPassword;