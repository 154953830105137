import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { isIEBrowser } from './../../../utils/utils';

import { fetchLinks } from './../../../api/other'

class Links extends Component {

    constructor(props) {
        super(props)

        this.state = {
            links: []
        }
    }

    componentDidMount() {
        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";
        fetchLinks()
            .then(res => {
                if (res.status == 200) {
                    this.setState({ links: res.data }, () => {
                        spinner.style.display = "none";
                    })
                } else {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                }
            })
            .catch((err) => {
                alert('Error Occured.. Please Try after some time')
                spinner.style.display = "none";
            })
    }

    render() {
        return (
            <div className="main">
                <Helmet>
                    <title>Shivshahi Network</title>
                </Helmet>

                <h1 className="d-none">Shivshahi Network</h1>

                <div className="page-content pt-3 all-bg">
                    <div id="spinneranimid" className="overlay-spinner">
                        <div className="cssload-whirlpool" />
                    </div>

                    <div className='container mt-2'>
                        {this.state.links.length ?
                            this.state.links.map((item, index) =>
                                <div key={index} className="col-12 all-bg-light mb-3 pt-2 pb-2" style={{ borderRadius: '10px', paddingLeft: '15px' }}>
                                    <h4 className='font-primary'>{item.title}</h4>
                                    <p className='all-font-dark mb-1'>{item.descr}</p>
                                    <a href={item.link} target="_blank" rel="noopener noreferrer"><strong>{item.link}</strong></a>
                                </div>
                            )
                            :
                            <div className="col-12 all-bg-light mb-5">
                                No Links Yet..!
                        </div>
                        }
                    </div>
                </div>

                <div className="cta cta-display bg-image pt-4 pb-4" style={{ backgroundImage: `url(assets/images/backgrounds/bg-7.jpg)` }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-9 col-xl-7">
                                <div className={`row no-gutters ${isIEBrowser() ? '' : 'flex-column'} flex-sm-row align-items-sm-center`} >
                                    <div className="col">
                                        <h3 className="cta-title text-white">आपल्याकडे अधिक प्रश्न असल्यास</h3>
                                        <p className="cta-desc text-white">आमच्याशी संपर्क साधा मोकळ्या मनाने</p>
                                    </div>

                                    <div className="col-auto">
                                        <Link to={`${process.env.PUBLIC_URL}/faq`} className="btn btn-outline-white"><span>Ask Us</span><i className="icon-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Links;