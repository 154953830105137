import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';

import 'react-app-polyfill/ie11';

// Store
import store from './store';

// Actions
import { getAllProducts, closeQuickViewModal, getAllCategoriesCustom, getAllNetworksCustom, getAllTrackings, resetWishlist, resetCartlist, replaceWishlist, replaceCartlist, getAllIntroSliders, getAllClientTestimonials, getAllTeamMembers, getAllCatWall, getGalleryPosts } from './actions';

// Layouts
import Layout from './components/app';

//pages
import Landing from './components/pages/landing/landing'
import ShopGrid from './components/pages/shop-grid'
import DetailProduct from './components/pages/detail-product'
import LoginRegister from './components/pages/auth/login-register'
import ForgetPassword from './components/pages/auth/forgetPassword'
import ResetPassword from './components/pages/auth/resetPassword'
import VerifyEmail from './components/pages/auth/verifyEmail'
import EmailVerified from './components/pages/auth/emailVerified'
import AboutUs from './components/pages/other/about'
import FAQs from './components/pages/other/faq'
import ContactUs from './components/pages/other/contact'
import Cart from './components/pages/other/cart'
import Wishlist from './components/pages/other/wishlist'
import Checkout from './components/pages/other/checkout'
import BusinessOpportunity from './components/pages/other/businessOpportunity'
import HirkaniPlan from './components/pages/other/hirkaniPlan'
import RechargePlan from './components/pages/other/rechargePlan'
import OrderSuccess from './components/pages/other/orderSuccess'
import OrderDetails from './components/pages/other/orderDetails'
import JoinUs from './components/pages/other/joinUs'
import ReturnExchangePolicy from './components/pages/other/returnExchange-policy'
import ShipDeliverPolicy from './components/pages/other/shipDeliver-policy'
import TermsOfService from './components/pages/other/terms-of-service'
import RequestDetails from './components/pages/other/requestDetails'

import About from './components/pages/myAccount/about'
import Dashboard from './components/pages/myAccount/dashboard'
import Wallet from './components/pages/myAccount/wallet'
import Orders from './components/pages/myAccount/orders'
import Transactions from './components/pages/myAccount/transactions'
import MyPosts from './components/pages/myAccount/my-posts'

import Links from './components/pages/other/links'
import Gallery from './components/pages/other/gallery'

// import Utils
import { initFunctions } from './utils/utils';

import { fetchWishlistANDCartlist } from './api/user'

export class Root extends Component {
    constructor(props) {
        super(props);
        initFunctions();
    }

    componentDidMount() {
        // close quick view modal if it is opened in new page
        store.dispatch(closeQuickViewModal());

        const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer
        if (usertokenShivshahiCustomer != null) {
            const data = {
                userToken: usertokenShivshahiCustomer,
            }
            fetchWishlistANDCartlist(data)
                .then(async res => {
                    if (res.status == 200) {
                        store.dispatch(replaceWishlist(res.data.wishlist))
                        store.dispatch(replaceCartlist(res.data.cartlist))
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Session Expired..Please Login Again..!')
                        // localStorage.removeItem('usertokenShivshahiCustomer')
                        localStorage.clear();
                        window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    } else {
                        alert('Something Went Wrong')
                        // localStorage.removeItem('usertokenShivshahiCustomer')
                        localStorage.clear();
                        store.dispatch(resetWishlist())
                        store.dispatch(resetCartlist())
                        window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    }
                })
                .catch((err) => {
                    alert('Something Went Wrong')
                    // localStorage.removeItem('usertokenShivshahiCustomer')
                    localStorage.clear();
                    store.dispatch(resetWishlist())
                    store.dispatch(resetCartlist())
                    window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                })
        } else {
            store.dispatch(resetWishlist())
            store.dispatch(resetCartlist())
            if (window.location.pathname == '/wishlist' || window.location.pathname == '/shop/cart' || window.location.pathname == '/shop/checkout') {
                window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
            }
        }
    }

    componentDidUpdate() {
        console.log(window.location.pathname)
    }

    render() {
        store.dispatch(getAllProducts());
        store.dispatch(getAllCategoriesCustom());
        store.dispatch(getAllNetworksCustom());
        store.dispatch(getAllTrackings());
        store.dispatch(getAllIntroSliders());
        store.dispatch(getAllClientTestimonials());
        store.dispatch(getAllTeamMembers());
        store.dispatch(getAllCatWall());
        store.dispatch(getGalleryPosts());

        return (
            <Provider store={store} >
                <BrowserRouter basename={'/'} >
                    <ScrollContext>
                        <Switch>

                            <Layout>

                                <Route exact path={`${process.env.PUBLIC_URL}/`} component={Landing} />
                                <Route exact path={`${process.env.PUBLIC_URL}/shop`} component={ShopGrid} />
                                <Route exact path={`${process.env.PUBLIC_URL}/shop/detail/:id`} component={DetailProduct} />
                                <Route exact path={`${process.env.PUBLIC_URL}/faq`} component={FAQs} />
                                <Route exact path={`${process.env.PUBLIC_URL}/auth/login-register/:data`} component={LoginRegister} />
                                <Route exact path={`${process.env.PUBLIC_URL}/auth/forgotPassword`} component={ForgetPassword} />
                                <Route exact path={`${process.env.PUBLIC_URL}/auth/resetPassword/:token`} component={ResetPassword} />
                                <Route exact path={`${process.env.PUBLIC_URL}/auth/verifyEmail`} component={VerifyEmail} />
                                <Route exact path={`${process.env.PUBLIC_URL}/auth/emailVerified/:verificationtoken`} component={EmailVerified} />
                                <Route exact path={`${process.env.PUBLIC_URL}/about`} component={AboutUs} />
                                <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={ContactUs} />
                                <Route exact path={`${process.env.PUBLIC_URL}/shop/cart`} component={Cart} />
                                <Route exact path={`${process.env.PUBLIC_URL}/shop/checkout`} component={Checkout} />
                                <Route exact path={`${process.env.PUBLIC_URL}/wishlist`} component={Wishlist} />
                                <Route exact path={`${process.env.PUBLIC_URL}/business-opportunity`} component={BusinessOpportunity} />
                                <Route exact path={`${process.env.PUBLIC_URL}/hirkani-plan`} component={HirkaniPlan} />
                                <Route exact path={`${process.env.PUBLIC_URL}/recharge-plan`} component={RechargePlan} />
                                <Route exact path={`${process.env.PUBLIC_URL}/order-success/:orderid`} component={OrderSuccess} />
                                <Route exact path={`${process.env.PUBLIC_URL}/order-details/:orderid`} component={OrderDetails} />
                                <Route exact path={`${process.env.PUBLIC_URL}/join-us/:planinfo`} component={JoinUs} />
                                <Route exact path={`${process.env.PUBLIC_URL}/return&exchange-policy`} component={ReturnExchangePolicy} />
                                <Route exact path={`${process.env.PUBLIC_URL}/ship&deliver-policy`} component={ShipDeliverPolicy} />
                                <Route exact path={`${process.env.PUBLIC_URL}/termsofservice`} component={TermsOfService} />
                                <Route exact path={`${process.env.PUBLIC_URL}/request-details`} component={RequestDetails} />
                                <Route exact path={`${process.env.PUBLIC_URL}/links`} component={Links} />
                                <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery} />

                                <Route exact path={`${process.env.PUBLIC_URL}/my-account/about`} component={About} />
                                <Route exact path={`${process.env.PUBLIC_URL}/my-account/dashboard`} component={Dashboard} />
                                <Route exact path={`${process.env.PUBLIC_URL}/my-account/wallet`} component={Wallet} />
                                <Route exact path={`${process.env.PUBLIC_URL}/my-account/orders`} component={Orders} />
                                <Route exact path={`${process.env.PUBLIC_URL}/my-account/transactions`} component={Transactions} />
                                <Route exact path={`${process.env.PUBLIC_URL}/my-account/my-posts`} component={MyPosts} />

                            </Layout>

                        </Switch>
                    </ScrollContext>
                </BrowserRouter>
            </Provider>
        );
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));