import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import ProfileOne from './../../components/profile-one';
// import OwlCarousels from './../../components/owl-carousel';
// import TestimonialSingle from './../../components/sub-components/testimonialSingle';

// import { mainSlider5 } from './../../components/sub-components/slider-settings';

// import team_testimonials_data from './../../../data/team-testimonials.json'

import { isIEBrowser } from './../../../utils/utils';

class About extends Component {

    componentDidMount() {
        document.querySelector(".footer-middle").classList.add("border-0");
        document.body.classList.add('all-bg');
    }

    componentWillUnmount() {
        document.body.classList.remove('all-bg');
    }

    render() {
        return (
            <div className="main">
                <Helmet>
                    <title>Shivshahi Network</title>
                </Helmet>

                <h1 className="d-none">Shivshahi Network</h1>

                <div className="container pt-4">
                    <div className="page-header page-header-big text-center" style={{ backgroundImage: `url('assets/images/backgrounds/about-header-bg.jpg')` }}>
                        <h1 className="page-title text-white" style={{ fontSize: '60px' }}>Shivshahi Network<span style={{ fontSize: '30px', color: '#fff' }}>About Us</span></h1>
                    </div>
                </div>

                <div className="page-content pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mb-3 mb-lg-0">
                                <h2 className="title all-font">Our Vision</h2>
                                <p>घरा घरा मध्ये उद्योजग आणि एक भक्कम नेता तयार झाला पाहिजे। शिवशाही मध्ये सहभागीं असलेल्या प्रत्येक व्यक्तीचा व्यवसाय सोबतच ,शिक्षण कौशल्य, नेतृत्व गुणवत्ता, निर्भय वक्ता, प्रभावी व्यक्तिमत्वा चा सर्वांगी विकास।  शिवशाही हा फक्त एक व्यवसाय नसुन। हे एक कुटुंब आहे। ज्यात सर्व लीडर्स एक मेकांनां धरून कुटूंब चालवतात।</p>
                            </div>

                            <div className="col-lg-6">
                                <h2 className="title all-font">Our Mission</h2>
                                <p><strong>2022</strong> पर्यंत संपूर्ण जग भरात शिवशाही असेल। व्यवसायात एकमेकांचे पाय ओढण्या पेक्षा, एकमेकांना सोबत घेऊन चालणारा व्यवसाय म्हणजेच शिवशाही नेटवर्क। ज्याच्या साठी व्यवसायाचे सर्व दरवाजे बंद असतात तेव्हा त्याच्या सोबत शिवशाही उभी असते। ते ही भक्कम पने।</p>
                            </div>
                        </div>
                        <div className="mb-5"></div>
                    </div>

                    <div className="all-bg-light pt-6 pb-11 mb-6 mb-lg-8">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5 mb-3 mb-lg-0">
                                    <h2 className="title all-font">Who We Are</h2>
                                    <p className="lead text-primary mb-3">FROM WEAVING TO BUSINESS</p>
                                    <p className="mb-2">An initiative to promote this beautiful art and our rich tradition. We pride ourselves on the fact that we have something for every taste, every occasion, and every budget. Based on valuable feedback from our customers, we wish to continue the work towards keeping our customers delighted, and our weavers busy, without any change to the faith and standard associated with us. </p>
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/about/certifications.png`} alt="" style={{ width: '300px' }} />
                                </div>

                                <div className="col-lg-6 offset-lg-1">
                                    <div className="about-images">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/about/img-1.jpg`} alt="" className="about-img-front" />
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/about/img-3.jpg`} alt="" className="about-img-back" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="bg-image pt-7 pb-7" style={{ backgroundImage: 'url(assets/images/backgrounds/bg-4.jpg)' }}>
                            <div className="container">
                                <div className="row" style={{ textAlign: 'center', justifyContent: 'center' }}>
                                    <div className="col-lg-9">
                                        <blockquote className='testimonial testimonial-icon text-center text-white' />
                                        <p style={{ color: 'white' }}>
                                            मी शुभम नाकोड, पैठणी साडी बनवतो आणि ऑनलाइन विकतो तसेच आमचे आउटलेट पाच सिटीमध्ये आहेत. ( येवला | ठाणे | वसई | दादर | वाशी )
                                            तसेच आमचे रिसेलर्स महाराष्ट्राच्या प्रत्येक गावात आहेत. ते आमच्याकडून होलसेल भावात साडी खरेदी करून पुढे त्यांचा प्रॉफिट ऍड करून त्यांच्या कस्टमर्स ना विकतात.
                                            सुरुवातीला त्यांनी चांगला बिजनेस केला पण काही काळानंतर त्यांचे कस्टमर्स ते पैठणी कुठुन मागवतात त्यांचे contact शोधायला लागले.
                                            त्यांना गुगल आणि फेसबुक द्वारे भरपूर Wholesaler आणि Manufacturer ची मोबाईल नंबर मिळाले.
                                            मग किंमती मध्ये फरक जाणवल्याने ते थेट Wholesaler आणि Manufacturer ना ऑर्डर देऊ लागले. किंमतीच्या गॅप मुळे आमच्या रिसेलर्स चे कस्टमर तुटू लागले.
                                            माझ्या रिसेलर च भरपूर नुकसान व्हायला लागलं त्यांचा बिजनेस डाऊन जायला लागला आणि मग पर्याय म्हणून आम्ही नेटवर्क मार्केटिंग बिजनेस निवडला जेणेकरून आमचे रिसेलर्स आमच्याच किंमत मध्ये त्यांच्या कस्टमरला साडी विकेल. त्यामुळे रेट चा फरक पण पडणार नाही आणि त्यांचे कस्टमर पण तुटणार नाही.
                                            यामध्ये आमचा फायदा असा आहे की आम्हाला लागणारा खर्च जसे की (दुकान भाडे ,लाईट बिल, सेल्समन चे पगार , वाहतूक, व इतर छोटे छोटे खर्च) पण लागणार नाही. या मध्ये वाचलेली रक्कम आम्ही आमच्या ग्राहकांना प्रॉफिट च्या स्वरुपात देऊ. त्या मुळे ग्राहक च ग्राहक शोधून आणतील. ज्यांनी कधी रीसेलिंग पण केली नाही. त्यांनाही हा बिजनेस करता येईल.
                                            आणि ज्यांना स्वतःची मार्केटिंग, मॅनेजमेंट, मोठे भांडवल लावुन शोरूम सुरू करण्याची क्षमता नाही. तेसुद्धा एक फॉर्म भरून आणि एक ऑर्डर करून आमच्या नेटवर्क ला जॉइन करून घरबसल्या बिझनेस करेल. अशा उद्देशाने आम्ही ही नेटवर्क मार्केटिंग बिजनेस सुरू केला आहे.
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container all-bg-light text-center pt-3 pb-3 mt-10">
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/about/iso.jpg`} alt="" style={{ width: '350px' }} />
                        </div>
                    </div>

                    {/* <div className="container">
                        <h2 className="text-center mb-4 all-font mt-3">Our Shops</h2>

                        <div className="row">
                            <div className="col-md-4">
                                <ProfileOne image="assets/images/stores/yeola_shop2.jpg" name="Shivshahi Paithani, Yeola" title="" content="Shivshahi Paithani, Kukkar Lane, Yeola, Nashik, Maharashtra, 423401." />
                            </div>
                            <div className="col-md-4">
                                <ProfileOne image="assets/images/stores/thane_shop2.jpg" name="Shivshahi Paithani, Thane" title="" content="Ram Maruti Rd, Opposite ICICI bank, near Gajanan Maharaj Mandir, Naupada, Thane West, Thane, Maharashtra, 400602." />
                            </div>
                            <div className="col-md-4">
                                <ProfileOne image="assets/images/stores/vasai_shop2.jpg" name="Shivshahi Paithani, Vasai" title="" content="Sai Nagar, Vasai West, Navghar-Manikpur, Maharashtra, 401202." />
                            </div>
                            <div className="col-md-4">
                                <ProfileOne image="assets/images/stores/vashi_shop2.jpg" name="Shivshahi Paithani, Vashi" title="" content="Chatrapati Shivaji Maharaj Chowk, Sector 17, Vashi, Navi Mumbai, Maharashtra, 400703." />
                            </div>
                            <div className="col-md-4">
                                <ProfileOne image="assets/images/stores/dadar_shop2.jpg" name="Shivshahi Paithani, Dadar" title="" content="Nakshatra Mall, Shop no. 44 First Floor, Ranade Road, Dadar West, Mumbai, Maharashtra, 400028." />
                            </div>
                            <div className="col-md-4">
                                <ProfileOne image="assets/images/stores/kalyan_shop2.jpg" name="Shivshahi Paithani, Kalyan" title="" content="Shop No.1, Shree Suprata Apartment, Ganpati Chowk, Joshi Compound Agra Road, Kalyan (West), Mumbai, Maharashtra, 421301." />
                            </div>
                        </div>
                    </div> */}

                    <div className="mb-2" />

                    <div className="stores mb-4 mb-lg-5">
                        <h2 className="title text-center mb-3 all-font">Our Stores</h2>

                        <div className="row">
                            <div className="col-lg-4" style={{ textAlign: 'center' }}>
                                <div className="store">
                                    <figure className="store-media mb-2 mb-lg-0" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/stores/yeola_shop.jpg`} alt="desc" />
                                    </figure>

                                    <div className="store-content pt-2">
                                        <h3 className="store-title all-font-light">Shivshahi Paithani, Yeola</h3>
                                        <address>Shivshahi Paithani, Kukkar Lane, Yeola, Nashik, Maharashtra, 423401.</address>
                                        <p className="all-font">Contact: <strong>+91 8446703040</strong></p>
                                        <p className="all-font">Timing: 10:00 AM - 8:00 AM (Open All Days)</p>
                                        <a href="https://goo.gl/maps/gQt91rt2EpzcUoHZ6" className="btn btn-link" target="_blank" rel="noopener noreferrer"><span>View on Map</span><i className="icon-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4" style={{ textAlign: 'center' }}>
                                <div className="store">
                                    <figure className="store-media mb-2 mb-lg-0" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/stores/thane_shop.jpg`} alt="desc" />
                                    </figure>

                                    <div className="store-content pt-2">
                                        <h3 className="store-title all-font-light">Shivshahi Paithani, Thane</h3>
                                        <address>Ram Maruti Rd, Opposite ICICI bank, near Gajanan Maharaj Mandir, Naupada, Thane West, Thane, Maharashtra, 400602.</address>
                                        <p className="all-font">Contact: <strong>+91 8208238376</strong></p>
                                        <p className="all-font">Timing: 10:00 AM - 8:00 AM (Open All Days)</p>
                                        <a href="https://g.page/ShivshahiPaithani?share" className="btn btn-link" target="_blank" rel="noopener noreferrer"><span>View on Map</span><i className="icon-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4" style={{ textAlign: 'center' }}>
                                <div className="store">
                                    <figure className="store-media mb-2 mb-lg-0" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/stores/vasai_shop.jpg`} alt="desc" />
                                    </figure>

                                    <div className="store-content pt-2">
                                        <h3 className="store-title all-font-light">Shivshahi Paithani, Vasai</h3>
                                        <address>Sai Nagar, Vasai West, Navghar-Manikpur, Maharashtra, 401202.</address>
                                        <p className="all-font">Contact: <strong>+91 8208238376</strong></p>
                                        <p className="all-font">Timing: 10:00 AM - 8:00 AM (Open All Days)</p>
                                        <a href="https://goo.gl/maps/6RnsMDw32gJdYyxM6" className="btn btn-link" target="_blank" rel="noopener noreferrer"><span>View on Map</span><i className="icon-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4" style={{ textAlign: 'center' }}>
                                <div className="store">
                                    <figure className="store-media mb-2 mb-lg-0" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/stores/vashi_shop.jpg`} alt="desc" />
                                    </figure>

                                    <div className="store-content pt-2">
                                        <h3 className="store-title all-font-light">Shivshahi Paithani, Vashi</h3>
                                        <address>Chatrapati Shivaji Maharaj Chowk, Sector 17, Vashi, Navi Mumbai, Maharashtra, 400703.</address>
                                        <p className="all-font">Contact: <strong>+91 9833024555</strong></p>
                                        <p className="all-font">Timing: 10:00 AM - 8:00 AM (Open All Days)</p>
                                        <a href="https://goo.gl/maps/GPEX2DW1C3DNvPR88" className="btn btn-link" target="_blank" rel="noopener noreferrer"><span>View on Map</span><i className="icon-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4" style={{ textAlign: 'center' }}>
                                <div className="store">
                                    <figure className="store-media mb-2 mb-lg-0" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/stores/dadar_shop.jpg`} alt="desc" />
                                    </figure>

                                    <div className="store-content pt-2">
                                        <h3 className="store-title all-font-light">Shivshahi Paithani, Dadar</h3>
                                        <address>Nakshatra Mall, Shop no. 44 First Floor, Ranade Road, Dadar West, Mumbai, Maharashtra, 400028.</address>
                                        <p className="all-font">Contact: <strong>+91 9987487561</strong></p>
                                        <p className="all-font">Timing: 10:00 AM - 8:00 AM (Open All Days)</p>
                                        <a href="https://goo.gl/maps/pSqSATE6rWnhXdLR7" className="btn btn-link" target="_blank" rel="noopener noreferrer"><span>View on Map</span><i className="icon-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4" style={{ textAlign: 'center' }}>
                                <div className="store">
                                    <figure className="store-media mb-2 mb-lg-0" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/stores/kalyan_shop.jpg`} alt="desc" />
                                    </figure>

                                    <div className="store-content pt-2">
                                        <h3 className="store-title all-font-light">Shivshahi Paithani, Kalyan</h3>
                                        <address>Shop No.1, Shree Suprata Apartment, Ganpati Chowk, Joshi Compound Agra Road, Kalyan (West), Mumbai, Maharashtra, 421301.</address>
                                        <p className="all-font">Contact: <strong>+91 7208603285</strong></p>
                                        <p className="all-font">Timing: 10:00 AM - 8:00 AM (Open All Days)</p>
                                        <a href="https://goo.gl/maps/bXJ5mCYj78JWGXieA" className="btn btn-link" target="_blank" rel="noopener noreferrer"><span>View on Map</span><i className="icon-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    {/* <div className="about-testimonials all-bg-light pt-6 pb-6">
                        <div className="container">
                            <h2 className="title text-center mb-3 all-font">What Our People Think</h2>
                            <OwlCarousels adClass="owl-simple owl-testimonials-photo" carouselOptions={mainSlider5} >
                                {team_testimonials_data.team_testimonials.map((member, index) =>
                                    <div key={index}>
                                        <TestimonialSingle content={member.content} name={member.name} job={member.job} image={member.image} />
                                    </div>
                                )}
                            </OwlCarousels>
                        </div>
                    </div> */}
                </div>

                <div className="cta cta-display bg-image pt-4 pb-4" style={{ backgroundImage: `url(assets/images/backgrounds/bg-7.jpg)` }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-9 col-xl-7">
                                <div className={`row no-gutters ${isIEBrowser() ? '' : 'flex-column'} flex-sm-row align-items-sm-center`} >
                                    <div className="col">
                                        <h3 className="cta-title text-white">Want to Connect with Us..?</h3>
                                        <p className="cta-desc text-white">We are Here for You</p>
                                    </div>

                                    <div className="col-auto">
                                        <Link to={`${process.env.PUBLIC_URL}/contact`} className="btn btn-outline-white"><span>CONTACT US</span><i className="icon-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default About;