import React, { Component } from 'react';

import _data from './../../data/banner-one.json';

class BannerOne extends Component {
    render() {
        const { container = "container", adClass = "justify-content-center", iconAdClass = "", boxAdClass = "text-center", start = 1, end = 3 } = this.props;

        return (
            <div className={container}>
                <div className={`row ${adClass}`}>
                    {_data.services.slice(start - 1, end).map((item, index) =>
                        <div className="col-sm-4 col-lg-4 col-noPadding" key={index}>
                            <div className={`icon-box ${boxAdClass}`} >
                                <span className={`icon-box-icon ${iconAdClass}`}>
                                    <i className={item.icon}></i>
                                </span>
                                <div className="icon-box-content">
                                    <h3 className="icon-box-title all-font-light">{item.title}</h3>
                                    <p>{item.subtitle}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default BannerOne;