import React, { Component } from 'react';
// import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import style from './style.scss';

import { changeDemo, outerLoading } from './../../../actions';
import { initSettings } from './../../../utils/utils';
import store from './../../../store/index';

import { introSlider } from './settings';

// import WhatFoundersSay from './../../components/whatFoundersSay';
import Newsletter from './../../components/newsletter';
import IntroSlide from './../../components/intro-slide';
import OwlCarousel from './../../components/owl-carousel';
import ClientTestimonial from './../../components/clientTestimonial';
import OuterOverlay from './../../components/overlay/outer-overlay';
import BannerOne from './../../components/banner-one';
// import NewsletterModal from './../../components/newsletter-modal';
import CollectionGrid from './../../components/collectionGrid'
import Connections from './../../components/connections';
import ProfileTwo from './../../components/profile-two'

class Landing extends Component {

    componentDidMount() {
        // this.reloadPage();
        initSettings("demo-2", "skin-demo-2");
        store.dispatch(changeDemo("1"));
        document.body.classList.add('all-bg');
    }

    UNSAFE_componentWillMount() {
        store.dispatch(outerLoading());
        style.use();
    }

    componentWillUnmount() {
        style.unuse();
        document.body.classList.remove('all-bg');
    }

    // reloadPage = () => {
    //     var currentDocumentTimestamp = new Date(performance.timing.domLoading).getTime();
    //     // Current Time //
    //     var now = Date.now();
    //     // Total Process Lenght as Minutes //
    //     var tenSec = 10 * 1000;
    //     // End Time of Process //
    //     var plusTenSec = currentDocumentTimestamp + tenSec;
    //     console.log(now, plusTenSec)
    //     if (now > plusTenSec) {
    //         window.location.reload();
    //     }
    // }

    render() {
        return (
            <React.Fragment>

                <Helmet>
                    <title>Shivshahi Network</title>
                </Helmet>

                <h1 className="d-none">Shivshahi Network</h1>

                <OuterOverlay />

                <div className="page-wrapper">

                    <div className="main">
                        <div className="intro-slider-container small-hide">
                            <OwlCarousel adClass="owl-simple owl-light owl-nav-inside" carouselOptions={introSlider}>
                                {
                                    this.props.sliders.map((item, index) =>
                                        <IntroSlide data={item} newClass='small-hide' key={index} />
                                    )
                                }
                            </OwlCarousel>
                            <span className="slider-loader text-white"></span>
                        </div>
                        <div className="intro-slider-container big-hide">
                            <OwlCarousel adClass="owl-simple owl-light owl-nav-inside" carouselOptions={introSlider}>
                                {
                                    this.props.sliders.map((item, index) =>
                                        <IntroSlide data={item} newClass='big-hide' key={index} />
                                    )
                                }
                            </OwlCarousel>
                            <span className="slider-loader text-white"></span>
                        </div>
                    </div>

                    <CollectionGrid />

                    {/* <WhatFoundersSay
                        title1="Mr. Shubham Nakod"
                        subTitle1="Owner & Founder"
                        content1="स्वतःचे विक्री कौशल्य, व व्यवसायिक बुद्धिमत्तेचा वापर करून. स्वतःच्या पायावर उभे राहण्याचे व सर्वांना सोबत घेऊन व्यवसाय करण्याचे व्यासपीठ म्हणजेच शिवशाही नेटवर्क."
                        image1="assets/images/team/landing/founder.jpg"

                        title2="Mr. Gaurav Kshatriya"
                        subTitle2="CEO"
                        content2="व्यवसायिक बुद्धिमत्तेचा वापर करून. स्वतःच्या पायावर उभे राहण्याचे व सर्वांना सोबत घेऊन व्यवसाय करण्याचे व्यासपीठ म्हणजेच शिवशाही नेटवर्क."
                        image2="assets/images/team/landing/ceo.jpg"
                    /> */}

                    <ClientTestimonial data={this.props.testimonials} />

                    <div className="all-bg pt-3 pb-0 mb-0">
                        <div className="container">
                            <h2 className="text-center mb-4 all-font">Meet Our Team</h2>

                            <div className="row">
                                {this.props.members.map((member, index) =>
                                    <div className="col-sm-4 col-lg-3 col-6" key={index}>
                                        <ProfileTwo image={member.image} name={member.name} title={member.title} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* <Connections start={1} end={80} show='yes' /> */}
                    <Connections show='yes' />

                    <BannerOne adClass="" />

                    <div style={{ display: 'flex', justifyContent: 'center' }} className="mb-5">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/about/certifications.png`} alt="" style={{ width: '500px' }} />
                    </div>

                    <Newsletter />

                    {/* <ToastContainer autoClose={3000} className="toast-container" /> */}

                </div>

                {/* <NewsletterModal demo="1" /> */}

                {/* <MobileMenuTwo adClass="mobile-menu-light" /> */}

            </React.Fragment>
        );
    };
}

export const mapStateToProps = (state) => {
    return {
        sliders: state.data.sliders ? state.data.sliders : [],
        testimonials: state.data.testimonials ? state.data.testimonials : [],
        members: state.data.members ? state.data.members : []
    };
}

export default connect(mapStateToProps, {})(Landing);
