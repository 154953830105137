import React, { Component } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import jwt_decode from 'jwt-decode';

import { closeModal } from './../../../actions';

import { createGalleryPost } from './../../../api/other'

const customStyles = {
    content: {
        top: '50%',
        transform: 'translateY(-50%)'
    },
    overlay: {
        backgroundColor: 'rgba(77,77,77,0.6)',
        zIndex: '10000'
    }
};

var emojis = ["Disappointed", "Smiling", "Happy", "Superb", "Awesome"]

// Modal.setAppElement('#root');
export class PostCreateModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            content: "",
            image: null,
            img_preview: null,
            isVerticle: true,
            emojiRating: 5,

            stars: [],
            rating: 0,
            hovered: 0,
            selectedIcon: "★",
            deselectedIcon: "☆"
        }

        let outOf = props.outOf ? props.outOf : 5;

        for (var i = 0; i < outOf; i++) {
            this.state.stars.push(i + 1);
        }
    }

    closeModal = () => {
        document.getElementById("postCreate-modal").classList.remove("ReactModal__Content--after-open");

        setTimeout(() => {
            this.props.closeModal('postCreate');
        }, 200);
    }

    uploadImages = (e) => {
        let file = e.target.files[0];
        if (file != undefined) {
            if (
                file.type.toLowerCase() != 'image/jpg' &&
                file.type.toLowerCase() != 'image/jpeg' &&
                file.type.toLowerCase() != 'image/png'
            ) {
                this.setState({ image: null, img_preview: null }, () => {
                    alert('Please upload a valid Image file..!')
                })
            } else {
                var _URL = window.URL || window.webkitURL;
                var img = new Image();
                var objectUrl = _URL.createObjectURL(file);
                const scope = this
                img.onload = function () {
                    if (this.width >= this.height) {
                        scope.setState({ isVerticle: false })
                    } else {
                        scope.setState({ isVerticle: true })
                    }
                    _URL.revokeObjectURL(objectUrl);
                };
                img.src = objectUrl;
                this.setState({ image: file, img_preview: objectUrl })
            }
        } else {
            this.setState({ image: null, img_preview: null })
        }

    }


    onRequestSubmit = async (e) => {
        e.preventDefault();

        if (this.state.image === null) {
            alert('Please upload the image')
        } else if (this.state.content.replace(new RegExp("\\s*", "gm"), '').length === 0) {
            alert('Please enter the status')
        } else if (this.state.emojiRating === 0) {
            alert('Please select the mood')
        } else if (this.state.rating === 0) {
            alert('Please select the rating')
        } else if (window.confirm("Do you want to create a post ?")) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer
            const decoded = jwt_decode(usertokenShivshahiCustomer)

            let dataToUpload = {
                customerId: decoded.data.id,
                isVerticle: this.state.isVerticle,
                mood: emojis[this.state.emojiRating - 1],
                rating: this.state.rating,
                content: this.state.content
            }

            const data = new FormData()
            data.append('files', this.state.image)
            data.append('dataToUpload', JSON.stringify(dataToUpload))

            await createGalleryPost(data)
                .then(res => {
                    if (res.status == 200) {
                        alert('Post Created')
                        spinner.style.display = "none";
                        document.getElementById("postCreate-modal").classList.remove("ReactModal__Content--after-open");

                        // setTimeout(() => {
                        this.props.closeModal('postCreate');
                        // }, 10);
                        window.location.reload();
                    } else {
                        alert('Something went wrong')
                        spinner.style.display = "none";
                        document.getElementById("postCreate-modal").classList.remove("ReactModal__Content--after-open");

                        // setTimeout(() => {
                        this.props.closeModal('postCreate');
                        // }, 10);
                        window.location.reload();
                    }
                })
                .catch((err) => {
                    alert('Something went wrong')
                    spinner.style.display = "none";
                    document.getElementById("postCreate-modal").classList.remove("ReactModal__Content--after-open");

                    // setTimeout(() => {
                    this.props.closeModal('postCreate');
                    // }, 10);
                    window.location.reload();
                })
        }

    }

    changeRating(newRating) {
        this.setState({
            rating: newRating
        });

        if (this.props.onChange)
            this.props.onChange(newRating);
    }

    hoverRating(rating) {
        this.setState({
            hovered: rating
        });
    }


    render() {
        const { showModal, modal } = this.props;
        const { stars, rating, hovered, deselectedIcon, selectedIcon } = this.state;

        return (
            <Modal
                isOpen={showModal && 'postCreate' == modal}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="postCreate Modal"
                className="modal-dialog modal-dialog-centered"
                id="postCreate-modal"
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEsc={false}
            >
                <div className="modal-content all-bg">
                    <div id="spinneranimid2" className="overlay-spinner" style={{ zIndex: 9999999999999999999999 }}>
                        <div className="cssload-whirlpool" />
                    </div>
                    <div className="modal-body" style={{ overflowY: 'auto', height: '80vh' }}>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                            <span aria-hidden="true"><i className="icon-close"></i></span>
                        </button>
                        <div className="form-box all-bg">
                            <div className="form-tab">
                                <Tabs selectedTabClassName="show" defaultIndex={0}>
                                    <TabList className="nav nav-pills nav-fill">
                                        <Tab className="nav-item all-font">
                                            <span className="nav-link">Create new Post</span>
                                        </Tab>
                                    </TabList>

                                    <div className="tab-content">
                                        <TabPanel style={{ paddingTop: "2rem" }}>
                                            <div>
                                                <form onSubmit={this.onRequestSubmit}>

                                                    <div className='mb-2' style={{ display: this.state.img_preview === null ? 'none' : 'flex', justifyContent: 'center' }}>
                                                        <img src={this.state.img_preview} alt="img_preview" style={{ width: "50%", height: 'auto' }} />
                                                    </div>

                                                    <div className="form-group">
                                                        <input type="file" accept="image/*" id="fileLoader2" name="files" title="Load File" onChange={this.uploadImages} required />
                                                    </div>

                                                    <hr />

                                                    <div className="form-group">
                                                        <label className="all-font-dark" htmlFor="address">Status *</label>
                                                        <textarea style={{ minHeight: 'auto' }} rows={3} value={this.state.content} className="form-control all-font" id="content" name="content" pattern=".*\S+.*" onChange={e => this.setState({ content: e.target.value })} required />
                                                    </div>

                                                    {/* <h6 className='all-font text-center'>{emojis[this.state.emojiRating - 1]}</h6> */}

                                                    {/* <div className='mood-container mt-2 mb-3'>
                                                        {emojis.map((emoji, index) =>
                                                            <div key={index} onClick={e => this.setState({ emojiRating: index + 1 })} className={((index + 1) === this.state.emojiRating) ? 'mood-bg' : ''}>
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/emojis/${emoji}.png`} alt="icon" style={{ width: "48px", height: 'auto' }} />
                                                            </div>
                                                        )}
                                                    </div> */}

                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <div className='star-rating-for-pc mb-2'>
                                                            {stars.map(star =>
                                                                <span
                                                                    onClick={() => { this.changeRating(star); }}
                                                                    onMouseEnter={() => { this.hoverRating(star); }}
                                                                    onMouseLeave={() => { this.hoverRating(0); }}
                                                                    style={{
                                                                        color: rating < star ? hovered < star ? "#666" : "#c96" : "#c96",
                                                                        cursor: 'pointer',
                                                                        fontSize: "37px",
                                                                        transition: "0.3s",
                                                                        marginLeft: "3px",
                                                                        marginRight: "3px"
                                                                    }}
                                                                >
                                                                    {rating < star ?
                                                                        hovered < star ? deselectedIcon : selectedIcon
                                                                        :
                                                                        selectedIcon
                                                                    }
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <button type="submit" className="btn btn-outline-primary-2">
                                                            <span>Create</span>
                                                            <i className="icon-long-arrow-up"></i>
                                                        </button>
                                                    </div>

                                                </form>

                                            </div>
                                        </TabPanel>

                                    </div>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )

    }

}

const mapStateToProps = state => {
    return {
        showModal: state.demo.showModal,
        modal: state.demo.modal
    }
}

export default connect(mapStateToProps, { closeModal })(PostCreateModal);