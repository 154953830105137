import React, { Component } from 'react';

import OwlCarousels from './owl-carousel';
import TestimonialSingle from './sub-components/testimonialSingle';

import { mainSlider5 } from './sub-components/slider-settings';

class ClientTestimonial extends Component {
    render() {
        return (
            <div>
                <h2 className="title text-center mb-3 all-font" style={{ fontSize: '35px' }}>What Leaders Say About Us</h2>

                <div className="about-testimonials pt-6 pb-6 all-bg-light">
                    <div className="container all-bg-light">
                        <OwlCarousels adClass="owl-simple owl-testimonials-photo" carouselOptions={mainSlider5} >
                            {this.props.data.map((client, index) =>
                                <div key={index}>
                                    <TestimonialSingle content={client.content} name={client.name} job={client.job} image={client.image} />
                                </div>
                            )}
                        </OwlCarousels>
                    </div>
                </div>
            </div>
        );
    }
}

export default ClientTestimonial;