import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import PageHeader from './../../components/page-header';
import Accordion from './../../components/accordion/accordion';
import Card from './../../components/accordion/card';

import { isIEBrowser } from './../../../utils/utils';

import faq_data from './../../../data/faqs.json'

class BusinessOpportunity extends Component {
    render() {
        return (
            <div className="main">
                <Helmet>
                    <title>Shivshahi Network</title>
                </Helmet>

                <h1 className="d-none">Shivshahi Network</h1>

                <PageHeader title="Business" subTitle="Opportunity" />

                <div className="page-content pt-3 all-bg">
                    <div className="container all-bg-light mb-5">
                        <ul className="pt-3 pr-3 pl-5 all-font">
                            <li>❈&nbsp;&nbsp;सर्व व्यवसायीकां साठी घर बसल्या “ऑनलाईन विक्री करून पैसे” कमविण्याची संधी.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;तुम्ही शिवशाही नेटवर्क व्यवसाय सुरू करण्या साठी कुठलीही गुंतवणूक करण्याची गरज नाही.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;तुम्हीं स्वतःच Registation केल्या नंतर, तुम्हाला अकाऊंट लॉग-इन करावे लागते. नंतर युजर मध्ये जाऊन तुम्हाला तिथे एक Certificate मिळते. त्यात तुमचा DS CODE दिलेला असतो. तो code इतरांना तुमच्या श्रृंखला मध्ये जॉइन करून घेण्यासाठी असतो.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;तुमचा DS Code जितके लोक वापरून अकाऊंट लॉग-इन करतील. तितके लोक तुमच्या श्रृंखले मध्ये जोडले जातील.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;समजा तुम्ही (शीतल) आहात, तुम्ही (मीनल) ला जॉइन केले तर (शीतल) ला मिळणार (मीनल) च्या खरेदी वर 10% प्रॉफिट.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;जर (मीनल) ने (सोनल) ला जॉईन केले तर (मीनल) ला मिळणार (सोनल) च्या खरेदी वर 10% प्रॉफिट, आणि (शीतल) ला मिळणार 7% प्रॉफिट.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;आणि (सोनल) ने (स्नेहल) ला जॉईन केले तर (सोनल) ला मिळणार (स्नेहल) च्या खरेदी वर 10% प्रॉफिट, (मीनल) ला मिळणार 7% प्रॉफिट, आणि (शीतल) ला मिळणार 5% प्रॉफिट.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;सर्वांनी विक्री केलेल्या माला चा नफा (टक्केवारी नुसार) प्रत्येकाच्या वॉलेट मध्ये जमा होतो.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;झालेल्या व्यवहारातून, तुम्हाला मिळालेल्या प्रत्येक नफ्या ची संपूर्ण माहिती Transaction या Option मध्ये तुम्हाला मिळते.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;ऑर्डर मिळाल्या नंतर, उत्पादन निघाल्या वर (Dispatch झाल्यावर), प्रत्येक टप्प्याच्या नफा प्रत्येकाच्या वॉलेट मध्ये टक्केवारी नुसार जमा होऊन जातो.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;वॉलेट मध्ये जमा झालेला नफा, तुम्हीं प्रॉडक्ट ऑर्डर करतांना वापरू शकतात. व ग्राहक कडून त्याचे पैशे घेऊन तुम्हीं वॉलेट चे पैशे Incash (Withdraw) करू शकता.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;जर तुमच्या वॉलेट मध्ये ५०० रूपये च्या वर नफा जमा असेल, तर ती रक्कम तुम्हीं तुमच्या बँक खात्या वर घेऊ शकता. </li>
                            <li className="pt-1">❈&nbsp;&nbsp;वॉलेट चा जमा नफा निव्वळ नफा असल्या मुळे तो Withdraw करताना तुम्हाला 2% (व्यवहार शुल्क) द्यावा लागतो.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;अश्या पद्धतीने तुम्ही तुमच्या ग्राहकांना लीडर्स बनवत गेले आणि त्या लीडर्स ने अजून लीडर्स जॉईन केले तर तुम्हाला घर बसल्या दुय्यम उत्पन्न सुरू होते.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;अशा प्रकारे तुम्ही एकाच व्यवसायात दोन प्रकारे उत्पन्न घेऊ शकता. तुम्ही विकलेल्या साडीवर 10% प्रॉफिट तसेच तुमच्या टीम मध्ये जे जॉईन होणार त्यांचे 7% व तुमच्या टीम ने जॉईन केलेल्या मेंबर च्या केलेल्या बिझनेस वर 5%. म्हणजे 10% हे तुमचे *Active income* झाले आणि 7% आणि 5% हे तुमचे *Passive income* झाले. त्यामुळे तुमच्या downline ला जास्तीत जास्त मदत करा.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;तुम्ही कितीही ग्राहकांना, लीडर्स बनऊन तुमच्या सोबत व्यवसाय करायला लाऊ शकतात. आणि त्यांनी केलेल्या कामा वर ही प्रॉफिट कमावु शकतात.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;तुम्ही दिलेली प्रॉडक्ट ची ऑर्डर तुम्हीं "My Orders" मध्ये जाऊन बघू शकता. तिथेच ट्रॅकिंग नंबर तुम्हाला मिळेल. आणि पत्ता सुधारणा करायचा असेल तर तिथेच करू शकता.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;आपले हप्त्या मध्ये ३ झूम मीटिंग असतात त्यात ज्यांना आपला व्यवसाय समजुन घ्यायचा असतो ते समजुन सांगितल्या जाते. व व्यवसाय कसा मोठं करायचा, मार्केटिंग कशी करायची, ग्राहकाला कसा समजुन सांगायचं, अश्या इतर भरपूर विषयावर मार्गदर्शन दिली जाते. जे आपला घर शिवशाही चा व्यवसाय करून चालवताय ते त्यांचे अनुभव सांगतात. मीटिंग चे update तुम्हाला update ग्रुप मध्ये मिळतात.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;वस्तु अनवधानाने चुकीचे गेल्यास प्रॉडक्ट बदलून मिळेल. परंतु वस्तू रद्द होणार नाही अथवा पैसे परत होणार नाहीत.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;सोशल मीडिया चा वापर करून ग्राहक कसे मिळवायचे. त्या वर Marketing कश्या पद्धतीने केली पाहिजे त्याचे टिप्स सुद्धा आम्ही तुम्हाला देत राहु.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;आम्ही शिवशाही नेटवर्क का सुरू केलंय व कंपनी बाबत अधिक माहिती हवी असेल तर *About Us* मध्ये जाऊन संपूर्ण माहिती वाचा.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;कोणाला काही अजुन शंका असेल, व आपल्या अंडर जॉइन करण्यासाठी अडचण येत असेल तर हेल्पलाइन नंबर वर कॉल करू शकता. +91 8180070617</li>
                            <li className="pt-1">❈&nbsp;&nbsp;आमचे ध्येय – महाराष्ट्रातील प्रत्येक व्यक्ती ला व्यावसायिक बनवणे आणि व्यवसाय करायला शिकवणे.</li>
                        </ul>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Link to={`${process.env.PUBLIC_URL}/shop`} className="btn btn-outline-primary mt-2 mb-4 cust-btn"><span>Get Started</span><i className="icon-long-arrow-right"></i></Link>
                        </div>
                    </div>

                    <div className="container all-bg-light mb-5">
                        <Accordion adClass="accordion-plus">
                            {faq_data.faqs.map((faq, index) =>
                                <Card title={faq.question} adClass="card-box card-sm all-bg-light">{faq.answer}</Card>
                            )}
                        </Accordion>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Link to={`${process.env.PUBLIC_URL}/faq`} className="btn btn-outline-primary mt-2 mb-4 cust-btn"><span>अधिक प्रश्नांसाठी क्लिक करा</span><i className="icon-long-arrow-right"></i></Link>
                        </div>
                    </div>

                    <div className="container all-bg-light text-center mb-5">
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <ul className="pt-3 pb-3 all-font col-lg-8 col-md-9 col-sm-10">
                                <li>ही झाली व्यवसाय बद्दल माहिती. आता सत्य परिस्थिती मध्ये ह्या व्यवसाय मध्ये. तुम्हाला पैशा सोबत मिळते तुमची ओळख. तुम्हीं कोणाचे तरी लीडर असतात. तुमचे पूर्ण महाराष्ट्र भर ओळखी बनतात. तुमच्या लोकान मध्ये तुमची स्वतःची एक ओळख बनते.</li>
                                <li className="pt-2">सर्वात महत्वाचा तुम्हीं कस्टमर कडून 10% कमवताय म्हणजे म्हणजे किती कमी आहे हे बघू नका.</li>
                                <li className="pt-2">तुम्हीं त्या पलीकडे जाऊन विचार करा. जेव्हा ते पुढे १० लोकांना जॉईन करून सेल करतील तेव्हा आपल्याला न काही करता प्रॉफिट भेटणार आहे त्या कडे लक्ष द्या.</li>
                                <li className="pt-2">तुम्हीं positive असाल शिवशाही बद्दल तरच तुम्हीं जॉईन करा.  उगीच स्वतःला जबरदस्ती करून उपयोग नाही होणार. शिवशाही ही फक्त कंपनी नसुन, आमच्या लीडर्स च्या जिव्हाळा ची गोष्ट आहे.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="container all-bg-light text-center">
                        <h1 className="text-center mb-3 pt-3 all-font-light">Take Action Now</h1>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <ul className="pt-3 pb-3 all-font col-lg-8 col-md-9 col-sm-10">
                                <li>जर तुम्ही विचार करत असाल कि मि हा बिज़नेस माज्या जवळच्या लोकांना दाखवून बगतो/बगते, ते काय म्हणतील. ज्यांना खरंच काही करायचं नाहीये ते पहिले तुम्हाला निगेटिव प्रश्न विचारतील आणि तुम्हाला निगेटिव बनवण्याचा प्रयत्न करतील.</li>
                                <li className="pt-2">तुम्ही अभिमानाने सांगा की नेटवर्क मार्केटिंग बिजनेस आहे. आणि खूप चांगला आहे कारण या बिजनेस ने अमेरिकेमध्ये खूप सारे मिलेनियर, बिलिनिइयर्स बनवले आहे.</li>
                                <li className="pt-2">मग ते तुम्हाला विचारतात की तुम्ही ह्या बिज़नेस मध्ये जॉइन आहेत का?? जर तुम्ही जॉइन नसाल तर तुम्ही काहीच बोलू शकत नाही जर जॉइन असाल तर तुम्ही अभिमानने सांगू शकता कि हो मि जॉइन आहे, मला दर महीना एवढा प्रोफिट होतो,म्हणून आधि तुम्ही या बिज़नेस ला जॉइन असल पाहिजे जर तुम्ही जॉइन असाल तरच तुमच्या समोरच्या व्यक्ति वर इम्प्रेशन पड़ते</li>
                                <li className="pt-2">आणि होय तुम्हाला लाजायची बिलकुल गरज नाही,तुम्ही फक्त प्रोडक्ट विकत नाही आहात. तुम्ही समोरच्याला सेकंडरी इनकम सोर्स तयार करून देत आहात.</li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className="cta cta-display bg-image pt-4 pb-4" style={{ backgroundImage: `url(assets/images/backgrounds/bg-7.jpg)` }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-9 col-xl-7">
                                <div className={`row no-gutters ${isIEBrowser() ? '' : 'flex-column'} flex-sm-row align-items-sm-center`} >
                                    <div className="col">
                                        <h3 className="cta-title text-white">आपल्याकडे अधिक प्रश्न असल्यास</h3>
                                        <p className="cta-desc text-white">आमच्याशी संपर्क साधा मोकळ्या मनाने</p>
                                    </div>

                                    <div className="col-auto">
                                        <Link to={`${process.env.PUBLIC_URL}/faq`} className="btn btn-outline-white"><span>Ask Us</span><i className="icon-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BusinessOpportunity;