import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { quantityInputs, isIEBrowser, isEdgeBrowser, findIndex } from './../../utils/utils';

import * as userApi from './../../api/user'

const ProductDetail = ({
    adClass = "",
    type = 'default',
    onAddToCartClick,
    product,
    currency,
    wishlist,
    getDetailedProduct,
    onToggleWishlistClick,
} = this.props) => {
    const [selectedProductSize, setSelectedProductSize] = useState(
        product.variants ? JSON.parse(product.variants)[0].size : ""
    );
    const [selectedProductStock, setSelectedProductStock] = useState(
        product.variants ? JSON.parse(product.variants)[0].stock : ""
    );
    const [quantityCount, setQuantityCount] = useState(1);

    quantityInputs();

    const onSizeChange = (e) => {
        setSelectedProductSize(e.target.value)
        setSelectedProductStock(JSON.parse(product.variants)[e.target.options.selectedIndex].stock)
        setQuantityCount(1)
    };

    const toggleWishListHandler = (e) => {
        e.preventDefault();

        const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer
        if (usertokenShivshahiCustomer != null) {

            var data = {
                userToken: usertokenShivshahiCustomer,
                wishlistItem: product
            }

            userApi.addToWishlist(data)
                .then(async res => {
                    if (res.status == 200) {
                        // if (wishlist) {
                        //     window.location = '/wishlist';
                        // } else {
                        onToggleWishlistClick(product, wishlist)
                        // }
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Session Expired..Please Login Again..!')
                        localStorage.clear()
                        window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    } else {
                        alert('Something Went Wrong')
                        localStorage.clear()
                        window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    }
                })
                .catch((err) => {
                    alert('Something Went Wrong')
                    localStorage.clear()
                    window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                })
        } else {
            window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
        }

    }

    const addToCartHandler = (e) => {
        e.preventDefault();
        const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer
        if (usertokenShivshahiCustomer != null) {

            var data = {
                userToken: usertokenShivshahiCustomer,
                cartlistItem: product
            }
            data.cartlistItem.size = 6
            data.cartlistItem.qty = parseInt(document.querySelector("#qty").value)
            data.cartlistItem.sum = parseInt(document.querySelector("#qty").value) * parseInt(product.price)

            userApi.addToCartlist(data)
                .then(async res => {
                    if (res.status == 200) {

                        onAddToCartClick(product, document.querySelector("#qty").value, 6)
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Session Expired..Please Login Again..!')
                        localStorage.clear()
                        window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    } else {
                        // alert('Something Went Wrong')
                        // localStorage.clear()
                        // window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    }
                })
                .catch((err) => {
                    // alert('Something Went Wrong')
                    // localStorage.clear()
                    // window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                })
        } else {
            window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
        }
    }


    var totalStock = 0;

    {
        JSON.parse(product.variants).map((item, index) => (
            totalStock = totalStock + parseInt(item.stock)
        ))
    }

    return (

        <div className={`product-details ${adClass}`}>
            <h1 className="product-title all-font">{product.name}</h1>

            <div className="ratings-container">
                <div className="ratings">
                    <div className="ratings-val" style={{ width: product.ratings * 20 + '%' }}></div>
                </div>
                {/* <Link className="ratings-text" to="#product-review-link" id="review-link">( {product.reviews} Reviews )</Link> */}
            </div>

            {/* {0 === parseInt(product.stock) ?
                <div className="product-price">
                    <span className="out-price">${product.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                </div> :

                0 < product.discount ?
                    <div className="product-price">
                        <span className="new-price">${product.salePrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                        <span className="old-price">${product.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div> : */}
            <div className="product-price">{currency.currencySymbol}&nbsp;{(product.price * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
            {/* } */}

            <div className="product-content">
                <p>{product.shortDesc}</p>
            </div>

            <div className="details-filter-row details-row-size" style={{ display: `${totalStock ? '' : 'none'}` }}>
                <label htmlFor="size">Size:</label>
                <div className="select-custom">
                    <select name="size" id='size' className="form-control all-font" style={{ backgroundColor: 'transparent' }} onChange={onSizeChange}>
                        {JSON.parse(product.variants).map((item, index) => (
                            <option className="all-font all-bg" value={item.size} key={index}> {item.size} Yards</option>
                        ))}
                    </select>
                </div>

                {/* {'default' === type ?
                        <Link to="#" className="size-guide"><i className="icon-th-list"></i>size guide</Link> :
                        ''} */}
            </div>

            <div className="details-filter-row details-row-size mb-0" style={{ display: `${totalStock ? `${selectedProductStock ? '' : 'none'}` : `none`}` }}>
                <label htmlFor="qty">Qty:</label>
                <div className="product-details-quantity">
                    <div className="input-group input-spinner">
                        <div className="input-group-prepend">
                            <button style={{ minWidth: '26px' }} className="btn btn-decrement btn-spinner" type="button"
                                onClick={() =>
                                    setQuantityCount(quantityCount > 1 ? quantityCount - 1 : 1)
                                }
                            >
                                <i className="icon-minus all-font-hover"></i>
                            </button>
                        </div>
                        <input type="text" id='qty' style={{ textAlign: 'center', backgroundColor: '#212121' }} className="form-control " placeholder="" readOnly disabled value={quantityCount} />
                        <input type="text" id='stock' style={{ textAlign: 'center', backgroundColor: '#212121', display: 'none' }} className="form-control " placeholder="" readOnly disabled value={selectedProductStock} />
                        <div className="input-group-append">
                            <button style={{ minWidth: '26px' }} className="btn btn-increment btn-spinner" type="button"
                                onClick={() =>
                                    setQuantityCount(quantityCount < selectedProductStock && selectedProductStock != 0 ? quantityCount + 1 : quantityCount)
                                }
                            >
                                <i className="icon-plus all-font-hover"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <p className='mb-2' style={{ color: '#f54545' }}>Only <strong>{selectedProductStock}</strong> left in stock!</p>

            <div className="details-filter-row details-row-size">
                <label htmlFor="qty">Color:</label>
                <div className="color-display" style={{ backgroundColor: product.color }} />
            </div>

            <div className="product-details-action">

                {isIEBrowser() || isEdgeBrowser() ?
                    <button className="btn-product btn-cart" onClick={addToCartHandler} style={{ minHeight: "4rem", display: `${totalStock ? `${selectedProductStock ? '' : 'none'}` : `none`}` }}><span>add to cart</span></button>
                    :
                    <button className="btn-product btn-cart" style={{ display: `${totalStock ? `${selectedProductStock ? '' : 'none'}` : `none`}` }} onClick={addToCartHandler}><span>add to cart</span></button>
                }

                {isIEBrowser() || isEdgeBrowser() ?
                    <div className="details-action-wrapper IE-detail-action-wrapper">
                        <button className={`btn-product btn-wishlist ${wishlist ? 'added-to-wishlist' : ''}`}
                            onClick={toggleWishListHandler}
                            title={wishlist ? "Remove from wishlist" : "Add to wishlist"}
                            style={{ minHeight: isIEBrowser() ? '20px' : 'auto', minWidth: "127px" }}
                        >
                            <span>{wishlist ? "Remove from Wishlist" : "Add to Wishlist"}</span>
                        </button>
                    </div> :
                    <div className="details-action-wrapper">
                        <button className={`btn-product btn-wishlist ${wishlist ? 'added-to-wishlist' : ''}`}
                            onClick={toggleWishListHandler}
                            title={wishlist ? "Remove from wishlist" : "Add to wishlist"}
                            style={{ minHeight: isIEBrowser() ? '20px' : 'auto' }}
                        >
                            <span>{wishlist ? "Remove from Wishlist" : "Add to Wishlist"}</span>
                        </button>
                    </div>
                }

            </div>

        </div >

    );
}

const mapStateToProps = (state, ownprops) => {
    let wishlist;

    if (findIndex(state.wishlist.list, item => item.id == ownprops.product.id) !== -1) {
        wishlist = true;
    } else {
        wishlist = false;
    }

    return {
        showModal: state.data.quickView,
        detailedProduct: state.data.detailedProduct,
        wishlist: wishlist,
        currency: state.currencyData,
    };
}

export default connect(mapStateToProps)(ProductDetail);