import axios from 'axios';

export const createUser = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/authrequest/createuser`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const accountVerification = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/authrequest/accountverification`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const login = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/authrequest/login`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const sendPassResetLink = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/authrequest/sendpassresetlink`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const resetPassword = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/authrequest/resetpassword`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const verifyUser = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/authrequest/verifyuser`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};