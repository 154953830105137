import React from 'react';
import { Link } from 'react-router-dom';

import { safeContent } from './../../utils/utils';

export default (props) => {
    const { image_pc, image_mob, subtitle, title, link, shortdescrp, btnText = "Details" } = props.data;
    return (
        <div className="intro-slide" style={{ backgroundImage: props.newClass == 'small-hide' ? 'url(' + process.env.PUBLIC_URL + '/assets/images/slider/' + image_pc + ')' : 'url(' + process.env.PUBLIC_URL + '/assets/images/slider/' + image_mob + ')' }}>
            {title == null ?
                <div />
                :
                <div className="container intro-content">
                    <h3 className="intro-subtitle">{subtitle}</h3>
                    <h1 className="intro-title" style={{ color: '#ffbf80' }} dangerouslySetInnerHTML={safeContent(title)}></h1>
                    <h3 className="intro-subtitle">{shortdescrp}</h3>

                    <Link to={`${process.env.PUBLIC_URL}${link}`} className="btn btn-primary">
                        <span>{btnText}</span>
                        <i className="icon-long-arrow-right"></i>
                    </Link>
                </div>
            }
        </div>
    )
}