import React, { Component } from 'react';

class RechargePlanSingle extends Component {
    render() {
        const { id, plan, price, get, profit, upline1_profit, upline2_profit, shipping, shipping2, support, support2, fame, fame2 } = this.props;
        return (
            <div className="recharge-plans-mobile">
                <h4>{plan}</h4>
                <h3>{price}</h3>
                <p><i className="icon-check" />&nbsp;&nbsp;{profit}</p>
                <p>{get}</p>
                <p><i className="icon-check" />&nbsp;&nbsp;{upline1_profit}</p>
                <p><i className="icon-check" />&nbsp;&nbsp;{upline2_profit}</p>
                <p style={{ display: id == 2 ? 'none' : '' }}><i className={id == 1 || id == 2 ? 'icon-close' : 'icon-check'} />&nbsp;&nbsp;{shipping}</p>
                <p style={{ display: id == 2 ? '' : 'none' }}><i className={id == 2 ? 'icon-check' : 'icon-close'} />&nbsp;&nbsp;{shipping2}</p>
                <p><i className={id == 1 || id == 2 ? 'icon-close' : 'icon-check'} />&nbsp;&nbsp;{support}</p>
                <p><i className={id == 4 ? 'icon-check' : 'icon-close'} />&nbsp;&nbsp;{support2}</p>
                <p><i className="icon-check" />&nbsp;&nbsp;{fame}</p>
                <p><i className={id == 1 ? 'icon-close' : 'icon-check'} />&nbsp;&nbsp;{fame2}</p>
            </div>
        );
    }
}

export default RechargePlanSingle;