import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { fetchOrders, fetchReturnExchangeRequests } from './../../../api/order';

import MyAccountHeader from './../../components/my-account-header'

class Orders extends Component {

    constructor(props) {
        super(props)

        this.state = {
            orders: [],
            filteredOrders: [],
            returnExchangeRequests: [],
        }
    }

    componentDidMount = async () => {
        document.body.classList.add('all-bg');

        var checkusertoken = localStorage.getItem('usertokenShivshahiCustomer');
        // console.log(checkusertoken)
        if (checkusertoken == null) {
            this.props.history.push(`${process.env.PUBLIC_URL}/auth/login-register/:data`)
        } else {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer

            const data = {
                userToken: usertokenShivshahiCustomer,
            }

            fetchOrders(data)
                .then(res => {
                    if (res.status == 200) {
                        this.setState({
                            orders: (res.data).reverse(), filteredOrders: res.data
                        }, () => {
                            spinner.style.display = "none";
                        })
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Please Login Again...!')
                        // localStorage.removeItem('usertokenShivshahiCustomer')
                        localStorage.clear();
                        window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                })

        }
    }

    componentWillUnmount() {
        document.body.classList.remove('all-bg');
    }

    handleChangeDefaultIndex = (index) => {

        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";

        const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer

        const data = {
            userToken: usertokenShivshahiCustomer,
        }

        if (index == 0) {
            fetchOrders(data)
                .then(res => {
                    if (res.status == 200) {
                        this.setState({
                            orders: (res.data).reverse(), filteredOrders: res.data
                        }, () => {
                            spinner.style.display = "none";
                        })
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Please Login Again...!')
                        // localStorage.removeItem('usertokenShivshahiCustomer')
                        localStorage.clear();
                        window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                })

        } else if (index == 1) {
            fetchReturnExchangeRequests(data)
                .then(res => {
                    if (res.status == 200) {
                        this.setState({
                            returnExchangeRequests: (res.data).reverse(),
                        }, () => {
                            spinner.style.display = "none";
                        })
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Please Login Again...!')
                        // localStorage.removeItem('usertokenShivshahiCustomer')
                        localStorage.clear();
                        window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                })
        }
    }

    searchOrders = (e) => {
        var filteredOrders = []
        if (this.state.orders.length && e.target.value > 9999) {
            filteredOrders = this.state.orders.filter(order => order.id == parseInt(e.target.value) - 9999)
            this.setState({ filteredOrders: filteredOrders })
        } else {
            this.setState({ filteredOrders: this.state.orders })
        }

    }

    render() {
        const { currency } = this.props;

        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

        return (
            <div className="main">

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="page-content mt-3">
                    <div className="dashboard">
                        <div className="container">

                            <MyAccountHeader />

                            <h3 className='text-center all-font mb-3'>Orders & Exchange</h3>

                            <Tabs selectedTabClassName="show" onSelect={(index) => this.handleChangeDefaultIndex(index)}>
                                <TabList className="nav nav-pills nav-fill">
                                    <Tab className="nav-item all-font">
                                        <span className="nav-link">Orders</span>
                                    </Tab>

                                    <Tab className="nav-item all-font">
                                        <span className="nav-link">Exchange</span>
                                    </Tab>
                                </TabList>

                                <div className="tab-content">
                                    <TabPanel style={{ paddingTop: "2rem" }}>
                                        <div className="mt-2 mb-2 col-lg-6 col-md-6 col-sm-12">
                                            <label htmlFor='cfoOrderNumber'>Enter the Order Number to search</label>
                                            <div className="input-group">
                                                <input type="number" className="form-control" id='cfoOrderNumber' aria-label="cfoOrderNumber" aria-describedby="cfoOrderNumber" onChange={this.searchOrders} />
                                            </div>
                                        </div>
                                        <div className="order-list">
                                            {this.state.filteredOrders.length > 0 ?
                                                this.state.filteredOrders.map((item, index) =>

                                                    <div key={index}>
                                                        <div className="left">
                                                            <h6>Order ID : &nbsp;&nbsp;<span>{parseInt(item.id) + 9999}</span></h6>
                                                            <h6>Order Date : &nbsp;&nbsp;<span>{days[new Date(item.order_date).getDay()] + ', ' + monthNames[new Date(item.order_date).getMonth()] + ' ' + new Date(item.order_date).getDate() + ', ' + new Date(item.order_date).getFullYear()}</span></h6>
                                                            <h6>Total : &nbsp;&nbsp;<span>{currency.currencySymbol}&nbsp;{(item.total * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span><span style={{ color: 'red', display: `${item.payment_status == 'Completed' ? 'none' : item.status == 'Cancelled' ? 'none' : ''}` }}> {`{Payment Pending}`}</span></h6>
                                                            <h6 className='other-yes'>Order Status : &nbsp;&nbsp;<span>{item.status}</span></h6>
                                                            <Link to={`${process.env.PUBLIC_URL}/order-details/${item.order_id}`} className="btn btn-outline-white mt-2 other-yes"><span>View Order Details</span><i className="icon-long-arrow-right"></i></Link>
                                                        </div>

                                                        <div className="right other-none">
                                                            <Link to={`${process.env.PUBLIC_URL}/order-details/${item.order_id}`} className="btn btn-outline-white mb-3"><span>View Order Details</span><i className="icon-long-arrow-right"></i></Link>
                                                            {item.status == 'Placed' || item.status == 'Processed' || item.status == 'Packed' || item.status == 'Shipped' || item.status == 'Delivered' ?
                                                                <ul className="container">
                                                                    <li title="Order Placed" className={`link ${item.status == 'Placed' ? 'active' : 'active-done'}`}></li>
                                                                    {/* <li title="Order Processed" className={`link ${item.status == 'Processed' ? 'active' : item.status == 'Placed' ? '' : 'active-done'}`}></li> */}
                                                                    <li title="Order Packed/Processed" className={`link ${item.status == 'Packed' ? 'active' : item.status == 'Processed' ? 'active' : item.status == 'Placed' ? '' : 'active-done'}`}></li>
                                                                    <li title="Order Shipped" className={`link ${item.status == 'Shipped' ? 'active' : item.status == 'Packed' ? '' : item.status == 'Processed' ? '' : item.status == 'Placed' ? '' : 'active-done'}`}></li>
                                                                    <li title="Order Delivered" className={`link ${item.status == 'Delivered' ? 'active' : ''}`}></li>
                                                                </ul>
                                                                :
                                                                <h5>{item.status == 'Return in Progress' ? 'Return in Progress' : item.status == 'Exchange in Progress' ? 'Exchange in Progress' : item.status == 'On Hold' ? 'Order On Hold' : 'Order Cancelled'}</h5>
                                                            }
                                                        </div>
                                                    </div>

                                                )
                                                :
                                                <div className="empty-list">
                                                    <p>No order has been made yet.</p>
                                                    <Link to={`${process.env.PUBLIC_URL}/shop`} className="btn btn-outline-primary-2"><span>GO TO SHOP</span><i className="icon-long-arrow-right"></i></Link>
                                                </div>
                                            }
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="order-list">
                                            {this.state.returnExchangeRequests.length > 0 ?
                                                this.state.returnExchangeRequests.map((item, index) =>

                                                    <div key={index}>
                                                        <div className="left">
                                                            <h6>Order ID : &nbsp;&nbsp;<span>{parseInt(JSON.parse(item.order_details).id) + 9999}</span></h6>
                                                            <h6>Request Date : &nbsp;&nbsp;<span>{days[new Date(item.date).getDay()] + ', ' + monthNames[new Date(item.date).getMonth()] + ' ' + new Date(item.date).getDate() + ', ' + new Date(item.date).getFullYear()}</span></h6>
                                                            <h6>Request : &nbsp;&nbsp;<span style={{ color: '#c96' }}>{item.request}</span></h6>
                                                            <h6 className='other-yes'>Status : &nbsp;&nbsp;<span>{item.status}</span></h6>
                                                            <Link to={{ pathname: `${process.env.PUBLIC_URL}/request-details`, state: { id: item.id } }} className="btn btn-outline-white mt-2 other-yes"><span>View Request Details</span><i className="icon-long-arrow-right"></i></Link>
                                                        </div>

                                                        <div className="right other-none">
                                                            <Link to={{ pathname: `${process.env.PUBLIC_URL}/request-details`, state: { id: item.id } }} className="btn btn-outline-white mb-3"><span>View Request Details</span><i className="icon-long-arrow-right"></i></Link>
                                                            <h6>Status: &nbsp;&nbsp;<span>{item.status}</span></h6>
                                                        </div>
                                                    </div>

                                                )
                                                :
                                                <div className="empty-list">
                                                    <p>No request has been made yet.</p>
                                                </div>
                                            }
                                        </div>
                                    </TabPanel>
                                </div>
                            </Tabs>

                        </div>
                    </div >
                </div >
            </div >
        )
    }
}

export const mapStateToProps = (state) => ({
    currency: state.currencyData,
})

export default connect(mapStateToProps)(Orders);