import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class PageNotFound extends Component {
    render() {
        return (
            <div className="main">
                <Helmet>
                    <title>Shivshahi Network</title>
                </Helmet>

                <h1 className="d-none">Shivshahi Network</h1>

                <div className="error-content text-center" style={{ backgroundImage: "url(assets/images/backgrounds/error-bg.jpg)", position: 'relative' }}>
                    <div className="overlay-404">
                        <div className="container">
                            <h1 className="error-title mt-10" style={{color: '#c96'}}>Error 404</h1>

                            <p style={{color: '#fff'}}>We are sorry, the page you've requested is not available.</p>
                            <Link to="/" className="btn btn-outline-primary-2 custom-btn-primary-2 btn-minwidth-lg">
                                <i className="icon-long-arrow-left" />
                                <span>BACK TO HOMEPAGE</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PageNotFound;
