import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputRange from 'react-input-range';
import { SlideToggle } from 'react-slide-toggle';
// import { Link } from 'react-router-dom';
import { Filter } from 'react-feather'

import 'react-input-range/lib/css/index.css';

// import _data from './../../data/categories.json';

import { getCountByCategory } from './../../services';
import { toggleCategoryFilter, resetFilter, toggleSizeFilter, toggleBrandFilter, toggleColorFilter, filterPrice } from './../../actions';
import { findIndex } from './../../utils/utils';

class ShopSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: { max: 10000, min: 0 },
            toggle: false
        };

        this.resizeHandle = this.resizeHandle.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.resizeHandle);
        this.resizeHandle();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeHandle);
    }

    resizeHandle() {
        if (document.querySelector("body").offsetWidth < 992)
            this.setState({ toggle: true });
        else
            this.setState({ toggle: false });
    }

    changeIcon(e) {
        e.target.classList.toggle('collapsed');
    }

    hideSideBar() {
        if (document.querySelector('body').classList.contains('sidebar-filter-active'))
            document.querySelector('body').classList.remove('sidebar-filter-active');
    }

    toggleSideBar() {
        if (document.querySelector('body').classList.contains('sidebar-filter-active'))
            document.querySelector('body').classList.remove('sidebar-filter-active');
        else
            document.querySelector('body').classList.add('sidebar-filter-active');
    }

    getWidth() {
        return document.querySelector("body").offsetWidth;
    }

    clearAll(e) {
        let items = document.querySelector('.sidebar-shop').querySelectorAll('input');
        for (let i = 0; i < items.length; i++) {
            items[i].checked = false;
        }

        items = document.querySelector('.sidebar-shop').querySelectorAll('.selected');

        for (let i = 0; i < items.length; i++) {
            items[i].classList.remove('selected');
        }

        this.setState({ value: { max: 10000, min: 0 } });
        this.props.resetFilter();
        e.preventDefault();
    }

    toggleSideBarFn(e) {
        e.preventDefault();
        this.toggleSideBar()
    }

    selectColor(e) {
        e.currentTarget.classList.toggle('selected');
        e.preventDefault();
    }

    render() {
        const sizes = ["6", "9", "11"];
        const brands = ["Next", "River Island", "Geox", "New Balance", "UGG", "F&F", "Nike"];
        const colors = ["#b87145", "#f0c04a", "#333333", "#cc3333", "#3399cc", "#669933", "#f2719c", "#ebebeb"];
        let categoryCountArr = [];
        const { filters, products, currency } = this.props;

        this.props.cats.map((item, index) => {
            return categoryCountArr.push(getCountByCategory(products, item.category))
        });

        return (
            <React.Fragment>
                <aside className={`${this.state.toggle ? 'sidebar-filter all-bg' : 'sidebar'} sidebar-shop`}>
                    <div className={this.state.toggle ? 'sidebar-filter-wrapper' : ''}>
                        <div className="widget widget-clean">
                            <label>Filters:</label>
                            <a href="#a" className="sidebar-filter-clear" onClick={(e) => this.clearAll(e)}>Clear All</a>
                            <p className="sidebar-filter-clear" onClick={this.toggleSideBar}>close</p>
                        </div>

                        <SlideToggle collapsed={false}>
                            {({ onToggle, setCollapsibleElement }) => (
                                <div className="widget widget-collapsible">
                                    <h3 className="widget-title"><a href="#category" onClick={(e) => { onToggle(e); this.changeIcon(e); e.preventDefault() }} role="button" aria-expanded="true" aria-controls="widget-1">Category</a></h3>
                                    <div ref={setCollapsibleElement}>
                                        <div className="widget-body">
                                            <div className="filter-items filter-items-count">
                                                {
                                                    this.props.cats.map((item, index) =>

                                                        <div className="filter-item" key={item.id}>
                                                            <div className="custom-control custom-checkbox">

                                                                <input type="checkbox"
                                                                    className="custom-control-input"
                                                                    id={`cat-${index + 1}`}
                                                                    onClick={(e) => this.props.toggleCategoryFilter(item.category)}
                                                                    defaultChecked={-1 < findIndex(filters['category'], filter => filter == item.category) ? true : false}
                                                                />

                                                                <label className="custom-control-label" htmlFor={`cat-${index + 1}`}><span className="all-font-dark">{item.category}</span></label>

                                                            </div>
                                                            <span className="item-count" style={{ backgroundColor: 'black' }}>{categoryCountArr[index]}</span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </SlideToggle>

                        {/* <SlideToggle collapsed={false}>
                            {({ onToggle, setCollapsibleElement }) => (
                                <div className="widget widget-collapsible">
                                    <h3 className="widget-title"><a href="#Size" onClick={(e) => { onToggle(e); this.changeIcon(e); e.preventDefault() }} role="button" aria-expanded="true" aria-controls="widget-1">Size</a></h3>
                                    <div ref={setCollapsibleElement}>
                                        <div className="widget-body">
                                            <div className="filter-items">
                                                {sizes.map((item, index) => (

                                                    <div className="filter-item" key={index}>
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox"
                                                                className="custom-control-input"
                                                                id={`size-${index + 1}`}
                                                                onClick={(e) => this.props.toggleSizeFilter(item)}
                                                                defaultChecked={-1 < findIndex(filters['size'], filter => filter === item) ? true : false}
                                                            />
                                                            <label className="custom-control-label" htmlFor={`size-${index + 1}`}><span className="all-font-dark">{item} Yards</span></label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </SlideToggle> */}

                        {/* <SlideToggle collapsed={false}>
                            {({ onToggle, setCollapsibleElement }) => (
                                <div className="widget widget-collapsible">
                                    <h3 className="widget-title"><a href="#colour" onClick={(e) => { onToggle(e); this.changeIcon(e); e.preventDefault() }} role="button" aria-expanded="true" aria-controls="widget-1">Colour</a></h3>
                                    <div ref={setCollapsibleElement}>
                                        <div className="widget-body">
                                            <div className="filter-colors">
                                                {
                                                    colors.map((item, index) => (
                                                        <Link to="#" className={-1 < findIndex(filters['color'], filter => filter === item) ? "selected" : ""} style={{ background: item }} onClick={(e) => { this.props.toggleColorFilter(item); e.preventDefault(); }} key={index}>
                                                            <span className="sr-only">Color Name</span>
                                                        </Link>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </SlideToggle> */}

                        {/* <SlideToggle collapsed={false}>
                            {({ onToggle, setCollapsibleElement }) => (
                                <div className="widget widget-collapsible">
                                    <h3 className="widget-title"><a href="#brand" onClick={(e) => { onToggle(e); this.changeIcon(e); e.preventDefault() }} role="button" aria-expanded="true" aria-controls="widget-1">Brand</a></h3>
                                    <div ref={setCollapsibleElement}>
                                        <div className="widget-body">
                                            <div className="filter-items">
                                                {brands.map((item, index) => (

                                                    <div className="filter-item" key={index}>
                                                        <div className="custom-control custom-checkbox">

                                                            <input type="checkbox"
                                                                className="custom-control-input"
                                                                id={`brand-${index + 1}`}
                                                                onClick={(e) => this.props.toggleBrandFilter(item)}
                                                                defaultChecked={-1 < findIndex(filters['brand'],
                                                                    filter => filter === item) ? true : false}
                                                            />
                                                            <label className="custom-control-label" htmlFor={`brand-${index + 1}`}>{item}</label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </SlideToggle> */}

                        <SlideToggle collapsed={false}>
                            {({ onToggle, setCollapsibleElement }) => (
                                <div className="widget widget-collapsible">
                                    <h3 className="widget-title">
                                        <a href="#price" onClick={(e) => { onToggle(e); this.changeIcon(e); e.preventDefault() }} role="button" aria-expanded="true" aria-controls="widget-1">Price</a>
                                    </h3>

                                    <div ref={setCollapsibleElement}>
                                        <div className="widget-body">
                                            <div className="filter-price">
                                                <div className="filter-price-text">
                                                    <span className="all-font">Price Range:&nbsp;&nbsp;&nbsp;</span>
                                                    <span className="filter-price-range">{currency.currencySymbol}&nbsp;{(this.state.value.min * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })} - {currency.currencySymbol}&nbsp;{(this.state.value.max * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</span>
                                                </div>

                                                <div className="price-slider">
                                                    <InputRange
                                                        formatLabel={value => `${currency.currencySymbol}${value}`}
                                                        maxValue={10000}
                                                        minValue={0}
                                                        step={50}
                                                        value={this.state.value}
                                                        onChange={value => { this.setState({ value }); this.props.filterPrice(value); }} key={this.state.max} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </SlideToggle>
                    </div>
                </aside>
                { this.state.toggle ?
                    <button className="sidebar-fixed-toggler" onClick={this.toggleSideBar}>Filters<Filter /></button> :
                    ''
                }
                <div className="sidebar-filter-overlay" onClick={this.hideSideBar}></div>
            </React.Fragment>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        filters: state.filters,
        products: state.data.products ? state.data.products : [],
        currency: state.currencyData,
        cats: state.data.cats,
    }
}

export default connect(mapStateToProps, { toggleCategoryFilter, toggleSizeFilter, toggleBrandFilter, toggleColorFilter, filterPrice, resetFilter })(ShopSidebar);