import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import * as userApi from './../../../api/user'

class BaseProduct extends Component {

    constructor(props) {
        super(props);

        this.addToCart = this.addToCart.bind(this);
        this.showQuickView = this.showQuickView.bind(this);
        this.addToCompareList = this.addToCompareList.bind(this);
        this.toggleWishList = this.toggleWishList.bind(this);
        this.getDetailedProduct = this.getDetailedProduct.bind(this);
    }


    getDetailedProduct(e) {
        const { product, getDetailedProduct } = this.props;
        getDetailedProduct(product.id);
        e.preventDefault();
    }

    showProductImgSection() {
        const { product } = this.props;

        return (
            <div onClick={this.getDetailedProduct} >
                <Link to={`${process.env.PUBLIC_URL}/shop/detail/${product.id}`} >
                    <img src={`${process.env.PUBLIC_URL}/${JSON.parse(product.pictures)[0]}`} alt="Product" className="product-image" />
                    {JSON.parse(product.pictures)[1] ? <img src={`${process.env.PUBLIC_URL}/${JSON.parse(product.pictures)[1]}`} alt="Product" className="product-image-hover" /> : ''}
                </Link>
            </div>
        )
    }

    showFadeImage() {
        const { product } = this.props;

        return (

            <Link to={`${process.env.PUBLIC_URL}/shop/detail/${product.id}`} >
                <img src={`${process.env.PUBLIC_URL}/assets/images/demos-img/lazy.png`} data-oi={process.env.PUBLIC_URL + '/' + JSON.parse(product.pictures)[0]} alt="Product" className="product-image molla-lz" />
                { JSON.parse(product.pictures)[1] ? <img src={`${process.env.PUBLIC_URL}/assets/images/demos-img/lazy.png`} data-oi={process.env.PUBLIC_URL + '/' + JSON.parse(product.pictures)[1]} alt="Product" className="product-image-hover molla-lz" /> : ''}
            </Link>

        )
    }

    // showProductCatSection() {
    //     const { product } = this.props;

    //     return (
    //         <div className="product-cat">
    //             {product.category.map((cat, index) => (
    //                 <span key={index} className="mr-0">
    //                     <Link to="#">{cat}</Link>
    //                     {index < product.category.length - 1 ? ', ' : ''}
    //                 </span>
    //             ))}
    //         </div>
    //     )
    // }

    showProductName() {
        const { product } = this.props;

        return (
            <h3 className="product-title all-font-hover" onClick={this.getDetailedProduct}>
                <Link to={`${process.env.PUBLIC_URL}/shop/detail/${product.id}`} >{product.name}</Link>
            </h3>
        );
    }

    showProductPrice(oldLabel = "Was", newLabel = "") {
        const { product, currency } = this.props;

        return (
            // 0 === product.stock ?
            //     <div className="product-price">
            //         <span className="out-price" style={{ color: '#c96' }}>${product.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
            //     </div> :

            //     0 < product.discount ?
            //         <div className="product-price">
            //             <span className="new-price" style={{ color: '#c96' }}>{newLabel} ${product.salePrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
            //             <span className="old-price" style={{ color: '#c96' }}>{oldLabel} ${product.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
            //         </div> :

            <div className="product-price mb-0" style={{ color: '#c96' }}>{currency.currencySymbol}&nbsp;{(product.price * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
        );
    }

    showProductRating(classNameAdded = "ratings") {
        const { product } = this.props;

        return (
            <div className="ratings-container">
                <div className={classNameAdded}>
                    <div className="ratings-val" style={{ width: product.ratings * 20 + '%' }}></div>
                </div>
                {/* <span className="ratings-text">( {product.reviews} Reviews )</span> */}
            </div>
        )
    }

    showProductVariants(colorType = "rgb") {
        const { product } = this.props;
        let trueType = colorType;
        if (colorType === "default")
            if (product.variants[0].image || product.variants[0].modal) trueType = "image";
            else trueType = "rgb";

        return (
            product.variants ?
                ("model" === trueType && product.variants[0].model) || ("image" === trueType && product.variants[0].image) ?
                    <div className="product-nav product-nav-thumbs">
                        {product.variants.map((vari, i) =>
                            <Link to="#" key={i} className={0 === i ? 'active' : ''}>
                                <img src={process.env.PUBLIC_URL + '/' + ("model" === trueType ? vari.model : vari.image)} alt="product desc" />
                            </Link>
                        )}
                    </div> :

                    "rgb" === trueType && product.variants[0].rgb ?
                        <div className="product-nav product-nav-dots">
                            {product.variants.map((vari, i) =>
                                <Link to="#" key={i} className={0 === i ? 'active' : ''} style={{ background: vari.rgb }}>
                                </Link>
                            )}
                        </div> : ''
                : ''
        )
    }

    addToCart(e) {
        e.preventDefault();
        const { product, onAddToCartClick } = this.props;

        const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer
        if (usertokenShivshahiCustomer != null) {

            var data = {
                userToken: usertokenShivshahiCustomer,
                cartlistItem: product
            }
            data.cartlistItem.size = 6
            data.cartlistItem.qty = 1
            data.cartlistItem.sum = product.price

            userApi.addToCartlist(data)
                .then(async res => {
                    if (res.status == 200) {
                        // if (0 !== parseInt(product.stock))
                        // onAddToCartClick(product, 1, product.variants[product.variants.findIndex(stockItem => parseInt(stockItem.stock) != 0)].size);
                        var SizeofStock = JSON.parse(product.variants)[JSON.parse(product.variants).findIndex(stockItem => stockItem.stock != 0)].size
                        onAddToCartClick(product, 1, SizeofStock);
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Session Expired..Please Login Again..!')
                        localStorage.clear()
                        window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    } else {
                        // alert('Something Went Wrong')
                        // localStorage.clear()
                        // window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    }
                })
                .catch((err) => {
                    // alert('Something Went Wrong')
                    // localStorage.clear()
                    // window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                })
        } else {
            window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
        }
    }

    addToCompareList(e) {
        const { product, onAddToCompareClick } = this.props;
        onAddToCompareClick(product);
        e.preventDefault();
    }

    toggleWishList(e) {
        e.preventDefault();
        const { product, wishlist, onToggleWishlistClick } = this.props;

        const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer
        if (usertokenShivshahiCustomer != null) {

            var data = {
                userToken: usertokenShivshahiCustomer,
                wishlistItem: product
            }

            userApi.addToWishlist(data)
                .then(async res => {
                    if (res.status == 200) {
                        // if (wishlist) {
                        //     window.location = '/wishlist';
                        // } else {
                        onToggleWishlistClick(product, this.props.wishlist);
                        // }
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Session Expired..Please Login Again..!')
                        localStorage.clear()
                        window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    } else {
                        alert('Something Went Wrong')
                        localStorage.clear()
                        window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    }
                })
                .catch((err) => {
                    alert('Something Went Wrong')
                    localStorage.clear()
                    window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                })
        } else {
            window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
        }

    }

    showQuickView(e) {
        const { product, showQuickViewModal } = this.props;
        // console.log("active element ", document.activeElement);
        showQuickViewModal(product.id);
        e.preventDefault();
    }

    showAddToCartBtn(classNameAdded = "btn-product btn-cart", btnText = "add to cart") {

        const { product } = this.props;

        var totalStock = 0;

        {
            JSON.parse(product.variants).map((item, index) => (
                totalStock = totalStock + parseInt(item.stock)
            ))
        }

        return (
            <button className={`${classNameAdded}`} id="addtocart-btn" onClick={this.addToCart} style={{ display: `${totalStock ? '' : 'none'}` }}>
                <span className="all-font-hover">{btnText}</span>
            </button>
        )
    }

    showAddToCompareBtn(classNameAdded = "btn-product btn-compare", btnText = "compare") {
        return (
            <button className={classNameAdded} title="Compare" onClick={this.addToCompareList}>
                <span>{btnText}</span>
            </button>
        )
    }

    showToggleWishlistBtn(classNameAdded = "btn-product-icon btn-wishlist") {
        return (
            <button className={`${classNameAdded} ${this.props.wishlist ? 'added-to-wishlist' : 'remove-from-wishlist'} all-bg-light`}
                onClick={this.toggleWishList}
                title={this.props.wishlist ? "Go to wishlist" : "Add to wishlist"}
            >
                <span>{this.props.wishlist ? "go to wishlist" : "add to wishlist"}</span>
            </button>
        )
    }

    showQuickViewBtn(classNameAdded = "btn-product-icon btn-quickview") {
        return (
            <button className={`${classNameAdded} all-bg-light`} title="Quick view" onClick={this.showQuickView}><span>Quick view</span></button>
        )
    }

    showToggleCompareBtn(classname = "btn-product-icon btn-compare") {
        return (
            <button className="btn-product-icon btn-compare" title="Compare" onClick={this.addToCompareList}><span>Compare</span></button>
        )
    }

    showQuickViewBtnWithIcon(classNameAdded = "btn-product btn-quickview", btnText = "quick view") {
        return (
            <button className={classNameAdded} title="Quick view" onClick={this.showQuickView}>
                <span>{btnText}</span>
            </button>
        )
    }
}

export default BaseProduct;