import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import PageHeader from './../../components/page-header';

import { isIEBrowser } from './../../../utils/utils';

class TermsOfService extends Component {
    render() {
        return (
            <div className="main">
                <Helmet>
                    <title>Shivshahi Network</title>
                </Helmet>

                <h1 className="d-none">Shivshahi Network</h1>

                <PageHeader title="Terms of Service" subTitle="Policy" />

                <div className="page-content pt-3 all-bg">
                    <div className="container all-bg-light mb-5">
                        <div className='pl-5 pr-5 pt-3 pb-3'>
                            <p className='all-font mb-3' style={{ textAlign: 'justify', whiteSpace: 'normal' }}>
                                &emsp;&emsp;&emsp;Welcome to SHIVSHAHI NETWORK. www.shivshahinetwork.com this website is owned and operated by SHIVSHAHI NETWORK.  a company incorporated under laws of India and having its office at  Shivshahi Paithani Kukkar lane,  Tal. Yeola, Maharashtra 423401.<span style={{ display: 'block', marginBottom: '1rem' }} />
                                &emsp;&emsp;&emsp;Please note that this business/commercial  site only can be use/operated by valid account  holder. Your use of the SHIVSHAHI NETWORK and services and tools are governed by the following terms and conditions ("Terms of Use"). By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”).<span style={{ display: 'block', marginBottom: '1rem' }} />
                                &emsp;&emsp;&emsp;Please read these Terms of Service carefully before accessing or using our website.  If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services.<span style={{ display: 'block', marginBottom: '1rem' }} />
                                &emsp;&emsp;&emsp;For the purpose of these Terms of Use, wherever the context so requires "You" or "User" shall mean any natural or legal person who has agreed to terms of service on Platform by providing data while registering on the Platform as Registered User. The term SHIVSHAHI, "We", "Us", "Our" shall refer to SHIVSHAHI NETWORK.<span style={{ display: 'block', marginBottom: '1rem' }} />
                            </p>

                            <h5 className='all-font-light'>Target Audience</h5>
                            <ul className='all-font mb-3'>
                                <li>This site is bounded to two categories of users</li>
                                <li>&emsp;&nbsp;1.&nbsp;&nbsp;SHIVSHAHI LEADERS</li>
                                <li>&emsp;2.&nbsp;&nbsp;CUSTOMERS</li>
                            </ul>

                            <h5 className='all-font-light'>Customer Amusement Programme</h5>
                            <p className='all-font mb-1' style={{ textAlign: 'justify', whiteSpace: 'normal' }}>
                                &emsp;&emsp;&emsp;We provide detailed description of products at reasonable price with HD photo quality for ease of  our customers. We ensure that all information provided on our site it's true. Customers will receive the product within 15 days of order. We observe an irreversible 100% Satisfaction Guarantee on our products, wherever specified.   consumers of   SHIVSHAHI products will be satisfied with their purchases.
                            </p>
                            <ul className='all-font mb-3'>
                                <li>According to information provided above</li>
                                <li>&emsp;❈&nbsp;&nbsp;If customer is not completely satisfied with our products he/she can exchange the product without any extra charges.</li>
                                <li>&emsp;❈&nbsp;&nbsp;SHIVSHAHI LEADERS  shall advice SHIVSHAHI  about customer complaint can provide any information and conversation related to complaint to SHIVSHAHI.</li>
                                <li>&emsp;❈&nbsp;&nbsp;SHIVSHAHI LEADERS Are authorized to remain answerable to customers. If  problem  does not solve by our leaders , then SHIVSHAHI will solve the problem if possible.</li>
                            </ul>

                            <h5 className='all-font-light'>Product Return Procedure</h5>
                            <p className='all-font mb-2' style={{ textAlign: 'justify', whiteSpace: 'normal' }}>
                                &emsp;&emsp;&emsp;The Company will accept return of products along with relevant Customer Order Receipt. We can only exchange the product can not refund the money.
                            </p>

                            <h5 className='all-font-light'>Product Return Conditions</h5>
                            <ul className='all-font mb-3'>
                                <li>&emsp;&nbsp;1.&nbsp;&nbsp;Customer must return the original product to SHIVSHAHI.</li>
                                <li>&emsp;2.&nbsp;&nbsp;Period of return of product is calculated as 7 days from delivered date of product.</li>
                                <li>&emsp;3.&nbsp;&nbsp;The customer should courier at his own expense.</li>
                                <li>&emsp;4.&nbsp;&nbsp;We cannot refund you the money but can ensure exchanging of products.</li>
                                <li>&emsp;5.&nbsp;&nbsp;Customers can choose same or different product of same amount or value.</li>
                                <li>&emsp;6.&nbsp;&nbsp;We can not accept used products.</li>
                            </ul>

                            <h5 className='all-font-light'>Insure</h5>
                            <p className='all-font mb-3' style={{ textAlign: 'justify', whiteSpace: 'normal' }}>
                                &emsp;&emsp;&emsp;All the materials and products  and services, included on or otherwise made available to You through Platform are  available” basis without any warranties. The information on Platform is complete, true, accurate or non-misleading.<span style={{ display: 'block', marginBottom: '1rem' }} />
                                &emsp;&emsp;&emsp;We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the service will be corrected.<span style={{ display: 'block', marginBottom: '1rem' }} />
                                &emsp;&emsp;&emsp;We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that it will shown as it is on your screen.<span style={{ display: 'block', marginBottom: '1rem' }} />
                                &emsp;&emsp;&emsp;The prices of our goods may change in the any kind of emergency conditions we are not determinate to notify it in advance.<span style={{ display: 'block', marginBottom: '1rem' }} />
                                &emsp;&emsp;&emsp;We shall not be liable to you or to any third-party for any modification, price change, suspension, or discontinuance of any product.<span style={{ display: 'block', marginBottom: '1rem' }} />
                                &emsp;&emsp;&emsp;We reserve the right to refuse any order you place with us. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made.<span style={{ display: 'block', marginBottom: '1rem' }} />
                            </p>

                            <h5 className='all-font-light'>Customer Information</h5>
                            <p className='all-font mb-3' style={{ textAlign: 'justify', whiteSpace: 'normal' }}>
                                &emsp;&emsp;&emsp;Any information or material submitted by you to the SHIVSHAHI website (whether through e-mail or otherwise) will be deemed non-proprietary and non-confidential, and may be used by SHIVSHAHI without restriction. Notwithstanding the foregoing, all personal data (e.g., name, address and telephone number) provided to SHIVSHAHI will be handled in accordance with SHIVSHAHI Privacy Policy.<span style={{ display: 'block', marginBottom: '1rem' }} />
                                &emsp;&emsp;&emsp;SHIVSHAHI is always improving its products and services and developing new features. If you have ideas regarding improvements or additions to the SHIVSHAHI, we would like to hear them. However, please note that under no circumstances shall any disclosure of any idea, related materials to SHIVSHAHI be subject to any obligation of confidentiality or expectation of compensation.<span style={{ display: 'block', marginBottom: '1rem' }} />
                                &emsp;&emsp;&emsp;If at our request, you send certain specific submissions or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, ‘comments’), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation To maintain any comments in confidence;   to pay compensation for any comments; or To respond to any comments.<span style={{ display: 'block', marginBottom: '1rem' }} />
                            </p>

                            <h5 className='all-font-light'>Closure of the Operation</h5>
                            <p className='all-font mb-3' style={{ textAlign: 'justify', whiteSpace: 'normal' }}>
                                &emsp;&emsp;&emsp;The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes. These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.<span style={{ display: 'block', marginBottom: '1rem' }} />
                                &emsp;&emsp;&emsp;This agreement will terminate immediately without notice from us if in our sole discretion you fail to comply with any term or provision of this Agreement. whether made under the terms of this Agreement or otherwise. In the event of termination, the disclaimers of warranties and limitations of liabilities, damages and remedies set forth in this Agreement shall survive.<span style={{ display: 'block', marginBottom: '1rem' }} />
                                &emsp;&emsp;&emsp;If we observe any false activity by user/customer, any activity against terms of service, any activity that cause harm to SHIVSHAHI’S reputation or any person related to SHIVSHAHI business we have right to cancel, block, terminate the customer or user.<span style={{ display: 'block', marginBottom: '1rem' }} />
                            </p>

                            <h5 className='all-font-light'>Governing Law</h5>
                            <p className='all-font mb-3' style={{ textAlign: 'justify', whiteSpace: 'normal' }}>
                                &emsp;&emsp;&emsp;This Agreement, and the Privacy Policy or the documents they incorporate by reference shall be governed and construed in accordance with the laws of India, with exclusive jurisdiction conferred on the Courts at NASHIK.<span style={{ display: 'block', marginBottom: '1rem' }} />
                            </p>

                            <h5 className='all-font-light'>Warranties</h5>
                            <p className='all-font mb-3' style={{ textAlign: 'justify', whiteSpace: 'normal' }}>
                                &emsp;&emsp;&emsp;This covers damaged product caused by not proper handling of courier, weather effect on courier.<span style={{ display: 'block', marginBottom: '1rem' }} />
                                &emsp;&emsp;&emsp;If customer got any damaged product.<span style={{ display: 'block', marginBottom: '1rem' }} />
                                &emsp;&emsp;&emsp;In above both conditions we ensure to exchange the product in SHIVSHAHI’S expenses. In this condition return policy rules are not applied.<span style={{ display: 'block', marginBottom: '1rem' }} />
                            </p>

                            <h5 className='all-font-light'>Copyright</h5>
                            <p className='all-font mb-3' style={{ textAlign: 'justify', whiteSpace: 'normal' }}>
                                &emsp;&emsp;&emsp;All material on this Site , including but not limited to text, images, illustrations, etc are protected by copyrights which are owned and controlled by us or by other parties that have licensed their material to us. Material from the site may not be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way.<span style={{ display: 'block', marginBottom: '1rem' }} />
                                &emsp;&emsp;&emsp;We grant you a limited license to access and make personal use of this site and not to download (other than page caching) or modify it, or any portion of it, except with the written consent of SHIVSHAHI. This license does not include any resale or commercial use of this site or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this site or its contents; any downloading or copying of account information for the benefit of another merchant; or any use of data mining, robots, or similar data gathering and extraction tools<span style={{ display: 'block', marginBottom: '1rem' }} />
                            </p>

                            <h5 className='all-font-light'>Contact Information</h5>
                            <p className='all-font mb-3' style={{ textAlign: 'justify', whiteSpace: 'normal' }}>
                                &emsp;&emsp;&emsp;For any quarry related to term and service contact SHIVSHAHI customer care +91 8180070617. Email id: shivshahinetwork@gmail.com<span style={{ display: 'block', marginBottom: '1rem' }} />
                            </p>

                        </div>

                    </div>

                </div>

                <div className="cta cta-display bg-image pt-4 pb-4" style={{ backgroundImage: `url(assets/images/backgrounds/bg-7.jpg)` }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-9 col-xl-7">
                                <div className={`row no-gutters ${isIEBrowser() ? '' : 'flex-column'} flex-sm-row align-items-sm-center`} >
                                    <div className="col">
                                        <h3 className="cta-title text-white">आपल्याकडे अधिक प्रश्न असल्यास</h3>
                                        <p className="cta-desc text-white">आमच्याशी संपर्क साधा मोकळ्या मनाने</p>
                                    </div>

                                    <div className="col-auto">
                                        <Link to={`${process.env.PUBLIC_URL}/faq`} className="btn btn-outline-white"><span>Ask Us</span><i className="icon-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TermsOfService;