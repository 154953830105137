import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { isIEBrowser } from './../../../utils/utils';

import GalleryPostSingle from '../../components/sub-components/single-gallery-post';

import { fetchGalleryProductSuggestions } from '../../../api/other';

import { showModal } from '../../../actions';
import PostCreateModal from '../../components/sub-components/post-create-modal';

class Gallery extends Component {

    constructor(props) {
        super(props)

        this.state = {
            galleryProductSuggestions: []
        }
    }

    componentDidMount() {
        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";
        fetchGalleryProductSuggestions()
            .then(res => {
                if (res.status == 200) {
                    this.setState({ galleryProductSuggestions: res.data }, () => {
                        spinner.style.display = "none";
                    })
                } else {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                }
            })
            .catch((err) => {
                alert('Error Occured.. Please Try after some time')
                spinner.style.display = "none";
            })
    }

    createPost = (e) => {
        e.preventDefault();
        const usertokenShivshahiCustomer = localStorage.getItem('usertokenShivshahiCustomer')
        if (usertokenShivshahiCustomer != null) {
            this.props.showModal('postCreate');
            // window.location.reload();
        } else {
            window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
        }
    }

    render() {
        return (
            <div className="main">
                <Helmet>
                    <title>Shivshahi Network</title>
                </Helmet>

                <h1 className="d-none">Shivshahi Network</h1>

                <div className="page-content pt-3 all-bg">
                    <div id="spinneranimid" className="overlay-spinner">
                        <div className="cssload-whirlpool" />
                    </div>

                    <div className='container mt-2' style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='col-xl-6 col-lg-6 col-md-8 col-sm-9 col-12 gallery-card-container'>
                            <button type="btn" className="btn btn-primary" onClick={this.createPost}>
                                <span>Create new Post</span>
                                <i className="icon-instagram" />
                            </button>
                            <hr />
                        </div>
                    </div>

                    <GalleryPostSingle calledFor="gallery" galleryProductSuggestions={this.state.galleryProductSuggestions} />

                </div>

                <div className="cta cta-display bg-image pt-4 pb-4" style={{ backgroundImage: `url(assets/images/backgrounds/bg-7.jpg)` }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-9 col-xl-7">
                                <div className={`row no-gutters ${isIEBrowser() ? '' : 'flex-column'} flex-sm-row align-items-sm-center`} >
                                    <div className="col">
                                        <h3 className="cta-title text-white">आपल्याकडे अधिक प्रश्न असल्यास</h3>
                                        <p className="cta-desc text-white">आमच्याशी संपर्क साधा मोकळ्या मनाने</p>
                                    </div>

                                    <div className="col-auto">
                                        <Link to={`${process.env.PUBLIC_URL}/faq`} className="btn btn-outline-white"><span>Ask Us</span><i className="icon-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PostCreateModal />
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        currency: state.currencyData,
        trackings: state.data.trackings,
    };
};

export default connect(mapStateToProps, { showModal })(Gallery);