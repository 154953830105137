import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { SocialLink } from './sub-components/social-link';

class Footer extends Component {

    render() {
        const { type = 2, logo = "assets/images/logo.png" } = this.props;
        return (
            <footer className="footer footer-2 all-bg-light">
                {this.props.children}
                <div className={`footer-middle ${type === 2 ? 'border-0' : ''}`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-lg-6">
                                <div className="widget widget-about">
                                    <Link to={`${process.env.PUBLIC_URL}/`}>
                                        <img src={process.env.PUBLIC_URL + '/' + logo} className="footer-logo" alt="Footer Logo" width="205" height="auto" />
                                    </Link>
                                    <p className='mb-1'>नेटवर्कविषयी आपल्याला काही प्रश्न असल्यास, किंवा आपल्याला काही समस्या असल्यास आमच्या ग्राहक सेवा अधिकारीशी संपर्क साधा..!</p>

                                    <span className="widget-about-title all-font">Payment Method</span>
                                    <figure className="footer-payments">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/payments.png`} alt="Payment methods" width="250" height="auto" />
                                    </figure>

                                </div>
                            </div>

                            <div className="col-sm-4 col-lg-2 mobile-none">
                                <div className="widget">
                                    <h4 className="widget-title"><span className='all-font-light'>Information</span></h4>

                                    <ul className="widget-list">
                                        <li className='all-font'><Link to={`${process.env.PUBLIC_URL}/about`}>About Us</Link></li>
                                        <li className='all-font'><Link to={`${process.env.PUBLIC_URL}/contact`}>Contact us</Link></li>
                                        <li className='all-font'><Link to={`${process.env.PUBLIC_URL}/termsofservice`}>Terms of Service</Link></li>
                                        <li className='all-font'><Link to={`${process.env.PUBLIC_URL}/faq`}>FAQs</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-sm-4 col-lg-2 mobile-none">
                                <div className="widget">
                                    <h4 className="widget-title"><span className='all-font-light'>Policies</span></h4>

                                    <ul className="widget-list">
                                        <li className='all-font'><Link to={`${process.env.PUBLIC_URL}/ship&deliver-policy`}>Shipping & Delivery</Link></li>
                                        <li className='all-font'><Link to={`${process.env.PUBLIC_URL}/return&exchange-policy`}>Return & Exchange</Link></li>
                                        <li className='all-font'><Link to={`${process.env.PUBLIC_URL}/privacy-policy`}>Privacy</Link></li>
                                        <li className='all-font'><Link to={`${process.env.PUBLIC_URL}/cancel&refund-policy`}>Cancellation & Refund</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-sm-4 col-lg-2 mobile-none">
                                <div className="widget">
                                    <h4 className="widget-title"><span className='all-font-light'>Useful Links</span></h4>

                                    <ul className="widget-list">
                                        <li className='all-font'><Link to={`${process.env.PUBLIC_URL}/business-opportunity`}>Business Opportunity</Link></li>
                                        <li className='all-font'><Link to={`${process.env.PUBLIC_URL}/hirkani-plan`}>Hirakani Plan</Link></li>
                                        <li className='all-font'><Link to={`${process.env.PUBLIC_URL}/recharge-plan`}>Recharge Plan</Link></li>
                                        <li className='all-font'><Link to={`${process.env.PUBLIC_URL}/links`}>Links</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <hr style={{ borderColor: '#6b6b6b', marginBottom: '25px', marginTop: '0px' }} />
                    <h4 className='text-center all-font-light'>Department Helpdesk Contacts</h4>
                    <hr style={{ borderColor: '#6b6b6b', width: '350px', marginBottom: '20px', marginTop: '5px' }} />
                    <div className='row'>
                        {/* <div className='col-lg-3 col-md-6 col-sm-6 text-center mb-3'>
                            <h5 className="all-font">Return and Exchange</h5>
                            <a style={{ color: '#fff', fontSize: '18px' }} href="tel://+918180070617">+91 8180070617</a><br />
                            <p style={{ fontSize: '12px' }}>Monday To Saturday, 10:00 AM - 7:00 PM</p>
                        </div> */}
                        <div className='col-lg-6 col-md-6 col-sm-6 text-center mb-3'>
                            <h5 className="all-font">Customer Care</h5>
                            <a style={{ color: '#fff', fontSize: '18px' }} href="tel://+918180070617">+91 8180070617</a><br />
                            <p style={{ fontSize: '12px' }}>Monday To Saturday, 10:00 AM - 7:00 PM</p>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 text-center mb-3'>
                            <h5 className="all-font">Other Support</h5>
                            <a style={{ color: '#fff', fontSize: '18px' }} href="tel://+917447751617">+91 7447751617</a><br />
                            <p style={{ fontSize: '12px' }}>Monday To Saturday, 10:00 AM - 7:00 PM</p>
                        </div>
                        {/* <div className='col-lg-4 col-md-4 col-sm-4 text-center mb-3'>
                            <h5 className="all-font">Payment Related</h5>
                            <a style={{ color: '#fff', fontSize: '18px' }} href="tel://+918983451617">+91 8983451617</a><br />
                            <p style={{ fontSize: '12px' }}>Monday To Saturday, 10:00 AM - 7:00 PM</p>
                        </div> */}
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container pt-1 pb-0">
                        <p className="footer-copyright">Copyright © {(new Date()).getFullYear()} Shivshahi Network. All Rights Reserved.</p>
                        <SocialLink label={true} />
                    </div>
                    <hr style={{ borderColor: '#6b6b6b', width: '350px', marginBottom: '0px', marginTop: '10px' }} />
                    <div className="reecraft-footer">
                        <p className="footer-copyright">Designed and maintained by <a href="https://www.reecraft.com" target="_blank" rel="noopener noreferrer">reecraft</a></p>
                    </div>
                </div>
                <div className='desktop-hide-bnb' style={{ marginBottom: '70px' }} />
            </footer>
        );
    }
}

export default Footer;