import React, { Component } from 'react';
import jwt_decode from 'jwt-decode'
import { Link } from 'react-router-dom';
import { Trash2, Edit, UploadCloud, LogOut, X } from 'react-feather'

import * as userApi from './../../../api/user'

class About extends Component {

    constructor(props) {
        super(props)

        this.state = {

            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            address: "",
            city: "",
            district: "",
            pincode: "",
            state: "",

            profile_pic_img: null,
            profile_pic_img_temp: null,

            id: '',
            profile_pic: null
        }
    }

    componentDidMount = async () => {
        document.body.classList.add('all-bg');

        var checkusertoken = localStorage.getItem('usertokenShivshahiCustomer');
        // console.log(checkusertoken)
        if (checkusertoken == null) {
            this.props.history.push(`${process.env.PUBLIC_URL}/auth/login-register/:data`)
        } else {

            const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer
            const decoded = jwt_decode(usertokenShivshahiCustomer).data

            this.setState({
                id: decoded.id,
                firstname: decoded.firstname,
                lastname: decoded.lastname,
                email: decoded.email,
                phone: decoded.phone,
                address: decoded.address,
                city: decoded.city,
                district: decoded.district,
                pincode: decoded.pincode,
                state: decoded.state,
                profile_pic_img: decoded.profile_pic != null ? `${process.env.PUBLIC_URL}/assets/images/profile_pictures/${decoded.profile_pic}` : `${process.env.PUBLIC_URL}/assets/images/profile_pictures/dummy.jpg`,
                profile_pic_img_temp: decoded.profile_pic != null ? `${process.env.PUBLIC_URL}/assets/images/profile_pictures/${decoded.profile_pic}` : `${process.env.PUBLIC_URL}/assets/images/profile_pictures/dummy.jpg`,
            })

        }
    }

    componentWillUnmount() {
        document.body.classList.remove('all-bg');
    }

    handleChange = e => {
        if (e.target.name == 'address') {
            this.setState({ [e.target.name]: e.target.value })
        } else {
            this.setState({ [e.target.name]: e.target.value.replace(new RegExp("\\s*", "gm"), '') })
        }
    }

    handleSubmit = e => {
        e.preventDefault();

        if (window.confirm('Do you want to update the Information..?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer

            const data = {
                userToken: usertokenShivshahiCustomer,
                updateData: {
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    email: this.state.email,
                    phone: this.state.phone,
                    address: this.state.address,
                    city: this.state.city,
                    district: this.state.district,
                    pincode: this.state.pincode,
                    state: this.state.state,
                }
            }

            userApi.updateProfile(data)
                .then(async res => {
                    if (res.status == 200) {
                        localStorage.setItem("usertokenShivshahiCustomer", res.data);
                        alert('Your Details are updated successfully..!')
                        spinner.style.display = "none";
                        window.location.reload()
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Please Login Again...!')
                        // localStorage.removeItem('usertokenShivshahiCustomer')
                        localStorage.clear();
                        window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        spinner.style.display = "none";
                        window.location.reload()
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                    window.location.reload()
                })

        }
    }

    manageProfilePic = (e, action) => {
        e.preventDefault();
        let uploadBtn = document.getElementById("upload-btn")

        if (action == 'delete') {
            if (window.confirm('Do you want to delete the profile picture..?')) {

                let spinner = document.getElementById("spinneranimid");
                spinner.style.display = "block";

                const data = {
                    userToken: localStorage.usertokenShivshahiCustomer,
                }

                userApi.removeProfilePicture(data)
                    .then(async res => {
                        if (res.status == 200) {
                            localStorage.setItem("usertokenShivshahiCustomer", res.data);
                            alert('Profile Picture Removed..!')
                            spinner.style.display = "none";
                            window.location.reload()
                        } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                            alert('Please Login Again...!')
                            // localStorage.removeItem('usertokenShivshahiCustomer')
                            localStorage.clear();
                            window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                        } else {
                            alert('Error Occured.. Please Try after some time')
                            spinner.style.display = "none";
                            window.location.reload()
                        }
                    })
                    .catch((err) => {
                        alert('Error Occured.. Please Try after some time')
                        spinner.style.display = "none";
                        window.location.reload()
                    })

            }
        } else if (action == 'edit') {
            var loader = document.getElementById("profileLoader")

            loader.click();
            loader.onchange = () => {
                var file = loader.files[0]
                if (file != undefined) {
                    if (
                        file.type.toLowerCase() != 'image/jpg' &&
                        file.type.toLowerCase() != 'image/jpeg' &&
                        file.type.toLowerCase() != 'image/png'
                    ) {
                        this.setState({ profile_pic_img_temp: this.state.profile_pic_img, profile_pic: null }, () => {
                            alert('Please upload a valid Image file..!')
                            uploadBtn.style.display = 'none'
                        })
                    } else {
                        this.setState({ profile_pic_img_temp: URL.createObjectURL(file), profile_pic: file }, () => {
                            uploadBtn.style.display = 'flex'
                        })
                    }
                } else {
                    this.setState({ profile_pic_img_temp: this.state.profile_pic_img, profile_pic: null }, () => {
                        uploadBtn.style.display = 'none'
                    })
                }
            }
        } else if (action == 'dupload') {
            this.setState({ profile_pic_img_temp: this.state.profile_pic_img, profile_pic: null }, () => {
                document.getElementById("profileLoader").value = ''
                uploadBtn.style.display = 'none'
            })
        } else if (action == 'upload') {
            if (window.confirm('Upload the Image..?')) {

                let spinner = document.getElementById("spinneranimid");
                spinner.style.display = "block";

                const formData = new FormData();
                formData.append('files', this.state.profile_pic)
                formData.append('id', this.state.id)

                userApi.uploadProfilePicture(formData)
                    .then(async res => {
                        if (res.status == 200) {
                            localStorage.setItem("usertokenShivshahiCustomer", res.data);
                            alert('Profile Picture Added..!')
                            spinner.style.display = "none";
                            window.location.reload()
                        } else {
                            alert('Error Occured.. Please Try after some time')
                            spinner.style.display = "none";
                            window.location.reload()
                        }
                    })
                    .catch((err) => {
                        alert('Error Occured.. Please Try after some time')
                        spinner.style.display = "none";
                        window.location.reload()
                    })

            }
        }
    }

    logout = () => {
        localStorage.removeItem('usertokenShivshahiCustomer')
        window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
    }

    render() {

        return (
            <div className="main">

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="page-content mt-3">
                    <div className="dashboard">
                        <div className="container">

                            <div className="row">
                                <div className="col-md-4 col-lg-3 col-sm-4 col-12">
                                    <div className='profile-pic'>
                                        <img id='profile_pic_img' src={this.state.profile_pic_img_temp} alt="profile_pic" />
                                        <div className='btns'>
                                            <div className='ml-3 mr-3 delete' onClick={(e) => { this.manageProfilePic(e, 'delete') }}><Trash2 /></div>
                                            <div className='ml-3 mr-3 edit' onClick={(e) => { this.manageProfilePic(e, 'edit') }}><Edit /></div>
                                        </div>
                                        <div className='upload-btn' id='upload-btn'>
                                            <div className='ml-3 mr-3 upload' onClick={(e) => { this.manageProfilePic(e, 'upload') }}><UploadCloud /></div>
                                            <div className='ml-3 mr-3 upload' onClick={(e) => { this.manageProfilePic(e, 'dupload') }}><X /></div>
                                        </div>
                                    </div>
                                    <input type="file" single="single" accept="image/*" id="profileLoader" name="profilepic" title="Load DP" style={{ display: 'none' }} />
                                </div>

                                <div className="col-md-8 col-lg-9 col-sm-8 col-12">
                                    <div className='mt-3'>
                                        <h6 style={{ color: '#757575' }}>Name : <span className='all-font'>{`${this.state.firstname} ${this.state.lastname}`}</span></h6>
                                        <h6 style={{ color: '#757575' }}>Email ID : <span className='all-font'>{`${this.state.email}`}</span></h6>
                                        <h6 style={{ color: '#757575' }}>Phone No. : <span className='all-font'>{`${this.state.phone}`}</span></h6>
                                        <h6 style={{ color: '#757575' }}>Address :
                                            <span className='all-font pl-2'>
                                                {this.state.address},<br />
                                                {this.state.city}, {this.state.district},<br />
                                                {this.state.state},{this.state.pincode}.
                                            </span>
                                        </h6>
                                    </div>

                                    <div className='cust-links mt-3'>
                                        <div className='left'>
                                            <Link to={`${process.env.PUBLIC_URL}/my-account/dashboard`}><i className="las la-chalkboard" />&nbsp;<span>Dashboard</span></Link>&nbsp;&nbsp;&bull;&nbsp;&nbsp;
                                            <Link to={`${process.env.PUBLIC_URL}/my-account/wallet`}><i className="las la-wallet" />&nbsp;<span>Wallet</span></Link>&nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;
                                            <Link to={`${process.env.PUBLIC_URL}/my-account/orders`}><i className="las la-file-alt" />&nbsp;<span>Orders</span></Link>&nbsp;&nbsp;&bull;&nbsp;&nbsp;
                                            <Link to={`${process.env.PUBLIC_URL}/my-account/transactions`}><i className="las la-exchange-alt" />&nbsp;<span>Transactions</span></Link>&nbsp;&nbsp;&bull;&nbsp;&nbsp;
                                            <Link to={`${process.env.PUBLIC_URL}/my-account/about`}><i className="las la-edit" />&nbsp;<span>Edit&nbsp;Info</span></Link>&nbsp;&nbsp;&bull;&nbsp;&nbsp;
                                            <Link to={`${process.env.PUBLIC_URL}/my-account/my-posts`}><i className="las la-image" />&nbsp;<span>My&nbsp;Posts</span></Link>
                                        </div>
                                        <div className='right'>
                                            <button type='btn' onClick={this.logout}><span>Sign Out</span>&nbsp;&nbsp;<LogOut /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr />

                            <h3 className='text-center all-font mb-3'>Update Your Profile</h3>

                            <form onSubmit={this.handleSubmit}>

                                <div className="form-group" style={{ display: 'flex' }}>
                                    <div className="col-6" style={{ paddingLeft: '0px' }}>
                                        <label className="all-font-dark" htmlFor="firstname">First Name *</label>
                                        <input value={this.state.firstname} type="text" className="form-control all-font" id="firstname" name="firstname" pattern=".*\S+.*" onChange={this.handleChange} required />
                                    </div>
                                    <div className="col-6" style={{ paddingRight: '0px' }}>
                                        <label className="all-font-dark" htmlFor="lastname">Last Name *</label>
                                        <input value={this.state.lastname} type="text" className="form-control all-font" id="lastname" name="lastname" pattern=".*\S+.*" onChange={this.handleChange} required />
                                    </div>
                                </div>

                                <div className="form-group" style={{ display: 'flex' }}>
                                    <div className="col-6" style={{ paddingLeft: '0px' }}>
                                        <label className="all-font-dark" htmlFor="email">E-mail address *</label>
                                        <input value={this.state.email} type="email" className="form-control all-font" id="email" pattern=".*\S+.*" name="email" readOnly required />
                                    </div>
                                    <div className="col-6" style={{ paddingRight: '0px' }}>
                                        <label className="all-font-dark" htmlFor="phone">Phone Number</label>
                                        <input value={this.state.phone} type="number" className="form-control all-font" id="phone" pattern=".*\S+.*" name="phone" readOnly required />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="all-font-dark" htmlFor="address">Address</label>
                                    <textarea value={this.state.address} className="form-control all-font" id="address" name="address" pattern=".*\S+.*" onChange={this.handleChange} required />
                                </div>

                                <div className="form-group" style={{ display: 'flex' }}>
                                    <div className="col-6" style={{ paddingLeft: '0px' }}>
                                        <label className="all-font-dark" htmlFor="city">City</label>
                                        <input value={this.state.city} type="text" className="form-control all-font" id="city" name="city" pattern=".*\S+.*" onChange={this.handleChange} required />
                                    </div>
                                    <div className="col-6" style={{ paddingRight: '0px' }}>
                                        <label className="all-font-dark" htmlFor="district">District</label>
                                        <input value={this.state.district} type="text" className="form-control all-font" id="district" name="district" pattern=".*\S+.*" onChange={this.handleChange} required />
                                    </div>
                                </div>

                                <div className="form-group" style={{ display: 'flex' }}>
                                    <div className="col-6" style={{ paddingLeft: '0px' }}>
                                        <label className="all-font-dark" htmlFor="state">State</label>
                                        <input value={this.state.state} type="text" className="form-control all-font" id="state" name="state" pattern=".*\S+.*" onChange={this.handleChange} required />
                                    </div>
                                    <div className="col-6" style={{ paddingRight: '0px' }}>
                                        <label className="all-font-dark" htmlFor="pincode">Pin Code</label>
                                        <input value={this.state.pincode} type="number" className="form-control all-font" id="pincode" name="pincode" pattern=".*\S+.*" onChange={this.handleChange} required />
                                    </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <button type="submit" className="btn btn-outline-primary-2">
                                        <span>Save Changes</span>
                                        <i className="icon-long-arrow-down"></i>
                                    </button>
                                </div>

                            </form>

                        </div>
                    </div >
                </div >
            </div >
        )
    }
}

export default About;