import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import jwt_decode from 'jwt-decode'

import PageHeader from './../../components/page-header';
import OwlCarousels from './../../components/owl-carousel';
import RechargePlanSingle from './../../components/rechargePlanSingle';

import { mainSlider5 } from './../../components/sub-components/slider-settings';

import recharge_plans from './../../../data/recharge-plans.json';

import { makeRechargeRequest } from './../../../api/user'
import { verifyUser } from './../../../api/auth'

class JoinUs extends Component {

    constructor(props) {
        super(props)

        this.state = {
            plan: '',
            amount: '',
            receipt: '',
            receiptsNames: [],
            fileArray: [],
            condition: 'You must select a Plan..!',
        }
    }

    componentDidMount = () => {
        var checkusertoken = localStorage.getItem('usertokenShivshahiCustomer');
        // console.log(checkusertoken)
        if (checkusertoken == null) {
            this.props.history.push(`${process.env.PUBLIC_URL}/auth/login-register/:data`)
        } else {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            var param = this.props.match.params.planinfo;
            if (param == 'basic') {
                this.setState({ plan: 'BR', condition: 'You can enter the Recharge amount between ₹ 5000 and ₹ 19999 ..!' })
            } else if (param == 'light') {
                this.setState({ plan: 'LR', condition: 'You can enter the Recharge amount between ₹ 20000 and ₹ 49999 ..!' })
            } else if (param == 'premium') {
                this.setState({ plan: 'PR', condition: 'You can enter the Recharge amount between ₹ 50000 and ₹ 99999 ..!' })
            } else if (param == 'ultrapremium') {
                this.setState({ plan: 'UPR', condition: 'You can enter the Recharge amount above ₹ 100000 ..!' })
            }

            const data = {
                userToken: localStorage.usertokenShivshahiCustomer,
            }

            verifyUser(data)
                .then(async res => {
                    if (res.status == 200) {
                        spinner.style.display = "none";
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Please Login Again...!')
                        // localStorage.removeItem('usertokenShivshahiCustomer')
                        localStorage.clear();
                        window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                })
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })

        if ([e.target.value] == '' && [e.target.name] != 'amount') {
            this.setState({ condition: 'You must select a Plan..!' })
        } else if ([e.target.value] == 'UPR') {
            this.setState({ condition: 'You can enter the Recharge amount above ₹ 100000 ..!' })
        } else if ([e.target.value] == 'PR') {
            this.setState({ condition: 'You can enter the Recharge amount between ₹ 50000 and ₹ 99999 ..!' })
        } else if ([e.target.value] == 'LR') {
            this.setState({ condition: 'You can enter the Recharge amount between ₹ 20000 and ₹ 49999 ..!' })
        } else if ([e.target.value] == 'BR') {
            this.setState({ condition: 'You can enter the Recharge amount between ₹ 5000 and ₹ 19999 ..!' })
        }

    }

    uploadReceipt = (e) => {
        var loader = document.getElementById("fileLoader")
        var fileArray = [];

        loader.click();
        loader.onchange = () => {

            for (var i = 0; i < loader.files.length; i++) {
                fileArray.push(loader.files[i])
                if (
                    loader.files[i].type.toLowerCase() != 'image/jpg' &&
                    loader.files[i].type.toLowerCase() != 'image/jpeg' &&
                    loader.files[i].type.toLowerCase() != 'image/png'
                ) {
                    this.setState({ receipt: '' }, () => {
                        alert('Please upload a valid file..!')
                        fileArray = [];
                        i = loader.files.length
                    })
                } else {
                    this.setState({ receipt: `${loader.files.length}  receipt(s)` })
                }
            }
            this.setState({ fileArray: fileArray, receipt: loader.files.length == 0 ? '' : this.state.receipt })
        };
    }

    callJoinUs = (e) => {
        e.preventDefault();
        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";

        const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer
        const decoded = jwt_decode(usertokenShivshahiCustomer)

        let dataToUpload = {
            user_id: decoded.data.id,
            user: {
                id: decoded.data.id,
                firstname: decoded.data.firstname,
                lastname: decoded.data.lastname,
                email: decoded.data.email,
                phone: decoded.data.phone,
                ownref: decoded.data.ownref,
                referenceby: decoded.data.referenceby,
                city: decoded.data.city,
            },
            plan: this.state.plan == 'BR' ? 'Basic Recharge' : this.state.plan == 'LR' ? 'Light Recharge' : this.state.plan == 'PR' ? 'Premium Recharge' : 'Ultra Premium Recharge',
            date: new Date(),
            amount: this.state.amount,
            status: 'Requested'
        }

        const data = new FormData()
        for (let j = 0; j < this.state.fileArray.length; j++) {
            data.append('files', this.state.fileArray[j])
        }
        data.append('dataToUpload', JSON.stringify(dataToUpload))

        makeRechargeRequest(data)
            .then(res => {
                if (res.status == 200) {
                    alert('Request Successful')
                    spinner.style.display = "none";
                    window.location.reload();
                } else {
                    alert('Something went wrong')
                    spinner.style.display = "none";
                    window.location.reload();
                }
            })
            .catch((err) => {
                alert('Something went wrong')
                spinner.style.display = "none";
                window.location.reload();
            })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.state.fileArray.length == 0) {
            alert('Please upload the file')
        } else {
            if (this.state.plan == 'BR') {
                if (this.state.amount > 4999 && this.state.amount < 20000) {
                    this.callJoinUs(e);
                } else {
                    alert('Please enter the amount between ₹ 5000 and ₹ 19999 for Basic Recharge')
                }
            } else if (this.state.plan == 'LR') {
                if (this.state.amount > 19999 && this.state.amount < 50000) {
                    this.callJoinUs(e);
                } else {
                    alert('Please enter the amount between ₹ 20000 and ₹ 49999 for Light Recharge')
                }
            } else if (this.state.plan == 'PR') {
                if (this.state.amount > 49999 && this.state.amount < 100000) {
                    this.callJoinUs(e);
                } else {
                    alert('Please enter the amount between ₹ 50000 and ₹ 99999 for Premium Recharge')
                }
            } else {
                if (this.state.amount > 99999) {
                    this.callJoinUs(e);
                } else {
                    alert('Please enter the amount above ₹ 100000 for Ultra Premium Recharge')
                }
            }
        }
    }

    render() {
        return (
            <div className="main" >

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <Helmet>
                    <title>Shivshahi Network</title>
                </Helmet>

                <h1 className="d-none">Shivshahi Network</h1>

                <PageHeader title="Shivshahi Network" subTitle="Join Us" />

                <div className="page-content pt-3 all-bg">

                    <div className='container desktop-hide'>
                        <OwlCarousels adClass="owl-simple owl-testimonials-photo mb-5" carouselOptions={mainSlider5} >
                            {recharge_plans.recharge_plans.map((plan, index) =>
                                <div key={index}>
                                    <RechargePlanSingle
                                        id={plan.id}
                                        plan={plan.plan}
                                        price={plan.price}
                                        get={plan.get}
                                        profit={plan.profit}
                                        upline1_profit={plan.upline1_profit}
                                        upline2_profit={plan.upline2_profit}
                                        shipping={plan.shipping}
                                        shipping2={plan.shipping2}
                                        support={plan.support}
                                        support2={plan.support2}
                                        fame={plan.fame}
                                        fame2={plan.fame2}
                                    />
                                </div>
                            )}
                        </OwlCarousels>
                    </div>

                    <div className='container mobile-none'>
                        <div className="recharge-plans-desktop mb-5">
                            {recharge_plans.recharge_plans.slice(0, 2).map((plan, index) =>
                                <div key={index} className="all-bg-light pt-3 pb-3">
                                    <h4 className="all-font">{plan.plan}</h4>
                                    <h3 className="font-primary">{plan.price}</h3>
                                    <p><i className="icon-check" />&nbsp;&nbsp;{plan.profit}</p>
                                    <p>{plan.get}</p>
                                    <p><i className="icon-check" />&nbsp;&nbsp;{plan.upline1_profit}</p>
                                    <p><i className="icon-check" />&nbsp;&nbsp;{plan.upline2_profit}</p>
                                    <p style={{ display: plan.id == 2 ? 'none' : '' }}><i className={plan.id == 1 || plan.id == 2 ? 'icon-close' : 'icon-check'} />&nbsp;&nbsp;{plan.shipping}</p>
                                    <p style={{ display: plan.id == 2 ? '' : 'none' }}><i className={plan.id == 2 ? 'icon-check' : 'icon-close'} />&nbsp;&nbsp;{plan.shipping2}</p>
                                    <p><i className={plan.id == 1 || plan.id == 2 ? 'icon-close' : 'icon-check'} />&nbsp;&nbsp;{plan.support}</p>
                                    <p><i className={plan.id == 4 ? 'icon-check' : 'icon-close'} />&nbsp;&nbsp;{plan.support2}</p>
                                    <p><i className="icon-check" />&nbsp;&nbsp;{plan.fame}</p>
                                    <p><i className={plan.id == 1 ? 'icon-close' : 'icon-check'} />&nbsp;&nbsp;{plan.fame2}</p>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='container mobile-none'>
                        <div className="recharge-plans-desktop mb-5">
                            {recharge_plans.recharge_plans.slice(2, 4).map((plan, index) =>
                                <div key={index} className="all-bg-light pt-3 pb-3">
                                    <h4 className="all-font">{plan.plan}</h4>
                                    <h3 className="font-primary">{plan.price}</h3>
                                    <p><i className="icon-check" />&nbsp;&nbsp;{plan.profit}</p>
                                    <p>{plan.get}</p>
                                    <p><i className="icon-check" />&nbsp;&nbsp;{plan.upline1_profit}</p>
                                    <p><i className="icon-check" />&nbsp;&nbsp;{plan.upline2_profit}</p>
                                    <p style={{ display: plan.id == 2 ? 'none' : '' }}><i className={plan.id == 1 || plan.id == 2 ? 'icon-close' : 'icon-check'} />&nbsp;&nbsp;{plan.shipping}</p>
                                    <p style={{ display: plan.id == 2 ? '' : 'none' }}><i className={plan.id == 2 ? 'icon-check' : 'icon-close'} />&nbsp;&nbsp;{plan.shipping2}</p>
                                    <p><i className={plan.id == 1 || plan.id == 2 ? 'icon-close' : 'icon-check'} />&nbsp;&nbsp;{plan.support}</p>
                                    <p><i className={plan.id == 4 ? 'icon-check' : 'icon-close'} />&nbsp;&nbsp;{plan.support2}</p>
                                    <p><i className="icon-check" />&nbsp;&nbsp;{plan.fame}</p>
                                    <p><i className={plan.id == 1 ? 'icon-close' : 'icon-check'} />&nbsp;&nbsp;{plan.fame2}</p>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="container all-bg-light">
                        <h4 className="all-font-light text-center pt-3">आपण खालील खात्यावर पैसे पाठवू शकता..</h4>
                        <div className="account-details">
                            <ul>
                                <li>Bank Name: <span>ICICI BANK</span></li>
                                <li>Account Name: <span>Shivshahi Network </span></li>
                                <li>Account No.: <span>376405001968</span></li>
                                <li>IFSC Code: <span>icic0003764</span></li>
                                <li>Branch Name: <span>Yeola</span></li>
                                <li>Account Type: <span>Current Account</span></li>
                            </ul>
                        </div>
                        <div className="joinus-form">
                            <div className='col-lg-5 col-md-7 col-sm-8 all-bg'>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <label className="all-font-dark" htmlFor="plan">Select a Plan *</label>
                                        <select name="plan" id="plan" className="form-control all-font" value={this.state.plan} onChange={this.handleChange} required>
                                            <option className="all-font all-bg" value=''>-- Select a Plan --</option>
                                            <option className="all-font all-bg" value='UPR'>Ultra Premium Recharge</option>
                                            <option className="all-font all-bg" value='PR'>Premium Recharge</option>
                                            <option className="all-font all-bg" value='LR'>Light Recharge</option>
                                            <option className="all-font all-bg" value='BR'>Basic Recharge</option>
                                        </select>
                                        <p style={{ color: '#c96' }}>{this.state.condition}</p>
                                    </div>
                                    <div className="form-group">
                                        <label className="all-font-dark" htmlFor="amount">Amount *</label>
                                        <input type="number" className="form-control all-font" id="amount" name="amount" value={this.state.amount} pattern=".*\S+.*" onChange={this.handleChange} required />
                                    </div>
                                    <div className="form-group">
                                        <label className="all-font-dark" htmlFor="receipt">Upload the Receipt *</label>
                                        <div className="input-group">
                                            <input type="text" value={this.state.receipt} className="form-control all-font" id="receipt" name="receipt" style={{ backgroundColor: '#212121' }} readOnly />
                                            <div className="input-group-append" title='click to upload' onClick={this.uploadReceipt}>
                                                <span className="input-group-text"><i className="icon-creative-commons-share" /></span>
                                            </div>
                                        </div>
                                        <input type="file" multiple="multiple" accept="image/*" id="fileLoader" name="files" title="Load File" style={{ display: 'none' }} />
                                    </div>
                                    <button type="submit" className="btn btn-outline-primary-2">
                                        <span>Request</span>
                                        <i className="icon-long-arrow-up" />
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default JoinUs;