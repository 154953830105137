import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import PageHeader from './../../components/page-header';
import BaseProduct from './../../components/sub-components/base-product';

import { quantityInputs } from './../../../utils/utils';
import { changetQty, removeFromWishlist, addToCartFromWishlist } from './../../../actions';

import * as userApi from './../../../api/user'

class Wishlist extends BaseProduct {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.onChangeQty = this.onChangeQty.bind(this);
    }

    componentDidMount() {
        let items = document.querySelectorAll(".action-col .dropdown");

        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            item.addEventListener("click", function (e) {
                item.classList.add("show");
                item.querySelector(".dropdown-menu").classList.add("show");
                item.querySelector(".dropdown-menu").setAttribute("style", "position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 40px, 0px);");
                e.stopPropagation();
            });
        }
        document.addEventListener("click", this.hiddenHandle);
        quantityInputs();

        window.onload = function () {
            if (!window.location.hash) {
                window.location = window.location + '#loaded';
                window.location.reload();
            }
        }

    }

    componentWillUnmount() {
        document.removeEventListener("click", this.hiddenHandle);
    }

    hiddenHandle(e) {
        let items = document.querySelectorAll(".action-col .dropdown");
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            item.classList.remove("show");
            item.querySelector(".dropdown-menu").classList.remove("show");
            item.querySelector(".dropdown-menu").setAttribute("style", "");
        }
    }

    removeFromWishlistHandler = (e, product) => {
        e.preventDefault();
        const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer
        if (usertokenShivshahiCustomer != null) {

            const data = {
                userToken: usertokenShivshahiCustomer,
                wishlistItem: product
            }
            userApi.removeFromWishlist(data)
                .then(async res => {
                    if (res.status == 200) {
                        this.props.removeFromWishlist(product.id);
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Session Expired..Please Login Again..!')
                        localStorage.clear()
                        window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    } else {
                        alert('Something Went Wrong')
                        localStorage.clear()
                        window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    }
                })
                .catch((err) => {
                    alert('Something Went Wrong')
                    localStorage.clear()
                    window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                })
        }
    }

    addToCartFromWishlistHandler = (e, product) => {
        e.preventDefault();
        const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer
        if (usertokenShivshahiCustomer != null) {

            var data = {
                userToken: usertokenShivshahiCustomer,
                item: product,
            }
            data.item.size = 6
            data.item.qty = 1
            data.item.sum = product.price

            userApi.removeFromWishlistANDaddToCartlist(data)
                .then(async res => {
                    if (res.status == 200) {
                        this.props.addToCartFromWishlist(product, 1, JSON.parse(product.variants)[JSON.parse(product.variants).findIndex(stockItem => stockItem.stock != 0)].size)
                    } else if (res.status == 403 && res.data.error == 'isLogoutOK Failed') {
                        alert('Session Expired..Please Login Again..!')
                        localStorage.clear()
                        window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    } else {
                        alert('Something Went Wrong')
                        localStorage.clear()
                        window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    }
                })
                .catch((err) => {
                    alert('Something Went Wrong')
                    localStorage.clear()
                    window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                })
        }
    }

    onChangeQty(e, product_id) {
        this.props.changetQty(product_id, e.currentTarget.querySelector('input[type="number"]').value);
    }

    render() {
        const { wishlist, currency } = this.props;

        var totalStock = 0;

        return (
            <div className="main all-bg">

                <PageHeader title="Wishlist" subTitle="Your Likings" />

                <div className="page-content pt-3">
                    <div className="cart">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <table className="table table-cart table-mobile">
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th style={{ textAlign: 'center' }}>Color</th>
                                                <th style={{ textAlign: 'center' }}>Price</th>
                                                <th style={{ textAlign: 'center' }}>Add to Cart</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {wishlist.length > 0 ?
                                                wishlist.map((item, index) =>
                                                    <tr key={index}>

                                                        <td className="product-col">
                                                            <div className="product all-bg">
                                                                <figure style={{ width: '125px' }}>

                                                                    <Link to={`${process.env.PUBLIC_URL}/shop/detail/${item.id}`}>
                                                                        <img src={process.env.PUBLIC_URL + '/' + JSON.parse(item.pictures)[0]} alt="Product" />
                                                                    </Link>

                                                                </figure>

                                                                <h3 className="product-title pl-5" style={{ color: '#c2c2c2' }}>

                                                                    <Link to={`${process.env.PUBLIC_URL}/shop/detail/${item.id}`}>{item.name}</Link>
                                                                    {/* {item.name} */}

                                                                </h3>

                                                                <div className="all-font desktop-hide" style={{ textAlign: 'center', paddingLeft: '20px', paddingRight: '20px' }}>
                                                                    <div className="color-display" style={{ backgroundColor: item.color }} />
                                                                </div>

                                                            </div>

                                                        </td>

                                                        <td className="all-font" style={{ textAlign: 'center', paddingLeft: '20px', paddingRight: '20px' }}>
                                                            <div className='mobile-none-color'>
                                                                <div className="color-display" style={{ backgroundColor: item.color }} />
                                                            </div>
                                                        </td>

                                                        <td className="price-col" style={{ textAlign: 'center', paddingLeft: '20px', paddingRight: '20px', color: '#fff' }}>
                                                            {currency.currencySymbol}&nbsp;
                                                            {0 < item.discount ?

                                                                (item.salePrice * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :

                                                                (item.price * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })

                                                            }
                                                        </td>

                                                        <td className="total-col" style={{ textAlign: 'center', paddingLeft: '20px', paddingRight: '20px' }}>
                                                            <div style={{ display: 'none' }}>
                                                                {
                                                                    JSON.parse(item.variants).map((stockItem, index2) => (
                                                                        totalStock = parseInt(totalStock) + parseInt(stockItem.stock)
                                                                    ))
                                                                }
                                                            </div>

                                                            <button className="btn btn-block btn-outline-primary-2"
                                                                onClick={(e) => this.addToCartFromWishlistHandler(e, item)} disabled={!totalStock}>
                                                                {/* onClick={(e) => console.log(item, item.variants[item.variants.findIndex(stockItem => parseInt(stockItem.stock) != 0)].size)} disabled={!totalStock}> */}
                                                                <i className="icon-cart-plus" />{`${totalStock ? 'Add to Cart' : 'Out of Stock'}`}
                                                            </button>

                                                            <div style={{ display: 'none' }}>
                                                                {totalStock = 0}
                                                            </div>
                                                        </td>

                                                        <td className="remove-col">
                                                            <button className="btn-remove"
                                                                onClick={(e) => this.removeFromWishlistHandler(e, item)}
                                                            >
                                                                <i className="icon-close"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ) :
                                                <tr>
                                                    <td>
                                                        <p className="pl-2 pt-1 pb-1 all-font-light"> No Products in Wishlist </p>
                                                    </td>
                                                </tr>
                                            }

                                        </tbody>
                                    </table>
                                    <Link to={`${process.env.PUBLIC_URL}/shop`} className="btn btn-custom"><span>Continue Shopping</span><i className="icon-long-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    wishlist: state.wishlist.list,
    currency: state.currencyData,
})

export default connect(mapStateToProps, { changetQty, removeFromWishlist, addToCartFromWishlist })(Wishlist);