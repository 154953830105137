import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { fetchTransactions } from "./../../../api/order";

import MyAccountHeader from './../../components/my-account-header'

class Transactions extends Component {

    constructor(props) {
        super(props)

        this.state = {
            txns: [],
            txns_filtered: [],
        }
    }

    componentDidMount = async () => {
        document.body.classList.add('all-bg');

        var checkusertoken = localStorage.getItem('usertokenShivshahiCustomer');
        // console.log(checkusertoken)
        if (checkusertoken == null) {
            this.props.history.push(`${process.env.PUBLIC_URL}/auth/login-register/:data`)
        } else {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer

            const data = {
                userToken: usertokenShivshahiCustomer,
            }

            fetchTransactions(data)
                .then(res => {
                    if (res.status == 200) {
                        this.setState({
                            txns: (res.data).reverse(),
                            txns_filtered: res.data.filter(txn => txn.payment_status != 'Pending'),
                        }, () => {
                            spinner.style.display = "none";
                        })
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Please Login Again...!')
                        // localStorage.removeItem('usertokenShivshahiCustomer')
                        localStorage.clear();
                        window.location = process.env.PUBLIC_URL + '/auth/login-register/:data'
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                })

        }
    }

    componentWillUnmount() {
        document.body.classList.remove('all-bg');
    }

    render() {
        const { currency } = this.props;

        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

        return (
            <div className="main">

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="page-content mt-3">
                    <div className="dashboard">
                        <div className="container">

                            <MyAccountHeader />

                            <h3 className='text-center all-font mb-3'>Transactions</h3>

                            <div className='txn-container'>
                                {this.state.txns_filtered.length > 0 ?
                                    <div className="txns">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className='first'>Txn&nbsp;Id</th>
                                                    <th>Date</th>
                                                    <th>Order&nbsp;No.</th>
                                                    <th>Name</th>
                                                    <th>Credit</th>
                                                    <th>Debit</th>
                                                    <th>Normal&nbsp;Balance</th>
                                                    <th>Plan&nbsp;Balance</th>
                                                    <th>Source</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.txns_filtered.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className='no-align first'>{item.txn_id}</td>
                                                            <td className='no-align'>{days[new Date(item.date).getDay()] + ','}&nbsp;{monthNames[new Date(item.date).getMonth()]}&nbsp;{new Date(item.date).getDate() + ','}&nbsp;{new Date(item.date).getFullYear()}</td>
                                                            <td>{item.order_id.replace("PO", "").replace("W", "").replace("H", "").replace("E", "").replace("R", "")}</td>
                                                            <td>{item.name}</td>
                                                            <td style={{ color: 'green' }}>
                                                                <span style={{ display: `${item.source.charAt(0) == 'T' ? '' : 'none'}` }}>
                                                                    {item.source.charAt(0) == 'T' ? '+' : '-'}
                                                                     &nbsp;{currency.currencySymbol}&nbsp;{(item.amount * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </span>
                                                            </td>
                                                            <td style={{ color: '#ed5c5c' }}>
                                                                <span style={{ display: `${item.source.charAt(0) == 'T' ? 'none' : ''}` }}>
                                                                    {item.source.charAt(0) == 'T' ? '+' : '-'}
                                                                     &nbsp;{currency.currencySymbol}&nbsp;{(item.amount * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {currency.currencySymbol}&nbsp;{(item.normal_balance * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                            </td>
                                                            <td>
                                                                {currency.currencySymbol}&nbsp;{((parseInt(item.ultrapremium_balance) + parseInt(item.premium_balance) + parseInt(item.light_balance) + parseInt(item.basic_balance)) * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                            </td>
                                                            <td className='no-align'>{item.source}</td>
                                                            <td>
                                                                <span style={{ color: `${item.payment_status == 'Completed' ? 'green' : '#ed5c5c'}` }}>
                                                                    {item.payment_status}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div className="empty-list">
                                        <p>No Transaction has been made yet.</p>
                                        <Link to={`${process.env.PUBLIC_URL}/shop`} className="btn btn-outline-primary-2"><span>GO TO SHOP</span><i className="icon-long-arrow-right"></i></Link>
                                    </div>
                                }
                            </div>

                        </div>
                    </div >
                </div >
            </div >
        )
    }
}

export const mapStateToProps = (state) => ({
    currency: state.currencyData,
})

export default connect(mapStateToProps)(Transactions);