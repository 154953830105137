import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import PageHeader from './../../components/page-header';

import { isIEBrowser } from './../../../utils/utils';

class RechargePlan extends Component {

    render() {
        const { currency } = this.props;
        return (
            <div className="main">
                <Helmet>
                    <title>Shivshahi Network</title>
                </Helmet>

                <h1 className="d-none">Shivshahi Network</h1>

                <PageHeader title="Recharge" subTitle="Business Plan" />

                <div className="page-content pt-3 all-bg">

                    <div className="container all-bg-light mb-5 text-center">
                        <h2 className="text-center pt-5 font-primary">शिवशाही बेसिक रिचार्ज</h2>
                        <h4 className="text-center mb-1 pt-1 all-font">
                            {currency.currencySymbol}&nbsp;{(5000 * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            &nbsp;&nbsp;ते&nbsp;&nbsp;
                            {currency.currencySymbol}&nbsp;{(19999 * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            &nbsp;&nbsp;पर्यंत
                        </h4>
                        <ul className="pt-3 pb-1 pr-3 pl-5 all-font">
                            <li>❈&nbsp;&nbsp;त्यामध्ये तुम्ही ₹ 5,000/- रुपये चा रिचार्ज करून ₹ 5,750/- रुपयाची शॉपिंग करू शकतात.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;(15% extra) म्हणजे तुम्हाला ₹ 750/- जास्त चे मिळता व अपलाइन ला 5% चा प्रॉफिट मिळतो.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;अपलाइन च्या अपलाइन ला 2% चा प्रॉफिट मिळतो.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;वेबसाईटवर तुमचं नाव तुमच्या शहराच्या नावाने हायलाईट करण्यात येईल.</li>
                        </ul>
                        <Link to={`${process.env.PUBLIC_URL}/join-us/basic`} className="btn btn-outline-primary mt-1 mb-1"><span>बेसिक रिचार्ज</span><i className="icon-long-arrow-right"></i></Link>
                        <hr className="custom-hr" />

                        <h2 className="text-center pt-5 font-primary">शिवशाही लाईट रिचार्ज</h2>
                        <h4 className="text-center mb-1 pt-1 all-font">
                            {currency.currencySymbol}&nbsp;{(20000 * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            &nbsp;&nbsp;ते&nbsp;&nbsp;
                            {currency.currencySymbol}&nbsp;{(49999 * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            &nbsp;&nbsp;पर्यंत
                        </h4>
                        <ul className="pt-3 pb-1 pr-3 pl-5 all-font">
                            <li>❈&nbsp;&nbsp;त्यामध्ये तुम्ही ₹ 20,000/- रुपये चा रिचार्ज करून ₹ 23,600/- रुपयाची शॉपिंग करू शकतात.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;(18% extra) म्हणजे तुम्हाला ₹ 3,600/- जास्त चे मिळता व अपलाइन ला 3% चा प्रॉफिट मिळतो.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;अपलाइन च्या अपलाइन ला 1% चा प्रॉफिट मिळतो.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;आणि सर्वात महत्त्वाचे त्यात ( शिपिंग 50% OFF ) मिळेल.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;म्हणजेच तुम्ही 20 साडी ऑर्डर केल्या तर शिपिंग चे प्रत्येक साडी वर वाचेल ₹ 50. टोटल ₹ 1,000/- ते ही तुम्हालाच मिळेल.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;आणि आपल्याला शिवशाही नेटवर्क Diamond Leader ची पदवी मिळणार.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;वेबसाईटवर तुमचं नाव तुमच्या शहराच्या नावाने हायलाईट करण्यात येईल.</li>
                        </ul>
                        <Link to={`${process.env.PUBLIC_URL}/join-us/light`} className="btn btn-outline-primary mt-1 mb-1"><span>लाईट रिचार्ज</span><i className="icon-long-arrow-right"></i></Link>
                        <hr className="custom-hr" />

                        <h2 className="text-center pt-3 font-primary">शिवशाही प्रीमियम रिचार्ज</h2>
                        <h4 className="text-center mb-1 pt-1 all-font">
                            {currency.currencySymbol}&nbsp;{(50000 * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            &nbsp;&nbsp;ते&nbsp;&nbsp;
                            {currency.currencySymbol}&nbsp;{(99999 * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            &nbsp;&nbsp;पर्यंत
                        </h4>
                        <ul className="pt-3 pb-1 pr-3 pl-5 all-font">
                            <li>❈&nbsp;&nbsp;त्यामध्ये तुम्ही ₹ 50,000/- रुपये चा रिचार्ज करून तुम्ही ₹ 60,000/- रुपयाची शॉपिंग करू शकतात.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;(20% extra) म्हणजे तुम्हाला ₹ 10,000/- जास्त चे मिळता.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;आणि सर्वात महत्त्वाचे त्यात (शिपिंग फ्री) मिळेल.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;म्हणजेच तुम्ही 60 साडी ऑर्डर केल्या तर शिपिंग चे प्रत्येक साडी वर वाचेल ₹ 100. टोटल ₹ 6,000/- ते ही तुम्हालाच मिळेल.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;अपलाइन ला 1% चा प्रॉफिट </li>
                            <li className="pt-1">❈&nbsp;&nbsp;अपलाइन च्या अपलाइन ला 1% चा प्रॉफिट मिळतो.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;आणि आपल्याला शिवशाही नेटवर्क Diamond Leader ची पदवी मिळणार.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;वेबसाईटवर तुमचं नाव तुमच्या शहराच्या नावाने हायलाईट करण्यात येईल.</li>
                        </ul>
                        <Link to={`${process.env.PUBLIC_URL}/join-us/premium`} className="btn btn-outline-primary mt-1 mb-1"><span>प्रीमियम रिचार्ज</span><i className="icon-long-arrow-right"></i></Link>
                        <hr className="custom-hr" />

                        <h2 className="text-center pt-3 font-primary">शिवशाही अल्ट्रा प्रिमिअम रिचार्ज</h2>
                        <h4 className="text-center mb-1 pt-1 all-font">
                            {currency.currencySymbol}&nbsp;{(100000 * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            &nbsp;&nbsp;पासून पुढे
                        </h4>
                        <ul className="pt-3 pb-1 pr-3 pl-5 all-font">
                            <li>❈&nbsp;&nbsp;त्यामध्ये तुम्ही ₹ 1,00,000/- रुपये चा रिचार्ज करून तुम्ही  ₹ 1,20,000/- रुपयाची शॉपिंग करू शकतात.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;(20% extra) म्हणजे तुम्हाला ₹ 20,000/- जास्त चे मिळता. व 60 × 100 = ₹ 6,000 (शिपिंग फ्री. चा फायदा.)</li>
                            <li className="pt-1">❈&nbsp;&nbsp;अपलाइन ला 1% चा प्रॉफिट </li>
                            <li className="pt-1">❈&nbsp;&nbsp;अपलाइन च्या अपलाइन ला 1% चा प्रॉफिट मिळतो.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;आणि आपल्याला शिवशाही नेटवर्क Diamond Leader ची पदवी मिळणार.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;तुम्हाला कस्टमर ही शिवशाही प्रोव्हाइड करेल.</li>
                            <li className="pt-1">❈&nbsp;&nbsp;वेबसाईटवर तुमचं नाव तुमच्या शहराच्या नावाने हायलाईट करण्यात येईल.</li>
                        </ul>
                        <Link to={`${process.env.PUBLIC_URL}/join-us/ultrapremium`} className="btn btn-outline-primary mt-1 mb-5"><span>अल्ट्रा प्रिमिअम रिचार्ज</span><i className="icon-long-arrow-right"></i></Link>
                    </div>

                    <div className="container all-bg-light text-center">
                        <h1 className="text-center mb-3 pt-3 all-font-light">Take Action Now</h1>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <ul className="pt-3 pb-3 all-font col-lg-8 col-md-9 col-sm-10">
                                <li>जर तुम्ही विचार करत असाल कि मि हा बिज़नेस माज्या जवळच्या लोकांना दाखवून बगतो/बगते, ते काय म्हणतील. ज्यांना खरंच काही करायचं नाहीये ते पहिले तुम्हाला निगेटिव प्रश्न विचारतील आणि तुम्हाला निगेटिव बनवण्याचा प्रयत्न करतील.</li>
                                <li className="pt-2">तुम्ही अभिमानाने सांगा की नेटवर्क मार्केटिंग बिजनेस आहे. आणि खूप चांगला आहे कारण या बिजनेस ने अमेरिकेमध्ये खूप सारे मिलेनियर, बिलिनिइयर्स बनवले आहे.</li>
                                <li className="pt-2">मग ते तुम्हाला विचारतात की तुम्ही ह्या बिज़नेस मध्ये जॉइन आहेत का?? जर तुम्ही जॉइन नसाल तर तुम्ही काहीच बोलू शकत नाही जर जॉइन असाल तर तुम्ही अभिमानने सांगू शकता कि हो मि जॉइन आहे, मला दर महीना एवढा प्रोफिट होतो,म्हणून आधि तुम्ही या बिज़नेस ला जॉइन असल पाहिजे जर तुम्ही जॉइन असाल तरच तुमच्या समोरच्या व्यक्ति वर इम्प्रेशन पड़ते</li>
                                <li className="pt-2">आणि होय तुम्हाला लाजायची बिलकुल गरज नाही,तुम्ही फक्त प्रोडक्ट विकत नाही आहात. तुम्ही समोरच्याला सेकंडरी इनकम सोर्स तयार करून देत आहात.</li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className="cta cta-display bg-image pt-4 pb-4" style={{ backgroundImage: `url(assets/images/backgrounds/bg-7.jpg)` }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-9 col-xl-7">
                                <div className={`row no-gutters ${isIEBrowser() ? '' : 'flex-column'} flex-sm-row align-items-sm-center`} >
                                    <div className="col">
                                        <h3 className="cta-title text-white">आपल्याकडे अधिक प्रश्न असल्यास</h3>
                                        <p className="cta-desc text-white">आमच्याशी संपर्क साधा मोकळ्या मनाने</p>
                                    </div>

                                    <div className="col-auto">
                                        <Link to={`${process.env.PUBLIC_URL}/faq`} className="btn btn-outline-white"><span>Ask Us</span><i className="icon-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currency: state.currencyData,
    };
};

export default connect(mapStateToProps)(RechargePlan);