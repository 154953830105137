import PropTypes from "prop-types";
import React, { useState } from 'react';
import { changeLanguage } from "redux-multilanguage";
import { Link } from "react-router-dom";

const TopHeader = ({
    currency,
    setCurrency,
    currentLanguageCode,
    dispatch,
    products
}) => {

    const [sku, setsku] = useState('');

    const changeLanguageTrigger = e => {
        // const languageCode = e.target.value;
        // dispatch(changeLanguage(languageCode));
    };

    const setCurrencyTrigger = (e, name) => {
        const currencyName = name;
        // console.log(currencyName)
        setCurrency(currencyName);
    };

    const onSubmitSearch = (e) => {
        e.preventDefault();
       
        let productFound = products.find(item => item.sku.toLowerCase() == sku.toLowerCase())
        if (productFound != undefined) {
            window.location = process.env.PUBLIC_URL + '/shop/detail/' + productFound.id
        } else {
            alert('There is no such product of given sku..!')
        }

    }

    return (
        <div className="header-top">
            <div className="container">
                <div className="header-left">
                    {/* <p>Wanna do the business..?</p><Link to={`${process.env.PUBLIC_URL}/business-opportunity`}>&nbsp; <strong>Opportunities ...</strong></Link> */}
                   
                        <form onSubmit={onSubmitSearch} className="mobile-search">
                            <label htmlFor="mobile-search" className="sr-only">Search</label>
                            <input type="search" className="form-control" value={sku} name="mobile-search" id="mobile-search" placeholder="Search by sku..." required pattern=".*\S+.*" onChange={e => setsku(e.target.value.replace(new RegExp("\\s*", "gm"), ''))} />
                            <button className="btn btn-primary" type="submit"><i className="icon-search"></i></button>
                        </form>                      

                                   </div>
                <div className="header-right">
                    <ul className="top-menu">
                        <li>
                            <Link to="#">Links</Link>
                            <ul>
                                <li>
                                    <div className="header-dropdown">
                                        {/* <Link to="#">INR</Link> */}
                                        <Link to="#">{currency.currencyName}&nbsp;{currency.currencySymbol}</Link>
                                        <div className="header-menu all-bg">
                                            <ul>
                                                <li><button value="INR" onClick={e => setCurrencyTrigger(e, "INR")}><span>INR&nbsp;₹</span></button></li>
                                                <li><button value="USD" onClick={e => setCurrencyTrigger(e, "USD")}><span>USD&nbsp;$</span></button></li>
                                                <li><button value="EUR" onClick={e => setCurrencyTrigger(e, "EUR")}><span>EUR&nbsp;€</span></button></li>
                                                <li><button value="GBP" onClick={e => setCurrencyTrigger(e, "GBP")}><span>GBP&nbsp;£</span></button></li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="header-dropdown">
                                        {/* <Link to="#">
                                                {currentLanguageCode === "mar"
                                                    ? "मराठी"
                                                    : currentLanguageCode === "fn"
                                                        ? "English"
                                                        : ""}
                                            </Link> */}
                                        <Link to="#">मराठी</Link>
                                        <div className="header-menu all-bg">
                                            <ul>
                                                <li><button value="mar" onClick={e => changeLanguageTrigger(e)}><span>मराठी</span></button></li>
                                                <li><button value="en" onClick={e => changeLanguageTrigger(e)}><span>English</span></button></li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li><Link to={`${process.env.PUBLIC_URL}/join-us/noplan`}>Recharge</Link></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

// TopHeader.propTypes = {
//     setCurrency: PropTypes.func,
//     currency: PropTypes.object,
//     currentLanguageCode: PropTypes.string,
//     dispatch: PropTypes.func
// };

export default TopHeader;
