import React from 'react';
import { connect } from 'react-redux';

import BaseProduct from './base-product';

import { findIndex } from './../../../utils/utils';

class SimilarProducts extends BaseProduct {

    render() {
        const { product, adClass, currency } = this.props;

        var totalStock = 0;

        {
            JSON.parse(product.variants).map((item, index) => (
                totalStock = totalStock + parseInt(item.stock)
            ))
        }

        return (
            product ?
                <div className={`product product-7 ${adClass} all-bg`}>
                    <figure className="product-media">

                        {(0 === totalStock) ? <span className="product-label label-out">Out of Stock</span> :
                            product.new ? <span className="product-label label-new">New</span> : ''}

                        {(0 === totalStock) ? '' :
                            product.off != 0 ? <span className="product-label label-sale">{product.off}% off</span> :
                                ''}

                        {this.showProductImgSection()}

                        <div className="product-action-vertical">
                            {this.showToggleWishlistBtn()}
                            {this.showQuickViewBtn('btn-product-icon btn-quickview')}
                        </div>

                        <div className="product-action all-bg-light">
                            {this.showAddToCartBtn()}
                        </div>
                    </figure>

                    <div className="product-body all-bg-light">
                        {this.showProductName()}
                        {this.showProductPrice('', '', currency)}
                        {this.showProductRating()}
                    </div>
                </div> : ''
        )
    }
}

export const mapStateToProps = (state, ownprops) => {
    let wishlist = false;
    if (findIndex(state.wishlist.list, item => item.id == ownprops.product.id) !== -1)
        wishlist = true;
    return {
        wishlist: wishlist
    };
}

export default connect(mapStateToProps)(SimilarProducts);