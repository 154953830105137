import { RECEIVE_GALLERY_POSTS, RECEIVE_POSTS, TOGGLE_POST_LIKE } from "../constants/action-types";
import { findIndex } from "../utils/utils";

const initialState = {
    items: [],
    galleryPosts: [],
};

const postReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_POSTS:
            return {
                ...state,
                items: action.posts
            };

        case RECEIVE_GALLERY_POSTS:
            return {
                ...state,
                galleryPosts: action.galleryPosts
            };

        case TOGGLE_POST_LIKE:
            let postId = action.post.postId;
            let postIndex = findIndex(state.galleryPosts, post => post.postId === postId);
            if (postIndex !== -1) {
                let isLikedByUserVal = state.galleryPosts[postIndex].isLikedByUser
                let isLikedByUserNewVal = isLikedByUserVal == 0 || isLikedByUserVal == null || isLikedByUserVal == undefined ? 1 : 0;
                let newLikeCount = isLikedByUserNewVal == 1 ? (parseInt(state.galleryPosts[postIndex].totalLikes) + 1) : (parseInt(state.galleryPosts[postIndex].totalLikes) - 1);
                let newGalleyPosts = [...state.galleryPosts];
                newGalleyPosts[postIndex].isLikedByUser = isLikedByUserNewVal;
                newGalleyPosts[postIndex].totalLikes = newLikeCount;
                return { ...state, galleryPosts: newGalleyPosts }
            } else {
                return state;
            }

        default:
            return state;
    }
};
export default postReducer;