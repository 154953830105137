import React, { Component } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { accountVerification } from "./../../../api/auth";

class EmailVerified extends Component {

    componentDidMount() {
        var verificationtoken = this.props.match.params.verificationtoken;
        var checkusertoken = localStorage.getItem('usertokenShivshahiCustomer');

        if (checkusertoken !== null) {
            this.props.history.push(`${process.env.PUBLIC_URL}/`)
        } else if (verificationtoken == ":verificationtoken") {
            this.props.history.push(`${process.env.PUBLIC_URL}/auth/login-register/:data`)
        } else {
            const data = {
                verificationtoken: verificationtoken,
            }
            accountVerification(data)
                .then(res => {
                    if (res.status == 200) {
                        setTimeout(() => {
                            this.props.history.push(`${process.env.PUBLIC_URL}/auth/login-register/:data`)
                        }, 10000);
                    } else {
                        alert('Token Expired')
                        this.props.history.push(`${process.env.PUBLIC_URL}/auth/login-register/:data`)
                    }
                })
                .catch((err) => {
                    alert('Something went Wrong')
                    this.props.history.push(`${process.env.PUBLIC_URL}/auth/login-register/:data`)
                })
        }
    }

    render() {
        return (
            <div className="main">
                <Helmet>
                    <title>Shivshahi Network</title>
                </Helmet>

                <h1 className="d-none">Shivshahi Network</h1>

                <div className="login-page bg-image pt-8 pb-8 pt-md-12 pb-md-12 pt-lg-17 pb-lg-17" style={{ backgroundImage: `url('assets/images/backgrounds/login-bg.jpg')` }}>
                    <div className="container">
                        <div className="form-box all-bg">
                            <div className="form-tab">
                                <Tabs selectedTabClassName="show" defaultIndex={0}>
                                    <TabList className="nav nav-pills nav-fill">
                                        <Tab className="nav-item all-font">
                                            <span className="nav-link">Email Verified</span>
                                        </Tab>
                                    </TabList>

                                    <div className="tab-content">
                                        <TabPanel style={{ paddingTop: "2rem", textAlign: 'center' }}>
                                            <div style={{ color: '#22ab50' }}>
                                                Your Email has been successfully Verified.
                                            </div>
                                            <div style={{ color: '#c2c2c2' }}>
                                                You will be redirectd to Login in 10 seconds..
                                            </div>
                                            <div className="pt-1">
                                                <Link className="forgot-link" to={`${process.env.PUBLIC_URL}/auth/login-register/:data`} >Click here to Login</Link>
                                            </div>
                                        </TabPanel>
                                    </div>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EmailVerified;