import * as api from '../api'
import * as types from '../constants/action-types'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

/********** Network Action ********/
// recieve Network
export const receiveNetworksCustom = networks => ({
    type: types.RECEIVE_NETWORK,
    networks
});

// get all products
export const getAllNetworksCustom = () => dispatch => {
    api.fetchNetworks().then(networks => {
        dispatch(receiveNetworksCustom(networks));
        return networks;
    })
}

/********** Network Action ********/
// recieve Network
export const receiveGalleryPosts = galleryPosts => ({
    type: types.RECEIVE_GALLERY_POSTS,
    galleryPosts
});

// get all products
export const getGalleryPosts = () => dispatch => {
    api.fetchGalleryPosts().then(galleryPosts => {
        dispatch(receiveGalleryPosts(galleryPosts));
        return galleryPosts;
    })
}

export const togglePostLike = (post) => (dispatch) => {
    dispatch(togglePostLikeUnsafe(post))
}

export const togglePostLikeUnsafe = (post) => ({
    type: types.TOGGLE_POST_LIKE,
    post
});

export const receiveIntroSliders = sliders => ({
    type: types.RECEIVE_INTRO_SLIDERS,
    sliders
});

// get all products
export const getAllIntroSliders = () => dispatch => {
    api.fetchIntroSliders().then(sliders => {
        dispatch(receiveIntroSliders(sliders));
        return sliders;
    })
}

export const receiveClientTesimonials = testimonials => ({
    type: types.RECEIVE_CLIENT_TESTIMONIALS,
    testimonials
});

// get all products
export const getAllClientTestimonials = () => dispatch => {
    api.fetchClientTestimonials().then(testimonials => {
        dispatch(receiveClientTesimonials(testimonials));
        return testimonials;
    })
}

/********** Category Action ********/
// recieve Category
export const receiveCategoriesCustom = categories => ({
    type: types.RECEIVE_CATS,
    categories
});

// get all products
export const getAllCategoriesCustom = () => dispatch => {
    api.fetchCategories().then(categories => {
        dispatch(receiveCategoriesCustom(categories));
        return categories;
    })
}

export const receiveTrackings = trackings => ({
    type: types.RECEIVE_TRACKINGS,
    trackings
});

export const receiveTeamMembers = members => ({
    type: types.RECEIVE_TEAM_MEMBERS,
    members
});

export const receiveCatWall = catwall => ({
    type: types.RECEIVE_CAT_WALL,
    catwall
});

// get all products
export const getAllTrackings = () => dispatch => {
    api.fetchTrackings().then(trackings => {
        dispatch(receiveTrackings(trackings));
        return trackings;
    })
}

export const getAllTeamMembers = () => dispatch => {
    api.fetchTeamMembers().then(members => {
        dispatch(receiveTeamMembers(members));
        return members;
    })
}

export const getAllCatWall = () => dispatch => {
    api.fetchCatWall().then(catwall => {
        dispatch(receiveCatWall(catwall));
        return catwall;
    })
}


/********** Product Action ********/
// recieve products
export const receiveProducts = products => ({
    type: types.RECEIVE_PRODUCTS,
    products
});

export const getDetailedProduct = productId => ({
    type: types.GET_DETAILED_PRODUCT,
    productId
})

// get all products
export const getAllProducts = () => dispatch => {
    api.fetchProducts().then(products => {
        dispatch(receiveProducts(products));
        return products;
    })
}

/*********** Modal related Action **********/
// display quickview 
export const showQuickViewModal = productId => ({
    type: types.SHOW_QUICKVIEW,
    productId
})

// close quickview modal
export const closeQuickViewModal = () => ({
    type: types.CLOSE_QUICKVIEW
})

// Show Video & Login modal
export const showModal = (modal) => ({
    type: types.SHOW_MODAL,
    modal: modal
});

// close Video & Login modal
export const closeModal = (modal) => ({
    type: types.CLOSE_MODAL,
    modal: modal
});

/************ Cart Action **************/
// add item to cart
export const addToCart = (product, qty, size, stock) => (dispatch) => {
    toast.success("Item Added to Cart");
    dispatch(addToCartUnsafe(product, qty, size, stock))
}

// add item to cart from wishlist
export const addToCartFromWishlist = (product, qty, size) => (dispatch) => {
    toast.success("Item added to Cart");

    dispatch({
        type: types.REMOVE_FROM_WISHLIST,
        productId: product.id
    })

    dispatch(addToCartUnsafe(product, qty, size))
}

// add item to cart : typical action
export const addToCartUnsafe = (product, qty, size, stock) => ({
    type: types.ADD_TO_CART,
    product,
    qty,
    size,
    stock
});

// remove item from cart
export const removeFromCart = (productId, productSize) => (dispatch) => {
    toast.error("Item removed from Cart");
    dispatch({
        type: types.REMOVE_FROM_CART,
        productId,
        productSize
    })
};

//change item's qty
export const changetQty = (productId, qty, size) => ({
    type: types.CHANGE_QTY,
    productId,
    qty,
    size
});

//change shipping method
export const changeShipping = (shipping) => ({
    type: types.CHANGE_SHIPPING,
    shipping
})

export const resetCartlist = () => (dispatch) => {
    dispatch({
        type: types.RESET_CARTLIST
    })
};

export const replaceCartlist = (cartlist) => (dispatch) => {
    dispatch({
        type: types.REPLACE_CARTLIST,
        cartlist
    })
};
//
/*********** Wishlist Action *********/

// add item to wishlist
export const toggleWishlist = (product) => (dispatch) => {
    dispatch(toggleWishlistUnsafe(product))
}

//add item to wishlist : typical action
export const toggleWishlistUnsafe = (product) => ({
    type: types.TOGGLE_WISHLIST,
    product
});

// remove item from wishlist
export const removeFromWishlist = productId => (dispatch) => {
    toast.error("Item removed from Wishlist");
    dispatch({
        type: types.REMOVE_FROM_WISHLIST,
        productId
    })
};

export const resetWishlist = () => (dispatch) => {
    dispatch({
        type: types.RESET_WISHLIST
    })
};

export const replaceWishlist = (wishlist) => (dispatch) => {
    dispatch({
        type: types.REPLACE_WISHLIST,
        wishlist
    })
};


/************* Compare Action ***********/
//CompareList
export const addToCompare = (product) => (dispatch) => {
    toast.success("Item added to Compare");
    dispatch(addToCompareUnsafe(product))
}

export const addToCompareUnsafe = (product) => ({
    type: types.ADD_TO_COMPARE,
    product
});

export const removeFromCompare = productId => ({
    type: types.REMOVE_FROM_COMPARE,
    productId
});

export const resetCompare = () => ({
    type: types.RESET_COMPARE
});


/************ Overlay Action ***********/
export const innerLoadBegin = (container) => ({
    type: types.INNER_LOADING,
    loading: true
});

export const innerLoadEnd = (container) => ({
    type: types.INNER_LOADING,
    loading: false
});

export const outerLoadBegin = () => ({
    type: types.OUTER_LOADING,
    loading: true
});

export const outerLoadEnd = () => ({
    type: types.OUTER_LOADING,
    loading: false
});

export const innerLoading = () => (dispatch) => {
    dispatch(innerLoadBegin());

    setTimeout(() => {
        dispatch(innerLoadEnd());
    }, 1000);
};

export const outerLoading = () => (dispatch) => {
    dispatch(outerLoadBegin());

    setTimeout(() => {
        dispatch(outerLoadEnd());
    }, 1000);
}


/************** Filter Action ***********/

export const filterSort = (sortBy) => (dispatch) => {
    dispatch(innerLoading());
    dispatch({
        type: types.SORT_BY,
        sortBy
    })
}

export const filterPrice = (range) => (dispatch) => {
    dispatch(innerLoading());
    dispatch({
        type: types.PRICE_FILTER,
        range
    })
}

export const toggleCategoryFilter = (category) => (dispatch) => {
    dispatch(innerLoading());
    dispatch({
        type: types.CATEGORY_FILTER,
        category
    })
}

export const toggleSizeFilter = (size) => (dispatch) => {
    dispatch(innerLoading());
    dispatch({
        type: types.SIZE_FILTER,
        size
    })
}

export const toggleColorFilter = (color) => (dispatch) => {
    dispatch(innerLoading());
    dispatch({
        type: types.COLOR_FILTER,
        color
    })
}

export const toggleBrandFilter = (brand) => (dispatch) => {
    dispatch(innerLoading());
    dispatch({
        type: types.BRAND_FILTER,
        brand
    })
}

export const toggleRatingFilter = (rating) => (dispatch) => {
    dispatch(innerLoading());
    dispatch({
        type: types.RATING_FILTER,
        rating
    })
}

export const resetFilter = () => (dispatch) => {
    dispatch(innerLoading());
    dispatch({
        type: types.RESET_FILTER
    })
}

/************** Newsletter Modal ************/
export const hideNewsletterModal = (demo) => ({
    type: types.HIDE_NEWSLETTER_MODAL,
    demo
})


/************** Demo  **********************/
export const changeDemo = (demo) => ({
    type: types.CHANGE_DEMO,
    demo
})