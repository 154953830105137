import axios from 'axios';

export const fetchLinks = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetchlinks`, {})
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const createGalleryPost = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/creategallerypost`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const deleteGalleryPost = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/deletegallerypost`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const likeGalleryPost = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/likegallerypost`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchGalleryProductSuggestions = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetchgalleryproductsuggestions`, {})
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const updateGalleryPostStatus = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/updategallerypoststatus`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const getGeoLocation = (pincode) => {
    return axios
        .get(`https://api.reecraft.com/gglrequest/${pincode}`)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

