import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import PageHeader from './../../components/page-header';
import BaseProduct from './../../components/sub-components/base-product';

import { getCartTotal } from './../../../services';
import { quantityInputs } from './../../../utils/utils';
import { changetQty, removeFromCart, changeShipping } from './../../../actions';

import * as userApi from './../../../api/user'

class Cart extends BaseProduct {
    constructor(props) {
        super(props);
        this.state = {
            shipping: this.props.shipping,
            shippingcost: 0,
        }
        this.onChangeShipping = this.onChangeShipping.bind(this);
        this.onChangeQty = this.onChangeQty.bind(this);
        this.goToCheckout = this.goToCheckout.bind(this);
    }

    componentDidMount() {
        quantityInputs();
        if (this.props.cartlist.length > 0) {
            this.props.cartlist.map((item, index) => {
                {
                    var productIndex = this.props.products.findIndex((element) => element.id == item.id)
                    JSON.parse(this.props.products[productIndex].variants).map((item2, index2) => {
                        if (item2.size == item.size && parseInt(item2.stock) < parseInt(item.qty)) {
                            // console.log(item.id, item.qty, item2.stock)
                            this.props.changetQty(item.id, item2.stock, item2.size);
                        }
                        if (item2.size == item.size && item2.stock == 0) {
                            // console.log(item.id, item.size)
                            this.props.removeFromCart(item.id, item.size)
                        }
                    })
                }
            })
        }

        window.onload = function () {
            if (!window.location.hash) {
                window.location = window.location + '#loaded';
                window.location.reload();
            }
        }
    }

    onChangeShipping(val) {
        this.setState({ shipping: val });
    }

    onChangeQty(e, product) {
        var quantity = parseInt(e.currentTarget.querySelector('input[type="number"]').value)
        if (parseInt(JSON.parse(product.variants)[0].stock) >= quantity) {

            const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer
            if (usertokenShivshahiCustomer != null) {

                var data = {
                    userToken: usertokenShivshahiCustomer,
                    cartlistItem: product,
                    newQty: quantity,
                    amount: quantity * product.price
                }

                userApi.updateCartlistQty(data)
                    .then(async res => {
                        if (res.status == 200) {
                            this.props.changetQty(product.id, quantity, product.size);
                        } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                            alert('Session Expired..Please Login Again..!')
                            localStorage.clear()
                            window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                        } else {
                            // alert('Something Went Wrong')
                            // localStorage.clear()
                            // window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                        }
                    })
                    .catch((err) => {
                        // alert('Something Went Wrong')
                        // localStorage.clear()
                        // window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    })
            }

        }

    }

    goToCheckout() {
        this.props.changeShipping(this.state.shipping);
        window.location = process.env.PUBLIC_URL + '/shop/checkout'
    }

    removeFromCartHandler = (e, product) => {
        e.preventDefault();
        const usertokenShivshahiCustomer = localStorage.usertokenShivshahiCustomer
        if (usertokenShivshahiCustomer != null) {

            var data = {
                userToken: usertokenShivshahiCustomer,
                cartlistItem: product
            }
            userApi.removeFromCartlist(data)
                .then(async res => {
                    if (res.status == 200) {
                        this.props.removeFromCart(product.id, product.size);
                    } else if (res.status == 403 && res.error == 'isLogoutOK Failed') {
                        alert('Session Expired..Please Login Again..!')
                        localStorage.clear()
                        window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    } else {
                        alert('Something Went Wrong')
                        localStorage.clear()
                        window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                    }
                })
                .catch((err) => {
                    alert('Something Went Wrong')
                    localStorage.clear()
                    window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
                })
        }
    }

    render() {
        const { cartlist, total, removeFromCart, currency, products } = this.props;

        var totalCartQty = 0;
        // console.log(totalCartQty)

        if (cartlist.length > 0) {
            cartlist.map((item, index) => {
                {
                    totalCartQty = parseInt(totalCartQty) + parseInt(item.qty);
                }
            })
        }

        // console.log(totalCartQty)

        const shippingPrice = { "free": 0, "standard": ((100 + ((parseInt(totalCartQty) - 1) * 50)) * currency.currencyRate), "express": 20 };

        return (
            <div className="main all-bg">

                <PageHeader title="Shopping Cart" subTitle="Shop" />

                <div className="page-content pt-3">
                    <div className="cart">
                        <div className="container">
                            <div className="row">
                                <div className={`${cartlist.length == 0 ? 'col-lg-12' : 'col-lg-9'}`}>
                                    <table className="table table-cart table-mobile">
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th style={{ textAlign: 'center' }}>Price</th>
                                                <th style={{ textAlign: 'center' }}>Size</th>
                                                <th style={{ textAlign: 'center' }}>Quantity</th>
                                                <th style={{ textAlign: 'center' }}>Total</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {cartlist.length > 0 ?
                                                cartlist.map((item, index) =>
                                                    <tr key={index}>
                                                        <td className="product-col">
                                                            <div className="product all-bg">
                                                                <figure style={{ width: '100px' }}>

                                                                    <Link to={`${process.env.PUBLIC_URL}/shop/detail/${item.id}`}>
                                                                        <img src={process.env.PUBLIC_URL + '/' + JSON.parse(item.pictures)[0]} alt="Product" />
                                                                    </Link>

                                                                </figure>

                                                                <div>
                                                                    <h3 className="product-title pl-5" style={{ color: '#c2c2c2' }}>
                                                                        <Link to={`${process.env.PUBLIC_URL}/shop/detail/${item.id}`}>{item.name}</Link>
                                                                    </h3>
                                                                    <br />
                                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                                        <div className="color-display" style={{ backgroundColor: item.color }} />
                                                                    </div>
                                                                    <div className="desktop-hide" style={{ textAlign: 'center', color: '#fff' }}>
                                                                        {currency.currencySymbol}&nbsp;
                                                                        {0 < item.discount ?

                                                                            (item.salePrice * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :

                                                                            (item.price * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })

                                                                        }
                                                                    </div>
                                                                    <div className="all-font-light desktop-hide">{item.size} Yards</div>
                                                                </div>

                                                            </div>
                                                        </td>

                                                        <td className="price-col" style={{ textAlign: 'center' }}>
                                                            <div className='mobile-none'>
                                                                {currency.currencySymbol}&nbsp;
                                                            {0 < item.discount ?

                                                                    (item.salePrice * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :

                                                                    (item.price * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })

                                                                }
                                                            </div>
                                                        </td>

                                                        <td className="price-col" style={{ textAlign: 'center' }}>
                                                            <div className="all-font-light mobile-none">{item.size} Yards</div>
                                                        </td>

                                                        <td className="quantity-col" style={{ justifyContent: 'center' }}>

                                                            <div className="cart-product-quantity" onClick={(e) => this.onChangeQty(e, item)}>
                                                                <input type="number"
                                                                    className="form-control"
                                                                    defaultValue={item.qty
                                                                        // item.qty > item.variants[item.variants.findIndex(stockItem => stockItem.size == item.size)].stock
                                                                        //     ?
                                                                        //     item.variants[item.variants.findIndex(stockItem => stockItem.size == item.size)].stock
                                                                        //     :
                                                                        //     item.qty
                                                                    }
                                                                    min="1"
                                                                    max={JSON.parse(products[products.findIndex((element) => element.id == item.id)].variants)[JSON.parse(products[products.findIndex((element) => element.id == item.id)].variants).findIndex((stockItem) => stockItem.size == item.size)].stock}
                                                                    // max={item.variants[item.variants.findIndex(stockItem => stockItem.size == item.size)].stock}
                                                                    step="1"
                                                                    data-decimals="0"
                                                                    required
                                                                    readOnly
                                                                />
                                                            </div>
                                                            <p className='mb-2' style={{ color: '#f54545' }}>Only <strong>{JSON.parse(products[products.findIndex((element) => element.id == item.id)].variants)[JSON.parse(products[products.findIndex((element) => element.id == item.id)].variants).findIndex((stockItem) => stockItem.size == item.size)].stock}</strong> left!</p>

                                                        </td>

                                                        <td className="total-col" style={{ textAlign: 'center' }}>
                                                            {/* ${item.qty > item.variants[item.variants.findIndex(stockItem => stockItem.size == item.size)].stock
                                                                ?
                                                                (item.variants[item.variants.findIndex(stockItem => stockItem.size == item.size)].stock * item.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                :
                                                                item.sum.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                            } */}
                                                            {currency.currencySymbol}&nbsp;
                                                             {
                                                                (item.sum * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                            }
                                                            {/* <div style={{ display: 'none' }}>
                                                                {totalCart = item.qty > item.variants[item.variants.findIndex(stockItem => stockItem.size == item.size)].stock
                                                                ?
                                                                (item.variants[item.variants.findIndex(stockItem => stockItem.size == item.size)].stock * item.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                :
                                                                item.sum.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                            </div> */}
                                                        </td>

                                                        <td className="remove-col">
                                                            <button className="btn-remove" onClick={(e) => this.removeFromCartHandler(e, item)}><i className="icon-close"></i></button>
                                                        </td>
                                                    </tr>
                                                ) :
                                                <tr>
                                                    <td>
                                                        <p className="pl-2 pt-1 pb-1"> No Products in Cart </p>
                                                    </td>
                                                </tr>
                                            }

                                        </tbody>
                                    </table>
                                    <Link to={`${process.env.PUBLIC_URL}/shop`} className="btn btn-custom"><span>Continue Shopping</span><i className="icon-long-arrow-right"></i></Link>

                                </div>
                                <aside className="col-lg-3" style={{ display: `${cartlist.length == 0 ? 'none' : ''}` }}>
                                    <div className="summary summary-cart all-bg-light">
                                        <h3 className="summary-title all-font-light">Cart Total</h3>
                                        <table className="table table-summary">
                                            <tbody>
                                                <tr className="summary-subtotal">
                                                    <td>Subtotal:</td>
                                                    <td>{currency.currencySymbol}&nbsp;{(total * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                </tr>
                                                {/* <tr className="summary-shipping">
                                                    <td>Shipping:</td>
                                                    <td>&nbsp;</td>
                                                </tr> */}

                                                {/* <tr className="summary-shipping-row">
                                                    <td>
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio"
                                                                id="free-shipping"
                                                                name="shipping"
                                                                className="custom-control-input"
                                                                onChange={(e) => this.onChangeShipping("free")}
                                                                defaultChecked={"free" === this.props.shipping ? true : false}
                                                            />
                                                            <label className="custom-control-label" htmlFor="free-shipping">Free Shipping</label>
                                                        </div>
                                                    </td>
                                                    <td>{currency.currencySymbol}&nbsp;{0.00 * currency.currencyRate}</td>
                                                </tr> */}

                                                {/* <tr className="summary-shipping-row">
                                                    <td>
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio"
                                                                id="standard-shipping"
                                                                name="shipping"
                                                                className="custom-control-input"
                                                                onChange={(e) => this.onChangeShipping("standard")}
                                                                defaultChecked={"standard" === this.props.shipping ? true : false}
                                                            />
                                                            <label className="custom-control-label" htmlFor="standard-shipping">Standard:</label>
                                                        </div>
                                                    </td>
                                                    <td>{currency.currencySymbol}&nbsp;{shippingPrice.standard.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                </tr> */}

                                                {/* <tr className="summary-shipping-row">
                                                    <td>
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio"
                                                                id="express-shipping"
                                                                name="shipping"
                                                                className="custom-control-input"
                                                                onChange={(e) => this.onChangeShipping("express")}
                                                                defaultChecked={"express" === this.props.shipping ? true : false}
                                                            />
                                                            <label className="custom-control-label" htmlFor="express-shipping">Express:</label>
                                                        </div>
                                                    </td>
                                                    <td>{currency.currencySymbol}&nbsp;{(20.00 * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                </tr> */}

                                                {/* <tr className="summary-shipping-estimate">
                                                    <td>Estimate for Your Country<br /> <a href={`${process.env.PUBLIC_URL}/shop/dashboard`}>Change address</a></td>
                                                    <td>&nbsp;</td>
                                                </tr> */}

                                                <tr className="summary-total">
                                                    <td>Total:</td>
                                                    <td style={{ color: '#c96' }}>
                                                        {/* {currency.currencySymbol}&nbsp;{((total * currency.currencyRate) + shippingPrice.standard).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                                                        {currency.currencySymbol}&nbsp;{(total * currency.currencyRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <button type='btn'
                                            className="btn btn-outline-primary-2 btn-order btn-block"
                                            onClick={this.goToCheckout}
                                        >
                                            PROCEED TO CHECKOUT
                                        </button>
                                    </div>

                                    {/* <Link to={`${process.env.PUBLIC_URL}/shop`} className="btn btn-outline-primary-2 btn-order btn-block mb-3"><span>CONTINUE SHOPPING</span><i className="icon-refresh"></i></Link> */}
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    cartlist: state.cartlist.cart,
    total: getCartTotal(state.cartlist.cart),
    shipping: state.cartlist.shipping,
    currency: state.currencyData,
    products: state.data.products ? state.data.products : []
})

export default connect(mapStateToProps, { changetQty, removeFromCart, changeShipping })(Cart);